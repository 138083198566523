import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";

import { ListItemText, ListItem, ListItemIcon } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { List } from "@material-ui/core";
import userStore from "../../../Stores/UserStore";
import InboxIcon from "@material-ui/icons/Inbox";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import businessStore from "../../../Stores/BusinessStore";
import CameraIcon from "@material-ui/icons/Camera";

class BusinessButtons extends Component {
  render() {
    return (
      <List>
        <ListItem
          button
          onClick={() => {
            this.props.history.push(`/business/${userStore.user.business}`);
            businessStore.getSingleBusiness(userStore.user.business);
            this.props.closeDrawer();
          }}
        >
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          <ListItemText primary={"My Company"} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            this.props.closeDrawer();
            this.props.history.push("/openrequests");
          }}
        >
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary={"Open Requests"} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            this.props.closeDrawer();
            this.props.history.push("/quotes");
          }}
        >
          <ListItemIcon>
            <LocalOfferIcon />
          </ListItemIcon>
          <ListItemText primary={"My Quotes"} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            this.props.closeDrawer();
            this.props.history.push("/newpost");
          }}
        >
          <ListItemIcon>
            <CameraIcon />
          </ListItemIcon>
          <ListItemText primary={"New Post"} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            this.props.closeDrawer();
            this.props.history.push("/statistics");
          }}
        >
          <ListItemIcon>
            <TrendingUpIcon />
          </ListItemIcon>
          <ListItemText primary={"Statistics"} />
        </ListItem>
      </List>
    );
  }
}

export default withRouter(observer(BusinessButtons));
