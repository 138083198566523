import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import logo from "../../Media/logo.png";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Image } from "antd";

class BusPostImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      image: null,
      visible: false,
    };
  }
  render() {
    let medias = [];
    if (this.props.medias) {
      medias = this.props.medias;
    }
    if (medias.length === 0) {
      medias.push(logo);
    }
    let cardimages;
    cardimages = medias.map((image, index) => (
      <Image
        key={index}
        minHeight={281.25}
        src={image}
        preview={!this.props.hide}
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          objectFit: "cover",
        }}
      />
    ));
    //<img alt="postpic" src={image} key={index} style={{ height: 200 }} />

    return (
      <div style={{ maxWidth: 500, borderBottom: "1px solid black" }}>
        <Carousel
          autoPlay={this.props.play}
          infiniteLoop={medias.length > 1}
          swipeable
          showIndicators={medias.length > 1}
          showArrows
          useKeyboardArrows
          showThumbs={false}
        >
          {cardimages}
        </Carousel>
      </div>
    );
  }
}

export default withRouter(observer(BusPostImages));
