import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Col, FlexboxGrid, HelpBlock, SelectPicker } from "rsuite";
import StdProdSubTitle from "../../../../Components/StandardInputs/StdProdSubTitle";
import StdProdTitle from "../../../../Components/StandardInputs/StdProdTitle";
import productStore from "../../../../Stores/ProductStore";
import MediaNewProduct from "../Media/MediaNewProduct";
import SampleOption from "../OptionModals.js/SampleOption";

class Step3 extends Component {
  render() {
    let newprod = productStore.newproduct;

    let productTypes = [
      { value: "Product", label: "Product" },
      { value: "Service", label: "Service" },
    ];
    let showtypes = [
      { value: false, label: "Hide" },
      { value: true, label: "Show" },
    ];

    return (
      <div>
        <h6 style={{ textAlign: "center" }}>Media</h6>
        <MediaNewProduct />
      </div>
    );
  }
}

export default withRouter(observer(Step3));
