import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import PaperSTD from "../PaperSTD/PaperSTD";
import ImageParallaxLG from "../ImageParallax/ImageParallaxLG";
class SecondSection extends Component {
  render() {
    let req = this.props.req;

    return (
      <div>
        <PaperSTD title="Request Images">
          <ImageParallaxLG request={req} />
        </PaperSTD>
      </div>
    );
  }
}

export default withRouter(observer(SecondSection));
