import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { Card, CardActionArea, CardContent } from "@material-ui/core";
import { CardMedia, Container, Typography } from "@material-ui/core";
import "react-multi-carousel/lib/styles.css";
import businessStore from "../../Stores/BusinessStore";
import PaperSTD from "../../Components/PaperSTD/PaperSTD";
import ScrollMenu from "react-horizontal-scrolling-menu";
import {
  LeftCircleFilled,
  CaretLeftOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import HorizontalScroll from "../../Components/HorizontalScroll/HorizontalScroll";
import { IconButton, Icon, FlexboxGrid } from "rsuite";
import busPostStore from "../../Stores/BusPostStore";
import SinglePostAntd from "../../Components/SinglePost/SinglePostAntd";
import { Modal } from "antd";
import LoadingParagraph from "../../Components/LoadingPages/LoadingParagraph";

class PostsCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false, post: null };
  }

  openModal(post) {
    this.setState({ open: true, post: post });
  }
  closeModal() {
    this.setState({ open: false, post: null });
  }

  render() {
    let busPosts = this.props.posts;

    let businesses = busPosts.map((post, index) => (
      <SinglePostAntd
        postId={post._id}
        postNumId={post.busPostNumId}
        text={post.description}
        medias={post.medias}
        business={post.business}
        liked={post.liked}
        key={index}
        small
        onClick={() => this.openModal(post)}
      />
    ));

    return (
      <PaperSTD title={"Latest Posts"}>
        <Modal
          visible={this.state.open}
          onCancel={() => this.closeModal()}
          footer={null}
        >
          {this.state.post ? (
            <SinglePostAntd
              postNumId={this.state.post.busPostNumId}
              postId={this.state.post._id}
              text={this.state.post.description}
              medias={this.state.post.medias}
              business={this.state.post.business}
              liked={this.state.post.liked}
            />
          ) : (
            <LoadingParagraph />
          )}
        </Modal>
        <HorizontalScroll data={businesses} />
        <FlexboxGrid justify="center">
          <IconButton
            icon={<Icon icon="arrow-right" />}
            placement="right"
            active
            style={{ boxShadow: "0px 0px 3px black" }}
            onClick={() => {
              if (this.props.business) {
                this.props.history.push(
                  `/feed/business/${this.props.business}`
                );
              } else {
                this.props.history.push("/garageposts");
              }
            }}
          >
            See All Posts
          </IconButton>
        </FlexboxGrid>
      </PaperSTD>
    );
  }
}

export default withRouter(observer(PostsCarousel));
