import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { Col, FlexboxGrid, HelpBlock, InputGroup, Notification } from "rsuite";
import { SelectPicker, Toggle, Tooltip, Button, Whisper } from "rsuite";
import productStore from "../../../Stores/ProductStore";
import moment from "moment";
import StdNumber from "../../../Components/StandardInputs/StdProdNum";
import StdProdTitle from "../../../Components/StandardInputs/StdProdTitle";
import StdProdSubTitle from "../../../Components/StandardInputs/StdProdSubTitle";
import OptionModal from "./OptionModals.js/OptionModal";
import SampleOption from "./OptionModals.js/SampleOption";
import MediaNewProduct from "./Media/MediaNewProduct";
import Step0 from "./Steps/Step0";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import NewProductTimeline from "../../../Components/Timeline/NewProductTimeline";
import ProductSmall from "../../../Components/Product/ProductSmall";
import baseURL from "../../../Media/baseURL";
import axios from "axios";
import userStore from "../../../Stores/UserStore";

const instance = axios.create({ baseURL: baseURL });

class NewProductPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showmodal: false,
      optionindex: 0,
      step: 0,
      loading: false,
      created: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.newOption = this.newOption.bind(this);
  }

  handleCloseNotification(type) {
    Notification.close();
    if (type === "success") {
      Notification[type]({
        title: "Successfully Created Product.",
      });
    }
    if (type === "error") {
      Notification["error"]({
        title: "Error creating the new product.",
        description:
          "An error occured. Please try and resubmit or contact our team for support.",
      });
    }
  }

  dataURItoBlob = async (dataURI) => {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  };

  randomString = async (length) => {
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };
  closeModal() {
    this.setState({ showmodal: false });
  }

  newOption() {
    this.setState({ showmodal: true });
  }

  createNewProduct = async () => {
    Notification["info"]({
      title: "Creating New Product",
      description: "Please wait while the product is created.",
    });
    this.setState({ loading: true });
    let newprod = productStore.newproduct;

    try {
      let blob;
      let media = "";
      let mediaList = [];
      // for (let i = 0; i < newprod.medias.length; i++) {
      //   blob = await this.dataURItoBlob(newprod.medias[i]);
      //   media = "";
      //   let fd = new FormData();
      //   let imagename = this.randomString(10);
      //   fd.append(
      //     "image",
      //     blob,
      //     `${moment().format("DDMMMYYhhmm")}_${Math.round(
      //       Math.random() * 1000000
      //     )}`
      //   );
      //   await instance
      //     .post("api/media/new/upload", fd, {
      //       headers: { authtoken: userStore.token },
      //     })
      //     .then((res) => {
      //       mediaList.push(res.data.fileLocation);
      //     })
      //     .catch((err) => {
      //       Notification["error"]({ title: `An error occured` });
      //     });
      // }

      mediaList = [
        "https://media.zigcdn.com/media/model/2020/Nov/hyundai-i20-2_270x180.jpg",
      ];

      let newproduct = {
        title: newprod.title,
        subtitle: newprod.subtitle,
        titlear: newprod.titlear,
        subtitlear: newprod.subtitlear,
        type: newprod.type,
        price: newprod.price,
        discount: newprod.discount,
        show: newprod.show,
        stockcount: newprod.stockcount,
        stockalarm: newprod.stockalarm,
        options: newprod.options,
        medias: mediaList,
      };

      await instance
        .post("/api/productroute/postnew", newproduct, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          // console.log(res.data);
          // userStore.changeUserCredit(-1);
          // userStore.getUserData();
          console.log(res.data);
          this.setState({
            loading: false,
            created: true,
            step: this.state.step + 1,
          });
          this.handleCloseNotification("success");
        })
        .catch((err) => {
          this.handleCloseNotification("error");
          this.setState({ loading: false });
        });
    } catch (err) {
      Notification["error"]({
        title: "Error creating the new product.",
        description:
          "An error occured. Please try and resubmit or contact our team for support.",
      });
    }
  };

  render() {
    let newprod = productStore.newproduct;
    let errdisable = false;
    let message = "";

    if (this.state.step === 0) {
      if (newprod.title === "") {
        errdisable = true;
        message = "English Product Name Required";
      } else if (newprod.titlear === "") {
        errdisable = true;
        message = "Arabic Product Name Required";
      }
    } else if (this.state.step === 1) {
      if (newprod.price === "") {
        errdisable = true;
        message = "Enter a valid number for Price";
      } else if (newprod.stockcount === "") {
        errdisable = true;
        message = "Enter a valid number for Stock Count";
      } else if (newprod.stockalarm === "") {
        errdisable = true;
        message = "Enter a valid number for Stock Count Alarm";
      } else if (newprod.discount.show & (newprod.discount.price === "")) {
        errdisable = true;
        message = "Enter a valid number for the Discount Price";
      }
    } else if (this.state.step === 3) {
      if (newprod.medias.length <= 0) {
        errdisable = true;
        message = "Please add at least 1 Image to proceed.";
      }
    }

    if (this.state.loading) {
      errdisable = true;
    }

    return (
      <div
        style={{
          boxShadow: "0px 0px 5px black",
          borderRadius: 3,
          margin: "1%",
          padding: "1%",
        }}
      >
        <OptionModal
          show={this.state.showmodal}
          index={this.state.index}
          onCancel={this.closeModal}
        />
        <NewProductTimeline activeStep={this.state.step} />
        <FlexboxGrid>
          <Col xs={12}>
            {this.state.step > 0 && (
              <Button
                block
                disabled={this.state.loading}
                appearance="ghost"
                onClick={() => this.setState({ step: this.state.step - 1 })}
              >
                Back
              </Button>
            )}
          </Col>
          <Col xs={12}>
            <Button
              block
              color="green"
              loading={this.state.loading}
              disabled={errdisable}
              onClick={() => {
                if (this.state.step === 4) {
                  this.createNewProduct();
                } else {
                  this.setState({ step: this.state.step + 1 });
                }
              }}
            >
              {this.state.step === 4 ? "Submit" : "Next"}
            </Button>
            <HelpBlock style={{ color: "red" }}>{message}</HelpBlock>
          </Col>
        </FlexboxGrid>
        <hr />
        {this.state.step === 0 && <Step0 />}
        {this.state.step === 1 && <Step1 />}
        {this.state.step === 2 && <Step2 newOption={this.newOption} />}
        {this.state.step === 3 && <Step3 />}
        <FlexboxGrid>
          {this.state.step === 4 && (
            <ProductSmall
              product={newprod}
              title={newprod.title}
              subtitle={newprod.subtitle}
            />
          )}
          {this.state.step === 4 && (
            <ProductSmall
              product={newprod}
              title={newprod.titlear}
              subtitle={newprod.subtitlear}
            />
          )}
        </FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(NewProductPage));
