import { Empty } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Button, Col, FlexboxGrid, HelpBlock, SelectPicker } from "rsuite";
import StdProdSubTitle from "../../../../Components/StandardInputs/StdProdSubTitle";
import StdProdTitle from "../../../../Components/StandardInputs/StdProdTitle";
import productStore from "../../../../Stores/ProductStore";
import SampleOption from "../OptionModals.js/SampleOption";

class Step2 extends Component {
  render() {
    let newprod = productStore.newproduct;

    let productTypes = [
      { value: "Product", label: "Product" },
      { value: "Service", label: "Service" },
    ];
    let showtypes = [
      { value: false, label: "Hide" },
      { value: true, label: "Show" },
    ];

    return (
      <div>
        <h6 style={{ textAlign: "center" }}>Options</h6>
        {newprod.options.map((option, index) => (
          <SampleOption option={option} key={index} />
        ))}
        {newprod.options.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Options Added"
          />
        )}

        <Button
          block
          color="green"
          appearance="ghost"
          onClick={() => this.props.newOption()}
        >
          Add Option
        </Button>
      </div>
    );
  }
}

export default withRouter(observer(Step2));
