import React, { Component } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Container,
  Typography,
} from "@material-ui/core";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import requestStore from "../../Stores/RequestStore";
import PaperSTD from "../../Components/PaperSTD/PaperSTD";

class RejectedSection extends Component {
  render() {
    let rejectioncards = requestStore.rejectedRequests.map((request, index) => (
      <Card style={{ margin: 10, boxShadow: "0px 0px 10px black" }} key={index}>
        <CardActionArea
          onClick={() => {
            this.props.history.push(`/modifynewrequest/${request.request._id}`);
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="h6" component="h4">
              {request.request.service.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {request.request.description}
            </Typography>
            <Typography variant="body2" color="secondary" component="p">
              <strong>Modification Feedback:</strong>{" "}
              {request.request.adminfeedback}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    ));
    return (
      <div>
        <br />
        <Container>
          <PaperSTD
            title={`${requestStore.rejectedRequests.length} Requests to Modify`}
          >
            {rejectioncards}
          </PaperSTD>
        </Container>
        <hr />
      </div>
    );
  }
}

export default withRouter(observer(RejectedSection));
