import { makeObservable, observable } from "mobx";
import axios from "axios";
import userStore from "./UserStore";
import baseURL from "../Media/baseURL";
import { message } from "antd";
import moment from "moment";

const instance = axios.create({ baseURL: baseURL });

class ProductStore {
  constructor() {
    this.allproducts = [];
    this.loading = false;
    this.newproduct = {
      title: "",
      subtitle: "",
      titlear: "",
      subtitlear: "",
      type: "Product",
      price: 1.0,
      discount: { show: false, price: 0 },
      show: false,
      stockcount: 0,
      stockalarm: 0,
      medias: [],
      options: [],
    };
    this.newoption = {
      type: "Single",
      title: "",
      titlear: "",
      items: [{ title: "", titlear: "", price: 0 }],
      min: 0,
      max: 1,
    };

    makeObservable(this, {
      allproducts: observable,
      newproduct: observable,
      newoption: observable,
      loading: observable,
    });
  }

  resetAllProducts() {
    this.allproducts = [];
    this.newproduct = {
      title: "",
      subtitle: "",
      titlear: "",
      subtitlear: "",
      type: "Product",
      price: 1.0,
      discount: { show: false, price: 0 },
      show: false,
      stockcount: 0,
      stockalarm: 0,
      medias: [],
      options: [],
    };
    this.resetOption();
  }

  resetOption() {
    this.newoption = {
      type: "Single",
      title: "",
      titlear: "",
      min: 0,
      max: 1,
      items: [{ title: "", titlear: "", price: 0 }],
    };
  }

  updateNewOption(type, val) {
    if (type === "min" || type === "max") {
      if (val === "") {
        val = 0;
      } else {
        val = parseFloat(val);
      }
      if (val > this.newoption.items.length) val = this.newoption.items.length;
    }
    if ((type === "min") & (val > this.newoption.max)) {
      this.newoption.max = val;
    } else if ((type === "max") & (val < this.newoption.min)) {
      this.newoption.min = val;
    }
    this.newoption[type] = val;
  }
  saveNewOption() {
    this.newproduct.options.push({ ...this.newoption });
  }

  updateOptionItem(type, val, index) {
    this.newoption.items[index][type] = val;
  }
  addNewOptionItem() {
    this.newoption.items.push({ title: "", titlear: "", price: 0 });
  }

  addNewImage(image) {
    this.newproduct.medias.push(image);
  }

  removeNewImage(image) {
    let files = [...this.newproduct.medias];
    let final = files.filter((file) => file !== image);
    this.newproduct.medias = final;
  }

  removeOptionItem(index) {
    let finalitems = [];
    for (let i = 0; i < this.newoption.items.length; i++) {
      if (i !== index) finalitems.push({ ...this.newoption.items[i] });
    }
    this.newoption.items = [...finalitems];
  }

  updateNewProduct(type, val) {
    if ((type === "price") & (val !== "")) val = parseFloat(val);
    if ((type === "discountprice") & (val !== "")) val = parseFloat(val);
    if ((type === "stockcount") & (val !== "")) val = parseFloat(val);
    if ((type === "stockalarm") & (val !== "")) val = parseFloat(val);
    if (type === "discountprice") {
      if (this.newproduct.price <= val) val = this.newproduct.price - 0.1;
      this.newproduct.discount.price = val;
    } else if (type === "discountshow") {
      this.newproduct.discount.show = val;
    } else {
      if (
        (type === "price") &
        this.newproduct.discount.show &
        (val < this.newproduct.discount.price)
      ) {
        this.newproduct.discount.price = val;
      }
      this.newproduct[type] = val;
    }
  }

  addremoveProdOption(type, index) {
    if (type === "remove") {
      let newoptions = [];
      for (let i = 0; i < this.newproduct.options.length; i++) {
        if (i !== index) {
          newoptions.push({ ...this.newproduct.options[i] });
        }
      }
      this.newproduct.options = [...newoptions];
    }
  }
}

const productStore = new ProductStore();
export default productStore;
