import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import { message } from "antd";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import userStore from "../../../Stores/UserStore";
import baseURL from "../../../Media/baseURL";
import { Button } from "rsuite";

const instance = axios.create({ baseURL: baseURL });

class PasswordEditBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      changed: false,
      showPassword: false,
      showPasswordConf: false,
      validation: false,
    };
  }

  updateUserInfo = async () => {
    this.setState({ validation: true });
    if (this.state.changed) {
      message.info("Information Already Updated");
    } else {
      try {
        // console.log("Test");
        this.setState({ loading: true });
        let requestData = { newpass: userStore.editprofileinfo.password };

        let newrequest = await instance
          .put("/auth/changepass", requestData, {
            headers: { authtoken: userStore.token },
          })
          .then((res) => {
            // console.log(res);
            userStore.changeProfileInfo("password", "");
            userStore.changeProfileInfo("passconfirm", "");
            this.setState({ loading: false, changed: true, validation: false });
            message.success("Successfully Updated User Password.");
          })
          .catch((err) => {
            console.error(err.response);
            this.setState({ loading: false, sent: false });
          });
      } catch (err) {
        console.log(err.response);
      }
    }
  };

  render() {
    let disabledSubmit = false;
    let user = userStore.editprofileinfo;

    let passerror = { val: false, message: "" };
    let passconferror = { val: false, message: "" };

    if (user.password.length === 0 || user.passconfirm.length === 0) {
      disabledSubmit = true;
    } else {
      if (user.password.length < 6) {
        passerror.val = true;
        passerror.message = "Password must be greater than 6 characters.";
        disabledSubmit = true;
      }
      if (user.passconfirm !== user.password) {
        passconferror.val = true;
        passconferror.message = "Passwords do not match.";
        disabledSubmit = true;
      }
    }

    return (
      <div style={{ flex: "wrap", justifyContent: "center" }}>
        <TextField
          error={passerror.val}
          helperText={passerror.val && passerror.message}
          variant="standard"
          margin="normal"
          fullWidth
          disabled={this.state.loading}
          name="password"
          label="Password"
          type={this.state.showPassword ? "text" : "password"}
          id="password"
          autoComplete="current-password"
          value={user.password}
          style={{ background: "rgb(255,255,255,0)" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  tabindex="-1"
                  aria-label="toggle password visibility"
                  onClick={() =>
                    this.setState({ showPassword: !this.state.showPassword })
                  }
                >
                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) =>
            userStore.changeProfileInfo("password", e.target.value)
          }
        />
        <TextField
          error={passconferror.val}
          helperText={passconferror.val && passconferror.message}
          variant="standard"
          margin="normal"
          fullWidth
          disabled={this.state.loading}
          name="passwordconf"
          label="Confirm Password"
          type={this.state.showPasswordConf ? "text" : "password"}
          id="passwordconf"
          autoComplete="current-passwordconf"
          value={user.passconfirm}
          style={{ background: "rgb(255,255,255,0)" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" tabindex="-1">
                <IconButton
                  tabindex="-1"
                  aria-label="toggle password visibility"
                  onClick={() =>
                    this.setState({
                      showPasswordConf: !this.state.showPasswordConf,
                    })
                  }
                >
                  {this.state.showPasswordConf ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) =>
            userStore.changeProfileInfo("passconfirm", e.target.value)
          }
        />
        <hr />
        <Button
          block
          color="green"
          style={{ boxShadow: "0px 0px 3px black" }}
          disabled={disabledSubmit}
          onClick={() => {
            this.updateUserInfo();
          }}
        >
          {this.state.loading ? "Loading ..." : "Update Password"}
        </Button>
      </div>
    );
  }
}

export default withRouter(observer(PasswordEditBlock));
