import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import userStore from "../../Stores/UserStore";
import { Result } from "antd";
import { Button } from "rsuite";

class AccountVerificationPage extends Component {
  componentDidMount() {
    userStore.confirmVerification(this.props.match.params.verificationcode);
  }
  render() {
    let subtitle = "Please click on the below link to Sign In.";
    let extra = [
      <Button color="green" onClick={() => this.props.history.push("/auth")}>
        Sign In
      </Button>,
    ];
    if (userStore.signedIn) {
      subtitle = "Please click on the below link to go to your dashboard.";
      extra = [
        <Button color="green" onClick={() => this.props.history.push("/")}>
          Go to Dashboard
        </Button>,
      ];
    }

    return (
      <Result
        status="success"
        title="Your Account has been Verified"
        subTitle={subtitle}
        extra={extra}
      />
    );
  }
}

export default withRouter(observer(AccountVerificationPage));
