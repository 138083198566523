import { Container } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import LoadingParagraph from "../LoadingPages/LoadingParagraph";
import busPostStore from "../../Stores/BusPostStore";
import baseURL from "../../Media/baseURL";

const instance = axios.create({ baseURL: baseURL });

class OnePostPageRedirect extends Component {
  componentDidMount() {
    try {
      let data = instance
        .get(
          `/api/businesspost/singlenumid/${this.props.match.params.postNumId}/`
        )
        .then((res) => res.data)
        .then((requests) => {
          console.log(requests);
          this.props.history.push(`/garagepost/${requests._id}`);
        })

        .catch((err) => {
          this.props.history.push("/");
        });
    } catch (err) {
      this.props.history.push("/");
    }
  }

  render() {
    if (busPostStore.loading) {
      return <LoadingParagraph />;
    }
    return <div style={{ minHeight: "100vh" }} />;
  }
}
export default withRouter(observer(OnePostPageRedirect));
