import React, { Component } from "react";
import { Comment, Tooltip, Avatar } from "antd";
import userStore from "../../../Stores/UserStore";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import moment from "moment";
import { Col, FlexboxGrid } from "rsuite";
import CheckIcon from "@material-ui/icons/Check";
import DoneAllIcon from "@material-ui/icons/DoneAll";

class LetterCard extends Component {
  render() {
    let letter = this.props.letter;
    let type = "Received";
    let avatar;
    let name;

    let singlecheckicon = <CheckIcon fontSize="small" />;
    if (letter.sender !== userStore.user.group) {
      singlecheckicon = <DoneAllIcon fontSize="small" />;
    }

    if (letter.sender === "Customer") {
      name = (
        <div>
          {letter.seenreceiver ? (
            <DoneAllIcon fontSize="small" />
          ) : (
            singlecheckicon
          )}{" "}
          {letter.user ? letter.user.name : "Old User"}
        </div>
      );
      avatar = (
        <Avatar style={{ boxShadow: "0px 0px 5px black" }}>
          {letter.user ? letter.user.name.substring(0, 1) : "?"}
        </Avatar>
      );
      if (letter.user) {
        if (userStore.user._id === letter.user._id) {
          type = "Sent";
        }
      }
    } else {
      name = letter.business.name;
      name = (
        <div>
          {letter.seenreceiver ? (
            <DoneAllIcon fontSize="small" />
          ) : (
            singlecheckicon
          )}{" "}
          {letter.business.name}
        </div>
      );
      avatar = (
        <Avatar
          src={letter.business.logo}
          alt={letter.business.name}
          style={{ boxShadow: "0px 0px 5px black" }}
        />
      );
      if (userStore.user.business === letter.business._id) {
        type = "Sent";
      }
    }

    return (
      <FlexboxGrid>
        {type === "Sent" && <Col xs={4} />}
        <Col xs={20}>
          <Comment
            style={{
              boxShadow: "0px 0px 2px black",
              borderRadius: 10,
              paddingLeft: 10,
              paddingRight: 10,
              marginTop: 5,
            }}
            author={name}
            avatar={avatar}
            content={<p>{letter.content}</p>}
            datetime={
              <Tooltip
                title={moment(letter.createdAt).format("DD-MMM-YY HH:mm")}
              >
                <span>{moment(letter.createdAt).fromNow()}</span>
              </Tooltip>
            }
          />
        </Col>
        {type === "Received" && <Col xs={4} />}
      </FlexboxGrid>
    );
  }
}

export default withRouter(observer(LetterCard));
