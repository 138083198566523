import { Button, Card, CardActionArea, CardActions } from "@material-ui/core";
import { CardMedia } from "@material-ui/core";
import React, { Component } from "react";
import requestStore from "../../../../Stores/RequestStore";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Image } from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";

class ImageCard extends Component {
  render() {
    let photo = this.props.photo;
    let photosrc = photo;
    if (photo.src) photosrc = photo.src;
    let hideButton = this.props.hideButton;

    return (
      <div style={{ padding: 10 }}>
        <Card style={{ maxWidth: 150 }} elevation={5}>
          <Image src={photosrc} />
          {!hideButton && (
            <CardActions>
              <Button
                size="small"
                color="secondary"
                style={{ textAlign: "center" }}
                onClick={() => {
                  requestStore.deleteImage(photo);
                }}
              >
                Delete
              </Button>
            </CardActions>
          )}
        </Card>
      </div>
    );
  }
}

export default withRouter(observer(ImageCard));
