import React, { Component } from "react";
import { observer } from "mobx-react";
import { Button, Col, Modal, FlexboxGrid } from "rsuite";
import { withRouter } from "react-router";
import SelectQuoteCard from "./SelectQuoteCard";
import userStore from "../../../Stores/UserStore";
import baseURL from "../../../Media/baseURL";
import axios from "axios";
import UnifiedModal from "../../../Components/UnifiedModal/UnifiedModal";
import { Notification } from "rsuite";

const instance = axios.create({ baseURL: baseURL });

class ConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  postRequestUpdate = async (quote) => {
    try {
      this.setState({ loading: true });
      let finalrequest = {
        status: "Approved",
        approved: quote._id,
      };
      let newrequest = await instance
        .put(`/api/request/update/${quote.request}`, finalrequest, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          userStore.getUserData();
          this.setState({ loading: false });
          Notification["success"]({
            title: "Quote Selected and Request Closed",
            duration: 0,
          });
          this.props.history.push("/dashboard");
        })
        .catch((err) => {
          this.setState({ loading: false });
          Notification["error"]({
            title: "An error occured",
            description:
              "An error occured and the quote selection was not saved. Please try again or contact our office if the issue persists.",
            duration: 0,
          });
        });
    } catch (err) {
      alert("Error in Creating Request");
    }
  };

  render() {
    let quote = this.props.quote;

    return (
      <UnifiedModal
        title={"Are you sure you want to proceed with this Quote?"}
        show={this.props.show}
        onHide={this.props.onHide}
        footer={
          <FlexboxGrid>
            <Col xs={12}>
              <Button
                block
                color="red"
                onClick={() => this.props.alternate()}
                disabled={this.state.loading}
              >
                No, Go Back
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                block
                color="green"
                disabled={this.state.loading}
                onClick={() => this.postRequestUpdate(quote)}
              >
                {this.state.loading
                  ? "Loading ..."
                  : "Yes, Close Request and Select"}
              </Button>
            </Col>
          </FlexboxGrid>
        }
      >
        {quote && <SelectQuoteCard quote={quote} disableclick={true} />}
        <hr />
        <br />
        <h6>
          <strong>
            By confirming the quote, the request will be closed and no other
            companies can quote.
          </strong>
          <br />
          <br />
          The selected company will also receive your information and contact
          you to arrange implementing the requested job.
        </h6>
      </UnifiedModal>
    );
  }
}

export default withRouter(observer(ConfirmationModal));
