import { makeObservable, observable, computed } from "mobx";
import axios from "axios";
import userStore from "./UserStore";
import baseURL from "../Media/baseURL";

const instance = axios.create({ baseURL: baseURL });

class RequestSummaryStore {
  constructor() {
    this.basicrequest = { service: { title: "" }, user: { _id: null } };
    this.basicquotes = [];
    this.allquotes = [];
    this.loading = false;
    this.userType = "Guest";

    makeObservable(this, {
      basicrequest: observable,
      basicquotes: observable,
      allquotes: observable,
      loading: observable,
      userType: observable,
      myquotes: computed,
      otherquotes: computed,
    });
  }

  closeLoading = async () => {
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve("resolved");
      }, 0);
    });
    this.loading = false;
  };

  fetchSingleRequest(id) {
    this.loading = true;
    return instance
      .get(`/api/request/uniquerequest/${id}/`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((requests) => {
        this.basicrequest = requests.request;
        this.basicquotes = requests.quote;
        this.fetchAllQuotes();

        this.checkAccess();
        console.log("Single Request Fetched");
      })
      .catch((err) => {
        console.log(err);
        console.error(err.response);
        this.closeLoading();
        alert("Error Fetching Single Requests");
      });
  }

  checkAccess() {
    if (userStore.signedIn) {
      if (userStore.userAccess === 4) {
        this.userType = "Business";
      } else {
        if (userStore.user._id === this.basicrequest.user._id) {
          this.userType = "Creator";
        } else {
          this.userType = "Client";
          this.closeLoading();
        }
      }
    } else {
      this.closeLoading();
    }
  }

  fetchDetailedQuotes() {
    this.allquotes = [];
    this.closeLoading();

    console.log("Detailed Quotes Fetched");
  }
  fetchAllQuotes() {
    this.allquotes = [];
    console.log("####### HELLO");
    return instance
      .get(`/api/quote/byrequest/${this.basicrequest._id}/`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((quotes) => {
        this.allquotes = quotes;
        console.log(this.allquotes);
        this.closeLoading();
        console.log("All Quotes Fetched");
      })
      .catch((err) => {
        console.log(err);
        console.error(err.response);
        this.closeLoading();
        alert("Error Fetching All Quotes");
      });
  }

  get myquotes() {
    return this.allquotes.filter(
      (quote) => quote.business._id === userStore.user.business
    );
  }
  get otherquotes() {
    return this.allquotes.filter(
      (quote) => quote.business._id !== userStore.user.business
    );
  }
}

const requestSummaryStore = new RequestSummaryStore();
export default requestSummaryStore;
