import { makeObservable, observable, computed } from "mobx";
import axios from "axios";
import userStore from "./UserStore";
import baseURL from "../Media/baseURL";

const instance = axios.create({ baseURL: baseURL });

class QuoteStore {
  constructor() {
    this.requests = [];
    this.submitted = [];
    this.quotes = [];
    this.loading = false;
    this.filtered = true;
    this.business = { service: [] };
    this.services = [];
    this.selectedrequest = {
      request: { closeDate: new Date(), service: { title: "" } },
      quote: [],
      quotecount: 0,
      allquotes: [],
      submittedquote: {},
    };
    this.newquote = {
      remarks: "",
      excludes: "",
      price: 0,
      duration: 0,
      status: "Submitted",
    };
    makeObservable(this, {
      requests: observable,
      submitted: observable,
      quotes: observable,
      loading: observable,
      filtered: observable,
      business: observable,
      services: observable,
      newquote: observable,
      selectedrequest: observable,
      filteredrequests: computed,
    });
  }

  closeLoading = async () => {
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve("resolved");
      }, 0);
    });
    this.loading = false;
  };

  fetchOpenRequests() {
    return instance
      .get(`/api/busrequest/openpending`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.requests = res.data.open;
        this.submitted = res.data.submitted;
        console.log("Open Requests Fetched");
      })
      .catch((err) => {
        console.log(err);
        console.error(err.response);
        alert("Error Fetching User Requests");
      });
  }

  fetchQuotes() {
    this.loading = true;
    return instance
      .get(`/api/quote/byuser`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.quotes = res.data;
        this.closeLoading();
        console.log("Past Quotes Fetched");
      })
      .catch((err) => {
        this.closeLoading();
        console.log(err);
        console.error(err.response);
        alert("Error Fetching User Requests");
      });
  }

  fetchBusiness() {
    return instance
      .get(`/api/business/selected/${userStore.user.business}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.business = res.data;

        console.log("Business Fetched");
      })
      .catch((err) => {
        console.log(err);
        console.error(err.response);
        alert("Error Fetching User Requests");
      });
  }

  fetchSingleRequest(id) {
    this.selectedrequest = {
      request: { closeDate: new Date(), service: { title: "" } },
      quote: [],
      quotecount: 0,
      allquotes: [],
      submittedquote: {},
    };
    this.loading = true;
    return instance
      .get(`/api/busrequest/requestbyid/${id}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.selectedrequest = res.data;

        this.closeLoading();
        console.log("Single Request Fetched");
      })
      .catch((err) => {
        console.log(err);
        this.closeLoading();
        console.error(err.response);
        alert("Error Fetching User Requests");
      });
  }

  changefilter(val) {
    this.filtered = val;
  }

  get filteredrequests() {
    let rqst = this.requests;
    if (!this.filtered) {
      return rqst;
    }
    let finalrqst = [];
    let indexVal;
    for (let i = 0; i < rqst.length; i++) {
      indexVal = this.business.services.findIndex(
        (service) => service.title === rqst[i].request.service.title
      );
      if (indexVal >= 0) {
        finalrqst.push(rqst[i]);
      }
    }
    return finalrqst;
  }

  createNewQuote(requestId) {
    this.newquote = {
      remarks: "",
      excludes: "",
      price: 0,
      duration: 0,
      status: "Submitted",
      user: userStore.user._id,
      business: userStore.user.business,
      request: requestId,
    };
  }
  selectQuote(quote) {
    this.newquote = { ...quote };
  }

  editNewQuote(type, val) {
    if (type === "price" || type === "duration") {
      if (val < 0) val = 0;
      if (type === "price") val = Math.round(1000 * val) / 1000;
      if (type === "duration") val = Math.round(10 * val) / 10;
      if ((type === "duration") & (val > 999)) val = 999;
    }

    this.newquote[type] = val;
  }
}

const quoteStore = new QuoteStore();
export default quoteStore;
