import React, { Component } from "react";
import { observer } from "mobx-react";
import { Button, Col, Modal, FlexboxGrid } from "rsuite";
import { withRouter } from "react-router";
import requestStore from "../../../Stores/RequestStore";
import moment from "moment";
import SelectQuoteCard from "./SelectQuoteCard";
import requestSummaryStore from "../../../Stores/RequestSummaryStore";
import UnifiedModal from "../../../Components/UnifiedModal/UnifiedModal";

class SelectQuoteModal extends Component {
  render() {
    let selectedQuote = this.props.selectedQuote;
    let editreq = requestStore.editrequest;
    let quotes = requestSummaryStore.allquotes;

    let cheapest = { value: 9999999, id: "" };
    let fastest = { value: 9999999, id: "" };

    let quotetotals = { price: 0, duration: 0, count: 0 };
    for (let i = 0; i < quotes.length; i++) {
      quotetotals.count += 1;
      quotetotals.price += quotes[i].price;
      quotetotals.duration += quotes[i].duration;
      if (quotes[i].price < cheapest.value)
        cheapest = { value: quotes[i].price, id: quotes[i]._id };
      if (quotes[i].duration < fastest.value)
        fastest = { value: quotes[i].duration, id: quotes[i]._id };
    }

    let finalButton;

    if (selectedQuote) {
      finalButton = (
        <div>
          <Button
            block
            appearance="ghost"
            onClick={() => this.props.select(null)}
          >
            Clear Selection
          </Button>
          <Button block color="green" onClick={() => this.props.alternate()}>
            Select Quote and Close Request
          </Button>
        </div>
      );
    } else {
      finalButton = (
        <Button block appearance="ghost" color="green">
          Select a Quote from Above
        </Button>
      );
    }

    return (
      <UnifiedModal
        title={`${editreq.service.title} Request Details:`}
        show={this.props.show}
        onHide={this.props.onHide}
        footer={
          <Button
            block
            appearance="ghost"
            color="red"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </Button>
        }
      >
        <div style={{ textAlign: "center" }}>
          <h5>
            <strong>Close Date: </strong>
            {moment(editreq.closedate).format("DD-MMM-YY")} (
            {moment(editreq.closedate).fromNow()})
          </h5>
          <FlexboxGrid>
            <Col xs={12}>
              <h5>
                <strong>Average Price: </strong>
                {Math.round((1000 * quotetotals.price) / quotetotals.count) /
                  1000}{" "}
                KD
              </h5>
            </Col>
            <Col xs={12}>
              <h5>
                <strong>Average Duration: </strong>
                {Math.round((10 * quotetotals.duration) / quotetotals.count) /
                  10}{" "}
                hrs
              </h5>
            </Col>
          </FlexboxGrid>
        </div>
        <hr />
        <h5>Select a Quote</h5>
        {quotes.map((quote, index) => (
          <SelectQuoteCard
            key={index}
            quote={quote}
            select={this.props.select}
            selected={selectedQuote === quote}
            cheapest={quote._id === cheapest.id}
            fastest={quote._id === fastest.id}
          />
        ))}
        <br />
        {finalButton}
      </UnifiedModal>
    );
  }
}

export default withRouter(observer(SelectQuoteModal));
