import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { Container } from "@material-ui/core";
import RequestCard from "./RequestCard";
import quoteStore from "../../../Stores/QuoteStore";
import "react-multi-carousel/lib/styles.css";
import RequestModal from "./RequestModal";
import { Button, Col, Row, Grid } from "rsuite";
import PaperSTD from "../../../Components/PaperSTD/PaperSTD";

class RequestList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  componentDidMount() {
    quoteStore.fetchOpenRequests();
    quoteStore.fetchBusiness();
  }

  closeModal = () => {
    this.setState({ showModal: false });
  };
  openModal = () => {
    this.setState({ showModal: true });
  };

  render() {
    let requests = quoteStore.filteredrequests.map((req, index) => (
      <RequestCard request={req} key={index} openModal={this.openModal} />
    ));
    let submitted = quoteStore.submitted.map((req, index) => (
      <RequestCard request={req} key={index} openModal={this.openModal} />
    ));

    return (
      <Container>
        <RequestModal show={this.state.showModal} onHide={this.closeModal} />
        <br />
        <Container>
          <Grid>
            <Col xs={24} sm={12}>
              <Button
                block
                appearance={quoteStore.filtered ? "primary" : "ghost"}
                onClick={() => quoteStore.changefilter(true)}
              >
                Selected Services
              </Button>
            </Col>
            <Col xs={24} sm={12}>
              <Button
                block
                appearance={!quoteStore.filtered ? "primary" : "ghost"}
                onClick={() => quoteStore.changefilter(false)}
              >
                All Requests
              </Button>
            </Col>
          </Grid>
        </Container>
        <br />
        <PaperSTD
          title={`${quoteStore.filteredrequests.length} Open Requests ${
            !quoteStore.filtered ? "(All)" : ""
          }`}
        >
          <Row style={{ flex: "wrap", justifyContent: "center" }}>
            {requests}
          </Row>
        </PaperSTD>
        <br />
        <PaperSTD title={`${quoteStore.submitted.length} Submitted`}>
          <Row style={{ flex: "wrap", justifyContent: "center" }}>
            {submitted}
          </Row>
        </PaperSTD>
      </Container>
    );
  }
}

export default withRouter(observer(RequestList));
