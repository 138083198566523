import React, { Component } from "react";
import { Collapse } from "antd";
import { Container } from "@material-ui/core";

const { Panel } = Collapse;

class BusinessFAQs extends Component {
  render() {
    let data = [
      {
        question: "What is Garage Arabia?",
        answer:
          "Garage Arabia is a quoting based marketplace that connects customers to Garages.",
      },
      {
        question: "Can any garage join Garage Arabia?",
        answer:
          "To ensure the quality of services for our customers, there is an application process where our team will ensure a standard level of service and customer care. Through this prrocess our team ensures that the highest quality garages are selected",
      },
      {
        question: "Is Garage Arabia only for garages?",
        answer:
          "No, any car related service is accepted and we are continuously expanding our services scope. If you have a unique service, our marketing and development team can integrarte it into our scope.",
      },
      {
        question: "Does Garage Arabia share customer data with Garages?",
        answer:
          "No, Garage Arabia does not collect sensitive information from our users. Garage Arabia only provides the name, email, and phone number of the user once a garage is approved. The information is only shared with the awarded Garage for the purpose of following up with the awarded request. Without an awarded Job, the user remains anonymous.",
      },
      {
        question: "Will Garage Arabia increase my sales?",
        answer:
          "Garage Arabia provides a platform and marketing for customers to find the most competitive service quote amongst businesses. There is a platform on the website that allows the customer communicate directly with the Garage, but sales would depend on the company submitting comptetive quotes.",
      },
    ];

    let panels = data.map((data, index) => (
      <Panel header={data.question} key={index + 1}>
        <p>{data.answer}</p>
      </Panel>
    ));
    return (
      <Container>
        <Collapse accordion onChange={(e) => console.log(e)}>
          {panels}
        </Collapse>
      </Container>
    );
  }
}

export default BusinessFAQs;
