import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { HelpBlock, InputGroup, InputNumber } from "rsuite";

class StdProdNum extends Component {
  render() {
    let title = this.props.title;
    let value = this.props.value;
    return (
      <div>
        <HelpBlock style={{ fontWeight: "bold", textAlign: "center" }}>
          {title}
        </HelpBlock>
        <InputGroup>
          <InputGroup.Button
            onClick={() => this.props.sub()}
            color="red"
            disabled={value < 1}
          >
            <strong>-</strong>
          </InputGroup.Button>
          <InputNumber
            max={10000}
            min={0}
            value={value}
            onChange={this.props.change}
          />
          <InputGroup.Button
            onClick={() => this.props.add()}
            color="green"
            disabled={value > 9999}
          >
            <strong>+</strong>
          </InputGroup.Button>
        </InputGroup>
      </div>
    );
  }
}

export default withRouter(observer(StdProdNum));
