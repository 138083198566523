import { Result } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Button } from "rsuite";
import { withRouter } from "react-router";

class NoCredit extends Component {
  render() {
    return (
      <div>
        <Result
          title="Out of Credit"
          subTitle="Kindly note that you are out of credit. To increase credit you can click on the below button to refer a friend by email and increase your credit. For every new referral you wil get 2 credits."
        />
        <Button
          block
          color="green"
          onClick={() => this.props.history.push("/referafriend")}
        >
          Refer A Friend
        </Button>
      </div>
    );
  }
}

export default withRouter(observer(NoCredit));
