import React, { Component } from "react";
import "leaflet/dist/leaflet.css";
import "antd/dist/antd.css";
import "rsuite/dist/styles/rsuite-default.css";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";

class CDNlinks extends Component {
  render() {
    return (
      <div>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@900&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        {/* <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
          integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
          crossOrigin="anonymous"
        /> */}
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <script
          src="https://unpkg.com/react@17/umd/react.production.min.js"
          crossOrigin=""
        ></script>
        <script
          src="https://unpkg.com/react-dom@17/umd/react-dom.production.min.js"
          crossOrigin=""
        ></script>
        <script src="https://cdn.jsdelivr.net/npm/apexcharts"></script>
        <script src="https://cdn.jsdelivr.net/npm/react-apexcharts"></script>
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
        />
        <script src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"></script>
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
          integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
          crossOrigin=""
        />
        <script
          type="text/javascript"
          src="https://cdn.jsdelivr.net/npm/emailjs-com@2/dist/email.min.js"
        />
        {/* <!-- Jarallax --> */}
        <script src="https://unpkg.com/jarallax@1/dist/jarallax.min.js"></script>

        {/* <!-- Include it if you want to use Video parallax --> */}
        <script src="https://unpkg.com/jarallax@1/dist/jarallax-video.min.js"></script>
      </div>
    );
  }
}

export default CDNlinks;
