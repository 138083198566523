import React, { Component } from "react";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Button from "@material-ui/core/Button";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import grey from "@material-ui/core/colors/grey";
import ImageCard from "./ImageCard";
import { FlexboxGrid } from "rsuite";
import carStore from "../../../../Stores/CarStore";
import Compress from "compress.js";

const compress = new Compress();

class CarPics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    carStore.addPhotos(event);
  }

  render() {
    let car = carStore.editcar;

    let imageCards = car.medias.map((file, index) => (
      <ImageCard key={index} index={index} photo={file} />
    ));
    return (
      <div>
        <input
          accept="image/*"
          id="contained-button-file"
          multiple
          type="file"
          hidden
          onChange={this.handleChange}
        />
        <label htmlFor="contained-button-file">
          <Button
            fullWidth
            variant="contained"
            color={grey[900]}
            component="span"
          >
            <PhotoCamera /> - Upload Images
          </Button>
        </label>
        <FlexboxGrid>{imageCards}</FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(CarPics));
