import { Container } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import LoadingParagraph from "../../../Components/LoadingPages/LoadingParagraph";
import SinglePostAntd from "../../../Components/SinglePost/SinglePostAntd";
import businessStore from "../../../Stores/BusinessStore";
import busPostStore from "../../../Stores/BusPostStore";
import PostsCarousel from "../../LandingPage/PostsCarousel";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import { Paper } from "@material-ui/core";

class OnePostPage extends Component {
  componentDidMount() {
    console.log(this.props.match.params.postId);
    busPostStore.fetchSinglePost(this.props.match.params.postId);
  }
  render() {
    let post = busPostStore.singlepost;
    console.log(post);
    if (busPostStore.loading || !post._id) {
      return (
        <div style={{ minHeight: "80vh" }}>
          <LoadingParagraph />
        </div>
      );
    }

    return (
      <div style={{ minHeight: "80vh" }}>
        <FlexboxGrid justify="center">
          <div style={{ minHeight: "40vh" }}>
            <SinglePostAntd
              postNumId={post.busPostNumId}
              postId={post._id}
              text={post.description}
              medias={post.medias}
              business={post.business}
              liked={post.liked}
            />
          </div>
        </FlexboxGrid>
        <FlexboxGrid justify="center">
          <IconButton
            block
            icon={<Icon icon="arrow-left" size="lg" />}
            placement="left"
            active
            size="lg"
            style={{ boxShadow: "0px 0px 3px black", maxWidth: 500 }}
            onClick={() => {
              this.props.history.push("/garageposts");
            }}
          >
            See All Posts
          </IconButton>
        </FlexboxGrid>
        <hr />
        <Container>
          <PostsCarousel posts={businessStore.businesspost} />
        </Container>
      </div>
    );
  }
}

export default withRouter(observer(OnePostPage));
