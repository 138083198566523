import { Container, Paper } from "@material-ui/core";
import { Divider } from "antd";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Button, Col, FlexboxGrid, Notification } from "rsuite";
import { Parallax } from "react-parallax";
import requestStore from "../../../Stores/RequestStore";
import moment from "moment";
import NewRequestTimeline from "../../../Components/Timeline/NewRequestTimeline";
import Step0 from "./Steps/Step0";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import userStore from "../../../Stores/UserStore";
import axios from "axios";
import baseURL from "../../../Media/baseURL";
import DeleteModal from "../Modals/DeleteModal";
import requestSummaryStore from "../../../Stores/RequestSummaryStore";
import LoadingParagraph from "../../../Components/LoadingPages/LoadingParagraph";

const instance = axios.create({ baseURL: baseURL });

class EditRequestPage extends Component {
  constructor(props) {
    super(props);
    this.state = { step: 0, loading: false, showModal: false };
    this.closeModal = this.closeModal.bind(this);
  }
  disabledDate(current) {
    // Can not select days before today and today
    return current < moment().add(24, "hours").endOf("day");
  }

  componentDidMount() {
    requestStore.fetchSingleRequest(this.props.match.params.requestId);
  }

  closeModal() {
    this.setState({ showModal: false });
  }
  updateRequestDetails = async () => {
    try {
      let newreq = requestStore.newrequest;
      this.setState({ loading: true });
      let mediaList = [];
      for (let i = 0; i < newreq.medias.length; i++) {
        if (newreq.medias[i].file) {
          let fd = new FormData();
          fd.append(
            "image",
            newreq.medias[i].file,
            `${newreq.medias[i].file.name}_${moment().format("DDMMMYYhhmm")}`
          );
          await instance
            .post("api/media/new/upload", fd, {
              headers: { authtoken: userStore.token },
            })
            .then((res) => mediaList.push(res.data.fileLocation));
        } else {
          mediaList.push(newreq.medias[i]);
        }
      }

      let requestData = {
        title: newreq.title,
        make: newreq.make,
        model: newreq.model,
        year: newreq.year,
        description: newreq.description,
        type: newreq.type,
        closedate: newreq.closedate,
        status: "Pending Approval",
        medias: mediaList,
        service: newreq.service,
        user: userStore.user._id,
      };
      // console.log(requestData);
      await instance
        .put(
          `/api/request/userchanges/${this.props.match.params.requestId}`,
          requestData,
          {
            headers: { authtoken: userStore.token },
          }
        )
        .then((res) => {
          // console.log(res);
          userStore.getUserData();
          this.setState({ loading: false });
          Notification["success"]({
            title: `Request Resubmitted`,
            description: (
              <div>
                Your request has been resubmitted for Admin Approval. <hr />
                You can track your request here:{" "}
                <Button
                  color="green"
                  onClick={() =>
                    this.props.history.push(
                      `/request/${this.props.match.params.requestId}`
                    )
                  }
                >
                  Track Request
                </Button>
              </div>
            ),
          });
          this.props.history.push("/dashboard");
        })
        .catch((err) =>
          Notification["error"]({
            title: `Error`,
            description:
              "An error occurred resubmitting your request. Please try again or contact our team for support.",
          })
        );
    } catch (err) {
      Notification["error"]({
        title: `Error`,
        description:
          "An error occurred resubmitting your request. Please try again or contact our team for support.",
      });
    }
  };

  render() {
    let request = requestStore.newrequest;

    const insideStyles = {
      minHeight: 800,
      paddingTop: 50,
      background:
        "radial-gradient(circle, rgba(51,47,47,0.95) 0%, rgba(70,70,70,0.70) 100%)",
    };

    let disabledButton = { val: false, message: "Resubmit Modification" };
    if (this.state.step === 0) {
      if (request.description.length < 3) {
        disabledButton = { val: true, message: "Add a Description" };
      } else {
        disabledButton.message = "Next";
      }
    } else if (this.state.step === 1) {
      if (request.make === "") {
        disabledButton = { val: true, message: "Add Car Make" };
      } else if (request.model === "") {
        disabledButton = { val: true, message: "Add Car Model" };
      } else {
        disabledButton.message = "Next";
      }
    } else if (this.state.step === 2) {
      disabledButton.message = "Next";
    }
    if (this.state.loading) {
      disabledButton = { val: true, message: "Loading..." };
    }

    let pageTitle = "";
    if (this.state.step === 0) pageTitle = "Request Details";
    if (this.state.step === 1) pageTitle = "Car Details";
    if (this.state.step === 2) pageTitle = "Add Photos";
    if (this.state.step === 3) pageTitle = "Checkout";

    if (requestStore.loading) {
      return (
        <Parallax
          blur={0}
          bgImage={"https://source.unsplash.com/1600x900/?car,garage"}
          bgImageAlt="Background Image"
          strength={150}
          style={{
            minHeight: 800,
            boxShadow: "10px 10px 70px -50px rgba(0,0,0,0.75)",
          }}
        >
          <div
            style={{
              minHeight: 800,
              width: "100%",
            }}
          >
            <div style={insideStyles}>
              <Container style={{ alignSelf: "center" }}>
                <Paper
                  elevation={10}
                  style={{
                    background:
                      "radial-gradient(circle, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 100%)",
                    borderRadius: 10,
                    padding: 30,
                    textAlign: "center",
                    maxWidth: 600,
                    margin: "auto",
                  }}
                >
                  <LoadingParagraph />
                </Paper>
              </Container>
            </div>
          </div>
        </Parallax>
      );
    }

    return (
      <Parallax
        blur={0}
        bgImage={"https://source.unsplash.com/1600x900/?car,garage"}
        bgImageAlt="Background Image"
        strength={150}
        style={{
          minHeight: 800,
          boxShadow: "10px 10px 70px -50px rgba(0,0,0,0.75)",
        }}
      >
        <DeleteModal
          request={request}
          show={this.state.showModal}
          onHide={this.closeModal}
        />
        <div
          style={{
            minHeight: 800,
            width: "100%",
          }}
        >
          <div style={insideStyles}>
            <Container style={{ alignSelf: "center" }}>
              <Paper
                elevation={10}
                style={{
                  background:
                    "radial-gradient(circle, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 100%)",
                  borderRadius: 10,
                  padding: 30,
                  textAlign: "center",
                  maxWidth: 600,
                  margin: "auto",
                }}
              >
                <Divider>
                  <h3>Modify {request.service.title}</h3>
                </Divider>
                <h6 style={{ color: "red" }}>
                  <strong>Feedback: </strong>
                  {request.adminfeedback}
                </h6>
                <Divider>
                  <h5>{pageTitle}</h5>
                </Divider>
                {this.state.step === 0 && (
                  <Step0 request={request} type={"Edit"} />
                )}
                {this.state.step === 1 && (
                  <Step1 request={request} type={"Edit"} />
                )}
                {this.state.step === 2 && (
                  <Step2 request={request} type={"Edit"} />
                )}
                {this.state.step === 3 && (
                  <Step3 request={request} type={"Edit"} />
                )}
                <hr />
                <FlexboxGrid>
                  {this.state.step > 0 && (
                    <Col xs={12}>
                      <Button
                        block
                        appearance="ghost"
                        style={{ boxShadow: "0px 0px 3px black" }}
                        onClick={() =>
                          this.setState({ step: this.state.step - 1 })
                        }
                      >
                        Back
                      </Button>
                    </Col>
                  )}
                  <Col xs={this.state.step > 0 ? 12 : 24}>
                    <Button
                      block
                      color="green"
                      style={{ boxShadow: "0px 0px 3px black" }}
                      onClick={() => {
                        if (this.state.step < 3) {
                          this.setState({ step: this.state.step + 1 });
                        } else {
                          this.updateRequestDetails();
                        }
                      }}
                      disabled={disabledButton.val}
                    >
                      {disabledButton.message}
                    </Button>
                  </Col>
                </FlexboxGrid>
                <Divider>
                  <h5>Modify {request.service.title} Request</h5>
                </Divider>
                <NewRequestTimeline step={this.state.step} />
                <br />
                <Button
                  block
                  color="red"
                  onClick={() => this.setState({ showModal: true })}
                >
                  Delete Request
                </Button>
              </Paper>
            </Container>
          </div>
        </div>
      </Parallax>
    );
  }
}

export default withRouter(observer(EditRequestPage));
