import { makeObservable, observable, computed } from "mobx";
import axios from "axios";
import userStore from "./UserStore";
import moment from "moment";
import baseURL from "../Media/baseURL";
import Compress from "react-image-file-resizer";

const instance = axios.create({ baseURL: baseURL });

class RequestStore {
  constructor() {
    this.requests = [];
    this.newrequest = {
      title: "",
      make: "",
      model: "",
      year: 2021,
      description: "",
      type: "",
      closedate: moment().add(3, "day"),
      status: "Pending Approval",
      medias: [],
      service: { title: "Body Protection" },
    };
    this.modifynewrequest = {
      title: "",
      make: "",
      model: "",
      year: 2021,
      description: "",
      type: "",
      closedate: moment().add(3, "day"),
      status: "Pending Approval",
      medias: [],
      service: { title: "" },
    };
    this.services = [];
    this.specialities = [];
    this.loading = true;
    this.remarks = "";
    this.editrequest = {
      title: "",
      remarks: "",
      make: "",
      model: "",
      year: 2021,
      description: "",
      type: "",
      closedate: moment().add(3, "day"),
      status: "Open",
      medias: [],
      service: { title: "" },
    };
    this.quotes = [];
    makeObservable(this, {
      requests: observable,
      newrequest: observable,
      modifynewrequest: observable,
      remarks: observable,
      editrequest: observable,
      services: observable,
      quotes: observable,
      loading: observable,
      openRequests: computed,
      rejectedRequests: computed,
      pendingRequests: computed,
      closedRequests: computed,
      requestsNotComplete: computed,
    });
  }

  resetRequest(service) {
    let finalservice = "";
    if (service) finalservice = service;
    this.newrequest = {
      title: "",
      make: "",
      model: "",
      year: 2021,
      description: "",
      type: "",
      closedate: moment().add(3, "day"),
      status: "Open",
      medias: [],
      service: finalservice,
    };
    this.editrequest = {
      title: "",
      remarks: "",
      make: "",
      model: "",
      year: 2021,
      description: "",
      type: "",
      closedate: moment().add(3, "day"),
      status: "Open",
      medias: [],
      service: { title: "" },
    };
    this.quotes = [];
  }

  closeLoading = async () => {
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve("resolved");
      }, 0);
    });
    this.loading = false;
  };

  editNewRequest(type, val) {
    if (type === "service") {
      let indexVal = this.services.findIndex((service) => service._id === val);
      if (indexVal > -1) {
        this.newrequest[type] = this.services[indexVal];
      } else {
        this.newrequest[type] = this.services = {
          title: "Body Protection",
          _id: "60105b9ee437897482951585",
        };
      }
    } else if (type === "make") {
      this.newrequest[type] = val;
      this.newrequest.model = "";
    } else {
      this.newrequest[type] = val;
      console.log(type);
      console.log(val);
      console.log(this.newrequest[type]);
    }
  }

  editEditRequest(type, val) {
    this.editrequest[type] = val;
  }
  dataURIToBlob = async (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };

  addPhotos = async (newfile, type) => {
    // this.newrequest.medias.push({
    //   src: URL.createObjectURL(newfile),
    //   file: newfile,
    // });
    this.newrequest.medias.push(newfile);
  };

  deleteImage(photo) {
    let files = [...this.newrequest.medias];
    let final = files.filter((file) => file !== photo);
    this.newrequest.medias = final;
  }
  deleteEditImage(photo) {
    let files = [...this.newrequest.medias];
    let final = files.filter((file) => file !== photo);
    this.newrequest.medias = final;
  }

  getUserRequests() {
    this.loading = true;
    return instance
      .get(`/api/request/myrequests/`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((requests) => {
        this.requests = requests;
        this.closeLoading();
        console.log("User Requests Fetched");
      })
      .catch((err) => {
        console.log(err);
        this.closeLoading();
        console.error(err.response);
        alert("Error Fetching User Requests");
      });
  }

  fetchSingleRequest(id) {
    this.loading = true;
    return instance
      .get(`/api/request/singlerequest/${id}/`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((requests) => {
        if (requests.length > 0) {
          this.editrequest = requests[0].request;
          this.newrequest = requests[0].request;
          this.quotes = requests[0].quote;
        } else {
          this.editrequest.description = "";
        }
        this.closeLoading();
        console.log("Single Request Fetched");
      })
      .catch((err) => {
        console.log(err);
        console.error(err.response);
        this.closeLoading();
        alert("Error Fetching Single Requests");
      });
  }

  getServices() {
    return instance
      .get(`/api/service/`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((services) => {
        this.services = services;
        console.log("Services Fetched");
      })
      .catch((err) => {
        console.log(err);
        console.error(err.response);
        alert("Error Fetching Services");
      });
  }
  getSpeciality() {
    return instance
      .get(`/api/speciality/all/`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((specialities) => {
        this.specialities = specialities;
        console.log("Specialities Fetched");
      })
      .catch((err) => {
        console.log(err);
        console.error(err.response);
        alert("Error Fetching Specialities");
      });
  }

  updateremark(val) {
    this.remarks = val;
  }

  get openRequests() {
    if (this.requests.length > 0) {
      return this.requests.filter((req) => req.request.status === "Open");
    }
    return [];
  }
  get rejectedRequests() {
    if (this.requests.length > 0) {
      return this.requests.filter((req) => req.request.status === "Rejected");
    }
    return [];
  }
  get pendingRequests() {
    if (this.requests.length > 0) {
      return this.requests.filter(
        (req) =>
          (req.request.status !== "Open") &
          (req.request.status !== "Completed") &
          (req.request.status !== "Cancelled")
      );
    }
    return [];
  }
  get closedRequests() {
    if (this.requests.length > 0) {
      return this.requests.filter((req) => req.request.status !== "Open");
    }
    return [];
  }
  get requestsNotComplete() {
    return this.requests.filter(
      (req) =>
        (req.request.status !== "Completed") &
        (req.request.status !== "Cancelled")
    );
  }
}

const requestStore = new RequestStore();
export default requestStore;
