import React, { Component } from "react";
import { Button, Notification } from "rsuite";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Divider } from "antd";
import axios from "axios";
import OtpInput from "react-otp-input";

import userStore from "../../../Stores/UserStore";
import baseURL from "../../../Media/baseURL";

const instance = axios.create({ baseURL: baseURL });

class PhoneSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showPasswordConf: false,
      signupform: false,
      step: 0,
      loading: false,
      otploading: false,
    };
  }

  checkOTP = async () => {
    try {
      let phone = userStore.newuser.phone;
      this.setState({ loading: true });
      let requestData = {
        phone: userStore.newuser.phone,
        otp: userStore.newuser.otp.substring(0, 4),
      };
      let newrequest = await instance
        .post("/auth/checktempotp", requestData)
        .then((res) => {
          this.setState({ loading: false, step: 3 });
        })
        .catch((err) => {
          this.setState({ loading: false });
          Notification["error"]({
            title: `Error!`,
            description: (
              <div>
                The OTP is Invalid. Please check your number or contact our
                office.
              </div>
            ),
          });
        });
    } catch (err) {
      this.setState({ loading: false });
      Notification["error"]({
        title: `Error!`,
        description: (
          <div>
            The OTP is Invalid. Please check your number or contact our office.
          </div>
        ),
      });
    }
  };
  sendOTP = async () => {
    try {
      let phone = userStore.newuser.phone;
      this.setState({ loading: true });
      let requestData = {
        phone: phone,
      };
      let newrequest = await instance
        .post("/auth/sendtempotp", requestData)
        .then((res) => {
          this.setState({ loading: false, step: 2 });
        })
        .catch((err) => {
          this.setState({ loading: false });
          Notification["error"]({
            title: `Error!`,
            description: (
              <div>
                An error occured. Please contact our team if this issue
                persists.
              </div>
            ),
          });
        });
    } catch (err) {
      this.setState({ loading: false });
      Notification["error"]({
        title: `Error!`,
        description: (
          <div>
            An error occured. Please contact our team if this issue persists.
          </div>
        ),
      });
    }
  };

  checkPhoneNumber = async () => {
    try {
      let phone = userStore.newuser.phone;
      this.setState({ loading: true });

      let requestData = {
        phone: phone,
      };
      let newrequest = await instance
        .post("/auth/checkphone", requestData)
        .then((res) => {
          this.setState({ loading: false, step: 1 });
        })
        .catch((err) => {
          this.setState({ loading: false });
          Notification["error"]({
            title: `Phone Number Exists!`,
            description: (
              <div>
                You can use the below buttons to Login or use the Forgot
                Password Button.
              </div>
            ),
          });
        });
    } catch (err) {
      this.setState({ loading: false });
      Notification["error"]({
        title: `Phone Number Exists!`,
        description: (
          <div>
            You can use the below buttons to Login or use the Forgot Password
            Button.
          </div>
        ),
      });
    }
  };

  render() {
    let user = userStore.newuser;
    let validation = userStore.newuser.validation;

    let nameerror = { val: false, message: "" };
    let emailerror = { val: false, message: "" };
    let passerror = { val: false, message: "" };
    let passconferror = { val: false, message: "" };
    let disabledSubmit = false;
    let finalValidation = true;

    if (user.password.length < 6) {
      passerror.val = true;
      passerror.message = "Password must be greater than 6 characters.";
      disabledSubmit = true;
      finalValidation = false;
    }
    if (user.passwordconf !== user.password) {
      passconferror.val = true;
      passconferror.message = "Passwords do not match.";
      disabledSubmit = true;
      finalValidation = false;
    }
    if (user.name.length < 3) {
      nameerror.val = true;
      nameerror.message = "Please enter a Name.";
      disabledSubmit = true;
      finalValidation = false;
    }
    if (!validation) {
      disabledSubmit = false;
      emailerror = { val: false, message: "" };
      passerror = { val: false, message: "" };
      passconferror = { val: false, message: "" };

      nameerror = { val: false, message: "" };
    }
    if (user.phone.length !== 8) {
      disabledSubmit = true;
    }
    if (this.state.set === 2) {
      if (!user.otp) {
        disabledSubmit = true;
      } else {
        if (user.otp.length !== 4) {
          disabledSubmit = true;
        }
      }
    }

    let step0 = (
      <div>
        <OtpInput
          value={user.phone}
          onChange={(e) => userStore.editnewuser("phone", e)}
          numInputs={8}
          isInputNum
          isDisabled={this.state.step > 0}
          fullWidth
          style={{ fontSize: 24, width: 50 }}
          separator={(pos) => {
            if (pos === 4) {
              <span> - </span>;
            }
          }}
          inputStyle={{
            width: "100%",
            background: "rgb(255,255,255,0)",
            borderRadius: 100,
            height: 40,
            fontSize: 20,
            fontWeight: "bold",
          }}
        />
      </div>
    );

    let step1 = (
      <div>
        <h6 style={{ color: "green" }}>Phone Number Valid</h6>
      </div>
    );

    let step2 = (
      <div>
        <hr />
        <Divider>OTP</Divider>
        <OtpInput
          value={user.otp}
          onChange={(e) => userStore.editnewuser("otp", e)}
          numInputs={4}
          isInputNum
          fullWidth
          style={{ fontSize: 24, width: 50 }}
          separator={(pos) => {
            if (pos === 4) {
              <span> - </span>;
            }
          }}
          inputStyle={{
            width: "100%",
            flex: "Wrap",
            justifyContent: "center",
            background: "rgb(255,30,0,0.1)",
            borderRadius: 100,
            height: 40,
            fontSize: 20,
            fontWeight: "bold",
          }}
        />
      </div>
    );

    let step3 = (
      <div>
        <TextField
          error={passerror.val}
          helperText={passerror.val && passerror.message}
          variant="standard"
          margin="normal"
          fullWidth
          disabled={userStore.loading}
          name="password"
          label="Password"
          type={this.state.showPassword ? "text" : "password"}
          id="password"
          autoComplete="current-password"
          value={userStore.newuser.password}
          style={{ background: "rgb(255,255,255,0)" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  tabindex="-1"
                  aria-label="toggle password visibility"
                  onMouseOver={() => this.setState({ showPassword: true })}
                  onMouseLeave={() => this.setState({ showPassword: false })}
                >
                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => userStore.editnewuser("password", e.target.value)}
        />
        <TextField
          error={passconferror.val}
          helperText={passconferror.val && passconferror.message}
          variant="standard"
          margin="normal"
          fullWidth
          disabled={userStore.loading}
          name="passwordconf"
          label="Confirm Password"
          type={this.state.showPasswordConf ? "text" : "password"}
          id="passwordconf"
          autoComplete="current-passwordconf"
          value={userStore.newuser.passwordconf}
          style={{ background: "rgb(255,255,255,0)" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" tabindex="-1">
                <IconButton
                  tabindex="-1"
                  aria-label="toggle password visibility"
                  onMouseOver={() => this.setState({ showPasswordConf: true })}
                  onMouseLeave={() =>
                    this.setState({ showPasswordConf: false })
                  }
                >
                  {this.state.showPasswordConf ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) =>
            userStore.editnewuser("passwordconf", e.target.value)
          }
        />
        <TextField
          error={nameerror.val}
          helperText={nameerror.val && nameerror.message}
          variant="standard"
          margin="normal"
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoComplete="name"
          disabled={userStore.loading}
          value={userStore.newuser.name}
          onChange={(e) => userStore.editnewuser("name", e.target.value)}
          style={{ background: "rgb(255,255,255,0)" }}
        />
      </div>
    );
    let registerbutton;
    if (this.state.step === 0) {
      registerbutton = (
        <Button
          block
          color={!this.state.loading && "green"}
          style={{ boxShadow: "0px 0px 3px black" }}
          disabled={disabledSubmit}
          onClick={() => {
            this.checkPhoneNumber();
          }}
        >
          {this.state.loading ? "Loading ..." : "Submit Phone Number"}
        </Button>
      );
    } else if (this.state.step === 1) {
      registerbutton = (
        <div>
          <Button
            block
            color={!this.state.loading && "green"}
            style={{ boxShadow: "0px 0px 3px black" }}
            disabled={disabledSubmit}
            onClick={() => {
              this.sendOTP();
            }}
          >
            {this.state.loading ? "Loading ..." : "Send OTP"}
          </Button>
          <Button
            block
            color="red"
            appearance="ghost"
            style={{ boxShadow: "0px 0px 3px black" }}
            disabled={disabledSubmit}
            onClick={() => {
              this.setState({ step: 0 });
            }}
          >
            Change Phone Number
          </Button>
        </div>
      );
    } else if (this.state.step === 2) {
      registerbutton = (
        <div>
          <Button
            block
            color={!this.state.loading && "green"}
            style={{ boxShadow: "0px 0px 3px black" }}
            disabled={disabledSubmit}
            onClick={() => {
              this.checkOTP();
            }}
          >
            {this.state.loading ? "Loading ..." : "Submit OTP"}
          </Button>
          <Button
            block
            color="red"
            appearance="ghost"
            style={{ boxShadow: "0px 0px 3px black" }}
            disabled={disabledSubmit}
            onClick={() => {
              this.setState({ step: 0 });
            }}
          >
            Change Phone Number
          </Button>
        </div>
      );
    } else if (this.state.step === 3) {
      registerbutton = (
        <div>
          <Button
            block
            color="green"
            style={{ boxShadow: "0px 0px 3px black" }}
            disabled={disabledSubmit}
            onClick={() => {
              let userData = {
                phone: user.phone,
                password: user.password,
                name: user.name,
                validation: finalValidation,
              };

              console.log(userData);

              userStore.phoneregisterUser(userData);
            }}
          >
            {this.state.loading ? "Loading ..." : "Create Account"}
          </Button>
          <Button
            block
            color="red"
            appearance="ghost"
            style={{ boxShadow: "0px 0px 3px black" }}
            disabled={disabledSubmit}
            onClick={() => {
              this.setState({ step: 0 });
            }}
          >
            Change Phone Number
          </Button>
        </div>
      );
    }
    return (
      <div>
        <Divider>
          <h5>Phone Signup</h5>
        </Divider>
        {step0}
        {this.state.step >= 1 && step1}
        {this.state.step === 2 && step2}
        {this.state.step >= 3 && step3}
        <br />
        {registerbutton}
      </div>
    );
  }
}

export default withRouter(observer(PhoneSignUp));
