import { computed, makeObservable, observable } from "mobx";
import userStore from "./UserStore";
import baseURL from "../Media/baseURL";
import axios from "axios";
const instance = axios.create({ baseURL: baseURL });

class StatisticStore {
  constructor() {
    this.allstats = [];
    this.quotes = [];
    this.loading = false;

    makeObservable(this, {
      allstats: observable,
      quotes: observable,
      calls: computed,
      direction: computed,
      pageViews: computed,
      awardedquotes: computed,
      pendingquotes: computed,
      lostquotes: computed,
    });
  }

  fetchAllStats() {
    this.loading = true;
    return instance
      .get(`/api/statistics/allstats`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((stats) => {
        this.allstats = stats;
      })
      .then((convo) => {
        console.log("statiscs loaded");
        this.fetchAllQuotes();
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
        console.error(err.response);
        alert("Error Fetching Conversation");
      });
  }

  fetchAllQuotes() {
    this.loading = true;
    return instance
      .get(`/api/statistics/quotes`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((stats) => {
        this.quotes = stats;
      })
      .then((convo) => {
        console.log("statiscs loaded");
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
        console.error(err.response);
        alert("Error Fetching Conversation");
      });
  }

  get pageViews() {
    let pageviewss = this.allstats.filter(
      (stat) => stat.action === "Page View"
    );
    return pageviewss;
  }
  get calls() {
    return this.allstats.filter((stat) => stat.action === "Call Click");
  }
  get direction() {
    return this.allstats.filter((stat) => stat.action === "Direction Click");
  }
  get awardedquotes() {
    return this.quotes.filter((quote) => quote.status === "Approved");
  }
  get pendingquotes() {
    return this.quotes.filter((quote) => quote.status === "Submitted");
  }
  get lostquotes() {
    return this.quotes.filter((quote) => quote.status === "Lost");
  }
}

const statisticStore = new StatisticStore();
export default statisticStore;
