import { Checkbox, FormControlLabel } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import businessStore from "../../../../../Stores/BusinessStore";

class ServiceRow extends Component {
  constructor(props) {
    super(props);
    this.state = { spec: true };
  }
  render() {
    let spec = this.props.spec;
    let indexVal = businessStore.editBusiness.speciality.findIndex(
      (item) => item._id === spec._id
    );

    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={indexVal > -1}
              onChange={() => {
                if (indexVal >= 0) {
                  businessStore.updateSpeciality("Remove", spec);
                } else {
                  businessStore.updateSpeciality("Add", spec);
                }
              }}
              name={spec.name}
            />
          }
          label={spec.name}
        />
      </div>
    );
  }
}

export default withRouter(observer(ServiceRow));
