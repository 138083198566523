import React, { Component } from "react";

// import YoutubeIcon from "@material-ui/icons/Youtube";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { IconButton, Icon, FlexboxGrid, Col, ButtonToolbar } from "rsuite";
import IconButtonFormat from "../../../MainPages/Business/ProfilePage/ContactSection/IconButtonFormat";
import { Button } from "antd";
import userStore from "../../../Stores/UserStore";
// backgroundColor: "#2d72b5",

class LandingFooter extends Component {
  render() {
    let signupgarage;
    console.log(userStore.signedIn);
    if (userStore.user.group !== "Business") {
      signupgarage = (
        <div>
          <br />
          <Button
            ghost
            onClick={() => this.props.history.push("/businessinfo")}
          >
            Add your business to Garage Arabia
          </Button>
        </div>
      );
    }
    return (
      <div
        style={{
          backgroundColor: "#242526",
          minHeight: 300,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          color: "white",
        }}
      >
        <br />
        <h3 style={{ color: "white" }}>Get In Touch</h3>
        <br />
        <ButtonToolbar>
          <IconButton
            size="lg"
            circle
            icon={<Icon icon="phone" size="lg" />}
            onClick={() => (window.location.href = `tel:+965-97666886`)}
          />
          <IconButton
            size="lg"
            circle
            icon={<Icon icon="instagram" size="lg" />}
            onClick={() =>
              window.open(`https://www.instagram.com/${"aljazzaf90"}`)
            }
          />
          <IconButton
            size="lg"
            circle
            icon={<Icon size="lg" icon="envelope" />}
            onClick={() =>
              (window.location.href = `mailto:info@garagearabia.com`)
            }
          />
          <IconButton
            size="lg"
            circle
            icon={<Icon size="lg" icon="map" />}
            onClick={() =>
              window.open(`https://maps.google.com/?q=${29.32335},${48.002265}`)
            }
          />
        </ButtonToolbar>

        {signupgarage}
        <br />
        <br />
        <p style={{ fontSize: 12 }}>
          {`Garage Arabia © ${new Date().getFullYear()}.`}{" "}
        </p>
        <p style={{ fontSize: 11 }}>
          <i onClick={() => this.props.history.push("/policy/privacypolicy")}>
            Privacy Policy
          </i>{" "}
          -{" "}
          <i onClick={() => this.props.history.push("/policy/cookiespolicy")}>
            Cookie Policy
          </i>{" "}
          -{" "}
          <i
            onClick={() => this.props.history.push("/policy/termsandservices")}
          >
            Terms and Conditions
          </i>
        </p>
      </div>
    );
  }
}

export default withRouter(observer(LandingFooter));
