import { Card, CardActionArea, CardMedia } from "@material-ui/core";
import { CardContent, Typography } from "@material-ui/core";
import React, { Component } from "react";
import carStore from "../../../Stores/CarStore";
import { Carousel } from "react-responsive-carousel";

class CarCard extends Component {
  render() {
    let car = this.props.car;
    let photos = this.props.car.medias;

    let cardimages = photos.map((photo, index) => (
      <CardMedia
        component="img"
        alt="Contemplative Reptile"
        height="150"
        image={photo}
        title="Contemplative Reptile"
      />
    ));

    return (
      <div style={{ padding: 10 }}>
        <Card style={{ width: 250, borderRadius: 10 }} elevation={10}>
          <CardActionArea
            onClick={() => {
              this.props.openEdit();
              carStore.resetEditCar(car);
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {car.year}
              </Typography>
              <Typography gutterBottom variant="h6" component="h2">
                {car.make} - {car.model}
              </Typography>
            </CardContent>
          </CardActionArea>
          <Carousel
            dynamicHeight={true}
            autoPlay
            infiniteLoop
            swipeable
            showArrows
            useKeyboardArrows
            showThumbs={false}
          >
            {cardimages}
          </Carousel>
        </Card>
      </div>
    );
  }
}

export default CarCard;
