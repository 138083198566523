import { Button, Card, CardActionArea, CardActions } from "@material-ui/core";
import { CardMedia } from "@material-ui/core";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import carStore from "../../../../Stores/CarStore";

class ImageCard extends Component {
  render() {
    let photo = this.props.photo;
    let photosrc = photo;
    if (photo.src) photosrc = photo.src;

    console.log(photosrc);
    let hideButton = this.props.hideButton;

    return (
      <div style={{ padding: 10 }}>
        <Card style={{ maxWidth: 150 }} elevation={5}>
          <CardActionArea>
            <CardMedia
              component="img"
              alt={"Image Upload"}
              height="140"
              image={photosrc}
              title={"Image Upload"}
            />
          </CardActionArea>
          {!hideButton && (
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={() => carStore.deleteImage(photo)}
              >
                Delete
              </Button>
            </CardActions>
          )}
        </Card>
      </div>
    );
  }
}

export default withRouter(observer(ImageCard));
