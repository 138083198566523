import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link, withRouter } from "react-router-dom";
import { Box, Drawer, AppBar, Toolbar, List } from "@material-ui/core";
import { Typography, CssBaseline, Divider } from "@material-ui/core";
import { IconButton, ListItem, ListItemIcon } from "@material-ui/core";
import { ListItemText } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import BusinessIcon from "@material-ui/icons/Business";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExitToAppTwoToneIcon from "@material-ui/icons/ExitToAppTwoTone";
import { observer } from "mobx-react";
import logo from "../../Media/logo.png";
import AccountBarIcon from "./AppBarIcons/Account";
import userStore from "../../Stores/UserStore";
import CustomerButtons from "./DrawerItems/CustomerButtons";
import BusinessButtons from "./DrawerItems/BusinessButtons";
import LandingFooter from "./Footer/LandingFooter";
import AvailableCredit from "./AppBarIcons/AvailableCredit";
import ShareIcon from "@material-ui/icons/Share";
import SettingsIcon from "@material-ui/icons/Settings";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import NewMessages from "./AppBarIcons/NewMessages";
import Notifications from "./AppBarIcons/Notifications";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";

let drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: 12010,
    height: 50,
    color: "white",
    backgroundColor: "#1f3d52",
    background:
      "linear-gradient(0deg, rgba(51,47,47,1) 0%, rgba(70,70,70,1) 100%)",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 5,
  },
  hide: {
    display: "none",
  },
  drawer: {
    zIndex: 1000,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.down("xl")]: {
      width: drawerWidth,
    },
    [theme.breakpoints.down("lg")]: {
      width: 50,
    },
    [theme.breakpoints.down("md")]: {
      width: 0,
    },
    width: !userStore.signedIn && 0,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const MiniDrawer = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Box display="block" displayPrint="none" style={{ zIndex: 200 }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <div
              style={{
                color: "white",
                flexGrow: 1,
                justifyContent: "start",
                paddingLeft: "5%",
              }}
              onClick={handleDrawerClose}
            >
              <img
                src={logo}
                height="30"
                className="d-inline-block align-top"
                alt="Garage Arabia Logo"
              />
            </div>
            {userStore.signedIn && <NewMessages />}
            {userStore.signedIn && <Notifications />}
            {userStore.signedIn ? <AvailableCredit /> : <AccountBarIcon />}
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          anchor="left"
          open={open}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          {open ? (
            <div className={classes.toolbar}>
              <ListItem
                component={Link}
                to={"/profile"}
                style={{ color: "black" }}
                onClick={handleDrawerClose}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Profile"}
                  style={{ color: "black", fontWeight: "bold" }}
                />
              </ListItem>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </div>
          ) : (
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerOpen}>
                {theme.direction === "rtl" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </div>
          )}
          <Divider />
          <List>
            <ListItem
              component={Link}
              to={"/landing"}
              style={{ color: "black" }}
              onClick={handleDrawerClose}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={"Home"} />
            </ListItem>
          </List>
          <List>
            <ListItem
              component={Link}
              to={"/businesses"}
              onClick={handleDrawerClose}
              style={{ color: "black" }}
            >
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary={"Businesses"} />
            </ListItem>
          </List>
          <List>
            <ListItem
              component={Link}
              to={"/garageposts"}
              onClick={handleDrawerClose}
              style={{ color: "black" }}
            >
              <ListItemIcon>
                <PhotoLibraryIcon />
              </ListItemIcon>
              <ListItemText primary={"Garage Posts"} />
            </ListItem>
          </List>
          <Divider />
          {userStore.userAccess === 8 && (
            <CustomerButtons closeDrawer={handleDrawerClose} />
          )}
          {userStore.userAccess === 4 && (
            <BusinessButtons closeDrawer={handleDrawerClose} />
          )}
          {userStore.signedIn && (
            <List>
              <ListItem
                button
                component={Link}
                to={"/messages"}
                style={{ color: "black" }}
                onClick={() => {
                  handleDrawerClose();
                }}
              >
                <ListItemIcon>
                  <MailOutlineIcon />
                </ListItemIcon>
                <ListItemText primary={"Messages"} />
              </ListItem>
            </List>
          )}
          <Divider />
          {userStore.signedIn ? (
            <List>
              <ListItem
                button
                component={Link}
                to={"/auth"}
                onClick={() => {
                  handleDrawerClose();
                  userStore.logoutUser();
                }}
              >
                <ListItemIcon>
                  <ExitToAppTwoToneIcon style={{ color: "#e06565" }} />
                </ListItemIcon>
                <ListItemText primary={"Logout"} />
              </ListItem>
            </List>
          ) : (
            <List>
              <ListItem
                button
                component={Link}
                to={"/auth"}
                onClick={handleDrawerClose}
              >
                <ListItemIcon>
                  <ExitToAppTwoToneIcon style={{ color: "green" }} />
                </ListItemIcon>
                <ListItemText primary={"Sign In"} />
              </ListItem>
            </List>
          )}
          {userStore.signedIn && (
            <List>
              <ListItem
                component={Link}
                to={"/referafriend"}
                style={{ color: "black" }}
                onClick={() => {
                  handleDrawerClose();
                  userStore.changeReferal("Reset");
                }}
              >
                <ListItemIcon>
                  <ShareIcon style={{ color: "green" }} />
                </ListItemIcon>
                <ListItemText primary={"Refer a Friend"} />
              </ListItem>
            </List>
          )}
          <Divider />
          {userStore.user.business && (
            <div>
              <Divider />
              <ListItem
                button
                onClick={() => {
                  handleDrawerClose();
                  userStore.updateGroup();
                }}
              >
                <ListItemIcon>
                  <AccountCircle style={{ color: "green" }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    userStore.user.group === "Customer"
                      ? "See Business"
                      : "See Customer"
                  }
                />
              </ListItem>
            </div>
          )}
        </Drawer>
      </Box>
      <main style={{ width: "100%", overflowX: "hidden" }}>
        <Box display="block" displayPrint="none">
          <div style={{ height: 50 }} />
        </Box>
        {children}
        <LandingFooter />
      </main>
    </div>
  );
};

export default withRouter(observer(MiniDrawer));
