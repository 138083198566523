import { Container, Paper } from "@material-ui/core";
import { Divider, message } from "antd";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Button, Col, FlexboxGrid, Progress, Notification } from "rsuite";
import { Parallax } from "react-parallax";
import requestStore from "../../../Stores/RequestStore";
import moment from "moment";
import NewRequestTimeline from "../../../Components/Timeline/NewRequestTimeline";
import Step0 from "./Steps/Step0";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import userStore from "../../../Stores/UserStore";
import axios from "axios";
import baseURL from "../../../Media/baseURL";
import Step4 from "./Steps/Step4";
import NoCredit from "../../../Components/ErrorMessages/NoCredit";
import MaxRequests from "../../../Components/ErrorMessages/MaxRequests";

const { Circle } = Progress;

const instance = axios.create({ baseURL: baseURL });

class NewRequestPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      loading: false,
      requestId: null,
      progress: 0,
      uploaded: 0,
    };
  }

  handleCloseNotification(type) {
    Notification.close();
    if (type === "success") {
      Notification[type]({
        title: "Successfully Uploaded Request.",
      });
    }
  }

  componentDidMount() {
    requestStore.resetRequest();
  }

  randomString = async (length) => {
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };

  disabledDate(current) {
    // Can not select days before today and today
    return current < moment().add(24, "hours").endOf("day");
  }

  dataURItoBlob = async (dataURI) => {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  };

  postNewRequest = async () => {
    try {
      let newreq = requestStore.newrequest;
      Notification["info"]({
        title: "Loading",
        duration: 0,
        description: (
          <div>
            Please wait while your Request is uploading... <hr />
            {newreq.medias.length > 0 &&
              `${this.state.uploaded} of ${newreq.medias.length} Uploaded.`}
          </div>
        ),
      });
      this.setState({ loading: true });
      let progress = 0;
      let totalprogress = 1 + 4 * newreq.medias.length;

      let blob;

      let media = "";
      let mediaList = [];
      for (let i = 0; i < newreq.medias.length; i++) {
        blob = await this.dataURItoBlob(newreq.medias[i]);
        media = "";
        let fd = new FormData();
        let imagename = this.randomString(10);
        fd.append(
          "image",
          blob,
          `${moment().format("DDMMMYYhhmm")}_${Math.round(
            Math.random() * 1000000
          )}`
        );

        await instance
          .post("api/media/new/upload", fd, {
            headers: { authtoken: userStore.token },
          })
          .then((res) => {
            mediaList.push(res.data.fileLocation);
            progress += 4;
            this.setState({
              progress: this.state.progress + (100 * 4) / totalprogress,
              uploaded: this.state.uploaded + 1,
            });
          });

        console.log(this.state.progress);
      }

      let requestData = {
        title: newreq.title,
        make: newreq.make,
        model: newreq.model,
        year: newreq.year,
        description: newreq.description,
        type: newreq.type,
        closedate: newreq.closedate,
        status: "Pending Approval",
        medias: mediaList,
        service: newreq.service,
        user: userStore.user._id,
      };
      let newrequest = await instance
        .post("/api/request/postnew", requestData, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          // console.log(res);
          this.handleCloseNotification("success");
          userStore.changeUserCredit(-1);
          userStore.getUserData();
          this.setState({ loading: false, step: 4, requestId: res.data._id });
          message.success("Request Submitted Successfully.");
        })
        .catch((err) => alert("An Error Occured and the car was not added."));
    } catch (err) {
      alert("Error in Creating Request");
    }
  };

  render() {
    let request = requestStore.newrequest;

    const insideStyles = {
      minHeight: 800,
      paddingTop: 50,
      background:
        "radial-gradient(circle, rgba(51,47,47,0.95) 0%, rgba(70,70,70,0.70) 100%)",
    };

    let disabledButton = { val: false, message: "Submit Request" };
    if (this.state.step === 0) {
      if (!request.service._id) {
        disabledButton = { val: true, message: "Select a Service" };
      } else if (request.service._id.length < 3) {
        disabledButton = { val: true, message: "Select a Service" };
      } else if (request.description.length < 3) {
        disabledButton = { val: true, message: "Add a Description" };
      } else {
        disabledButton.message = "Next";
      }
    } else if (this.state.step === 1) {
      if (request.make === "") {
        disabledButton = { val: true, message: "Add Car Make" };
      } else if (request.model === "") {
        disabledButton = { val: true, message: "Add Car Model" };
      } else {
        disabledButton.message = "Next";
      }
    } else if (this.state.step === 2) {
      disabledButton.message = "Next";
    }
    if (this.state.loading) {
      disabledButton = { val: true, message: "Loading..." };
    }

    let pageTitle = "";
    if (this.state.step === 0) pageTitle = "Request Details";
    if (this.state.step === 1) pageTitle = "Car Details";
    if (this.state.step === 2) pageTitle = "Add Photos";
    if (this.state.step === 3) pageTitle = "Checkout";
    if (this.state.step === 4) pageTitle = "Confirmation";

    let backButton = <Col xs={12} />;
    if ((this.state.step > 0) & (this.state.step < 4)) {
      backButton = (
        <Col xs={12}>
          <Button
            block
            appearance="ghost"
            color="red"
            disabled={this.state.loading}
            onClick={() => this.setState({ step: this.state.step - 1 })}
          >
            Back
          </Button>
        </Col>
      );
    }
    let finaloutput = (
      <div>
        <Divider>
          <h4>New Request</h4>
        </Divider>

        {this.state.step < 4 && (
          <Divider>
            <h5>{pageTitle}</h5>
          </Divider>
        )}
        {this.state.step === 0 && <Step0 request={request} type={"New"} />}
        {this.state.step === 1 && <Step1 request={request} type={"New"} />}
        {this.state.step === 2 && <Step2 request={request} type={"New"} />}
        {this.state.step === 3 && <Step3 request={request} type={"New"} />}
        {this.state.step === 4 && (
          <Step4
            request={request}
            requestId={this.state.requestId}
            type={"New"}
          />
        )}
        {this.state.step < 4 && (
          <div>
            <hr />
            <FlexboxGrid>
              {backButton}
              <Col xs={12}>
                <Button
                  block
                  color="green"
                  style={{ boxShadow: "0px 0px 3px black" }}
                  onClick={() => {
                    if (this.state.step < 3) {
                      this.setState({ step: this.state.step + 1 });
                    } else {
                      this.postNewRequest();
                    }
                  }}
                  disabled={disabledButton.val}
                >
                  {disabledButton.message}
                </Button>
              </Col>
            </FlexboxGrid>
            {this.state.loading && (
              <div style={{ width: 120 }}>
                <Circle
                  percent={Math.round(this.state.progress)}
                  status={this.state.progress < 100 ? "active" : "success"}
                />
              </div>
            )}
            <hr />
            <NewRequestTimeline step={this.state.step} />
          </div>
        )}
      </div>
    );
    if ((userStore.user.credit <= 0) & (this.state.step < 4)) {
      finaloutput = <NoCredit />;
    }
    if (
      (requestStore.requestsNotComplete.length >= 2) &
      (this.state.step < 4)
    ) {
      finaloutput = <MaxRequests />;
    }

    return (
      <Parallax
        blur={0}
        bgImage={"https://source.unsplash.com/1600x900/?car,garage"}
        bgImageAlt="Background Image"
        strength={150}
        style={{
          minHeight: 800,
          boxShadow: "10px 10px 70px -50px rgba(0,0,0,0.75)",
        }}
      >
        <div
          style={{
            minHeight: 800,
            width: "100%",
          }}
        >
          <div style={insideStyles}>
            <Container style={{ alignSelf: "center" }}>
              <Paper
                elevation={10}
                style={{
                  background:
                    "radial-gradient(circle, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 100%)",
                  borderRadius: 10,
                  padding: 30,
                  textAlign: "center",
                  maxWidth: 600,
                  margin: "auto",
                }}
              >
                {finaloutput}
              </Paper>
            </Container>
          </div>
        </div>
      </Parallax>
    );
  }
}

export default withRouter(observer(NewRequestPage));
