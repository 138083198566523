import React, { Component } from "react";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

class NewProductTimeline extends Component {
  render() {
    let steps = [
      {
        main: "General Information",
        detail: "Add Product or Service General Information.",
      },
      {
        main: "Pricing and Stock",
        detail: "Product/Service Pricing and Stock",
      },
      { main: "Options", detail: "Adding Unique Options (optional)" },
      { main: "Upload Images", detail: "Upload the Product/Service Images" },
      { main: "Submit Product", detail: "Confirm and Submit Product" },
    ];
    let activeStep = this.props.activeStep;
    let type = this.props.type;

    return (
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        style={{ backgroundColor: "transparent" }}
      >
        {steps.map((label) => (
          <Step key={label.main}>
            <StepLabel>{label.main}</StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  }
}

export default NewProductTimeline;
