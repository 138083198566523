import { observer } from "mobx-react";
import React, { Component } from "react";
import { Button } from "rsuite";
import { withRouter } from "react-router";
import userStore from "../../../Stores/UserStore";

class AvailableCredit extends Component {
  render() {
    return (
      <div>
        <Button
          size="sm"
          appearance="ghost"
          style={{
            backgroundColor: "#007774",
            boxShadow: "0px 0px 3px black",
            borderRadius: 10,
            color: "white",
            marginTop: -10,
          }}
          onClick={() => this.props.history.push("/credit")}
        >
          {userStore.user.credit}
        </Button>
      </div>
    );
  }
}

export default withRouter(observer(AvailableCredit));
