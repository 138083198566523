import React, { Component } from "react";
import PaperSTD from "../../../../Components/PaperSTD/PaperSTD";
import { Container } from "@material-ui/core";
import { Col, FlexboxGrid, Icon, IconButton } from "rsuite";
import businessStore from "../../../../Stores/BusinessStore";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import MapView from "../../../../Components/Map/MapView";
import SendMessageBox from "./SendMessageBox";
import userStore from "../../../../Stores/UserStore";
import axios from "axios";
import baseURL from "../../../../Media/baseURL";
import IconButtonFormat from "./IconButtonFormat";
import { WhatsappShareButton, WhatsappIcon } from "react-share";

const instance = axios.create({ baseURL: baseURL });

class ContactSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryload: false,
      sentquery: false,
    };
  }

  sendStat(action, businessId) {
    if (action === "call") {
      instance.get(`/api/statistics/call/${businessId}`, {
        headers: { authtoken: userStore.token },
      });
    }
    if (action === "direction") {
      instance.get(`/api/statistics/direction/${businessId}`, {
        headers: { authtoken: userStore.token },
      });
    }
  }

  sendUserQuery = async () => {
    this.setState({ loading: true });
  };

  render() {
    let business = this.props.business;

    let website;
    let instagram;
    if (business.website) {
      if (business.website.length > 2) {
        website = (
          <IconButtonFormat
            title="Site: "
            icon={"globe"}
            action={() => window.open(business.website)}
            text={"Visit Website"}
          />
        );
      }
    }
    if (business.instagram) {
      if (business.instagram.length > 2) {
        instagram = (
          <IconButtonFormat
            title="Instagram: "
            icon={"instagram"}
            action={() =>
              window.open(`https://www.instagram.com/${business.instagram}`)
            }
            text={`Go to Instagram`}
          />
        );
      }
    }
    let sendmessagebox;

    if ((userStore.user.group !== "Business") & userStore.signedIn) {
      sendmessagebox = (
        <div>
          <br />
          <SendMessageBox />
        </div>
      );
    }

    return (
      <PaperSTD title={"Contact"} orientation="center">
        <Container>
          <FlexboxGrid style={{ textAlign: "left" }}>
            <Col xs={24} sm={12}>
              {!businessStore.loading && (
                <MapView
                  lat={business.lat}
                  lng={business.lng}
                  address={business.address}
                />
              )}
            </Col>
            <hr />
            <Col xs={24} sm={12}>
              <IconButtonFormat
                icon={"phone"}
                title="Phone: "
                action={() =>
                  (window.location.href = `tel:+965${business.phone}`)
                }
                text={"Call Company"}
              />
              <IconButtonFormat
                icon={"envelope"}
                action={() =>
                  (window.location.href = `mailto:${business.email}`)
                }
                title="Email: "
                text={"Email Company"}
              />
              {website}
              {instagram}
              <IconButtonFormat
                icon={"map"}
                action={() =>
                  window.open(
                    `https://maps.google.com/?q=${business.lat},${business.lng}`
                  )
                }
                text={"Get Directions"}
              />

              <WhatsappShareButton
                url={`gakw.app/b/${business.businessNumId}`}
                title={`Follow ${business.name} on Garage Arabia:`}
                style={{ width: "100%", marginTop: 5 }}
              >
                <IconButton
                  size="lg"
                  block
                  color="green"
                  icon={<Icon icon={"whatsapp"} />}
                  style={{ fontWeight: "bold" }}
                >
                  Share with Whatsapp
                </IconButton>
              </WhatsappShareButton>
            </Col>
            <br />
          </FlexboxGrid>

          {sendmessagebox}
          <br />
        </Container>
      </PaperSTD>
    );
  }
}

export default withRouter(observer(ContactSection));
