import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Button, IconButton, Icon } from "rsuite";
import moment from "moment";
import axios from "axios";
import baseURL from "../../../Media/baseURL";
import userStore from "../../../Stores/UserStore";
import letterStore from "../../../Stores/LetterStore";
import { Alert, AlertTitle } from "@material-ui/lab";

const instance = axios.create({ baseURL: baseURL });

class NotificationCards extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }
  componentDidMount() {
    if (!this.props.notification.seen) {
      let notificationData = { notificationId: this.props.notification._id };
      instance.put("/api/notifi/byuser/seen", notificationData, {
        headers: { authtoken: userStore.token },
      });
    }
  }
  render() {
    let notification = this.props.notification;
    let buttonLink;

    let buttoncolor = "orange";
    if (notification.notitype === "success") buttoncolor = "green";
    if (notification.notitype === "info") buttoncolor = "blue";

    if (notification.link) {
      buttonLink = (
        <div>
          <Button
            size="sm"
            color={buttoncolor}
            appearance="ghost"
            onClick={() => this.props.history.push(notification.link)}
          >
            {notification.linkname}
          </Button>
        </div>
      );
    }

    let buttonColor = "blue";
    if (notification.notitype === "success") {
      buttonColor = "green";
    } else if (notification.notitype === "warning") {
      buttonColor = "yellow";
    } else if (notification.notitype === "error") {
      buttonColor = "red";
    }

    let description = (
      <IconButton
        appearance="subtle"
        style={{ color: "black", fontWeight: "bold", whiteSpace: "normal" }}
        icon={<Icon icon="chevron-up" />}
        placement="left"
        color={buttonColor}
        onClick={() => this.setState({ open: !this.state.open })}
      >
        {notification.subject}
      </IconButton>
    );
    description = (
      <Button
        appearance="subtle"
        size="sm"
        style={{ color: "black", fontWeight: "bold", whiteSpace: "normal" }}
        color={buttonColor}
        onClick={() => this.setState({ open: !this.state.open })}
      >
        {notification.subject}
      </Button>
    );

    description = (
      <div>
        {notification.content}
        {buttonLink}

        <i style={{ fontSize: 10 }}>
          {moment(notification.createdAt).format("DD-MMM-YY HH:mm")} -{" "}
          {moment(notification.createdAt).fromNow()}
        </i>
      </div>
    );
    return (
      <div>
        <Alert
          style={{ borderRadius: 20 }}
          severity={notification.notitype}
          action={
            <IconButton
              appearance="subtle"
              size="lg"
              icon={<Icon icon="close-circle" size="lg" />}
              color="red"
              circle
              onClick={() =>
                letterStore.deleteSingleNotification(notification._id)
              }
            />
          }
        >
          <AlertTitle>{notification.subject}</AlertTitle>
          {description}
        </Alert>
        <br />
      </div>
    );
  }
}

export default withRouter(observer(NotificationCards));
