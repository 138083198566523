import React, { Component } from "react";
import { Icon, IconButton } from "rsuite";

class IconButtonFormat extends Component {
  render() {
    return (
      <IconButton
        size="lg"
        block
        appearance="ghost"
        style={{ borderColor: "black", color: "black" }}
        icon={<Icon icon={this.props.icon} />}
        onClick={() => this.props.action()}
      >
        {this.props.text}
      </IconButton>
    );
  }
}

export default IconButtonFormat;
