import { Container } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import PaperSTD from "../../../Components/PaperSTD/PaperSTD";
import { Button, FlexboxGrid, Col, Notification, Progress } from "rsuite";
import "react-image-crop/dist/ReactCrop.css";
import busPostStore from "../../../Stores/BusPostStore";
import Step0 from "./Step0";
import { Input, message, Result } from "antd";
import Step1 from "./Step1";
import axios from "axios";
import baseURL from "../../../Media/baseURL";
import userStore from "../../../Stores/UserStore";
import moment from "moment";
import MaxPosts from "../../../Components/ErrorMessages/MaxPosts";
import SinglePostAntd from "../../../Components/SinglePost/SinglePostAntd";

const instance = axios.create({ baseURL: baseURL });

const { Circle } = Progress;

class NewPost extends Component {
  constructor(props) {
    super(props);
    this.state = { step: 0, loading: false, progress: 0, uploadedpost: null };
  }
  componentDidMount() {
    busPostStore.resetNewPost();
    busPostStore.getDailyPosts();
  }

  dataURItoBlob = async (dataURI) => {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  };

  handleCloseNotification(type) {
    Notification.close();
    if (type === "success") {
      Notification[type]({
        title: "Successfully Uploaded Post.",
      });
    }
  }

  uploadPost = async () => {
    Notification["info"]({
      title: "Loading",
      duration: 0,
      description: <div>Please wait while your post is uploading...</div>,
    });
    let post = busPostStore.newpost;
    let progress = 0;
    let totalprogress = 1 + 4 * post.medias.length;

    let data = new FormData();

    this.setState({ loading: false });

    let mediafiles = [];
    let singlemedia;
    // var reader = new FileReader();
    let media = "";
    let mediaList = [];
    let blob;

    for (let i = 0; i < post.medias.length; i++) {
      blob = await this.dataURItoBlob(post.medias[i]);
      media = "";
      let fd = new FormData();
      fd.append(
        "image",
        blob,
        `${moment().format("DDMMMYYhhmm")}_${Math.round(
          Math.random() * 1000000
        )}`
      );
      await instance
        .post("api/media/new/upload", fd, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          // console.log(res.data.fileLocation);
          mediaList.push(res.data.fileLocation);
          progress += 4;
          this.setState({
            progress: this.state.progress + (100 * 4) / totalprogress,
          });
        });
    }
    console.log(mediaList);
    let requestData = {
      description: post.description,
      medias: mediaList,
    };
    await instance
      .post("/api/businesspost/postnew", requestData, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        // console.log(res.data);
        // userStore.changeUserCredit(-1);
        // userStore.getUserData();
        console.log(res.data);
        this.setState({
          loading: false,
          uploadedpost: res.data,
          step: this.state.step + 1,
        });
        this.handleCloseNotification("success");
        message.success("Request Submitted Successfully.");
      })
      .catch((err) => {
        this.handleCloseNotification("error");
        this.setState({ loading: false });
        message.error(err.message);
      });
  };

  render() {
    let buttonText = "Submit Post";
    let disableButton = false;
    if (this.state.step === 0) {
      buttonText = "Add Text";
      if (busPostStore.newpost.medias.length <= 0) {
        disableButton = true;
        buttonText = "Add Images Below";
      }
    } else if (this.state.step === 1) {
      buttonText = "Finalize post";
      if (busPostStore.newpost.description.length < 10) {
        disableButton = true;
        buttonText = "Add Description Below";
      }
    }
    if (this.state.loading) {
      disableButton = true;
      buttonText = "Loading ...";
    }

    if (busPostStore.dailyposts >= 1) {
      return <MaxPosts limit={1} />;
    }

    return (
      <div>
        <Container>
          <PaperSTD title="New Post">
            {this.state.step < 3 && (
              <FlexboxGrid>
                <Col xs={12}>
                  {this.state.step > 0 && (
                    <Button
                      block
                      color="red"
                      appearance="ghost"
                      disabled={this.state.loading}
                      onClick={() =>
                        this.setState({ step: this.state.step - 1 })
                      }
                    >
                      Back
                    </Button>
                  )}
                </Col>
                <Col xs={12}>
                  <Button
                    block
                    color="green"
                    disabled={disableButton}
                    onClick={() => {
                      if (this.state.step === 2) {
                        this.uploadPost();

                        this.setState({ loading: true });
                      } else {
                        this.setState({ step: this.state.step + 1 });
                      }
                    }}
                  >
                    {buttonText}
                  </Button>
                </Col>
              </FlexboxGrid>
            )}

            {this.state.loading && (
              <div style={{ width: 120 }}>
                <Circle
                  percent={Math.round(this.state.progress)}
                  status={this.state.progress < 100 ? "active" : "success"}
                />
              </div>
            )}
            <hr />
            {this.state.step === 1 && <Step1 />}
            {this.state.step > 0 && (
              <div>
                <h5 style={{ textAlign: "center" }}>Sample Post</h5>
                <SinglePostAntd
                  text={busPostStore.newpost.description}
                  business={userStore.user.business}
                  medias={busPostStore.newpost.medias}
                  disable={true}
                />
              </div>
            )}
            {this.state.step > 2 && (
              <Result
                status="success"
                title={`Successfully Created the Post`}
                subTitle={
                  "You can click on the below link to go to the post page. It will also appear on the home page and your company profile."
                }
                extra={[
                  <Button
                    appearance="primary"
                    key="console"
                    onClick={() =>
                      this.props.history.push(
                        `/garagepost/${this.state.uploadedpost._id}`
                      )
                    }
                  >
                    Go To Post
                  </Button>,
                ]}
              />
            )}
            {this.state.step === 0 && <Step0 />}
          </PaperSTD>
        </Container>
      </div>
    );
  }
}

export default withRouter(observer(NewPost));
