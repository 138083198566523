import React, { Component } from "react";
import { Loader } from "rsuite";
import { Skeleton } from "antd";

class LoadingParagraph extends Component {
  render() {
    return (
      <div>
        <Loader content="Loading..." center size="lg" />
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </div>
    );
  }
}

export default LoadingParagraph;
