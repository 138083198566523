import { Result } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Button } from "rsuite";
import { withRouter } from "react-router";

class PostNotFound extends Component {
  render() {
    return (
      <div>
        <Result
          title="Post Not Found"
          subTitle="The requested post is no longer available. You can visit all garage posts below to see more:"
        />
        <Button
          block
          color="green"
          onClick={() => this.props.history.push("/garageposts")}
        >
          See All Posts
        </Button>
      </div>
    );
  }
}

export default withRouter(observer(PostNotFound));
