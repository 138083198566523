import { makeObservable, observable, computed } from "mobx";
import axios from "axios";
import userStore from "./UserStore";
import baseURL from "../Media/baseURL";

const instance = axios.create({ baseURL: baseURL });

class BusinessStore {
  constructor() {
    this.businesses = [];
    this.loading = false;
    this.newbusinessrequest = {
      contactname: "",
      businessname: "",
      services: [],
      description: "",
      phone: "",
      email: "",
      website: null,
      validation: false,
    };
    this.selectedBusiness = {
      name: "",
      arname: "",
      description: "",
      ardescription: "",
      foundedon: new Date("01-01-2021"),
      address: "",
      phone: "",
      email: "",
      lat: 0,
      lng: 0,
      medias: [],
      services: [],
      status: "Active",
    };
    this.editBusiness = {
      name: "",
      arname: "",
      description: "",
      ardescription: "",
      foundedon: new Date("01-01-2021"),
      address: "",
      phone: "",
      email: "",
      lat: 0,
      lng: 0,
      medias: [],
      services: [],
      status: "Active",
    };
    this.businesspost = [];
    this.filter = { query: "", service: "All", speciality: "All" };
    makeObservable(this, {
      businesses: observable,
      businesspost: observable,
      selectedBusiness: observable,
      editBusiness: observable,
      loading: observable,
      filter: observable,
      newbusinessrequest: observable,
      filteredBusinesses: computed,
      businessServices: computed,
    });
  }
  resetfilter() {
    this.filter = { query: "", service: "All", speciality: "All" };
  }
  newBusRequest() {
    this.newbusinessrequest = {
      contactname: "",
      businessname: "",
      services: [],
      description: "",
      phone: "",
      email: "",
      website: null,
      validation: false,
    };
  }
  changeNewBusinessRequest(type, val) {
    if ((val.length > 60) & (type !== "description")) {
    } else {
      if (val.length < 600) {
        this.newbusinessrequest[type] = val;
      }
    }
  }

  closeLoading = async () => {
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve("resolved");
      }, 0);
    });
    this.loading = false;
  };

  getBusinesses() {
    return instance
      .get(`/api/business/active`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.businesses = res.data;
        console.log("Businesses Fetched");
      })
      .catch((err) => {
        console.log(err);
        console.error(err.response);
        alert("Error Fetching User Requests");
      });
  }

  getSingleBusiness(id) {
    this.loading = true;
    return instance
      .get(`/api/business/selected/${id}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.selectedBusiness = res.data;
        console.log("Single Business Fetched");
        this.closeLoading();
      })
      .catch((err) => {
        console.log(err);
        console.error(err.response);
        this.closeLoading();
        alert("Error Fetching User Requests");
      });
  }

  getSingleBusinessPost(id) {
    return instance
      .get(`/api/businesspost/business/${id}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.businesspost = res.data;
        console.log(this.businesspost);
        console.log("Single Business Post Fetched");
      })
      .catch((err) => {
        console.log(err);
        console.error(err.response);
        alert("Error Fetching User Requests");
      });
  }

  selectEditBusiness() {
    this.editBusiness = { ...this.selectedBusiness };
  }

  updateService(type, val) {
    if (type === "Add") {
      this.editBusiness.services.push(val);
    } else {
      let indexVal = this.editBusiness.services.findIndex(
        (item) => item._id === val._id
      );
      if (indexVal >= 0) this.editBusiness.services.splice(indexVal, 1);
    }
  }
  updateSpeciality(type, val) {
    if (type === "Add") {
      console.log(this.editBusiness.speciality);
      this.editBusiness.speciality.push(val);
    } else {
      let indexVal = this.editBusiness.speciality.findIndex(
        (item) => item._id === val._id
      );
      if (indexVal >= 0) this.editBusiness.speciality.splice(indexVal, 1);
    }
  }

  updateQuery(type, val) {
    console.log(val);
    if ((type === "service" || type === "speciality") & (val === null)) {
      val = "All";
    }

    this.filter[type] = val;
  }

  editEditBusiness(type, val) {
    this.editBusiness[type] = val;
  }

  get businessServices() {
    let business = this.businesses;
    let services = [];
    for (let i = 0; i < business.length; i++) {
      for (let j = 0; j < business[i].services.length; j++) {
        services.push(business[i].services[j].title);
      }
      business[i].servicetext = services.join(", ");
      services = [];
    }
    return business;
  }
  get filteredBusinesses() {
    let business = this.businesses;
    let services = [];
    for (let i = 0; i < business.length; i++) {
      for (let j = 0; j < business[i].services.length; j++) {
        services.push(business[i].services[j].title);
      }
      business[i].servicetext = services.join(" ");
    }
    if (this.filter.query !== "") {
      business = this.businesses.filter((business) =>
        `
        ${business.name} 
        ${business.arname} 
        ${business.description} 
        ${business.ardescription}
        `
          .toLowerCase()
          .includes(this.filter.query.toLowerCase())
      );
    }
    if ((this.filter.service !== null) & (this.filter.service !== "All")) {
      let servicebusiness = [];
      for (let i = 0; i < business.length; i++) {
        for (let j = 0; j < business[i].services.length; j++) {
          if (this.filter.service === business[i].services[j].title) {
            servicebusiness.push(business[i]);
          }
        }
      }
      business = servicebusiness;
    }
    if (
      (this.filter.speciality !== null) &
      (this.filter.speciality !== "All")
    ) {
      let specialitybusiness = [];
      for (let i = 0; i < business.length; i++) {
        for (let j = 0; j < business[i].speciality.length; j++) {
          if (this.filter.speciality === business[i].speciality[j].name) {
            specialitybusiness.push(business[i]);
          }
        }
      }
      business = specialitybusiness;
    }

    return business;
  }
}

const businessStore = new BusinessStore();
export default businessStore;
