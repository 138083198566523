import React, { Component } from "react";
import { Button } from "rsuite";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Divider } from "antd";

import userStore from "../../../Stores/UserStore";

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showPasswordConf: false,
      signupform: false,
    };
  }
  render() {
    let user = userStore.newuser;
    let validation = userStore.newuser.validation;
    let phoneerror = { val: false, message: "" };
    let nameerror = { val: false, message: "" };
    let emailerror = { val: false, message: "" };
    let passerror = { val: false, message: "" };
    let passconferror = { val: false, message: "" };
    let disabledSubmit = false;
    let finalValidation = true;
    if (user.email.length < 6 || !validateEmail(user.email)) {
      emailerror.val = true;
      emailerror.message = "Please enter a Valid Email Address.";
      disabledSubmit = true;
      finalValidation = false;
    }
    if (user.password.length < 6) {
      passerror.val = true;
      passerror.message = "Password must be greater than 6 characters.";
      disabledSubmit = true;
      finalValidation = false;
    }
    if (user.passwordconf !== user.password) {
      passconferror.val = true;
      passconferror.message = "Passwords do not match.";
      disabledSubmit = true;
      finalValidation = false;
    }
    if (user.phone < 10000000 || user.phone > 99999999) {
      phoneerror.val = true;
      phoneerror.message = "Please enter a Valid Kuwait Number.";
      disabledSubmit = true;
      finalValidation = false;
    }
    if (user.name.length < 3) {
      nameerror.val = true;
      nameerror.message = "Please enter a Name.";
      disabledSubmit = true;
      finalValidation = false;
    }
    if (!validation) {
      disabledSubmit = false;
      emailerror = { val: false, message: "" };
      passerror = { val: false, message: "" };
      passconferror = { val: false, message: "" };
      phoneerror = { val: false, message: "" };
      nameerror = { val: false, message: "" };
    }

    return (
      <div>
        <Divider>
          <h5>Signup Form</h5>
        </Divider>
        <TextField
          error={emailerror.val}
          helperText={emailerror.val && emailerror.message}
          variant="standard"
          margin="normal"
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          disabled={userStore.loading}
          autoFocus
          value={userStore.newuser.email}
          onChange={(e) => userStore.editnewuser("email", e.target.value)}
          style={{ background: "rgb(255,255,255,0)" }}
        />
        <TextField
          error={passerror.val}
          helperText={passerror.val && passerror.message}
          variant="standard"
          margin="normal"
          fullWidth
          disabled={userStore.loading}
          name="password"
          label="Password"
          type={this.state.showPassword ? "text" : "password"}
          id="password"
          autoComplete="current-password"
          value={userStore.newuser.password}
          style={{ background: "rgb(255,255,255,0)" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  tabindex="-1"
                  aria-label="toggle password visibility"
                  onMouseOver={() => this.setState({ showPassword: true })}
                  onMouseLeave={() => this.setState({ showPassword: false })}
                >
                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => userStore.editnewuser("password", e.target.value)}
        />
        <TextField
          error={passconferror.val}
          helperText={passconferror.val && passconferror.message}
          variant="standard"
          margin="normal"
          fullWidth
          disabled={userStore.loading}
          name="passwordconf"
          label="Confirm Password"
          type={this.state.showPasswordConf ? "text" : "password"}
          id="passwordconf"
          autoComplete="current-passwordconf"
          value={userStore.newuser.passwordconf}
          style={{ background: "rgb(255,255,255,0)" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" tabindex="-1">
                <IconButton
                  tabindex="-1"
                  aria-label="toggle password visibility"
                  onMouseOver={() => this.setState({ showPasswordConf: true })}
                  onMouseLeave={() =>
                    this.setState({ showPasswordConf: false })
                  }
                >
                  {this.state.showPasswordConf ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) =>
            userStore.editnewuser("passwordconf", e.target.value)
          }
        />
        <TextField
          error={nameerror.val}
          helperText={nameerror.val && nameerror.message}
          variant="standard"
          margin="normal"
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoComplete="name"
          disabled={userStore.loading}
          value={userStore.newuser.name}
          onChange={(e) => userStore.editnewuser("name", e.target.value)}
          style={{ background: "rgb(255,255,255,0)" }}
        />
        <TextField
          error={phoneerror.val}
          helperText={phoneerror.val && phoneerror.message}
          variant="standard"
          margin="normal"
          fullWidth
          id="phone"
          label="Phone"
          name="phone"
          autoComplete="phone"
          disabled={userStore.loading}
          type="Number"
          value={userStore.newuser.phone}
          onChange={(e) => userStore.editnewuser("phone", e.target.value)}
          style={{ background: "rgb(255,255,255,0)" }}
        />

        <br />
        <Button
          block
          color="green"
          style={{ boxShadow: "0px 0px 3px black" }}
          disabled={disabledSubmit}
          onClick={() => {
            let userData = {
              email: user.email,
              password: user.password,
              name: user.name,
              phone: user.phone,
              validation: finalValidation,
            };
            userStore.registerUser(userData);
          }}
        >
          {userStore.loading ? "Loading ..." : "Sign Up"}
        </Button>
      </div>
    );
  }
}

export default withRouter(observer(LoginForm));
