import React, { Component } from "react";
import { observer } from "mobx-react";
import { Button, Col, FlexboxGrid, Notification } from "rsuite";
import { withRouter } from "react-router";
import moment from "moment";
import adminStore from "../../../Stores/AdminStore";
import ImageParallaxLG from "../../../Components/ImageParallax/ImageParallaxLG";
import { TextField } from "@material-ui/core";
import axios from "axios";
import baseURL from "../../../Media/baseURL";
import userStore from "../../../Stores/UserStore";
import UnifiedModal from "../../../Components/UnifiedModal/UnifiedModal";

const instance = axios.create({ baseURL: baseURL });

class RequestModal extends Component {
  constructor(props) {
    super(props);
    this.state = { adminfeedback: false, loading: false };
  }

  adminRejectRequest = async () => {
    try {
      this.setState({ loading: true });
      let req = adminStore.request;
      let requestData = {
        adminfeedback: req.adminfeedback,
        status: "Rejected",
      };
      return instance
        .put(`/api/request/admin/reject/${req._id}`, requestData, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          // console.log(res);
          adminStore.fetchPendingRequests();
          this.props.onHide();
          Notification["error"]({
            title: "Request Rejected",
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
          Notification["error"]({
            title: "Error",
            description: "An Error Occured and the request was not changed.",
          });
        });
    } catch (err) {
      console.log(err);
    }
  };

  approveRequest = async (type) => {
    try {
      this.setState({ loading: true });
      let req = adminStore.request;
      let requestData = { status: "Open", closedate: moment().add(3, "day") };

      return instance
        .put(`/api/request/admin/approve/${req._id}`, requestData, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          // console.log(res);
          adminStore.fetchPendingRequests();
          adminStore.resetRequest();
          this.props.onHide();

          Notification["success"]({
            title: "Request Approved",
            description: "Request approved and sent to Vendors.",
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
          Notification["error"]({
            title: "Request Rejected",
            description: "An Error Occured and the request was not changed.",
          });
        });
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    let editreq = adminStore.request;

    return (
      <UnifiedModal
        title={`${editreq.service.title} Request Details:`}
        show={this.props.show}
        onHide={this.props.onHide}
        footer={null}
      >
        <div style={{ paddingLeft: "5%" }}>
          <h6>
            <strong>Description: </strong>
            {editreq.description}
            <br />
            <strong>Car: </strong>
            {editreq.year} {editreq.make} - {editreq.model}
            <br />
            <strong>Close Date: </strong>
            {moment(editreq.closedate).format("DD-MMM-YY")} (
            {moment(editreq.closedate).fromNow()})
          </h6>
        </div>
        <ImageParallaxLG request={editreq} height={200} />
        <FlexboxGrid>
          {!this.state.adminfeedback && (
            <Col style={{ padding: 20 }} xs={12}>
              <Button
                block
                color="green"
                onClick={() => this.approveRequest("Approve")}
                disabled={this.state.loading}
              >
                Approve
              </Button>
            </Col>
          )}
          <Col style={{ padding: 20 }} xs={12}>
            <Button
              block
              disabled={this.state.loading}
              appearance={this.state.adminfeedback && "ghost"}
              color="red"
              onClick={() =>
                this.setState({ adminfeedback: !this.state.adminfeedback })
              }
            >
              {this.state.adminfeedback ? "Cancel Rejection" : "Reject Request"}
            </Button>
          </Col>
        </FlexboxGrid>
        {this.state.adminfeedback && (
          <div>
            <hr />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              error
              label="Rejection Comments"
              multiline
              value={adminStore.request.adminfeedback}
              onChange={(e) => adminStore.editfeedback(e.target.value)}
            />
            {adminStore.request.adminfeedback.length > 5 && (
              <Button
                disabled={this.state.loading}
                block
                color="red"
                onClick={() => this.adminRejectRequest()}
              >
                Send Feedback to Customer
              </Button>
            )}
          </div>
        )}
      </UnifiedModal>
    );
  }
}

export default withRouter(observer(RequestModal));
