import React, { Component } from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { IconButton } from "@material-ui/core";

class HorizontalScroll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showfirst: false,
      showlast: true,
    };
  }

  render() {
    let rightArrow = (
      <IconButton aria-label="Scroll Right">
        <CaretRightOutlined />
      </IconButton>
    );
    let leftArrow = (
      <IconButton aria-label="Scroll Left">
        <CaretLeftOutlined />
      </IconButton>
    );
    rightArrow = false;
    leftArrow = false;

    return (
      <ScrollMenu
        hideSingleArrow={true}
        hideArrows={true}
        alignCenter={false}
        wheel={true}
        dragging={true}
        arrowLeft={leftArrow}
        arrowRight={rightArrow}
        data={this.props.data}
        style={{ width: "100%" }}
      />
    );
  }
}

export default HorizontalScroll;
