import React, { Component } from "react";
import jarallax from "jarallax";

class JaraImage extends Component {
  constructor(props) {
    super(props);

    this.$el = React.createRef();
  }

  // init on mount.
  componentDidMount() {
    jarallax(this.$el.current, this.props.options);
  }

  // reinit when props changed.
  componentDidUpdate(prevProps) {
    if (
      !this.isDestroyed &&
      JSON.stringify(prevProps) !== JSON.stringify(this.props)
    ) {
      jarallax(this.$el.current, "destroy");
      jarallax(this.$el.current, this.props.options);
    }
  }

  // destroy on unmount.
  componentWillUnmount() {
    this.isDestroyed = true;
    jarallax(this.$el.current, "destroy");
  }

  render() {
    const { options, className = "" } = this.props;

    return (
      <div className={`jarallax ${className}`} ref={this.$el}>
        <img className="jarallax-img" src={options.src} />
      </div>
    );
  }
}

export default JaraImage;
