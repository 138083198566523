import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import BusPostImages from "../../../Components/ImageParallax/BusPostImages";
import busPostStore from "../../../Stores/BusPostStore";
import { FlexboxGrid } from "rsuite";
import { Paper } from "@material-ui/core";
import SinglePostAntd from "../../../Components/SinglePost/SinglePostAntd";
import LoadingParagraph from "../../../Components/LoadingPages/LoadingParagraph";

class GaragePosts extends Component {
  componentDidMount() {
    busPostStore.fetchAllPosts();
  }
  render() {
    let outputdata = (
      <div>
        {busPostStore.allposts.map((post, index) => (
          <SinglePostAntd
            postNumId={post.busPostNumId}
            postId={post._id}
            text={post.description}
            medias={post.medias}
            business={post.business}
            liked={post.liked}
            key={index}
          />
        ))}
      </div>
    );
    if (busPostStore.loading) {
      outputdata = (
        <div style={{ width: "100vh", maxWidth: 500 }}>
          <LoadingParagraph />
        </div>
      );
    }
    return (
      <div>
        <br />
        <FlexboxGrid justify="center">
          <Paper
            style={{
              padding: 10,
              borderRadius: 10,
              justifyContent: "center",
              maxWidth: 500,
              minHeight: "80vh",
            }}
            elevation={5}
          >
            <h3 style={{ textAlign: "center" }}>Garage Posts</h3>
            {outputdata}
          </Paper>
        </FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(GaragePosts));
