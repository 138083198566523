import { Button, Card, CardActions, CardMedia } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { FlexboxGrid, Message } from "rsuite";
import "react-image-crop/dist/ReactCrop.css";
import ImageCropper from "../../../Components/ImageCropper/ImageCropper";
import busPostStore from "../../../Stores/BusPostStore";

class Step0 extends Component {
  render() {
    let imagecrop = (
      <div>
        {" "}
        <h5>Add New Image</h5>
        <ImageCropper imageAction={"buspost"} />
      </div>
    );

    if (busPostStore.newpost.medias.length >= 3) {
      imagecrop = (
        <Message
          type="warning"
          title="3 Images Max"
          description={
            <p>
              Kindly note that a maximum of 3 images is allowed per post. To
              replace an image please one of the above images.
            </p>
          }
        />
      );
    }
    return (
      <div>
        <h5>Uploaded Images:</h5>
        <FlexboxGrid justify="space-around">
          {busPostStore.newpost.medias.map((image, index) => (
            <Card
              key={index}
              style={{
                width: 200,
                margin: 5,
                boxShadow: "0px 0px 3px black",
              }}
            >
              <CardMedia
                style={{ height: 90 }}
                image={image}
                title="uploaded"
              />
              <CardActions>
                <Button
                  size="small"
                  color="secondary"
                  onClick={() => busPostStore.removePhoto(image)}
                >
                  Delete
                </Button>
              </CardActions>
            </Card>
          ))}
        </FlexboxGrid>
        <hr />
        {imagecrop}
      </div>
    );
  }
}

export default withRouter(observer(Step0));
