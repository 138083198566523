import { Card, CardActionArea, CardMedia } from "@material-ui/core";
import { Chip, Typography } from "@material-ui/core";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import TimerIcon from "@material-ui/icons/Timer";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Col, FlexboxGrid } from "rsuite";
import { withRouter } from "react-router";

class SelectQuoteCard extends Component {
  render() {
    let quote = this.props.quote;
    let selected = this.props.selected;
    let disableclick = this.props.disableclick;

    return (
      <Col xs={24}>
        <Card
          style={{
            width: "100%",
            borderRadius: 5,
            margin: 5,
            boxShadow: !selected && "0px 0px 5px black",
            border: selected && "5px solid green",
          }}
          variant="outlined"
          onClick={() => {
            if (!disableclick) {
              this.props.select(quote);
            }
          }}
        >
          <CardActionArea>
            <FlexboxGrid>
              <Col xs={24} sm={4} md={4}>
                <CardMedia
                  alt={quote.business.name}
                  image={quote.business.logo}
                  style={{
                    height: 100,
                    borderRadius: 3,
                  }}
                />
              </Col>
              <Col xs={24} sm={20} md={8}>
                <Typography
                  style={{ fontSize: 14 }}
                  color="textSecondary"
                  gutterBottom
                >
                  {quote.business.name}
                </Typography>
                <Typography variant="h5" component="h2">
                  {Math.round(1000 * quote.price) / 1000} KD
                </Typography>
                <Typography
                  style={{ marginBottom: 12, fontWeight: "bold" }}
                  color="textSecondary"
                >
                  {Math.round(10 * quote.duration) / 10} hr
                  {Math.round(10 * quote.duration) / 10 !== 1 && "s"}
                </Typography>
              </Col>
              <Col xs={24} sm={24} md={12}>
                {this.props.cheapest && (
                  <Chip
                    variant="outlined"
                    label="Lowest Price"
                    size="small"
                    icon={<AttachMoneyIcon />}
                    style={{
                      backgroundColor: "#78ff7f",
                      boxShadow: "0px 0px 2px black",
                    }}
                  />
                )}
                {this.props.fastest && (
                  <Chip
                    variant="outlined"
                    label="Fastest"
                    size="small"
                    icon={<TimerIcon />}
                    style={{
                      backgroundColor: "#78ff7f",
                      boxShadow: "0px 0px 2px black",
                    }}
                  />
                )}
                <Typography variant="body2" component="p">
                  {quote.remarks}
                </Typography>
                {quote.excludes.length > 3 && (
                  <Typography style={{ color: "red" }}>
                    <strong>Excludes: </strong>
                    {quote.excludes}
                  </Typography>
                )}
              </Col>
            </FlexboxGrid>
          </CardActionArea>
        </Card>
      </Col>
    );
  }
}

export default withRouter(observer(SelectQuoteCard));
