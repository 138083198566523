import { Container } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Col, FlexboxGrid, Checkbox, Button } from "rsuite";
import { withRouter } from "react-router";
import PaperSTD from "../../../Components/PaperSTD/PaperSTD";
import userStore from "../../../Stores/UserStore";
import PasswordEditBlock from "./PasswordEditBlock";
import ProfileEditBlock from "./ProfileEditBlock";
import UserSubscriptions from "./UserSubscriptions";

class UserProfile extends Component {
  componentDidMount() {
    userStore.changeProfileInfo("name", userStore.user.name);
    userStore.changeProfileInfo("phone", userStore.user.phone);
    userStore.changeProfileInfo("email", userStore.user.email);
    userStore.changeProfileInfo("tempemail", userStore.user.tempemail);
  }
  render() {
    console.log(userStore.user);
    return (
      <div>
        <Container>
          <br />
          <PaperSTD title="Profile">
            <Container>
              <h4>
                <strong>Name: </strong>
                <i>{userStore.user.name}</i>
              </h4>
              <h4>
                <strong>Email: </strong>
                <i>{userStore.user.email}</i>
              </h4>
              <h4>
                <strong>Phone: </strong>
                <i>+965 - {userStore.user.phone}</i>
              </h4>
              <h4>
                <strong>Account Type: </strong>
                <i>{userStore.user.group}</i>
              </h4>
              <hr />
              <UserSubscriptions />
              <hr />
              <FlexboxGrid>
                <Col sm={24} md={12}>
                  <h5>Change Profile Information:</h5>
                  <ProfileEditBlock type={"name"} />
                  <ProfileEditBlock type={"email"} />
                  <ProfileEditBlock type={"phone"} />
                </Col>
                <Col sm={24} md={12} style={{ minHeight: 300 }}>
                  <h5>Change Password:</h5>
                  <PasswordEditBlock />
                </Col>
              </FlexboxGrid>
            </Container>
          </PaperSTD>
        </Container>
      </div>
    );
  }
}

export default withRouter(observer(UserProfile));
