import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { Card, CardActionArea, CardContent } from "@material-ui/core";
import { CardMedia, Typography } from "@material-ui/core";
import { Notification } from "rsuite";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import requestStore from "../../../Stores/RequestStore";
import HorizontalScroll from "../../../Components/HorizontalScroll/HorizontalScroll";
import userStore from "../../../Stores/UserStore";

class NewRequestCards extends Component {
  render() {
    let services = this.props.services.map((service, index) => (
      <Card style={{ width: 200, margin: 10 }} key={index}>
        <CardActionArea
          onClick={() => {
            if (userStore.user.group === "Business") {
              Notification["info"]({
                title: `Only Customers can submit Requests`,
                description: (
                  <div>
                    This link is meant for customers to submit Service Requests.
                    When the customer clicks here a New Request form will show
                    up.
                  </div>
                ),
              });
            } else {
              requestStore.resetRequest(service);
              this.props.history.push("/newrequest");
            }
          }}
        >
          <CardMedia
            component="img"
            alt={service.title}
            height="140"
            image={service.media}
            title={service.title}
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="h4">
              {service.title}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
            ></Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    ));

    return <HorizontalScroll data={services} />;
  }
}

export default withRouter(observer(NewRequestCards));
