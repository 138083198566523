import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import { Input, Tooltip, message } from "antd";
import { InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import userStore from "../../../Stores/UserStore";
import baseURL from "../../../Media/baseURL";

const { Search } = Input;

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
const instance = axios.create({ baseURL: baseURL });

class ReferEmailBox extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, sent: false };
  }

  sendEmail = async (email, validation) => {
    if (validation) {
      try {
        console.log("Test");
        this.setState({ loading: true });
        let requestData = { email: email };

        let newrequest = await instance
          .post("/email/auth/sendreferral", requestData, {
            headers: { authtoken: userStore.token },
          })
          .then((res) => {
            // console.log(res);
            this.setState({ loading: false, sent: true });
            message.success("Email Referral Sent.");
            userStore.changeReferal("Add");
          })
          .catch((err) => {
            console.error(err.response);
            message.error(err.response.data);
            this.setState({ loading: false, sent: false });
          });
      } catch (err) {
        console.log(err);
      }
    } else {
      message.error("Please write in a Valid Email.");
    }
  };

  render() {
    let email = this.props.email;
    let index = this.props.index;
    let emailvalidation = validateEmail(email);
    let suffixCode;

    if (!emailvalidation) {
      suffixCode = (
        <Tooltip title="Please enter a Valid Email.">
          <InfoCircleOutlined style={{ color: "red" }} />
        </Tooltip>
      );
    }

    let enterButton = "Send Email";
    if (this.state.loading) {
      enterButton = "Sending...";
    } else if (this.state.sent) {
      enterButton = "Sent!";
    }
    return (
      <div>
        <Search
          prefix={<UserOutlined className="site-form-item-icon" />}
          suffix={suffixCode}
          style={{ maxWidth: 400 }}
          placeholder={`${index + 1}) Write in an Email`}
          enterButton={enterButton}
          size="large"
          disabled={this.state.loading || this.state.sent}
          loading={this.state.loading}
          onChange={(e) => userStore.editReferal(index, e.target.value)}
          onSearch={() => this.sendEmail(email, emailvalidation)}
        />
        <br />
        <br />
      </div>
    );
  }
}

export default withRouter(observer(ReferEmailBox));
