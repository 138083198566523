import React, { Component } from "react";
import {  Collapse } from "antd";
const { Panel } = Collapse;

class MultipleCollapse extends Component {
  render() {
    let panels = this.props.panels;

    let panelrows = panels.map((panel, index) => (
      <Panel header={panel.title} key={index}>
        {panel.children}
      </Panel>
    ));
    return (
      <Collapse accordion defaultActiveKey={["0"]}>
        {panelrows}
      </Collapse>
    );
  }
}

export default MultipleCollapse;
