import { computed, makeObservable, observable } from "mobx";
import userStore from "./UserStore";
import baseURL from "../Media/baseURL";
import axios from "axios";
import { message } from "antd";
const instance = axios.create({ baseURL: baseURL });

class LetterStore {
  constructor() {
    this.conversations = [];
    this.unseen = [];
    this.letters = [];
    this.unseenNotifis = [];
    this.notifis = { nots: [], page: 1, total: 1 };
    this.loadnotification = false;
    this.selectedConvo = {};
    this.singleconvoloading = true;
    this.createletter = {};
    this.userQuery = { subject: "General Information", message: "" };
    this.newmessage = "";
    this.inboxtab = 0;
    makeObservable(this, {
      conversations: observable,
      unseen: observable,
      letters: observable,
      unseenNotifis: observable,
      notifis: observable,
      selectedConvo: observable,
      createletter: observable,
      userQuery: observable,
      newmessage: observable,
      singleconvoloading: observable,
      inboxtab: observable,
      loadnotification: observable,
    });
  }

  changeinbox(val) {
    this.inboxtab = val;
  }

  updateNewQuery(type, val) {
    if (type === "Reset") {
      this.userQuery = { subject: "", message: "" };
    } else {
      this.userQuery[type] = val;
    }
  }

  addLetter(letter) {
    this.selectedConvo.letters.push(letter);
    this.newmessage = "";
  }

  updateNewMessage(val) {
    this.newmessage = val;
  }

  resetMessage(val) {
    this.newmessage = "";
  }

  selectConversation(convo, loading) {
    if (!userStore.signedIn) {
      this.unseen = [];
      return;
    }
    this.changeinbox(2);
    // this.selectedConvo = convo;
    if (loading) this.selectedConvo = { subject: convo.subject };
    this.singleconvoloading = loading;
    return instance
      .get(`/api/letter/conversation/single/${convo._id}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((conversation) => {
        this.selectedConvo = conversation;
      })
      .then((convo) => {
        console.log("Single Convo Fetched");
        this.singleconvoloading = false;
      })
      .catch((err) => {
        this.singleconvoloading = false;
        console.log(err);
        console.error(err.response);
        alert("Error Fetching Conversation");
      });
  }

  fetchAll() {
    if (!userStore.signedIn) {
      this.unseen = [];
      return;
    }
    return instance
      .get(`/api/letter/received/all`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((conversations) => {
        if (this.conversations !== conversations) {
          this.conversations = conversations;
          console.log(conversations);
          console.log("All Received Messages Fetched");
        }
      })
      .catch((err) => {
        console.log(err);
        console.error(err.response);
        // alert("Error Fetching All Received Messages");
      });
  }

  fetchAllNotifications(inputpage) {
    if (inputpage !== this.notifis.currentPage) {
      console.log("CATCH!!!");
      this.loadnotification = true;
      this.notifis.currentPage = inputpage;
    }

    if (!userStore.signedIn) {
      this.unseen = [];
      return;
    }
    return instance
      .get(`/api/notifi/byuser/all?page=${this.notifis.currentPage}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((notifis) => {
        console.log(notifis);
        this.notifis = notifis;
        console.log("All Notifications Fetched");
        this.loadnotification = false;
      })
      .catch((err) => {
        console.log(err);
        console.error(err.response);
        this.loadnotification = false;
        // alert("Error Fetching All Received Messages");
      });
  }

  deleteSingleNotification(notifiId, page) {
    this.notifis.nots = this.notifis.nots.filter((not) => not._id !== notifiId);
    console.log(notifiId);
    return instance
      .delete(`/api/notifi/delete/${notifiId}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((res) => {
        let page = this.notifis.currentPage;
        if (this.notifis.nots.length <= 1) {
          if (this.notifis.currentPage > 1) {
            page = this.notifis.currentPage - 1;
          }
        }
        this.fetchAllNotifications(page);
        message.success("Notification Deleted");
      })
      .catch((err) => {
        console.log(err);
        console.error(err.response);
        this.loadnotification = false;
        // alert("Error Fetching All Received Messages");
      });
  }

  deleteAllNotifications() {
    return instance
      .delete(`/api/notifi/deleteall/notifications/`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((res) => {
        this.fetchAllNotifications(1);
        message.success("Notification Deleted");
      })
      .catch((err) => {
        console.log(err);
        console.error(err.response);
        this.loadnotification = false;
        // alert("Error Fetching All Received Messages");
      });
  }

  fetchUnseenLetters() {
    if (!userStore.signedIn) {
      this.unseen = [];
      return;
    }
    return instance
      .get(`/api/letter/received/unseen`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((unseen) => {
        this.fetchUnseenNotifis();
        if (this.unseen !== unseen) {
          this.unseen = unseen;
          console.log("New Messages Fetched");
        }
      })
      .catch((err) => {
        console.log(err);
        console.error(err.response);
        // alert("Error Fetching New Messages");
      });
  }

  fetchUnseenNotifis() {
    if (!userStore.signedIn) {
      this.unseenNotifis = [];
      return;
    }
    return instance
      .get(`/api/notifi/byuser/new`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((unseenNotifis) => {
        if (this.unseenNotifis !== unseenNotifis) {
          this.unseenNotifis = unseenNotifis;

          console.log("New Notifications Fetched");
        }
      })
      .catch((err) => {
        console.log(err);
        console.error(err.response);
        // alert("Error Fetching New Messages");
      });
  }
}

const letterStore = new LetterStore();
export default letterStore;
