import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  Button,
  Col,
  FlexboxGrid,
  HelpBlock,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputNumber,
} from "rsuite";
import productStore from "../../../../Stores/ProductStore";

class SingleItem extends Component {
  render() {
    let index = this.props.index;
    let item = productStore.newoption.items[index];
    let deletebutton = (
      <IconButton
        appearance="ghost"
        onClick={() => productStore.removeOptionItem(index)}
        icon={<Icon icon="close" />}
        color="red"
        circle
        size="xs"
      />
    );
    return (
      <div>
        <HelpBlock>
          {index > 0 && deletebutton} Item {index + 1}
        </HelpBlock>
        <FlexboxGrid>
          <Col xs={24} sm={12} md={8}>
            <InputGroup>
              <InputGroup.Addon style={{ color: item.title === "" && "red" }}>
                English:
              </InputGroup.Addon>
              <Input
                value={item.title}
                onChange={(e) =>
                  productStore.updateOptionItem("title", e, index)
                }
              />
            </InputGroup>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <InputGroup>
              <InputGroup.Addon style={{ color: item.titlear === "" && "red" }}>
                Arabic:
              </InputGroup.Addon>
              <Input
                value={item.titlear}
                onChange={(e) =>
                  productStore.updateOptionItem("titlear", e, index)
                }
              />
            </InputGroup>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <InputGroup>
              <InputGroup.Addon style={{ color: item.price === "" && "red" }}>
                Price:
              </InputGroup.Addon>
              <InputNumber
                value={item.price}
                NumbInputNumber
                min={0}
                max={9999}
                onChange={(e) =>
                  productStore.updateOptionItem("price", e, index)
                }
              />
              <InputGroup.Addon style={{ color: item.price === "" && "red" }}>
                KD
              </InputGroup.Addon>
            </InputGroup>
          </Col>
        </FlexboxGrid>
        <br />
      </div>
    );
  }
}

export default withRouter(observer(SingleItem));
