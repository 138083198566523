import React, { Component } from "react";
import { observer } from "mobx-react";
import { Button, Col, Modal, FlexboxGrid } from "rsuite";
import { withRouter } from "react-router";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import userStore from "../../../Stores/UserStore";
import baseURL from "../../../Media/baseURL";
import quoteStore from "../../../Stores/QuoteStore";
import UnifiedModal from "../../../Components/UnifiedModal/UnifiedModal";
import { Notification } from "rsuite";
import requestSummaryStore from "../../../Stores/RequestSummaryStore";

const instance = axios.create({ baseURL: baseURL });

class NewQuoteModal extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }
  postQuote(requestId) {
    this.setState({ loading: true });
    let finalData = {
      remarks: quoteStore.newquote.remarks,
      excludes: quoteStore.newquote.excludes,
      price: quoteStore.newquote.price,
      duration: quoteStore.newquote.duration,
      status: "Submitted",
      user: userStore.user._id,
      business: userStore.user.business,
      request: requestId,
    };
    return instance
      .post(`/api/quote/postnew`, finalData, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ loading: false });
        this.props.onHide();
        this.props.history.push(`/request/${requestId}`);
        quoteStore.fetchSingleRequest(requestId);
        Notification["success"]({
          title: `Quote Submitted (ID#${res.data.quoteNumId})`,
          description: (
            <div>Quote (ID# {res.data.quoteNumId}) Successfully Submitted.</div>
          ),
          duration: 0,
        });
        requestSummaryStore.fetchSingleRequest(requestId);
      })
      .catch((err) => {
        this.setState({ loading: false });
        Notification["error"]({
          title: "Link Expired",
          description:
            "An error occured. The Quote was not submitted. Please contact our office if this persists.",
          duration: 0,
        });
      });
  }

  render() {
    let quote = quoteStore.newquote;
    let disableButton = { val: false, message: "Create Quote" };
    let requestId = this.props.requestId;

    if (this.state.loading) {
      disableButton = { val: true, message: "Loading..." };
    }
    if (quote.price <= 0) {
      disableButton = { val: true, message: "Please add Valid Price" };
    } else if (quote.remarks.length < 10) {
      disableButton = { val: true, message: "Please add Quote Details" };
    }

    return (
      <UnifiedModal
        title={"Create a New Quote"}
        show={this.props.show}
        onHide={this.props.onHide}
        footer={
          <FlexboxGrid>
            {!this.state.loading && (
              <Col xs={12}>
                <Button
                  block
                  appearance="ghost"
                  color="red"
                  onClick={() => {
                    this.props.onHide();
                  }}
                >
                  Cancel
                </Button>
              </Col>
            )}
            <Col xs={!this.state.loading ? 12 : 24}>
              <Button
                block
                color="green"
                disabled={disableButton.val}
                onClick={() => {
                  this.postQuote(requestId);
                }}
              >
                {disableButton.message}
              </Button>
            </Col>
          </FlexboxGrid>
        }
      >
        <FlexboxGrid>
          <Col xs={12}>
            <TextField
              variant="standard"
              margin="normal"
              fullWidth
              label="Job Price (KD)"
              type="number"
              value={quote.price}
              onChange={(e) => quoteStore.editNewQuote("price", e.target.value)}
            />
          </Col>
          <Col xs={12}>
            <TextField
              variant="standard"
              margin="normal"
              fullWidth
              label={`Job Duration (hrs)`}
              type="number"
              value={quote.duration}
              onChange={(e) =>
                quoteStore.editNewQuote("duration", e.target.value)
              }
            />
          </Col>
        </FlexboxGrid>
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          label="Quote Details"
          multiline
          value={quote.remarks}
          onChange={(e) => quoteStore.editNewQuote("remarks", e.target.value)}
        />
        <TextField
          variant="standard"
          margin="normal"
          color="secondary"
          fullWidth
          label="Does Not Include the Following:"
          multiline
          value={quote.excludes}
          onChange={(e) => quoteStore.editNewQuote("excludes", e.target.value)}
        />
      </UnifiedModal>
    );
  }
}

export default withRouter(observer(NewQuoteModal));
