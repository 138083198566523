import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { Container } from "@material-ui/core";
import RequestCard from "./RequestCard";
import requestStore from "../../../Stores/RequestStore";
import "react-multi-carousel/lib/styles.css";
import RequestModal from "../Modals/RequestModal";
import { FlexboxGrid } from "rsuite";
import PaperSTD from "../../../Components/PaperSTD/PaperSTD";

class RequestList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  closeModal = () => {
    this.setState({ showModal: false });
  };
  openModal = () => {
    this.setState({ showModal: true });
  };

  render() {
    let requests = requestStore.openRequests.map((req, index) => (
      <RequestCard request={req} key={index} openModal={this.openModal} />
    ));

    return (
      <Container>
        <RequestModal show={this.state.showModal} onHide={this.closeModal} />
        <PaperSTD title={"My Requests"}>
          <FlexboxGrid>{requests}</FlexboxGrid>
        </PaperSTD>
      </Container>
    );
  }
}

export default withRouter(observer(RequestList));
