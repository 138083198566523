import "./App.css";
import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import CDNlinks from "./CDNLinks/CDNlinks";
import MiniDrawer from "./Components/HeaderFooter/MiniDrawer";
import Views from "./Views/Views";
import checkForIOS from "./CDNLinks/useIsIOS";
import InstallPWA from "./CDNLinks/InstallPWA";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { prompt: false };
    this.closePrompt = this.closePrompt.bind(this);
  }

  componentDidMount() {
    let prompt = checkForIOS();
    if (prompt !== false) {
      if (prompt.prompt) {
        this.setState({ prompt: prompt });
      }
    }
  }

  closePrompt() {
    this.setState({ prompt: false });
  }

  render() {
    if (window.location.host.split(".")[0] === "app") {
      return <h5>Welcome!!</h5>;
    }

    return (
      <div>
        <InstallPWA show={this.state.prompt} onHide={this.closePrompt} />
        <CDNlinks />
        <MiniDrawer>
          <Views />
        </MiniDrawer>
      </div>
    );
  }
}

export default withRouter(observer(App));
