import { observer } from "mobx-react";
import { withRouter } from "react-router";
import React, { Component } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import businessStore from "../../Stores/BusinessStore";
import Avatar from "antd/lib/avatar/avatar";
import { Tag } from "antd";

class BusinessMaps extends Component {
  constructor(props) {
    super(props);
    this.state = { items: [], user: {}, height: 400, refresh: true };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  componentDidUpdate() {}

  updateWindowDimensions() {
    this.setState({ height: (window.innerHeight * 2) / 3 });
  }

  render() {
    let markers = businessStore.filteredBusinesses.map((business, index) => (
      <Marker opacity={1} position={[business.lat, business.lng]} key={index}>
        <Popup>
          <Avatar size="small" src={business.logo} />
          <span> </span>
          <Tag
            color="green"
            style={{ color: "black" }}
            onClick={() => this.props.history.push(`/business/${business._id}`)}
          >
            {business.name}
          </Tag>
        </Popup>
      </Marker>
    ));

    return (
      <div>
        <div>
          {!businessStore.loading && (
            <MapContainer
              center={[
                businessStore.businesses[0].lat,
                businessStore.businesses[0].lng,
              ]}
              zoom={11}
              style={{ height: this.state.height, zIndex: 0 }}
              scrollWheelZoom={false}
            >
              <TileLayer
                attribution=""
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {markers}
            </MapContainer>
          )}
        </div>
        <hr />
      </div>
    );
  }
}

export default withRouter(observer(BusinessMaps));
