import React, { Component } from "react";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Button from "@material-ui/core/Button";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import grey from "@material-ui/core/colors/grey";
import ImageCard from "./ImageCard";
import { FlexboxGrid } from "rsuite";
import requestStore from "../../../../Stores/RequestStore";
import ImageCropper from "../../../../Components/ImageCropper/ImageCropper";
import Resizer from "react-image-file-resizer";

const resizeFile = async (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      800,
      800,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

const dataURIToBlob = async (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};

class Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = async (event) => {
    let file;
    let image;
    let newFile;
    for (let i = 0; i < event.target.files.length; i++) {
      file = event.target.files[i];
      image = await resizeFile(file);
      // newFile = await dataURIToBlob(image);
      // console.log(file);
      // console.log(newFile);
      requestStore.addPhotos(image, this.props.type);
    }
  };

  render() {
    let request = this.props.request;

    let imageCards = request.medias.map((file, index) => (
      <ImageCard
        key={index}
        index={index}
        photo={file}
        type={this.props.type}
      />
    ));
    return (
      <div>
        <input
          accept="image/*"
          id="contained-button-file"
          multiple
          type="file"
          hidden
          onChange={this.handleChange}
        />
        <label htmlFor="contained-button-file">
          <Button
            fullWidth
            variant="contained"
            color={grey[900]}
            component="span"
          >
            <PhotoCamera /> - Upload Images
          </Button>
        </label>
        <FlexboxGrid>{imageCards}</FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(Step2));
