import React, { Component } from "react";
import { Button } from "rsuite";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Divider } from "antd";

import userStore from "../../../Stores/UserStore";
import OtpInput from "react-otp-input";

class PhoneLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showPasswordConf: false,
      signupform: false,
    };
  }

  render() {
    let disabledButton = false;
    let user = userStore.newuser;
    let validation = user.validation;

    if (user.phone.length !== 8) disabledButton = true;
    if (user.password.length < 6) disabledButton = true;

    let passerror = { val: false, message: "" };
    let emailerror = { val: false, message: "" };
    if (validation) {
      if (user.phone.length !== 8) {
        emailerror.val = true;
        emailerror.message = "Please enter a Valid Phone Number.";
        disabledButton = true;
      }
      if (user.password.length < 6) {
        passerror.val = true;
        passerror.message = "Password must be greater than 6 characters.";
        disabledButton = true;
      }
    }

    return (
      <div>
        <Divider>
          <h5>Phone Login</h5>
        </Divider>
        <br />
        <div>
          <OtpInput
            value={user.phone}
            onChange={(e) => userStore.editnewuser("phone", e)}
            numInputs={8}
            isInputNum
            fullWidth
            style={{ fontSize: 24, width: 50 }}
            inputStyle={{
              width: "100%",
              background: "rgb(255,255,255,0)",
              borderRadius: 100,
              height: 40,
              fontSize: 20,
              fontWeight: "bold",
            }}
          />
        </div>

        <TextField
          error={passerror.val}
          helperText={passerror.val && passerror.message}
          variant="standard"
          margin="normal"
          fullWidth
          disabled={userStore.loading}
          name="password"
          label="Password"
          type={this.state.showPassword ? "text" : "password"}
          id="password"
          autoComplete="current-password"
          value={user.password}
          style={{ background: "rgb(255,255,255,0)" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onMouseOver={() => this.setState({ showPassword: true })}
                  onMouseLeave={() => this.setState({ showPassword: false })}
                >
                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => userStore.editnewuser("password", e.target.value)}
        />
        <br />
        <Button
          block
          color="green"
          disabled={disabledButton}
          style={{ boxShadow: "0px 0px 3px black" }}
          onClick={() => {
            let userData = {
              phone: user.phone,
              password: user.password,
            };

            userStore.phoneloginUser(userData);
          }}
        >
          {userStore.loading ? "Loading ..." : "Login"}
        </Button>
      </div>
    );
  }
}

export default withRouter(observer(PhoneLogin));
