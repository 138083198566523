import React, { Component } from "react";
import { Steps } from "antd";

const { Step } = Steps;

class RequestTimeline2 extends Component {
  render() {
    let steps = [
      { main: "Request Created", detail: "Customer created a Request." },
      {
        main: "Quote Submissions",
        detail: "Quotes are being submitted by Companies.",
      },
      {
        main: "Customer Approval",
        detail: "The Customer has awarded a winning company.",
      },
    ];
    let activeStep = this.props.activeStep;
    if (this.props.steps) steps = this.props.steps;

    let stepcode = steps.map((step, index) => (
      <Step
        key={index}
        status={
          index < activeStep
            ? "finish"
            : index > activeStep
            ? "wait"
            : "process"
        }
        details={step.detail}
        title={step.main}
        description={activeStep === index ? step.detail : false}
      />
    ));

    return (
      <Steps current={activeStep} size="small" direction={"vertical"}>
        {stepcode}
      </Steps>
    );
  }
}

export default RequestTimeline2;
