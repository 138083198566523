import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import { Input, Tooltip, message } from "antd";
import {
  InfoCircleOutlined,
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
} from "@ant-design/icons";
import userStore from "../../../Stores/UserStore";
import baseURL from "../../../Media/baseURL";
import { Button, Col, FlexboxGrid, Grid, Notification } from "rsuite";
import OtpInput from "react-otp-input";
import { Divider } from "antd";

const { Search } = Input;

const instance = axios.create({ baseURL: baseURL });

class ProfileEditBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      changed: false,
      val: "",
      otp: false,
      resend: false,
    };
  }

  confirmOTP = async () => {
    let requestData = {
      OTP: userStore.editprofileinfo.otp,
      phone: userStore.editprofileinfo.phone,
    };
    try {
      this.setState({ loading: true });
      let newrequest = await instance
        .post(`/auth/checkotp`, requestData, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.setState({ loading: false, changed: true, otp: false });
          console.log(res);
          message.success("Updated User Phone Successfully.");
          userStore.changeUserInfo(
            this.props.type,
            userStore.editprofileinfo[this.props.type]
          );
        })
        .catch((err) => {
          this.setState({ loading: false, sent: false });
          console.log(1);
          console.log(err.response);
          console.log(err.response.data.title);
          Notification["error"]({
            title: err.response.data.title,
          });
        });
    } catch (err) {
      Notification["error"]({
        title: "Error Updating Phone",
      });
    }
  };

  actuallySendOTP = async () => {
    try {
      this.setState({ loading: true, resend: true });
      let requestData = { phone: userStore.editprofileinfo.phone };
      console.log(requestData);
      let newrequest = await instance
        .post(`/auth/sendtempotp`, requestData)
        .then((res) => {
          Notification["info"]({
            title: "OTP Sent",
            description: `Please check for a message to ${userStore.editprofileinfo.phone} with the OTP.`,
          });
          this.setState({ loading: false, sent: false, otp: true });
        })
        .catch((err) => {
          this.setState({ loading: false, sent: false });
          console.log(1);
          console.log(err.response.data);
          Notification["error"]({
            title: err.response.data,
          });
        });

      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
    }
  };

  sendOTP = async () => {
    if (this.state.changed) {
      message.info("Information Already Updated");
    }
    try {
      this.setState({ loading: true });
      let requestData = { phone: userStore.editprofileinfo.phone };
      console.log(requestData);
      let newrequest = await instance
        .post(`/auth/checkphone`, requestData)
        .then((res) => {
          this.actuallySendOTP();
          // this.setState({ loading: false, otp: true });
        })
        .catch((err) => {
          this.setState({ loading: false, sent: false });
          console.log(1);
          console.log(err.response.data);
          Notification["error"]({
            title: err.response.data,
            description: `The phone number ${userStore.editprofileinfo.phone} is already assigned to a user. Please try a different phone number.`,
          });
        });

      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
    }
  };

  updateUserInfo = async (type) => {
    if (this.state.changed) {
      message.info("Information Already Updated");
    } else if (userStore.user[type] === userStore.editprofileinfo[type]) {
    } else {
      try {
        this.setState({ loading: true });
        let requestData;
        if (this.props.type === "phone") {
          requestData = { phone: userStore.editprofileinfo.phone };
        } else if (this.props.type === "name") {
          requestData = { name: userStore.editprofileinfo.name };
        } else if (this.props.type === "email") {
          requestData = { email: userStore.editprofileinfo.email };
        } else if (this.props.type === "tempemail") {
          requestData = { email: userStore.editprofileinfo.tempemail };
        }
        let newrequest = await instance
          .put(`/auth/update/profile/update${type}`, requestData, {
            headers: { authtoken: userStore.token },
          })
          .then((res) => {
            this.setState({ loading: false, changed: true });
            console.log(res);
            message.success("Updated User Info Successfully.");
            userStore.changeUserInfo(
              this.props.type,
              userStore.editprofileinfo[this.props.type]
            );
          })
          .catch((err) => {
            this.setState({ loading: false, sent: false });
            console.log(1);
            console.log(err.response.data.title);
            Notification["error"]({
              title: err.response.data.title,
              description: err.response.data.message,
            });
            console.log(err.response.data.title);
            if (type === "email") message.error(err.response.data.title);
            if (type === "phone") message.error("Phone Already Exists");
            if (type === "name") message.error("An error occured");
          });
      } catch (err) {
        if (type === "email") message.error("Email Already Exists");
        if (type === "phone") message.error("Phone Already Exists");
        if (type === "name") message.error("An error occured");
      }
    }
  };

  render() {
    let type = this.props.type;
    let validationerror = false;
    let suffixCode;
    let valuechange = userStore.user[type] !== userStore.editprofileinfo[type];

    if (valuechange) {
      if (type === "phone") {
        if (
          userStore.editprofileinfo[type] < 10000000 ||
          userStore.editprofileinfo[type] > 99999999
        ) {
          validationerror = true;
        }
      } else {
        if (userStore.editprofileinfo[type].length < 3) {
          validationerror = true;
        }
      }
    }
    let prefixCode = <PhoneOutlined className="site-form-item-icon" />;
    let enterButton = <PhoneOutlined className="site-form-item-icon" />;
    if (validationerror) {
      if (type === "phone") {
        suffixCode = (
          <Tooltip title="Please enter a Valid Kuwaiti Phone Number.">
            <InfoCircleOutlined style={{ color: "red" }} />
          </Tooltip>
        );
      } else if (type === "name") {
        suffixCode = (
          <Tooltip title="Please enter a Valid Name.">
            <InfoCircleOutlined style={{ color: "red" }} />
          </Tooltip>
        );
      } else if (type === "email") {
        suffixCode = (
          <Tooltip title="Please enter a Valid Email.">
            <InfoCircleOutlined style={{ color: "red" }} />
          </Tooltip>
        );
      }
    }
    if (type === "name") {
      prefixCode = <UserOutlined className="site-form-item-icon" />;
      enterButton = <UserOutlined className="site-form-item-icon" />;
    }
    if (type === "email") {
      enterButton = <MailOutlined className="site-form-item-icon" />;
      prefixCode = <MailOutlined className="site-form-item-icon" />;
    }

    if (this.state.loading) {
      enterButton = "Updating...";
    } else if (validationerror) {
    } else if (valuechange) {
      enterButton = "Update";
    } else if (this.state.changed) {
      enterButton = "Updated!";
    }

    let otpInput = (
      <div style={{ maxWidth: 400 }}>
        <h6>OTP Value:</h6>
        <OtpInput
          value={userStore.editprofileinfo.otp}
          onChange={(e) => userStore.changeProfileInfo("otp", e)}
          numInputs={4}
          isInputNum
          fullWidth
          style={{ fontSize: 24, width: 50 }}
          separator={(pos) => {
            if (pos === 4) {
              <span> - </span>;
            }
          }}
          inputStyle={{
            width: "100%",
            flex: "Wrap",
            justifyContent: "center",
            background: "rgb(255,30,0,0.1)",
            borderRadius: 100,
            height: 40,
            fontSize: 20,
            fontWeight: "bold",
          }}
        />
        <br />
        <FlexboxGrid>
          <Col xs={this.state.resend ? 12 : 8}>
            <Button
              block
              color="green"
              onClick={() => this.confirmOTP()}
              disabled={
                !userStore.editprofileinfo.otp ||
                userStore.editprofileinfo.otp.length < 4 ||
                this.state.loading
              }
            >
              Save
            </Button>
          </Col>
          {!this.state.resend && (
            <Col xs={8}>
              <Button
                block
                appearance="ghost"
                onClick={() => this.actuallySendOTP()}
              >
                Resend
              </Button>
            </Col>
          )}
          <Col xs={this.state.resend ? 12 : 8}>
            <Button
              block
              appearance="ghost"
              color="red"
              disabled={this.state.loading}
              onClick={() => {
                this.setState({ otp: false });
                userStore.changeProfileInfo("phone", userStore.user.phone);
                userStore.changeProfileInfo("otp", undefined);
              }}
            >
              Cancel
            </Button>
          </Col>
        </FlexboxGrid>
        <br />
      </div>
    );

    return (
      <div style={{ flex: "wrap", justifyContent: "center" }}>
        <h6>
          {this.props.type.charAt(0).toUpperCase() + this.props.type.slice(1)}
        </h6>
        <Search
          value={userStore.editprofileinfo[type]}
          prefix={prefixCode}
          suffix={suffixCode}
          style={{ maxWidth: 400 }}
          enterButton={enterButton}
          size="large"
          type={type === "phone" && "number"}
          disabled={this.state.loading || this.state.otp}
          loading={this.state.loading}
          onChange={(e) => {
            this.setState({ changed: false });
            userStore.changeProfileInfo(type, e.target.value);
          }}
          onSearch={() => {
            if (!validationerror) {
              if (this.props.type === "phone") {
                if (this.state.otp) {
                } else {
                  this.sendOTP();
                }
              } else {
                this.updateUserInfo(type);
              }
            }
          }}
        />
        <br />
        <br />
        {this.state.otp && otpInput}
      </div>
    );
  }
}

export default withRouter(observer(ProfileEditBlock));
