import React, { Component } from "react";
import { observer } from "mobx-react";
import { Button, Col, Notification, FlexboxGrid } from "rsuite";
import { withRouter } from "react-router";
import businessStore from "../../../../Stores/BusinessStore";
import userStore from "../../../../Stores/UserStore";
import axios from "axios";
import baseURL from "../../../../Media/baseURL";
import requestStore from "../../../../Stores/RequestStore";
import ServiceRow from "./subpages/ServiceRow";
import UnifiedModal from "../../../../Components/UnifiedModal/UnifiedModal";

const instance = axios.create({ baseURL: baseURL });

class EdtiServiceModal extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }
  postBusinessEdits() {
    this.setState({ loading: true });
    let business = businessStore.editBusiness;
    let finalData = { services: [] };

    for (let i = 0; i < business.services.length; i++) {
      finalData.services.push(business.services[i]._id);
    }
    console.log(finalData);

    return instance
      .put(`/api/business/update/${business._id}`, finalData, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        businessStore.getSingleBusiness(res.data._id);
        this.setState({ loading: false });
        this.props.onHide();
        Notification["success"]({
          title: "Services Updated",
        });
      })
      .catch((err) => {
        Notification["error"]({
          title: "Services Not Updated",
        });
      });
  }

  render() {
    let disableButton = { val: false, message: "Submit Updates" };
    if (this.state.loading) {
      disableButton = { val: true, message: "Loading..." };
    }
    return (
      <UnifiedModal
        show={this.props.show}
        onHide={this.props.onHide}
        title={"Select Services"}
        footer={
          <FlexboxGrid>
            <Col xs={12}>
              <Button
                block
                appearance="ghost"
                color="red"
                onClick={() => {
                  this.props.onHide();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                block
                color="green"
                disabled={disableButton.val}
                onClick={() => {
                  this.postBusinessEdits();
                }}
              >
                {disableButton.message}
              </Button>
            </Col>
          </FlexboxGrid>
        }
      >
        {requestStore.services.map((service, index) => (
          <ServiceRow service={service} key={index} />
        ))}
      </UnifiedModal>
    );
  }
}

export default withRouter(observer(EdtiServiceModal));
