import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import LandingPage from "../MainPages/LandingPage/LandingPage";
import userStore from "../Stores/UserStore";
import MainAuthPage from "../MainPages/Authentication/MainAuthPage";
import DashboardPage from "../MainPages/Customer/Dashboard/DashboardPage";
import NewRequestPage from "../MainPages/Customer/Requests/NewRequestPage";
import BusinessDashboard from "../MainPages/Business/Dashboard/BusinessDashboard";
import ProfilePage from "../MainPages/Business/ProfilePage/ProfilePage";
import BusinessesList from "../MainPages/SharedPages/BusinessesList";
import RequestList from "../MainPages/Business/Requests/RequestList";
import RequestTable from "../MainPages/Customer/RequestSummary/RequestTable";
import PastQuotesTable from "../MainPages/Business/PastQuotes/PastQuotesTable";
import RequestSummary from "../MainPages/SharedPages/RequestSummary/RequestSummary";
import RequestApprovalPage from "../MainPages/Admin/RequestApproval/RequestApprovalPage";
import EditRequestPage from "../MainPages/Customer/Requests/EditRequestPage";
import ResetPasswordPage from "../MainPages/Authentication/ResetPasswordPage";

import AccountVerification from "../MainPages/Authentication/AccountVerification";
import ReferAFriendPage from "../MainPages/SharedPages/Referral/ReferAFriendPage";
import UserProfile from "../MainPages/SharedPages/UserProfile/UserProfile";
import CreditPage from "../MainPages/SharedPages/Referral/CreditPage";
import PrivacyPolicy from "../Components/Policies/PrivacyPolicy";
import CookiePolicy from "../Components/Policies/CookiePolicy";
import TermsAndServices from "../Components/Policies/TermsAndServices";
import ConversationPage from "../MainPages/SharedPages/Conversations/ConversationPage";
import letterStore from "../Stores/LetterStore";
import StatisticsMain from "../MainPages/Business/Statistics/StatisticsMain";
import NewPost from "../MainPages/Business/NewPost/NewPost";
import GaragePosts from "../MainPages/SharedPages/Posts/GaragePosts";
import OnePostPage from "../MainPages/SharedPages/Posts/OnePostPage";
import RequestSummaryRedirect from "../Components/RedirectPages/RequestSummaryRedirect";
import ReferralPage from "../MainPages/Authentication/ReferralPage";
import BusinessPostPage from "../MainPages/SharedPages/Posts/BusinessPostPage";
import EditPost from "../MainPages/Business/NewPost/EditPost";
import OnePostPageRedirect from "../Components/RedirectPages/OnePostPageRedirect";
import BusinessPageRedirect from "../Components/RedirectPages/BusinessPageRedirect";
import NewBusinessRequest from "../MainPages/SharedPages/JoinGarageArabia/NewBusinessRequest";
import BusinessInfoPage from "../MainPages/SharedPages/Informational/BusinessInfoPage/BusinessInfoPage";
import CustomerLanding from "../MainPages/LandingPage/CustomerLanding";
import NewProductPage from "../MainPages/Business/Products/NewProductPage";

class Views extends Component {
  constructor(props) {
    super(props);
    this.state = { hide: false };
  }

  componentDidUpdate = async (prevProps) => {
    setTimeout(function () {
      if (userStore.signedIn) {
        letterStore.fetchUnseenLetters();
        letterStore.fetchUnseenNotifis();
      }
    }, 30000);
    if (this.props.location.pathname !== prevProps.location.pathname) {
      console.log("Changed");
      window.scrollTo(0, 0);
    }
  };

  getView() {
    let underconstruction = 0;
    if (underconstruction > 0) {
      return (
        <div>
          <h1>
            The System is currently updating, should be done within a few hours.
          </h1>
          <h1>
            Please let me know if you need any information and I will do my best
            to support.
          </h1>
        </div>
      );
    }
    let sharedURLS = [
      { path: "/policy/privacypolicy", component: PrivacyPolicy },
      { path: "/policy/cookiespolicy", component: CookiePolicy },
      { path: "/policy/termsandservices", component: TermsAndServices },
      { path: "/garageposts", component: GaragePosts },
      { path: "/garagepost/:postId", component: OnePostPage },
      { path: "/p/:postNumId", component: OnePostPageRedirect },
      { path: "/request/:requestId", component: RequestSummary },
      { path: "/r/:requestId", component: RequestSummaryRedirect },
      { path: "/feed/business/:businessId", component: BusinessPostPage },
      { path: "/businesses", component: BusinessesList },
      { path: "/clanding", component: CustomerLanding },
      { path: "/landing", component: LandingPage },
      { path: "/b/:businessNumId", component: BusinessPageRedirect },
      { path: "/business/:businessId", component: ProfilePage },
      { path: "/join-garage-arabia/", component: NewBusinessRequest },
      { path: "/businessinfo", component: BusinessInfoPage },
    ];
    let sharedSignedInURLS = [
      { path: "/profile", component: UserProfile },
      { path: "/credit", component: CreditPage },
      { path: "/messages", component: ConversationPage },
      { path: "/referafriend", component: ReferAFriendPage },
      {
        path: "/auth/verifyaccount/:verificationcode",
        component: AccountVerification,
      },
    ];
    let businessURLs = [
      { path: "/statistics", component: StatisticsMain },
      { path: "/home", component: BusinessDashboard },
      { path: "/openrequests", component: RequestList },
      { path: "/quotes", component: PastQuotesTable },
      { path: "/newproduct", component: NewProductPage },
      { path: "/newpost", component: NewPost },
      { path: "/editpost/:postId", component: EditPost },
      { path: "/", component: LandingPage },
    ];

    if (!userStore.signedIn) {
      return (
        <Switch>
          <Route
            exact
            path="/auth/resetpassword/:token"
            component={ResetPasswordPage}
          />
          <Redirect exact path="/login" to="/auth" />
          <Redirect exact path="/signup" to="/auth" />
          <Route
            path="/auth/verifyaccount/:verificationcode"
            component={AccountVerification}
          />
          <Route path="/a/:referralId" component={ReferralPage} />
          <Route path="/referral/:referralId" component={ReferralPage} />

          <Route path="/auth" component={MainAuthPage} />
          {sharedURLS.map((route, index) => (
            <Route key={index} path={route.path} component={route.component} />
          ))}
          <Route exact path="/" component={LandingPage} />
          <Route path="/" component={MainAuthPage} />
        </Switch>
      );
    }
    if (userStore.userAccess === 1) {
      return (
        <Switch>
          {sharedURLS.map((route, index) => (
            <Route key={index} path={route.path} component={route.component} />
          ))}
          {sharedSignedInURLS.map((route, index) => (
            <Route key={index} path={route.path} component={route.component} />
          ))}
          <Route path="/" component={RequestApprovalPage} />
        </Switch>
      );
    }
    if (userStore.userAccess === 4) {
      return (
        <Switch>
          {sharedURLS.map((route, index) => (
            <Route key={index} path={route.path} component={route.component} />
          ))}
          {sharedSignedInURLS.map((route, index) => (
            <Route key={index} path={route.path} component={route.component} />
          ))}
          {businessURLs.map((route, index) => (
            <Route key={index} path={route.path} component={route.component} />
          ))}
        </Switch>
      );
    }

    return (
      <Switch>
        <Route path="/dashboard" component={DashboardPage} />
        <Route path="/requests" component={RequestTable} />
        <Route path="/newrequest" component={NewRequestPage} />
        <Route
          path="/modifynewrequest/:requestId"
          component={EditRequestPage}
        />

        {sharedURLS.map((route, index) => (
          <Route key={index} path={route.path} component={route.component} />
        ))}
        {sharedSignedInURLS.map((route, index) => (
          <Route key={index} path={route.path} component={route.component} />
        ))}
        <Route path="/" component={LandingPage} />
      </Switch>
    );
  }
  render() {
    if (window.location.host.split(".")[0] === "app") {
      console.log("CATCH!");
      return (
        <div>
          <h5>HELLO THERE!</h5>
        </div>
      );
    }
    if (userStore.signedIn === false) {
      return <div>{this.getView()}</div>;
    }
    return <div style={{ minHeight: "80vh" }}>{this.getView()}</div>;
    // return (
    //   <div>
    //     <Container fluid={true} style={{ width: "100%" }}>
    //       {this.getView()}
    //     </Container>
    //   </div>
    // );
  }
}

export default withRouter(observer(Views));
