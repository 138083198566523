import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import RequestTimeline from "../Timeline/RequestTimeline";
import { Box, Container, Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import moment from "moment";
import PaperSTD from "../PaperSTD/PaperSTD";
import RequestTimeline2 from "../Timeline/RequestTimeline2";
import MultipleCollapse from "../CollapseSection/MultipleCollapse";
import requestSummaryStore from "../../Stores/RequestSummaryStore";
import { Button, Col, FlexboxGrid, Grid, Row } from "rsuite";
import OpenQuoteCards from "./OpenQuoteCard";

import userStore from "../../Stores/UserStore";
import SelectQuoteModal from "../../MainPages/Customer/Modals/SelectQuoteModal";
import ConfirmationModal from "../../MainPages/Customer/Modals/ConfirmationModal";

class RequestTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = { selectQuote: false, showConfirm: false, quote: null };
    this.selectQuote = this.selectQuote.bind(this);
  }

  closeModal = () => {
    this.setState({ selectQuote: false, showConfirm: false });
  };

  alternateModal = () => {
    this.setState({
      selectQuote: !this.state.selectQuote,
      showConfirm: !this.state.showConfirm,
    });
  };

  selectQuote(quote) {
    this.setState({ quote: quote });
  }

  render() {
    let req = this.props.req;
    let quote = this.props.quote;
    let hours;
    let minutes;
    let progress;
    let timeprint;
    let timeRem;
    if (moment(req.closedate) < moment()) {
      hours = 0;
      minutes = 0;
      progress = 100;
      timeprint = 0;
    } else {
      timeprint = moment(req.closedate).diff(moment(), "minutes");
      hours = Math.floor(timeprint / 60);
      minutes = Math.round(timeprint - 60 * hours);
      progress = 100 * (1 - (hours + minutes / 60) / 72);
    }

    timeRem = `${hours} hours and ${minutes} minutes remaining.`;
    let activeStep = 1;
    let status = req.status;
    if (req.status === "Pending Approval") {
      activeStep = 0;
    } else if (req.status === "Open") {
      activeStep = 1;
    } else if (req.status === "Approved") {
      activeStep = 2;
      if (req.rating >= 0) {
        activeStep = 3;
      }
    } else if (req.status === "Completed") {
      if (req.rating >= 0) {
        activeStep = 3;
      }
      activeStep = 5;
    }

    let title = "";
    if (req.service) {
      title = `Req. #${req.requestNumId} (${status})`;
    }

    let biddingdata;
    let otherquotes;
    let showduration = false;

    let cheapest = { value: 9999999, id: "" };
    let fastest = { value: 9999999, id: "" };

    let quotetotals = { price: 0, duration: 0, count: 0 };
    if (userStore.user) {
      if (userStore.user & req.user) {
        if (userStore.user._id & req.user._id) {
          if (userStore.user._id === req.user._id) {
            for (let i = 0; i < requestSummaryStore.allquotes.length; i++) {
              quotetotals.count += 1;
              quotetotals.price += quote[i].price;
              quotetotals.duration += requestSummaryStore.allquotes[i].duration;
              if (requestSummaryStore.allquotes[i].price < cheapest.value)
                cheapest = {
                  value: requestSummaryStore.allquotes[i].price,
                  id: requestSummaryStore.allquotes[i]._id,
                };
              if (requestSummaryStore.allquotes[i].duration < fastest.value)
                fastest = {
                  value: requestSummaryStore.allquotes[i].duration,
                  id: requestSummaryStore.allquotes[i]._id,
                };
            }
          }
        }
      }
    }

    if ((req.status === "Open") & (req.maxQuote > quote.length)) {
      showduration = true;
      biddingdata = (
        <Container>
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1} style={{ textAlign: "center" }}>
              <Typography variant="h6" color="textSecondary">
                {timeRem}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={progress}
                color="secondary"
                style={{ height: 30, borderRadius: 20 }}
              />
            </Box>
          </Box>
        </Container>
      );
    }

    otherquotes = (
      <FlexboxGrid justify>
        {requestSummaryStore.allquotes.map((quote, index) => (
          <OpenQuoteCards
            key={index}
            quote={quote}
            fastest={fastest.id === quote._id && fastest}
            cheapest={cheapest.id === quote._id && cheapest}
            showdetails={
              userStore.user.business === quote.business._id ||
              userStore.user._id === req.user._id
            }
          />
        ))}
      </FlexboxGrid>
    );

    let myquote;
    if (requestSummaryStore.myquotes.length > 0) {
      myquote = (
        <OpenQuoteCards quote={requestSummaryStore.myquotes[0]} myquote />
      );
    }

    let cardata = (
      <h5>
        {req.year} {req.make} - {req.model}
      </h5>
    );

    let updatedpanels = [
      {
        title: "Timeline",
        children:
          window.innerWidth > 600 ? (
            <RequestTimeline activeStep={activeStep} />
          ) : (
            <RequestTimeline2 activeStep={activeStep} />
          ),
      },
      {
        title: `Service Type: ${req.service.title}`,
        children: <h6>{req.service.title}</h6>,
      },
      { title: "Request Details:", children: <h6>{req.description}</h6> },
      {
        title: `Car: ${req.year} ${req.make} - ${req.model}`,
        children: cardata,
      },
      {
        title: `Quotes: ${quote.length} / ${req.maxQuote} Submitted`,
        children: otherquotes,
      },
    ];

    if (showduration)
      updatedpanels.push({
        title: `Duration: ${timeRem}`,
        children: biddingdata,
      });

    if (requestSummaryStore.myquotes.length > 0) {
      updatedpanels.splice(1, 0, { title: `My Quote`, children: myquote });
    }

    let topPanel;
    let indexVal;
    console.log(requestSummaryStore.allquotes);
    console.log(requestSummaryStore.basicquotes);
    if (req.status === "Open") {
      if (userStore.user & req.user) {
        if (
          (userStore.user._id === req.user._id) &
          (requestSummaryStore.allquotes.length > 0)
        ) {
          topPanel = (
            <PaperSTD
              title={`${requestSummaryStore.allquotes.length}/${req.maxQuote} Quotes`}
            >
              <Button
                block
                color="green"
                onClick={() => this.setState({ selectQuote: true })}
              >
                Select Quote and Close Bid
              </Button>
              <br />
              {otherquotes}
              <br />
              {biddingdata}
            </PaperSTD>
          );
        } else {
          topPanel = (
            <PaperSTD
              title={`${requestSummaryStore.allquotes.length}/${req.maxQuote} Quotes`}
            >
              {biddingdata}
              <br />
              {otherquotes}
            </PaperSTD>
          );
        }
      }
    } else if (req.status === "Approved") {
      indexVal = requestSummaryStore.allquotes.findIndex(
        (quote) => quote._id === req.approved._id
      );
      if (indexVal >= 0) {
        topPanel = (
          <PaperSTD title={req.status} orientation="center">
            <OpenQuoteCards
              quote={requestSummaryStore.allquotes[indexVal]}
              approved
              showdetails={
                requestSummaryStore.allquotes[indexVal].business._id ===
                  userStore.user.business || req.user._id === userStore.user._id
              }
            />
            <br />
          </PaperSTD>
        );
      }
    }

    let modifyButton;
    if (req.user) {
      if ((req.status === "Rejected") & (userStore.user._id === req.user._id)) {
        modifyButton = (
          <Button
            size="lg"
            block
            color="green"
            onClick={() =>
              this.props.history.push(`/modifynewrequest/${req._id}`)
            }
          >
            Submit Modifications
          </Button>
        );
      }
    }

    return (
      <PaperSTD title={title} orientation="center">
        <SelectQuoteModal
          show={this.state.selectQuote}
          onHide={this.closeModal}
          selectedQuote={this.state.quote}
          select={this.selectQuote}
          alternate={this.alternateModal}
        />
        <ConfirmationModal
          show={this.state.showConfirm}
          onHide={this.closeModal}
          alternate={this.alternateModal}
          quote={this.state.quote}
        />
        {this.props.firstsectionchildren}
        {modifyButton}
        <br />
        <FlexboxGrid>
          <Col xs={24}>{topPanel}</Col>
        </FlexboxGrid>
        <MultipleCollapse panels={updatedpanels} />
      </PaperSTD>
    );
  }
}

export default withRouter(observer(RequestTemplate));
