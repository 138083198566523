import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Button, FlexboxGrid, Notification } from "rsuite";
import LoadingParagraph from "../../../Components/LoadingPages/LoadingParagraph";
import busPostStore from "../../../Stores/BusPostStore";
import userStore from "../../../Stores/UserStore";
import { Input, Result } from "antd";
import { Container } from "@material-ui/core";
import businessStore from "../../../Stores/BusinessStore";
import axios from "axios";
import baseURL from "../../../Media/baseURL";
import MaxRequests from "../../../Components/ErrorMessages/MaxRequests";
import PostNotFound from "../../../Components/ErrorMessages/PostNotFound";
import SinglePostAntd from "../../../Components/SinglePost/SinglePostAntd";

const { TextArea } = Input;
const instance = axios.create({ baseURL: baseURL });

class EditPost extends Component {
  constructor(props) {
    super(props);
    this.state = { updated: false };
  }
  componentDidMount() {
    busPostStore.fetchEditPost(this.props.match.params.postId);
  }

  handleCloseNotification(type, text) {
    Notification.close();
    Notification[type]({
      title: text,
    });
  }

  deletePost = async () => {
    let postId = busPostStore.editpost._id;
    Notification["info"]({
      title: "Loading",
      duration: 0,
      description: <div>Please wait while your post is being deleted...</div>,
    });
    let post = busPostStore.editpost;

    await instance
      .delete(`/api/businesspost/deletepost/${postId}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({
          loading: false,
          uploadedpost: res.data,
          step: this.state.step + 1,
        });
        this.handleCloseNotification("success", "Post Deleted");
        this.props.history.push(
          `/feed/business/${busPostStore.editpost.business._id}`
        );
      })
      .catch((err) => {
        this.handleCloseNotification(
          "error",
          "An error occured. Please try again."
        );
        this.setState({ loading: false });
      });
  };

  updatePost = async () => {
    let postId = busPostStore.editpost._id;
    Notification["info"]({
      title: "Loading",
      duration: 0,
      description: <div>Please wait while your post is being updated...</div>,
    });
    let post = busPostStore.editpost;
    let rawData = { description: busPostStore.editpost.description };

    await instance
      .put(`/api/businesspost/editpost/${postId}`, rawData, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({
          loading: false,
          uploadedpost: res.data,
          step: this.state.step + 1,
        });
        this.handleCloseNotification("success", "Post Updated");
        this.setState({ updated: true });
      })
      .catch((err) => {
        this.handleCloseNotification(
          "error",
          "An error occured. Please try again."
        );
        this.setState({ loading: false });
      });
  };

  render() {
    if (busPostStore.loading) {
      return <LoadingParagraph />;
    }
    if (!busPostStore.editpost.business) {
      return (
        <Container style={{ maxWidth: 550 }}>
          <PostNotFound />
        </Container>
      );
    }

    let disablesubmit = true;
    if (
      busPostStore.editpost.description !== busPostStore.newpost.description
    ) {
      if (busPostStore.editpost.description.length > 1) {
        disablesubmit = false;
      }
    }

    let outputtext;
    if (this.state.updated) {
      outputtext = (
        <Result
          status="success"
          title={`Successfully Updated Post`}
          subTitle={"Click on the below link to go to the Post."}
          extra={[
            <Button
              appearance="primary"
              key="console"
              onClick={() =>
                this.props.history.push(
                  `/garagepost/${this.state.uploadedpost._id}`
                )
              }
            >
              Go To Post
            </Button>,
          ]}
        />
      );
    } else {
      outputtext = (
        <div>
          <Container style={{ maxWidth: 550 }}>
            <TextArea
              showCount
              maxLength={200}
              value={busPostStore.editpost.description}
              onChange={(e) => busPostStore.editpostText(e.target.value)}
              allowClear
              autoSize={{ minRows: 5, maxRows: 20 }}
            />
          </Container>
          <FlexboxGrid justify="center">
            <Button
              block
              disabled={disablesubmit}
              style={{ maxWidth: 500 }}
              color="green"
              onClick={() => this.updatePost()}
            >
              Update Post
            </Button>
          </FlexboxGrid>
          <hr />
          <FlexboxGrid justify="center">
            <Button
              block
              style={{ maxWidth: 500 }}
              color="red"
              onClick={() => this.deletePost()}
            >
              Delete Post
            </Button>
          </FlexboxGrid>
        </div>
      );
    }

    return (
      <div>
        <br />
        <h6>Edit Post Page</h6>
        <hr />
        <h6 style={{ textAlign: "center" }}>Modify Description</h6>

        {outputtext}
        <div>
          <h5 style={{ textAlign: "center" }}>Sample Post</h5>
          <SinglePostAntd
            text={busPostStore.editpost.description}
            business={userStore.user.business}
            medias={busPostStore.editpost.medias}
            disable={true}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(observer(EditPost));
