import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import LockIcon from "@material-ui/icons/Lock";
import { IconButton } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import { Notification } from "rsuite";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function Confidential() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    Notification["error"]({
      title: "Confidential Quote",
      description: (
        <div>
          Quote details are confidential. Only the Requestor and the Business
          Quoted can view this.
          <hr />
          This is meant to create a safe quoting process for both the Requestor
          and the Businesses.
        </div>
      ),
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton aria-label="delete">
        <Fab color="secondary" onClick={handleClick} size="small">
          <LockIcon fontSize="small" />
        </Fab>
      </IconButton>
    </div>
  );
}
