import React, { Component } from "react";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { TextField } from "@material-ui/core";
import { Button, Col, FlexboxGrid } from "rsuite";
import { Divider, Result } from "antd";
import ImageCard from "./ImageCard";

class Step4 extends Component {
  render() {
    let request = this.props.request;

    let imageCards = request.medias.map((file, index) => (
      <ImageCard key={index} index={index} photo={file} hideButton={true} />
    ));
    return (
      <div>
        <Result
          status="success"
          title="Request Successfully Submitted"
          subTitle="Your has been succesfully submitted. Please check your email for a confirmation receipt."
        />
        <FlexboxGrid>
          <Col xs={12}>
            <Button
              block
              color="green"
              onClick={() => this.props.history.push("/dashboard")}
            >
              Go to Dashboard
            </Button>
          </Col>
          <Col xs={12}>
            <Button
              block
              color="green"
              onClick={() =>
                this.props.history.push(`/request/${this.props.requestId}`)
              }
            >
              Track Request
            </Button>
          </Col>
        </FlexboxGrid>
        <hr />
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          label="Description"
          value={request.description}
          onChange={(e) => console.log(e)}
          style={{ background: "rgb(255,255,255,0)" }}
        />
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          label="Car"
          value={`${request.year} ${request.make} - ${request.model}`}
          onChange={(e) => console.log(e)}
          style={{ background: "rgb(255,255,255,0)" }}
        />
        <Divider orientation="left">Images</Divider>
        <FlexboxGrid>{imageCards}</FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(Step4));
