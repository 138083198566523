import { Container, Tab, Tabs } from "@material-ui/core";
import { Empty } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import SingleConversation from "./SingleConversation";
import PaperSTD from "../../../Components/PaperSTD/PaperSTD";
import letterStore from "../../../Stores/LetterStore";
import Message from "./Message";
import NotificationCards from "./NotificationCards";
import LoadingParagraph from "../../../Components/LoadingPages/LoadingParagraph";
import Pagination from "@material-ui/lab/Pagination";
import { Button } from "rsuite";

class ConversationPage extends Component {
  constructor(props) {
    super(props);
    this.state = { value: false, page: 1 };
    this.selectConvo = this.selectConvo.bind(this);
  }
  componentDidMount() {
    letterStore.fetchAll();
    letterStore.fetchAllNotifications(1);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.value !== this.state.value) {
      console.log("CATCHINGSDKJFKDSJKSDJF");
      letterStore.fetchUnseenLetters();
      letterStore.fetchAll();
    }
  }

  selectConvo(convo) {
    this.setState({ value: !this.state.value });
    letterStore.selectConversation(convo, true);
  }

  handleChange = (event, value) => {
    this.setState({ page: value });
    letterStore.fetchAllNotifications(value);
  };

  render() {
    let conversations = letterStore.conversations.map((convo, index) => (
      <Message convo={convo} key={index} selectConvo={this.selectConvo} />
    ));
    if (letterStore.conversations.length <= 0) {
      conversations = (
        <div>
          <br />
          <Empty description={"No Messages"} />
          <br />
        </div>
      );
    }
    let totalNots = letterStore.notifis.nots;

    let allnotifications = totalNots.map((notifi, index) => (
      <NotificationCards
        notification={notifi}
        key={index}
        page={this.state.page}
      />
    ));

    let notifications = (
      <div>
        <Pagination
          count={letterStore.notifis.total}
          page={letterStore.notifis.page}
          color="primary"
          onChange={this.handleChange}
        />
        <hr />
        {allnotifications}
        <br />
        <Button
          color="red"
          onClick={() => letterStore.deleteAllNotifications()}
        >
          Delete All notifications
        </Button>
      </div>
    );

    if (letterStore.notifis.nots.length <= 0) {
      notifications = (
        <div>
          <br />
          <Empty description={"No New Notifications"} />
          <br />
        </div>
      );
    }

    if (letterStore.loadnotification) {
      notifications = (
        <div>
          <Pagination
            count={letterStore.notifis.total}
            page={letterStore.notifis.page}
            color="primary"
            onChange={this.handleChange}
          />
          <hr />
          <LoadingParagraph />
        </div>
      );
    }

    let singleConvo = <SingleConversation />;
    if (letterStore.singleconvoloading) {
      singleConvo = <LoadingParagraph />;
    }

    return (
      <div>
        <Container>
          <br />
          <PaperSTD>
            <div style={{ minHeight: "60vh" }}>
              <Tabs
                value={letterStore.inboxtab}
                onChange={(e, value) => {
                  letterStore.changeinbox(value);
                  this.setState({ value: !this.state.value });
                }}
                variant="scrollable"
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="on"
              >
                <Tab label={`Messages (${letterStore.conversations.length})`} />
                <Tab label={`Notifications (${0})`} />
                {letterStore.selectedConvo._id && (
                  <Tab label={letterStore.selectedConvo.subject} />
                )}
              </Tabs>
              <hr />
              <div>
                {letterStore.inboxtab === 0 && conversations}
                {letterStore.inboxtab === 1 && notifications}
                {letterStore.inboxtab === 2 && singleConvo}
              </div>
            </div>
          </PaperSTD>
        </Container>
      </div>
    );
  }
}

export default withRouter(observer(ConversationPage));
