import React, { Component } from "react";
import { observer } from "mobx-react";
import { Button, Modal, FlexboxGrid, Col } from "rsuite";
import makeList from "../../../Media/Static/CarMakes";
import modelList from "../../../Media/Static/CarModels";
import { withRouter } from "react-router";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import carStore from "../../../Stores/CarStore";
import Years from "../../../Media/Static/Years";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import CarPics from "./CarPics/CarPics";
import UnifiedModal from "../../../Components/UnifiedModal/UnifiedModal";

class EditCarModal extends Component {
  render() {
    let models;
    if (carStore.editcar.make.length > 0) {
      models = modelList.filter(
        (car) => car.make.toLowerCase() === carStore.editcar.make.toLowerCase()
      );
    } else {
      models = modelList;
    }

    let disableButton = { val: false, message: "Submit Updates" };
    if (carStore.editcar.make === "") {
      disableButton = { val: true, message: "Add the Make" };
    } else if (carStore.editcar.make === "") {
      disableButton = { val: true, message: "Add the Model" };
    }

    return (
      <UnifiedModal
        title={"Edit Car"}
        show={this.props.show}
        onHide={this.props.onHide}
        footer={
          <FlexboxGrid>
            <Col xs={24} sm={12}>
              <Button
                block
                color="green"
                disabled={disableButton.val}
                onClick={() => {
                  this.props.onHide();
                  carStore.putEditCar();
                }}
              >
                {disableButton.message}
              </Button>
            </Col>
            <Col xs={24} sm={12}>
              <Button
                block
                color="red"
                disabled={disableButton.val}
                onClick={() => {
                  this.props.onHide();
                  carStore.deleteCar(carStore.editcar._id);
                }}
              >
                Delete Car
              </Button>
            </Col>
          </FlexboxGrid>
        }
      >
        <Autocomplete
          id="free-solo-demo"
          freeSolo
          defaultValue={carStore.editcar.make}
          options={makeList}
          onChange={(e) => carStore.editEditCar("make", e.target.innerText)}
          renderInput={(params) => (
            <TextField
              {...params}
              value={carStore.editcar.make}
              label="Car Make"
              margin="normal"
              variant="standard"
              onChange={(e) => carStore.editEditCar("make", e.target.value)}
            />
          )}
        />
        <Autocomplete
          id="free-solo-demo"
          freeSolo
          defaultValue={carStore.editcar.model}
          options={models.map((option) => option.model)}
          onChange={(e) => carStore.editEditCar("model", e.target.innerText)}
          renderInput={(params) => (
            <TextField
              {...params}
              value={carStore.editcar.model}
              label="Car Model"
              margin="normal"
              variant="standard"
              onChange={(e) => carStore.editEditCar("model", e.target.value)}
            />
          )}
        />

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Age</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={carStore.editcar.year}
            onChange={(e) => carStore.editEditCar("year", e.target.value)}
          >
            {Years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />
      </UnifiedModal>
    );
  }
}

export default withRouter(observer(EditCarModal));
