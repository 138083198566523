import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import logo from "../../Media/logo.png";
import { withRouter } from "react-router";
import { observer } from "mobx-react";

class ImageParallaxLG extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }
  render() {
    let medias = [];
    if (this.props.request.medias) {
      medias = this.props.request.medias;
    }
    if (medias.length === 0) {
      medias.push(logo);
    }
    let height = 300;
    if (this.props.height) height = this.props.height;
    let cardimages;
    cardimages = medias.map((image, index) => (
      <img
        alt={`${index} Image`}
        src={image}
        style={{ maxHeight: height, objectFit: "contain" }}
        key={index}
      />
    ));

    return (
      <div>
        <Carousel
          dynamicHeight={true}
          autoPlay
          infiniteLoop
          swipeable
          showArrows
          useKeyboardArrows
          style={{ maxHeight: 300 }}
        >
          {cardimages}
        </Carousel>
      </div>
    );
  }
}

export default withRouter(observer(ImageParallaxLG));
