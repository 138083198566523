import React, { Component } from "react";
import { Button } from "rsuite";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Input, Result, Select } from "antd";
import letterStore from "../../../../Stores/LetterStore";
import axios from "axios";
import baseURL from "../../../../Media/baseURL";
import { Notification } from "rsuite";
import userStore from "../../../../Stores/UserStore";

const instance = axios.create({ baseURL: baseURL });

const { Option } = Select;
const { TextArea } = Input;

class SendMessageBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryload: false,
      sentquery: false,
    };
  }

  sendUserQuery = async () => {
    this.setState({ loading: true });
    let letterData = {
      subject: `User Query: ${letterStore.userQuery.subject}`,
      businessId: this.props.match.params.businessId,
      content: letterStore.userQuery.message,
    };
    try {
      await instance
        .post("/api/letter/user/newquery", letterData, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          // console.log(res);
          this.setState({ loading: false, sentquery: true });
          Notification["success"]({
            title: "Message Sent",
          });
        })
        .catch((err) => alert("An Error Occured and the car was not added."));
    } catch (err) {
      this.setState({ loading: false });
      Notification["error"]({
        title: "An Issue Occured, please try again.",
      });
    }
  };

  render() {
    let buttonText = "Write a message to the Company";
    if (letterStore.userQuery.message.length > 3) buttonText = "Send Message";
    if (this.state.sentquery) buttonText = "Message Sent!";
    if (this.state.loading) buttonText = "Sending Message...";

    let queryInputBoxes = (
      <div>
        <Select
          size="large"
          showSearch
          placeholder="Select a Subject"
          optionFilterProp="children"
          value={letterStore.userQuery.subject}
          onChange={(e) => letterStore.updateNewQuery("subject", e)}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          style={{ width: "100%", backgroundColor: "rgb(255,255,255,0)" }}
        >
          <Option value={"General Information"}>General Information</Option>
          <Option value={"Product Question"}>Product Question</Option>
          <Option value={"Service Question"}>Service Question</Option>
          <Option value={"Complaint"}>Complaint</Option>
        </Select>
        <TextArea
          showCount
          maxLength={500}
          value={letterStore.userQuery.message}
          onChange={(e) =>
            letterStore.updateNewQuery("message", e.target.value)
          }
          allowClear
          autoSize={{ minRows: 5, maxRows: 20 }}
        />
      </div>
    );

    if (this.state.sentquery) {
      queryInputBoxes = (
        <Result
          status="success"
          title={`Successfully Sent Message: ${letterStore.userQuery.subject}`}
          subTitle={letterStore.userQuery.message}
          extra={[
            <Button
              appearance="primary"
              key="console"
              onClick={() => this.props.history.push("/messages")}
            >
              Go To Inbox
            </Button>,
          ]}
        />
      );
    }

    return (
      <div
        style={{
          boxShadow: "0px 0px 5px black",
          borderRadius: 10,
          padding: 5,
        }}
      >
        {queryInputBoxes}
        <Button
          block
          onClick={() => {
            if (buttonText === "Send Message") {
              this.sendUserQuery();
            }
          }}
          appearance={buttonText !== "Send Message" ? "ghost" : "default  "}
          color="green"
          style={{ fontWeight: "bold" }}
        >
          {buttonText}
        </Button>
      </div>
    );
  }
}

export default withRouter(observer(SendMessageBox));
