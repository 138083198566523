import { ToggleButton } from "@material-ui/lab";
import { Divider } from "antd";
import Modal from "antd/lib/modal/Modal";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  Button,
  Col,
  FlexboxGrid,
  HelpBlock,
  Input,
  InputGroup,
  InputNumber,
  Radio,
  RadioGroup,
  Toggle,
} from "rsuite";
import productStore from "../../../../Stores/ProductStore";
import SampleOption from "./SampleOption";
import SingleItem from "./SingleItem";

class OptionModal extends Component {
  render() {
    let option = productStore.newoption;
    let disableAdd = false;
    let items = option.items.map((item, index) => (
      <SingleItem key={index} index={index} />
    ));
    let error = "Save";
    if (option.type === "") {
      error = "Option Type Required";
    } else if (option.title === "") {
      error = "English Title Required";
    } else if (option.titlear === "") {
      error = "Arabic Title Required";
    } else {
      for (let i = 0; i < option.items.length; i++) {
        if (
          option.items[i].title === "" ||
          option.items[i].titlear === "" ||
          option.items[i].price === ""
        ) {
          error = `Item ${i + 1} Incomplete`;
        }
      }
    }

    return (
      <Modal
        title="New Option"
        onCancel={this.props.onCancel}
        visible={this.props.show}
        width={"95%"}
        footer={
          <div>
            <FlexboxGrid>
              <Col xs={12}>
                <Button
                  color="red"
                  block
                  appearance="ghost"
                  onClick={() => {
                    this.props.onCancel();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col xs={12}>
                <Button
                  block
                  color="green"
                  disabled={error !== "Save"}
                  onClick={() => {
                    productStore.saveNewOption();
                    this.props.onCancel();
                  }}
                >
                  {error}
                </Button>
              </Col>
            </FlexboxGrid>
          </div>
        }
      >
        <HelpBlock style={{ fontWeight: "bold" }}>Type: (Select 1)</HelpBlock>
        <RadioGroup inline>
          <Radio
            value="Single"
            checked={option.type === "Single"}
            onChange={() => productStore.updateNewOption("type", "Single")}
          >
            Single
          </Radio>
          <Radio
            value="Multiple"
            checked={option.type === "Multiple"}
            onChange={() => productStore.updateNewOption("type", "Multiple")}
          >
            Multiple
          </Radio>
        </RadioGroup>
        <hr />
        <FlexboxGrid>
          <Col xs={24} sm={12}>
            <HelpBlock style={{ fontWeight: "bold" }}>
              English Title:{" "}
              <i style={{ color: "red", fontWeight: "normal" }}>
                {option.title === "" && "Required"}
              </i>
            </HelpBlock>
            <Input
              value={option.title}
              onChange={(e) => productStore.updateNewOption("title", e)}
            />
          </Col>
          <Col xs={24} sm={12}>
            <HelpBlock style={{ fontWeight: "bold" }}>
              Arabic Title:{" "}
              <i style={{ color: "red", fontWeight: "normal" }}>
                {option.titlear === "" && "Required"}
              </i>
            </HelpBlock>
            <Input
              value={option.titlear}
              onChange={(e) => productStore.updateNewOption("titlear", e)}
            />
          </Col>
        </FlexboxGrid>
        <Divider>Items:</Divider>
        {items}
        <Button
          block
          appearance="ghost"
          color="green"
          onClick={() => productStore.addNewOptionItem()}
        >
          + New Item
        </Button>
        {option.type === "Multiple" && (
          <FlexboxGrid>
            <Divider>Required / Maximum</Divider>
            <Col xs={12}>
              <InputGroup>
                <InputGroup.Addon>Min Selection</InputGroup.Addon>
                <InputNumber
                  min={0}
                  max={option.items.length}
                  value={option.min}
                  onChange={(e) => productStore.updateNewOption("min", e)}
                />
              </InputGroup>
            </Col>
            <Col xs={12}>
              <InputGroup>
                <InputGroup.Addon>Max Selection</InputGroup.Addon>
                <InputNumber
                  min={option.min}
                  max={option.items.length}
                  value={option.max}
                  onChange={(e) => productStore.updateNewOption("max", e)}
                />
              </InputGroup>
            </Col>
          </FlexboxGrid>
        )}
        <Divider>Sample</Divider>
        <SampleOption option={option} />
      </Modal>
    );
  }
}

export default withRouter(observer(OptionModal));
