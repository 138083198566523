import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Col, FlexboxGrid, Checkbox, Button } from "rsuite";
import userStore from "../../../Stores/UserStore";
import IndividualSubBlock from "./IndividualSubBlock";
import axios from "axios";
import baseURL from "../../../Media/baseURL";
import { message } from "antd";

const instance = axios.create({ baseURL: baseURL });

class UserSubscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  componentDidMount() {
    userStore.changeProfileInfo("emailrequest", userStore.user.emailrequest);
    userStore.changeProfileInfo("textrequest", userStore.user.textrequest);
    userStore.changeProfileInfo("emailquote", userStore.user.emailquote);
    userStore.changeProfileInfo("textquote", userStore.user.textquote);
  }

  updateUserInfo = async () => {
    try {
      let data = userStore.editprofileinfo;
      this.setState({ loading: true });
      let requestData = {
        emailrequest: data.emailrequest,
        emailquote: data.emailquote,
        textrequest: data.textrequest,
        textquote: data.textquote,
      };

      let newrequest = await instance
        .put("/auth/update/profile/updateinfo", requestData, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.setState({ loading: false });
          message.success("User Subscription Chaned Successfully.");
          userStore.changeUserInfo("emailrequest", data.emailrequest);
          userStore.changeUserInfo("emailquote", data.emailquote);
          userStore.changeUserInfo("textrequest", data.textrequest);
          userStore.changeUserInfo("textquote", data.textquote);
        })
        .catch((err) => {
          console.error(err.response);
          message.error(err.response.data);
          this.setState({ loading: false });
        });
    } catch (err) {
      console.log(err.response);
    }
  };

  render() {
    let userdata = userStore.editprofileinfo;
    let subscriptionchange;
    if (userdata.emailrequest !== userStore.user.emailrequest) {
      subscriptionchange = true;
    } else if (userdata.emailquote !== userStore.user.emailquote) {
      subscriptionchange = true;
    } else if (userdata.textquote !== userStore.user.textquote) {
      subscriptionchange = true;
    } else if (userdata.textrequest !== userStore.user.textrequest) {
      subscriptionchange = true;
    }

    let emailblock;
    if (userStore.user.email) {
      emailblock = (
        <div>
          <h6>Email Subscriptions</h6>
          {userStore.userAccess === 4 && (
            <IndividualSubBlock
              type="emailrequest"
              disable={this.state.loading}
            />
          )}
          <IndividualSubBlock type="emailquote" disable={this.state.loading} />
        </div>
      );
    }
    let phoneblock;
    console.log(userStore.user);
    if (userStore.user.phone) {
      phoneblock = (
        <div>
          <h6>Mobile Text Subscriptions</h6>
          {userStore.userAccess === 4 && (
            <IndividualSubBlock
              type="textrequest"
              disable={this.state.loading}
            />
          )}
          <IndividualSubBlock type="textquote" disable={this.state.loading} />
        </div>
      );
    }

    return (
      <div>
        {emailblock}
        {phoneblock}
        {subscriptionchange && (
          <div>
            <Button
              color="green"
              disabled={this.state.loading}
              onClick={() => this.updateUserInfo()}
            >
              {this.state.loading ? "Updating..." : "Save Subscription Changes"}
            </Button>
            <Button
              color="red"
              disabled={this.state.loading}
              appearance="link"
              onClick={() => {
                userStore.changeProfileInfo(
                  "emailrequest",
                  userStore.user.emailrequest
                );
                userStore.changeProfileInfo(
                  "textrequest",
                  userStore.user.textrequest
                );
                userStore.changeProfileInfo(
                  "emailquote",
                  userStore.user.emailquote
                );
                userStore.changeProfileInfo(
                  "textquote",
                  userStore.user.textquote
                );
              }}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(observer(UserSubscriptions));
