import React, { Component } from "react";

class BusinessGarageArabia extends Component {
  render() {
    return (
      <div>
        <p>
          Garage Arabia connects businesses to customers through an online
          portal. We believe in a comptetitve quoting environment where
          customers can get quotes to selected services digitally.
        </p>
        <br />
        <p>
          Garages will be able to market their services, submit their projects
          and posts, and compete for customer requests.
        </p>
      </div>
    );
  }
}

export default BusinessGarageArabia;
