import { Empty } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Button, FlexboxGrid } from "rsuite";
import { Badge, Image } from "antd";
import productStore from "../../../../Stores/ProductStore";
import NewImageModal from "./NewImageModal";

class MediaNewProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      showdelete: false,
      showedit: false,
    };
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  showModal() {
    this.setState({ show: true });
  }
  closeModal() {
    this.setState({ show: false, showdelete: false, showedit: false });
  }

  render() {
    let medias = productStore.newproduct.medias;

    let imageBanner = medias.map((image, index) => (
      <Image src={image} key={index} width={150} preview={true} key={index} />
    ));

    return (
      <div>
        <NewImageModal show={this.state.show} onClose={this.closeModal} />
        <FlexboxGrid justify="space-around">{imageBanner}</FlexboxGrid>
        <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
          <br />
          {productStore.newproduct.medias.length <= 0 && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <div>
                  <h6>No Images</h6>
                </div>
              }
            />
          )}
          <Button
            color="green"
            block
            onClick={() => this.setState({ show: true })}
          >
            {medias.length === 0 ? "Add Images" : "Add/Change Images"}
          </Button>
          <hr />
        </div>
      </div>
    );
  }
}

export default withRouter(observer(MediaNewProduct));
