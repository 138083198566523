import React, { Component } from "react";
import { observer } from "mobx-react";
import { Button, Col, Modal, FlexboxGrid } from "rsuite";
import { withRouter } from "react-router";
import moment from "moment";
import ImageParallaxLG from "../../../Components/ImageParallax/ImageParallaxLG";
import { Divider } from "antd";
import quoteStore from "../../../Stores/QuoteStore";
import { Container } from "@material-ui/core";
import LoadingParagraph from "../../../Components/LoadingPages/LoadingParagraph";
import UnifiedModal from "../../../Components/UnifiedModal/UnifiedModal";

class RequestModal extends Component {
  render() {
    let req = quoteStore.selectedrequest.request;
    let quotecount = quoteStore.selectedrequest.quotecount;
    let submittedquote = quoteStore.selectedrequest.submittedquote;

    let title = "";
    if (req.service) {
      title = req.service.title;
    }

    if (quoteStore.loading) {
      let loadingtitle = "Loading Details...";
      return (
        <UnifiedModal
          title={"Loading Details"}
          show={this.props.show}
          onHide={this.props.onHide}
          footer={null}
        >
          <LoadingParagraph />
        </UnifiedModal>
      );
    }

    return (
      <UnifiedModal
        title={`${title} Request Details:`}
        show={this.props.show}
        onHide={this.props.onHide}
        footer={
          <Button block apperance="ghost" onClick={() => this.props.onHide()}>
            Close
          </Button>
        }
      >
        <div style={{ paddingLeft: "5%" }}>
          <h6>
            <strong>Description: </strong>
            {req.description}
            <br />
            <strong>Car: </strong>
            {req.year} {req.make} - {req.model}
            <br />
            <strong>Close Date: </strong>
            {moment(req.closedate).format("DD-MMM-YY")} (
            {moment(req.closedate).fromNow()})
          </h6>
        </div>
        <Divider orientation="left">
          <h5>
            {quotecount} <i>/ {req.maxQuote} </i> Quotes Submitted:
          </h5>
        </Divider>
        {submittedquote ? (
          <div>
            <Divider orientation="left">
              <h5>Quote:</h5>
            </Divider>
            <Container>
              <FlexboxGrid style={{ textAlign: "center" }}>
                <Col xs={12}>
                  <h6>
                    <strong>Price: </strong>
                    {Math.round(1000 * submittedquote.price) / 1000} KWD
                  </h6>
                </Col>
                <Col xs={12}>
                  <h6>
                    <strong>Duration: </strong>
                    {Math.round(10 * submittedquote.duration) / 10} hr
                    {submittedquote.duration !== 1 && "s"}
                  </h6>
                </Col>
              </FlexboxGrid>
              <h6>
                <strong>Quote Remarks: </strong> {submittedquote.remarks}
              </h6>
              <h6 style={{ color: "red" }}>
                <strong>Excluded from Quote: </strong>
                {submittedquote.excludes}
              </h6>
              <Button
                block
                color="green"
                onClick={() => this.props.history.push(`/request/${req._id}`)}
              >
                Go to Request Page
              </Button>
            </Container>
          </div>
        ) : (
          <Col xs={24} style={{ padding: 20 }}>
            <Button
              block
              color="green"
              onClick={() => this.props.history.push(`/request/${req._id}`)}
            >
              Go to Request Page
            </Button>
          </Col>
        )}

        <hr />
        <ImageParallaxLG request={req} />
      </UnifiedModal>
    );
  }
}

export default withRouter(observer(RequestModal));
