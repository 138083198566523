import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import axios from "axios";
import LoadingParagraph from "../LoadingPages/LoadingParagraph";
import baseURL from "../../Media/baseURL";
import requestSummaryStore from "../../Stores/RequestSummaryStore";

const instance = axios.create({ baseURL: baseURL });

class RequestSummaryRedirect extends Component {
  componentDidMount() {
    try {
      let data = instance
        .get(
          `/api/request/uniquereqnumid/${this.props.match.params.requestId}/`
        )
        .then((res) => res.data)
        .then((requests) => {
          console.log(requests);
          this.props.history.push(`/request/${requests._id}`);
        })

        .catch((err) => {
          this.props.history.push("/");
        });
    } catch (err) {
      this.props.history.push("/");
    }
  }

  render() {
    if (requestSummaryStore.loading) {
      return <LoadingParagraph />;
    }

    return <div style={{ minHeight: "100vh" }} />;
  }
}
export default withRouter(observer(RequestSummaryRedirect));
