//InstallPWA.js
import React, { Component } from "react";
import { Button } from "rsuite";

import image from "../Media/logo.png";
import share from "../Media/AppleShare.png";
import UnifiedModal from "../Components/UnifiedModal/UnifiedModal";

class InstallPWA extends Component {
  render() {
    return (
      <div>
        <UnifiedModal
          show={this.props.show}
          title={"Add Garage Arabia"}
          onCancel={this.props.onHide}
          footer={
            <Button
              appearance="ghost"
              block
              onClick={() => this.props.onHide()}
            >
              Close
            </Button>
          }
        >
          <div
            style={{
              textAlign: "center",
              display: "block",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              width: "100%",
            }}
          >
            <img src={image} alt="icon" rounded style={{ maxWidth: 100 }} />
          </div>

          <br />
          <h5 style={{ textAlign: "center" }}>Add Garage Arabia</h5>
          <hr />
          <p style={{ textAlign: "center" }}>
            Install Garage Arabia on your phone for a better experience.
          </p>
          <div>
            <p>
              Tap
              <img
                src={share}
                style={{ margin: "auto 4px 8px" }}
                alt="Add to homescreen"
                height="20"
                width="20"
              />
              then &quot;Add to Home Screen&quot;
            </p>
          </div>
        </UnifiedModal>
      </div>
    );
  }
}

export default InstallPWA;
