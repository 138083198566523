import React, { Component } from "react";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { TextField } from "@material-ui/core";
import { Col, FlexboxGrid } from "rsuite";
import { Divider } from "antd";
import ImageCard from "./ImageCard";
import userStore from "../../../../Stores/UserStore";

class Step3 extends Component {
  render() {
    let request = this.props.request;

    let imageCards = request.medias.map((file, index) => (
      <ImageCard key={index} index={index} photo={file} hideButton={true} />
    ));
    return (
      <div>
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          label="Description"
          value={request.description}
          onChange={(e) => console.log(e)}
          style={{ background: "rgb(255,255,255,0)" }}
        />
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          label="Car"
          value={`${request.year} ${request.make} - ${request.model}`}
          onChange={(e) => console.log(e)}
          style={{ background: "rgb(255,255,255,0)" }}
        />
        <Divider orientation="left">Images</Divider>
        <FlexboxGrid>{imageCards}</FlexboxGrid>
        {this.props.type !== "Edit" && (
          <div style={{ textAlign: "center" }}>
            <hr />
            <FlexboxGrid>
              <Col style={{ textAlign: "left" }} xs={12}>
                <strong>
                  <h6>Available:</h6>
                </strong>
              </Col>
              <Col style={{ textAlign: "right" }} xs={12}>
                <h6>
                  {userStore.user.credit} Credit
                  {userStore.user.credit !== 1 && "s"}
                </h6>
              </Col>
            </FlexboxGrid>
            <FlexboxGrid>
              <Col style={{ textAlign: "left" }} xs={12}>
                <strong>
                  <h6>Cost:</h6>
                </strong>
              </Col>
              <Col style={{ textAlign: "right" }} xs={12}>
                <h6>-1 Credit</h6>
              </Col>
            </FlexboxGrid>
            <hr style={{ lineHeight: 0.8 }} />
            <FlexboxGrid>
              <Col style={{ textAlign: "left" }} xs={12}>
                <strong>
                  <h5>Remaining:</h5>
                </strong>
              </Col>
              <Col style={{ textAlign: "right" }} xs={12}>
                <h5>
                  {userStore.user.credit - 1} Credit
                  {userStore.user.credit - 1 !== 1 && "s"}
                </h5>
              </Col>
            </FlexboxGrid>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(observer(Step3));
