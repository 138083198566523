import React, { Component } from "react";
import { Parallax } from "react-parallax";
import { Button, Notification } from "rsuite";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { Container, Paper } from "@material-ui/core";
import { Divider } from "antd";

import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import userStore from "../../Stores/UserStore";

class MainAuthPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showPasswordConf: false,
    };
  }

  componentDidMount = async () => {
    let user = await userStore.fetchReset(this.props.match.params.token);
    if (!user._id) {
      userStore.clearPassReset();
      Notification["error"]({
        title: "Link Expired",
        description:
          "The password reset link has expired. Please try the reset password setting again or contact our office for support.",
        duration: 0,
      });
      this.props.history.push("/auth");
    }
  };

  render() {
    const insideStyles = {
      minHeight: 800,
      paddingTop: 50,
      background:
        "radial-gradient(circle, rgba(51,47,47,0.95) 0%, rgba(70,70,70,0.70) 100%)",
    };

    let user = userStore.resetpassword;
    let validation = userStore.newuser.validation;
    let passerror = { val: false, message: "" };
    let passconferror = { val: false, message: "" };
    let disabledSubmit = false;
    let finalValidation = true;

    if (user.newpass.length < 6) {
      passerror.val = true;
      passerror.message = "Password must be greater than 6 characters.";
      disabledSubmit = true;
      finalValidation = false;
    }
    if (user.newpass2 !== user.newpass) {
      passconferror.val = true;
      passconferror.message = "Passwords do not match.";
      disabledSubmit = true;
      finalValidation = false;
    }

    if (!validation) {
      disabledSubmit = false;
      passerror = { val: false, message: "" };
      passconferror = { val: false, message: "" };
    }

    return (
      <Parallax
        blur={0}
        bgImage={"https://source.unsplash.com/1600x900/?car,garage"}
        bgImageAlt="Background Image"
        strength={150}
        style={{
          minHeight: 800,
          boxShadow: "10px 10px 70px -50px rgba(0,0,0,0.75)",
        }}
      >
        <div
          style={{
            minHeight: 800,
            width: "100%",
          }}
        >
          <div style={insideStyles}>
            <Container style={{ alignSelf: "center" }}>
              <Paper
                elevation={10}
                style={{
                  background:
                    "radial-gradient(circle, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 100%)",
                  borderRadius: 10,
                  padding: 30,
                  textAlign: "center",
                  maxWidth: 400,
                }}
              >
                <div>
                  <Divider>
                    <h5>Reset Password Form</h5>
                  </Divider>
                  <h6>{userStore.resetpassword.email}</h6>
                  <TextField
                    error={passerror.val}
                    helperText={passerror.val && passerror.message}
                    variant="standard"
                    margin="normal"
                    fullWidth
                    disabled={userStore.loading}
                    name="password"
                    label="Password"
                    type={this.state.showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="current-password"
                    value={userStore.resetpassword.newpass}
                    style={{ background: "rgb(255,255,255,0)" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            tabindex="-1"
                            aria-label="toggle password visibility"
                            onMouseOver={() =>
                              this.setState({ showPassword: true })
                            }
                            onMouseLeave={() =>
                              this.setState({ showPassword: false })
                            }
                          >
                            {this.state.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) =>
                      userStore.newPassReset("newpass", e.target.value)
                    }
                  />
                  <TextField
                    error={passconferror.val}
                    helperText={passconferror.val && passconferror.message}
                    variant="standard"
                    margin="normal"
                    fullWidth
                    disabled={userStore.loading}
                    name="passwordconf"
                    label="Confirm Password"
                    type={this.state.showPasswordConf ? "text" : "password"}
                    id="passwordconf"
                    autoComplete="current-passwordconf"
                    value={userStore.resetpassword.newpass2}
                    style={{ background: "rgb(255,255,255,0)" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" tabindex="-1">
                          <IconButton
                            tabindex="-1"
                            aria-label="toggle password visibility"
                            onMouseOver={() =>
                              this.setState({ showPasswordConf: true })
                            }
                            onMouseLeave={() =>
                              this.setState({ showPasswordConf: false })
                            }
                          >
                            {this.state.showPasswordConf ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) =>
                      userStore.newPassReset("newpass2", e.target.value)
                    }
                  />
                  <br />
                  <hr />
                  <Button
                    block
                    color="green"
                    style={{ boxShadow: "0px 0px 3px black" }}
                    disabled={disabledSubmit}
                    onClick={() => userStore.changePassword(finalValidation)}
                  >
                    {userStore.loading ? "Loading..." : "Change Password"}
                  </Button>
                </div>
              </Paper>
            </Container>
          </div>
        </div>
      </Parallax>
    );
  }
}

export default withRouter(observer(MainAuthPage));
