import { Result } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Button } from "rsuite";
import { withRouter } from "react-router";

class MaxRequests extends Component {
  render() {
    return (
      <div>
        <Result
          title="Maximum Requests Reached"
          subTitle="To better control the quality of the requests and the quotes, a user may only have a maximum of 2 Open requests at any time. You can use the below link to track and close any pending requests."
        />
        <Button
          block
          color="green"
          onClick={() => this.props.history.push("/requests")}
        >
          Track Requests
        </Button>
      </div>
    );
  }
}

export default withRouter(observer(MaxRequests));
