import { Result } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Button } from "rsuite";
import { withRouter } from "react-router";
import userStore from "../../Stores/UserStore";
import { Container } from "@material-ui/core";

class MaxPosts extends Component {
  render() {
    let limit = this.props.limit;
    return (
      <Container style={{ maxWidth: 500 }}>
        <Result
          title={`Maximum Posts Reached (${limit} Posts)`}
          subTitle={`To better control the quality of the posts and to apply fair marketing rules, each Garge gets a limit of ${limit} posts within a 24 hour period.`}
        />
        <Button
          block
          color="green"
          onClick={() =>
            this.props.history.push(`/business/${userStore.user.business}`)
          }
        >
          Company Profile
        </Button>
      </Container>
    );
  }
}

export default withRouter(observer(MaxPosts));
