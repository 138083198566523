import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { GoogleLogin } from "react-google-login";
import axios from "axios";
import baseURL from "../../../Media/baseURL";
import { message } from "antd";
import userStore from "../../../Stores/UserStore";

import { Button, Icon } from "rsuite";

const instance = axios.create({ baseURL: baseURL });

class GoogleAllAuth extends Component {
  handleLogin = async (googleData) => {
    userStore.changeLoadValue(true);
    try {
      let tokenData = {
        token: googleData.tokenId,
      };

      if (this.props.authType === "referral") {
        tokenData = {
          token: googleData.tokenId,
          referralcode: this.props.referralcode,
        };

        const res2 = await instance.post(
          "/api/google/auth/referral",
          tokenData,
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        userStore.allOAuthLogin(res2.data);
      } else {
        const res = await instance.post("/api/google/auth/login", tokenData, {
          headers: { "Content-Type": "application/json" },
        });

        userStore.allOAuthLogin(res.data);
      }
      userStore.changeLoadValue(false);
    } catch (err) {
      userStore.changeLoadValue(false);
      console.log(err);
      message.error(err.response.data.error);
    }
  };

  render() {
    return (
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        onSuccess={(e) => this.handleLogin(e)}
        onFailure={(e) => this.handleLogin(e)}
        cookiePolicy={"single_host_origin"}
        render={(renderProps) => (
          <Button
            block
            disabled={userStore.loading}
            appearance={userStore.loading ? "default" : "primary"}
            onClick={renderProps.onClick}
          >
            <Icon icon="google" />{" "}
            {this.props.type === "referral" ? "Sign Up" : "Sign In"} With Google
          </Button>
        )}
      />
    );
  }
}

export default withRouter(observer(GoogleAllAuth));
