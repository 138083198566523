import { Paper } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { FlexboxGrid, Col, Button, HelpBlock } from "rsuite";

class BusinessPackages extends Component {
  render() {
    return (
      <div>
        <FlexboxGrid>
          <Col md={6} sm={12} xs={24}>
            <Paper
              elevation={5}
              style={{
                borderRadius: 4,
                padding: "5%",
                margin: "5%",
                backgroundColor: "#cffadb",
              }}
            >
              <h6 style={{ textAlign: "center" }}>Basic Package</h6>
              <hr />
              <p>Unique Profile Page</p>
              <p>Customizable Services and Speciality</p>
              <p>Submit quotes to customer requests</p>
              <p>Can add 1 Post Per Day</p>
              <hr />
              <h4 style={{ textAlign: "center" }}>30 KD per month</h4>
              <HelpBlock style={{ textAlign: "center" }}>
                0% Commission
              </HelpBlock>
            </Paper>
          </Col>
          <Col md={6} sm={12} xs={24}>
            <Paper
              elevation={5}
              style={{
                borderRadius: 4,
                padding: "5%",
                margin: "5%",
                backgroundColor: "#adedbe",
              }}
            >
              <h6 style={{ textAlign: "center" }}>Premium Package</h6>
              <hr />
              <p>All Benefits of Basic Package</p>
              <p>View Company Statistics on Garge Arabia</p>
              <p>Customers can request Service Directly without Quoting</p>
              <p>Submit 3 Garage Posts Per Day</p>
              <hr />
              <h4 style={{ textAlign: "center" }}>50 KD per month</h4>
              <HelpBlock style={{ textAlign: "center" }}>
                0% Commission
              </HelpBlock>
            </Paper>
          </Col>
          <Col md={6} sm={12} xs={24}>
            <Paper
              elevation={5}
              style={{
                borderRadius: 4,
                padding: "5%",
                margin: "5%",
                backgroundColor: "#adedbe",
              }}
            >
              <h6 style={{ textAlign: "center" }}>Premium+ Package</h6>
              <hr />
              <p>All Benefits of Premium Package</p>
              <p>Display Products and Customer Checkout</p>
              <p>Customer direct purchases</p>
              <p>Submit 3 Garage Posts Per Day</p>
              <hr />
              <h4 style={{ textAlign: "center" }}>65 KD per month</h4>
              <HelpBlock style={{ textAlign: "center" }}>
                0% Commission
              </HelpBlock>
            </Paper>
          </Col>
          <Col md={6} sm={12} xs={24}>
            <Paper
              elevation={5}
              style={{
                borderRadius: 4,
                padding: "5%",
                margin: "5%",
                backgroundColor: "#97d1a6",
              }}
            >
              <h6 style={{ textAlign: "center" }}>Full Package</h6>
              <hr />
              <p>All Benefits of Transparent Package</p>
              <p>Can submit 4 Garage Posts Per Day</p>
              <p>Unique Whitelabel Website</p>
              <p>Customizable Email and SMS messages</p>
              <hr />
              <h4 style={{ textAlign: "center" }}>80 KD per month</h4>
              <HelpBlock style={{ textAlign: "center" }}>
                0% Commission
              </HelpBlock>
            </Paper>
          </Col>
        </FlexboxGrid>
        <hr />
        <div style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Button
            style={{ fontWeight: "bold" }}
            size="lg"
            block
            color="green"
            onClick={() => this.props.history.push("/join-garage-arabia/")}
          >
            Join Today
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(observer(BusinessPackages));
