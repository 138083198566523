import React, { Component } from "react";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import requestStore from "../../../../Stores/RequestStore";
import { TextField } from "@material-ui/core";
import moment from "moment";
import { Input, Select } from "antd";

const { TextArea } = Input;

const { Option } = Select;

class Step0 extends Component {
  render() {
    let request = this.props.request;
    let services = requestStore.services.map((service) => (
      <Option value={service._id}>{service.title}</Option>
    ));

    return (
      <div>
        <Select
          size="large"
          showSearch
          placeholder="Select a Service"
          optionFilterProp="children"
          value={request.service._id}
          onChange={(e) => requestStore.editNewRequest("service", e)}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          style={{ width: "100%", backgroundColor: "rgb(255,255,255,0)" }}
        >
          {services}
        </Select>
        <p style={{ textAlign: "center" }}>
          <i>{request.service.description}</i>
        </p>
        <hr />
        <TextArea
          showCount
          placeholder="Request Description"
          maxLength={1000}
          onChange={(e) => {
            if (this.props.type === "Edit") {
              requestStore.editEditRequest("description", e.target.value);
            } else {
              requestStore.editNewRequest("description", e.target.value);
            }
          }}
          value={request.description}
          autoSize={{ minRows: 5, maxRows: 20 }}
          style={{
            color: "rgb(255,255,255,0)",
            border: "1px solid black",
          }}
        />

        <TextField
          variant="standard"
          helperText="Currently Fixed at 3 days."
          margin="normal"
          fullWidth
          label="Close Date"
          multiline
          value={moment(request.closedate).format("DD-MMM-YY")}
          onChange={() => console.log()}
          style={{ background: "rgb(255,255,255,0)" }}
        />
      </div>
    );
  }
}

export default withRouter(observer(Step0));
