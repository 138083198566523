import { CardMedia, Container } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component } from "react";

import { withRouter } from "react-router";
import { Button, Icon, FlexboxGrid, Col } from "rsuite";
import PaperSTD from "../../../Components/PaperSTD/PaperSTD";
import userStore from "../../../Stores/UserStore";
import ReferEmailBox from "./ReferEmailBox";
import { WhatsappShareButton } from "react-share";
import diagram from "../../../Media/Wheel.png";

class ReferAFriendPage extends Component {
  render() {
    let referrals = userStore.referafriend.map((email, index) => (
      <ReferEmailBox email={email} key={index} index={index} />
    ));

    return (
      <div>
        <br />
        <Container>
          <PaperSTD title="Invite A Friend">
            <h6 style={{ textAlign: "center" }}>
              Invite a friend and increase your credit:
            </h6>
            <br />
            <FlexboxGrid>
              <Col md={12} sm={12} xs={24}>
                <br />
                <p>
                  For every friend referral, you will receive 2 credits into
                  your account which you can use to get new rates.
                </p>
                <br />
                <hr />
                <h6>Email Invites:</h6>
                {referrals}
                <hr />
                <FlexboxGrid justify="center">
                  <WhatsappShareButton
                    url={`garagearabia.com/referral/${userStore.user.referralCode}`}
                    title={"You have been invited to try Garage Arabia."}
                    style={{ width: "100%" }}
                  >
                    <Button
                      color="green"
                      size="lg"
                      block
                      style={{ fontWeight: "bold" }}
                    >
                      <Icon icon="whatsapp" size="lg" /> Refer Through Whatsapp
                    </Button>
                  </WhatsappShareButton>
                </FlexboxGrid>
                <hr />
              </Col>
              <Col md={12} sm={12} xs={24}>
                <CardMedia
                  component="img"
                  alt="Credit Icon"
                  width="100%"
                  image={diagram}
                  title="Credit Icon"
                  style={{ maxHeight: 300 }}
                />
              </Col>
            </FlexboxGrid>
          </PaperSTD>
        </Container>
      </div>
    );
  }
}

export default withRouter(observer(ReferAFriendPage));
