import React, { Component } from "react";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

class NewRequestTimeline extends Component {
  render() {
    let activeStep = this.props.step;
    let steps = [
      "Request Details",
      "Car Details",
      "Photos (Optional)",
      "Submit",
    ];
    return (
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        style={{ backgroundColor: "transparent" }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  }
}

export default NewRequestTimeline;
