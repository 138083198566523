import { observer } from "mobx-react";
import { withRouter } from "react-router";
import React, { Component } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { Fab } from "@material-ui/core";
import NavigationIcon from "@material-ui/icons/Navigation";
import { green } from "@material-ui/core/colors";

class MapView extends Component {
  constructor(props) {
    super(props);
    this.state = { items: [], user: {}, height: 400, refresh: true };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  componentDidUpdate() {}

  updateWindowDimensions() {
    this.setState({ height: 300 });
  }

  render() {
    return (
      <div>
        <div>
          <MapContainer
            center={[this.props.lat, this.props.lng]}
            zoom={13}
            style={{ height: 280, width: "100%", zIndex: 0 }}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution=""
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker opacity={1} position={[this.props.lat, this.props.lng]}>
              <Popup>
                {this.props.address}
                <br />
                <Fab
                  variant="extended"
                  size="small"
                  color={green}
                  aria-label="add"
                  onClick={() =>
                    window.open(
                      `https://maps.google.com/?q=${this.props.lat},${this.props.lng}`
                    )
                  }
                >
                  <NavigationIcon />
                  Directions
                </Fab>
              </Popup>
            </Marker>
          </MapContainer>
        </div>
        <hr />
      </div>
    );
  }
}

export default withRouter(observer(MapView));
