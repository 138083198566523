import { observer } from "mobx-react";
import React, { Component } from "react";
import { IconButton } from "@material-ui/core";
import { withRouter } from "react-router";
import letterStore from "../../../Stores/LetterStore";
import userStore from "../../../Stores/UserStore";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { Badge, Popover } from "antd";
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@material-ui/core";
import moment from "moment";

class NewMessages extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  closePopper() {
    this.setState({ open: false });
  }

  render() {
    if (userStore.signedIn) {
      return (
        <div>
          <IconButton
            aria-label="Notifications"
            onClick={() => {
              letterStore.changeinbox(0);
              this.props.history.push("/messages");
            }}
          >
            <Badge
              count={letterStore.unseen.length}
              overflowCount={9}
              style={{ backgroundColor: "yellow", color: "black" }}
              size="small"
            >
              <MailOutlineIcon style={{ color: "white" }} />
            </Badge>
          </IconButton>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default withRouter(observer(NewMessages));
