import { makeObservable, observable, computed } from "mobx";
import axios from "axios";
import jwt_decode from "jwt-decode";
import carStore from "./CarStore";
import requestStore from "./RequestStore";
import baseURL from "../Media/baseURL";
import { message } from "antd";
import moment from "moment";
import letterStore from "./LetterStore";
import { Notification, Button } from "rsuite";
import { subscribeUser } from "../subscription";

const instance = axios.create({ baseURL: baseURL });

class UserStore {
  constructor() {
    this.user = { group: "Visitor", credit: 0 };
    this.token = "";
    this.referafriend = [""];
    this.referral = { email: "", code: "" };
    this.signedIn = false;
    this.checkForToken();
    this.loading = false;
    this.newuser = {
      password: "",
      phone: "",
      passwordconf: "",
      email: "",
      validation: false,
      name: "",
      otp: undefined,
    };
    this.resetpassword = {
      token: "",
      date: null,
      newpass: "",
      newpass2: "",
      userId: "",
      email: "",
    };
    this.editprofileinfo = {
      name: "",
      email: "",
      tempemail: "",
      password: "",
      passconfirm: "",
      phone: 0,
      otp: undefined,
      emailrequest: true,
      emailquote: true,
      textrequest: true,
      textquote: true,
    };
    makeObservable(this, {
      user: observable,
      newuser: observable,
      token: observable,
      signedIn: observable,
      resetpassword: observable,
      referral: observable,
      referafriend: observable,
      loading: observable,
      editprofileinfo: observable,
      userAccess: computed,
    });
  }

  changeProfileInfo(type, val) {
    this.editprofileinfo[type] = val;
  }

  changeUserInfo(type, val) {
    this.user[type] = val;
  }

  resetUser() {
    this.newuser = {
      password: "",
      phone: "",
      passwordconf: "",
      email: "",
      validation: false,
      name: "",
      otp: undefined,
    };
  }

  // Profiles:
  // Admin      1 = Administrator
  // Business   4 = Business
  // Customer   7 = Customer
  // Guest      10= Guest

  get userAccess() {
    if (this.user) {
      if (this.user.group === "Admin") {
        return 1;
      } else if (this.user.group === "Business") {
        return 4;
      } else if (this.user.group === "Customer") {
        return 8;
      } else {
        return 9;
      }
    } else {
      return 9;
    }
  }

  changeLoadValue(val) {
    this.loading = val;
  }

  editReferal(index, val) {
    this.referafriend[index] = val;
  }

  changeReferal(type) {
    if (type === "Add") this.referafriend.push("");
    if (type === "Reset") this.referafriend = [""];
  }

  changeUserCredit(val) {
    this.user.credit += val;
  }

  fetchReferral(referralId) {
    let userData = { referral: referralId };
    return instance
      .put(`/auth/referral/getuserdetails`, userData)
      .then((res) => res.data)
      .then((res) => {
        // console.log(res);
        this.referral = { name: res.name, code: referralId };
        this.loading = false;
        return res;
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
        return err;
      });
  }
  fetchReset(token) {
    return instance
      .get(`/auth/passreset/getuserdetails/${token}`)
      .then((res) => res.data)
      .then((res) => {
        this.resetpassword.userId = res._id;
        this.resetpassword.email = res.email;
        this.resetpassword.token = token;
        this.loading = false;
        return res;
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
        return err;
      });
  }
  clearPassReset() {
    this.resetpassword = {
      token: "",
      date: null,
      newpass: "",
      newpass2: "",
      userId: "",
      email: "",
    };
  }

  newPassReset(type, val) {
    this.resetpassword[type] = val;
  }

  signOut = () => {
    this.signedIn = false;
    this.loading = false;
  };
  signIn = () => {
    let duration = moment
      .duration(moment().diff(this.user.lastsignin))
      .asHours();

    if (duration > 1) {
      Notification["success"]({
        title: `Welcome ${this.user.email}.`,
        description: (
          <div>
            Your last Sign In was {moment(this.user.lastsignin).fromNow()}.
          </div>
        ),
      });
      if (!this.user.accountVerified) {
        Notification["warning"]({
          title: `Pending Verification`,
          duration: 0,
          description: (
            <div>
              Please check your email {this.user.email} for the verification
              link.
              <br />
              <Button onClick={() => this.sendAccVerification()}>
                Resend Email
              </Button>
              <Button onClick={() => Notification.close()}>Close</Button>
            </div>
          ),
        });
      }
    }
    subscribeUser();
    this.signedIn = true;
    this.getUserData();
    this.loading = false;
    console.log("User Signed In");
  };

  editnewuser(type, val) {
    this.newuser[type] = val;
  }

  getUserData() {
    letterStore.fetchUnseenLetters();
    requestStore.getUserRequests();
    requestStore.getServices();
    requestStore.getSpeciality();
    carStore.getUserCars();
  }

  checkForToken() {
    this.loading = true;
    const token = localStorage.getItem("myToken");
    if (token) {
      console.log("Found Token");
      const user = jwt_decode(token);
      this.setUser(token, user);
      return instance
        .get("/auth/profile/" + user._id + "/", {
          headers: { authtoken: token },
        })
        .then((res) => res.data)
        .then((res) => {
          this.user = res;
          this.signIn();
        })
        .catch(() => {
          this.loading = false;
          this.logoutUser();
        });
    } else {
      this.loading = false;
    }
  }

  setUser(token, user) {
    // axios.defaults.headers.common.Authorization = `authtoken ${token}`;
    localStorage.setItem("myToken", token);
    this.token = token;
    // console.log("local storage check token");
    // console.log(localStorage.myToken);
    // this.signedIn = true;
  }

  logoutUser() {
    delete axios.defaults.headers.common.Authorization;
    this.token = "";
    this.user = { group: "Visitor" };
    this.signedIn = false;
    localStorage.removeItem("myToken");
    console.log("User Logged Out");
  }

  allOAuthLogin = async (logindata) => {
    try {
      this.loading = true;
      console.log("All Auth Sign In Attempt.");
      this.token = logindata.token;
      this.user = logindata.user;
      this.signIn();
      this.setUser(this.token, this.user);
    } catch (err) {
      console.log(err);
      this.loading = false;
      alert(
        "Sign In Failed. Please check Username and Password and try again."
      );
    }
  };

  loginUser = async (userData) => {
    try {
      this.newuser.validation = true;
      if (userData.validation) {
        this.loading = true;
        console.log("User Sign In Attempt.");
        const res = await instance.post("/auth/login", userData);
        this.token = res.data.token;
        this.user = res.data.user;
        this.signIn();
        this.setUser(this.token, this.user);
      }
    } catch (err) {
      console.log(err);
      this.loading = false;
      alert(
        "Sign In Failed. Please check Username and Password and try again."
      );
    }
  };

  phoneloginUser = async (userData) => {
    try {
      this.newuser.validation = true;
      this.loading = true;
      console.log("User Sign In Attempt.");
      const res = await instance.post("/auth/phonelogin", userData);
      this.token = res.data.token;
      this.user = res.data.user;
      this.signIn();
      this.setUser(this.token, this.user);
    } catch (err) {
      console.log(err.response.data);
      this.loading = false;

      Notification["error"]({
        title: `Login Failed!`,
        description: (
          <div>
            Sign In Failed. Please check the Phone Number / Password and try
            again.
          </div>
        ),
      });
    }
  };

  sendPassReset = async (userData) => {
    try {
      this.newuser.validation = true;
      if (userData.validation) {
        this.loading = true;
        console.log("Forgot Password In Attempt.");
        const res = await instance.post("/email/auth/sendpassreset", userData);

        if (res.data === "Email Sent!") {
          this.loading = false;
          return true;
        } else {
          this.loading = false;
          return res.data;
        }
      }
    } catch (err) {
      console.log(err);
      this.loading = false;
      alert("Password Reset Failed to Send.");
    }
  };

  sendAccVerification = async () => {
    try {
      let userData = { email: this.user.email };
      console.log("Forgot Password In Attempt.");
      const res = await instance.post("/email/auth/sendverification", userData);
      if (res.data === "Email Sent!") {
        console.log(`Email Verification Sent.`);
        message.success(`Email Verification Sent.`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  confirmVerification = async (token) => {
    try {
      this.loading = true;
      console.log("Account Verification Attempt.");
      let userData = { token: token };
      const res = await instance.put("/auth/account/verification", userData);
      if (res.data === "Email Sent!") {
        this.loading = false;
        this.user.accountVerified = true;
        return true;
      } else {
        this.loading = false;
        return res.data;
      }
    } catch (err) {
      console.log(err);
      this.loading = false;
      alert("Password Reset Failed to Send.");
    }
  };

  updateGroup() {
    let data = "Customer";
    if (this.user.group === "Customer") {
      data = "Business";
    }
    this.user.group = data;
  }

  registerUser = async (userData) => {
    try {
      this.newuser.validation = true;
      if (userData.validation) {
        this.loading = true;
        console.log("User Email Sign Up Attempt.");
        const res = await instance.post("/auth/register", userData);
        this.token = res.data.token;
        this.user = res.data.user;
        this.signIn();
        Notification["success"]({
          title: `Welcome ${this.user.email}.`,
          duration: 0,
          description: (
            <div>
              Thank you for joining Garage Arabia
              <hr />
              Our team are here to support you with anything you need.
            </div>
          ),
        });
        this.setUser(this.token, this.user);
      }
    } catch (err) {
      this.loading = false;
      alert(`Email ${userData.email} Already Exists.`);
    }
  };

  phoneregisterUser = async (userData) => {
    try {
      this.newuser.validation = true;
      if (userData.validation) {
        this.loading = true;
        console.log("User Phone Sign Up Attempt.");
        const res = await instance.post("/auth/phoneregister", userData);
        this.token = res.data.token;
        this.user = res.data.user;
        this.signIn();
        Notification["success"]({
          title: `Welcome ${this.user.name}.`,
          duration: 0,
          description: (
            <div>
              Thank you for joining Garage Arabia
              <hr />
              Our team are here to support you with anything you need.
            </div>
          ),
        });
        this.setUser(this.token, this.user);
      }
    } catch (err) {
      this.loading = false;
      alert(`Phone ${userData.email} Already Exists.`);
    }
  };

  phonereferregisterUser = async (userData) => {
    try {
      this.newuser.validation = true;
      if (userData.validation) {
        this.loading = true;
        console.log("User Phone Sign Up Attempt.");
        const res = await instance.post(
          "/auth/referral/phoneregister",
          userData
        );
        this.token = res.data.token;
        this.user = res.data.user;
        this.signIn();
        Notification["success"]({
          title: `Welcome ${this.user.name}.`,
          duration: 0,
          description: (
            <div>
              Thank you for joining Garage Arabia
              <hr />
              Our team are here to support you with anything you need.
            </div>
          ),
        });
        this.setUser(this.token, this.user);
      }
    } catch (err) {
      this.loading = false;
      alert(`Email ${userData.email} Already Exists.`);
    }
  };

  registerReferredUser = async (userData) => {
    try {
      this.newuser.validation = true;
      if (userData.validation) {
        this.loading = true;
        console.log("User Sign Up Attempt.");
        const res = await instance.post("/auth/referral/register", userData);
        this.token = res.data.token;
        this.user = res.data.user;
        this.signIn();
        this.setUser(this.token, this.user);
      }
    } catch (err) {
      this.loading = false;

      Notification["error"]({
        title: `Email Already Exists`,
        description: (
          <div>
            Email {userData.email} Already Exists.
            <hr />
            Please use a different email or sign in to your account using the
            buttons below.
          </div>
        ),
      });
    }
  };

  changePassword = async (validation) => {
    let userData = {
      userId: this.resetpassword.userId,
      token: this.resetpassword.token,
      newpass: this.resetpassword.newpass,
    };
    console.log(userData);
    try {
      this.newuser.validation = true;
      if (validation) {
        this.loading = true;
        console.log("User Password Change Attempt.");
        let res = await instance.put("/auth/updatepass", userData);
        this.token = res.data.token;

        this.user = res.data.user;
        message.success(`Password Change was Successful.`);

        this.signIn();
        this.setUser(this.token, this.user);
      }
    } catch (err) {
      this.loading = false;
      alert(`Error Resetting Password.`);
    }
  };
  changePhonePassword = async (validation) => {
    let userData = {
      phone: this.newuser.phone,
      newpass: this.newuser.password,
    };
    console.log(userData);
    try {
      this.newuser.validation = true;
      if (validation) {
        this.loading = true;
        console.log("User Password Change Attempt.");
        let res = await instance.put("/auth/updatephonepass", userData);
        console.log(res);
        this.token = res.data.token;
        this.user = res.data.user;
        this.setUser(this.token, this.user);

        message.success(`Password Change was Successful.`);

        this.signIn();
      }
    } catch (err) {
      this.loading = false;
      alert(`Error Resetting Password.`);
    }
  };
}

const userStore = new UserStore();
export default userStore;
