import React, { Component } from "react";
import { observer } from "mobx-react";
import { Button, Col, Notification, FlexboxGrid } from "rsuite";
import { withRouter } from "react-router";
import userStore from "../../../Stores/UserStore";
import baseURL from "../../../Media/baseURL";
import axios from "axios";
import UnifiedModal from "../../../Components/UnifiedModal/UnifiedModal";

const instance = axios.create({ baseURL: baseURL });

class DeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  deleteRequest = async () => {
    try {
      this.setState({ loading: true });
      let finalrequest = { status: "Cancelled" };
      let newrequest = await instance
        .put(`/api/request/delete/${this.props.request._id}`, finalrequest, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          userStore.getUserData();
          this.setState({ loading: false });
          Notification["success"]({
            title: "Request Cancelled",
            description:
              "Your request has been cancelled and credit restored in your account.",
          });
          this.props.history.push("/dashboard");
        })
        .catch((err) => {
          this.setState({ loading: false });
          Notification["error"]({
            title: "An Error Occured",
            description:
              "An error occured and the request was not cancelled. Please try again or contact our office if this persists.",
          });
        });
    } catch (err) {
      this.setState({ loading: false });
      Notification["error"]({
        title: "An Error Occured",
        description:
          "An error occured and the request was not cancelled. Please try again or contact our office if this persists.",
      });
    }
  };

  render() {
    let request = this.props.request;

    return (
      <UnifiedModal
        title={`Are you sure you want to Delete the ${request.service.title} Request?`}
        show={this.props.show}
        onHide={this.props.onHide}
        footer={
          <FlexboxGrid>
            <Col xs={12}>
              <Button
                block
                appearance="ghost"
                onClick={() => this.props.onHide()}
                disabled={this.state.loading}
              >
                No, Cancel
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                block
                color="red"
                disabled={this.state.loading}
                onClick={() => this.deleteRequest()}
              >
                {this.state.loading ? "Loading ..." : "Yes, Delete the Request"}
              </Button>
            </Col>
          </FlexboxGrid>
        }
      >
        <h6>
          <strong>
            By confirming the quote, the request will be deleted and your
            credits will be refunded.
          </strong>
          <br />
        </h6>
      </UnifiedModal>
    );
  }
}

export default withRouter(observer(DeleteModal));
