import React, { Component } from "react";
import {
  ButtonGroup,
  FlexboxGrid,
  Icon,
  IconButton,
  Notification,
} from "rsuite";
import { Button as NewButton } from "rsuite";
import BusPostImages from "../ImageParallax/BusPostImages";
import busPostStore from "../../Stores/BusPostStore";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { ShoppingCartOutlined } from "@ant-design/icons";

import { WhatsappShareButton, WhatsappIcon } from "react-share";
import userStore from "../../Stores/UserStore";
import { message } from "antd";

class ProductSmall extends Component {
  constructor(props) {
    super(props);
    this.state = { less: true };
  }
  render() {
    let product = this.props.product;
    let title = this.props.title;
    let subtitle = this.props.subtitle;
    let medias = product.medias;
    let postId;
    let small;

    return (
      <FlexboxGrid
        justify="center"
        style={{
          margin: 5,
          marginTop: 20,
        }}
      >
        <div
          style={{
            maxWidth: 500,
            borderRadius: 10,
            boxShadow: "0px 0px 10px black",
          }}
        >
          <Card style={{ maxWidth: 400, borderRadius: 10 }}>
            <BusPostImages medias={medias} play={false} hide={false} />
            <CardActionArea>
              <CardContent>
                <FlexboxGrid justify="space-between" align="middle">
                  <Typography gutterBottom variant="h6" component="h6">
                    <IconButton
                      icon={<Icon icon="info" />}
                      circle
                      appearance="subtle"
                      color="yellow"
                      size="xs"
                    />
                    {title}
                  </Typography>
                </FlexboxGrid>
                <Typography variant="body2" color="textSecondary" component="p">
                  {subtitle}
                </Typography>
              </CardContent>
            </CardActionArea>
            <IconButton
              icon={<Icon icon="cart-plus" />}
              block
              placement="right"
              appearance="ghost"
              color="green"
              onClick={() => message.success("Added to Cart.")}
            >
              {(Math.round(product.price * 1000) / 1000).toFixed(3)} KD
            </IconButton>
          </Card>
        </div>
      </FlexboxGrid>
    );
  }
}

export default withRouter(observer(ProductSmall));
