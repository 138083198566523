import Modal from "antd/lib/modal/Modal";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";

class UnifiedModal extends Component {
  render() {
    return (
      <Modal
        visible={this.props.show}
        title={this.props.title}
        destroyOnClose
        footer={this.props.footer}
        onCancel={this.props.onHide}
      >
        {this.props.children}
      </Modal>
    );
  }
}

export default withRouter(observer(UnifiedModal));
