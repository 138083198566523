import React, { Component, forwardRef } from "react";
import MaterialTable from "material-table";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import moment from "moment";
import requestStore from "../../../Stores/RequestStore";
import PaperSTD from "../../../Components/PaperSTD/PaperSTD";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import { Container } from "@material-ui/core";

class RequestsTable extends Component {
  constructor(props) {
    super(props);
    this.state = { modalShow: false };
  }

  componentDidMount() {
    requestStore.getUserRequests();
  }

  updateModal = () => this.setState({ modalShow: true });

  modalClose = () => this.setState({ modalShow: false });

  render() {
    const tableIcons = {
      Request: forwardRef((props, ref) => (
        <MoveToInboxIcon {...props} ref={ref} />
      )),
    };
    let columnnames = [
      {
        title: "Start",
        removable: true,
        render: (row) => (
          <span>{moment(row.request.createdAt).format("DD-MMM")}</span>
        ),
      },
      {
        title: "End",
        removable: true,
        render: (row) => (
          <span>{moment(row.request.closedate).format("DD-MMM")}</span>
        ),
      },
      {
        title: "Status",
        removable: true,
        render: (row) => {
          return (
            <p
              style={{
                color:
                  row.request.status === "Open" ||
                  row.request.status === "Completed"
                    ? "#006b17"
                    : "#57000e",
                fontWeight: "bold",
              }}
            >
              {row.request.status.toUpperCase()}
            </p>
          );
        },
      },
      { title: "Service", field: "request.service.title", removable: true },
      { title: "Remarks", field: "request.description", removable: true },
      {
        title: "Quotes",
        removable: true,
        render: (row) => {
          return (
            <p
              style={{
                color: row.quote.length !== 0 ? "#006b17" : "#57000e",
                fontWeight: "bold",
              }}
            >
              {row.quote.length}
            </p>
          );
        },
      },
    ];
    let request = [];

    if (!requestStore.loading) {
      request = requestStore.requests;
    }

    return (
      <div>
        <br />
        <Container>
          <PaperSTD title="Request List">
            {!requestStore.loading && (
              <MaterialTable
                title={request.length + " Requests"}
                columns={columnnames}
                data={request}
                options={{
                  paging: false,
                  grouping: true,

                  actionsColumnIndex: 0,
                  exportAllData: true,
                  toolbarButtonAlignment: "left",
                  maxBodyHeight: 1000,
                  tableLayout: "auto",
                  exportButton: false,

                  padding: "dense",
                }}
                actions={[
                  {
                    icon: tableIcons.Request,
                    tooltip: "Go To Request",
                    onClick: (event, rowData) =>
                      this.props.history.push(
                        `/request/${rowData.request._id}`
                      ),
                  },
                ]}
              />
            )}
          </PaperSTD>
        </Container>
      </div>
    );
  }
}

export default withRouter(observer(RequestsTable));
