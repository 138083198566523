import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import axios from "axios";
import LoadingParagraph from "../LoadingPages/LoadingParagraph";
import baseURL from "../../Media/baseURL";
import businessStore from "../../Stores/BusinessStore";

const instance = axios.create({ baseURL: baseURL });

class BusinessPageRedirect extends Component {
  componentDidMount() {
    try {
      let data = instance
        .get(`/api/business/bynumid/${this.props.match.params.businessNumId}/`)
        .then((res) => res.data)
        .then((business) => {
          console.log(business);
          this.props.history.push(`/business/${business._id}`);
        })
        .catch((err) => {
          this.props.history.push("/");
        });
    } catch (err) {
      this.props.history.push("/");
    }
  }

  render() {
    if (businessStore.loading) {
      return <LoadingParagraph />;
    }

    return <div style={{ minHeight: "100vh" }} />;
  }
}
export default withRouter(observer(BusinessPageRedirect));
