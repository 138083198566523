import React, { Component } from "react";

class BusinessServiceInfo extends Component {
  render() {
    return (
      <div>
        <p>Garage Arabia connects customers to Garages</p>
      </div>
    );
  }
}

export default BusinessServiceInfo;
