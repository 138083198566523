import { Container, Paper } from "@material-ui/core";
import { message, Result } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  Button,
  CheckPicker,
  FlexboxGrid,
  HelpBlock,
  Icon,
  Input,
  Notification,
  InputGroup,
  Loader,
  Tooltip,
  Whisper,
} from "rsuite";
import PaperSTD from "../../../Components/PaperSTD/PaperSTD";
import businessStore from "../../../Stores/BusinessStore";
import requestStore from "../../../Stores/RequestStore";
import axios from "axios";
import baseURL from "../../../Media/baseURL";

const instance = axios.create({ baseURL: baseURL });

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

class NewBusinessRequest extends Component {
  constructor(props) {
    super(props);
    this.state = { validated: false, loading: false, sent: false };
  }
  componentDidMount() {
    requestStore.getServices();
  }

  closeNotification(type) {
    Notification.closeAll();
    let text = "Request Sent!";
    if (type === "error") text = "An Error Occured";
    Notification[type]({ title: text });
  }

  submitRequest = async (validdata) => {
    let newbus = businessStore.newbusinessrequest;
    if (!this.state.validated) {
      this.setState({ validated: true });
      Notification.open({
        title: "Uploading Request",
        description: "Uploading... Please Wait",
      });
    }
    if (validdata) {
      this.setState({ loading: true });
      let requestData = {
        contactname: newbus.contactname,
        businessname: newbus.businessname,
        description: newbus.description,
        phone: newbus.phone,
        phone: newbus.phone,
        services: newbus.services.join(", "),
        website: newbus.website,
        email: newbus.email,
      };
      let newrequest = await instance
        .post("/api/info/newbusiness", requestData)
        .then((res) => {
          console.log(res);
          this.closeNotification("success");
          this.setState({ loading: false, sent: true });
        })
        .catch((err) => this.closeNotification("error"));
    } else {
    }
  };

  render() {
    let newbus = businessStore.newbusinessrequest;
    let validdata = true;

    let indValid = {
      contactname: true,
      businessname: true,
      email: true,
      phone: true,
      description: true,
      service: true,
    };
    if (newbus.contactname.length < 3) indValid.contactname = false;
    if (newbus.services.length === 0) indValid.service = false;
    if (newbus.businessname.length < 3) indValid.businessname = false;
    if (newbus.phone.length < 8) indValid.phone = false;
    if (newbus.email.length < 3) {
      indValid.email = false;
    } else {
      let emailvalidation = validateEmail(newbus.email);
      if (!emailvalidation) {
        indValid.email = false;
      }
    }
    if (newbus.description.length < 2) indValid.description = false;
    if (!indValid.contactname) validdata = false;
    if (!indValid.businessname) validdata = false;
    if (!indValid.service) validdata = false;
    if (!indValid.phone) validdata = false;
    if (!indValid.email) validdata = false;
    if (!indValid.description) validdata = false;

    if (!this.state.validated) {
      indValid = {
        contactname: true,
        businessname: true,
        email: true,
        phone: true,
        description: true,
        service: true,
      };
    }

    let services = requestStore.services;

    let servicesdata = [
      { value: "Body Protection", label: "Body Protection" },
      { value: "Paint", label: "Paint" },
    ];

    if (services.length > 0) {
      servicesdata = [];
      for (let i in services) {
        servicesdata.push({
          value: services[i].title,
          label: services[i].title,
        });
      }
      servicesdata.push({ value: "Other", label: "Other" });
    }

    let submitbutton;

    if (!this.state.validated) {
      submitbutton = (
        <Button
          block
          color="green"
          onClick={() => this.submitRequest(validdata)}
        >
          Submit Information
        </Button>
      );
    } else {
      submitbutton = (
        <Button
          block
          color="green"
          disabled={!validdata}
          onClick={() => this.submitRequest(validdata)}
        >
          Submit Information
        </Button>
      );
    }
    if (this.state.loading) {
      submitbutton = (
        <Button block color="green" disabled>
          Loading...
        </Button>
      );
    }

    let forminformation = (
      <div>
        {" "}
        <h6 style={{ textAlign: "center" }}>Contact Details:</h6>
        <br />
        <HelpBlock>Contact Name:</HelpBlock>
        <InputGroup>
          <InputGroup.Addon>
            <Icon icon="avatar" size="lg" />
          </InputGroup.Addon>
          <Input
            size="lg"
            disabled={this.state.loading}
            placeholder="Contact Name"
            value={newbus.contactname}
            onChange={(e) =>
              businessStore.changeNewBusinessRequest("contactname", e)
            }
          />
        </InputGroup>
        {!indValid.contactname && (
          <HelpBlock style={{ color: "red" }}>Required</HelpBlock>
        )}
        <br />
        <HelpBlock>Business Name:</HelpBlock>
        <InputGroup>
          <InputGroup.Addon>
            <Icon icon="building" size="lg" />
          </InputGroup.Addon>
          <Input
            disabled={this.state.loading}
            size="lg"
            placeholder="Business Name"
            value={newbus.businessname}
            onChange={(e) =>
              businessStore.changeNewBusinessRequest("businessname", e)
            }
          />
        </InputGroup>
        {!indValid.businessname && (
          <HelpBlock style={{ color: "red" }}>Required</HelpBlock>
        )}
        <br />
        <HelpBlock>Services:</HelpBlock>
        <InputGroup>
          <CheckPicker
            disabled={this.state.loading}
            size="lg"
            style={{ fontSize: 10 }}
            data={servicesdata}
            block
            style={{ width: "100%" }}
            value={newbus.services}
            onChange={(e) =>
              businessStore.changeNewBusinessRequest("services", e)
            }
          />
        </InputGroup>
        {!indValid.service && (
          <HelpBlock style={{ color: "red" }}>Required</HelpBlock>
        )}
        <br />
        <HelpBlock>Phone Number:</HelpBlock>
        <InputGroup>
          <InputGroup.Addon>
            <Icon icon="phone" size="lg" />
          </InputGroup.Addon>
          <Input
            size="lg"
            disabled={this.state.loading}
            placeholder="Phone Number"
            value={newbus.phone}
            onChange={(e) => businessStore.changeNewBusinessRequest("phone", e)}
          />
        </InputGroup>
        {!indValid.phone && (
          <HelpBlock style={{ color: "red" }}>Required</HelpBlock>
        )}
        <br />
        <HelpBlock>Email Address:</HelpBlock>
        <InputGroup>
          <InputGroup.Addon>
            <Icon icon="envelope" size="lg" />
          </InputGroup.Addon>
          <Input
            size="lg"
            disabled={this.state.loading}
            placeholder="Email Address"
            value={newbus.email}
            onChange={(e) => businessStore.changeNewBusinessRequest("email", e)}
          />
        </InputGroup>
        {!indValid.email && (
          <HelpBlock style={{ color: "red" }}>
            Required: Please enter a Valid Email
          </HelpBlock>
        )}
        <br />
        <HelpBlock>Website:</HelpBlock>
        <Whisper trigger="focus" speaker={<Tooltip>Optional</Tooltip>}>
          <InputGroup>
            <InputGroup.Addon>
              <Icon icon="globe" size="lg" />
            </InputGroup.Addon>
            <Input
              size="lg"
              disabled={this.state.loading}
              placeholder="Website"
              value={newbus.website}
              onChange={(e) =>
                businessStore.changeNewBusinessRequest("website", e)
              }
            />
          </InputGroup>
        </Whisper>
        <br />
        <HelpBlock>About Your Business:</HelpBlock>
        <Input
          componentClass="textarea"
          size="lg"
          rows={5}
          disabled={this.state.loading}
          placeholder="About Your Business"
          value={newbus.description}
          onChange={(e) =>
            businessStore.changeNewBusinessRequest("description", e)
          }
        />
        {!indValid.description && (
          <HelpBlock style={{ color: "red" }}>Required</HelpBlock>
        )}
        <hr />
        {submitbutton}
      </div>
    );

    if (this.state.sent) {
      forminformation = (
        <Result
          status="success"
          title={`Information Sent!`}
          subTitle={
            "Your information has been received and our team will contact you soon to discuss joinging Garage Arabia."
          }
          extra={[
            <Button
              color="green"
              block
              onClick={() => this.props.history.push("/auth")}
            >
              Sign In
            </Button>,
          ]}
        />
      );
    }

    return (
      <div style={{ minHeight: "80vh" }}>
        <br />
        <FlexboxGrid justify="center">
          <Container style={{ maxWidth: 600 }}>
            <Paper
              style={{
                padding: 10,
                borderRadius: 10,
                justifyContent: "space-around",
                width: "100%",
              }}
              elevation={5}
            >
              <h4 style={{ textAlign: "center" }}>Join Garage Arabia Today!</h4>
              <hr />
              {forminformation}
            </Paper>
          </Container>
        </FlexboxGrid>
        <br />
      </div>
    );
  }
}

export default withRouter(observer(NewBusinessRequest));
