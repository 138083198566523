import React, { Component } from "react";
import { observer } from "mobx-react";
import { Container, TextField } from "@material-ui/core";
import {
  FlexboxGrid,
  Col,
  Button,
  SelectPicker,
  HelpBlock,
  InputGroup,
  Input,
  Icon,
} from "rsuite";
import { withRouter } from "react-router";
import businessStore from "../../Stores/BusinessStore";
import BusinessCard from "./subpages/BusinessCard";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import PaperSTD from "../../Components/PaperSTD/PaperSTD";
import BusinessMaps from "../../Components/Map/BusinessMaps";
import { Divider } from "antd";
import requestStore from "../../Stores/RequestStore";

class BusinessesList extends Component {
  constructor(props) {
    super(props);
    this.state = { map: false };
  }
  componentDidMount() {
    requestStore.getServices();
    requestStore.getSpeciality();
    businessStore.getBusinesses();
  }
  componentWillUnmount() {
    businessStore.resetfilter();
  }

  render() {
    console.log(businessStore.filteredBusinesses);
    let businessblocks = (
      <FlexboxGrid style={{ justifyContent: "space-evenly", flex: "wrap" }}>
        {businessStore.filteredBusinesses.map((business, index) => (
          <BusinessCard business={business} key={index} />
        ))}
      </FlexboxGrid>
    );
    let mapview = <BusinessMaps />;

    let servicedata = [{ value: "All", label: "All" }];
    for (let i = 0; i < requestStore.services.length; i++) {
      servicedata.push({
        value: requestStore.services[i].title,
        label: requestStore.services[i].title,
      });
    }
    let specialitydata = [{ value: "All", label: "All" }];
    for (let i = 0; i < requestStore.specialities.length; i++) {
      specialitydata.push({
        value: requestStore.specialities[i].name,
        label: requestStore.specialities[i].name,
      });
    }

    return (
      <div>
        <br />
        <Container>
          <FlexboxGrid>
            <Col xs={24} sm={12}>
              <Button
                block
                color="primary"
                appearance={this.state.map && "ghost"}
                onClick={() => this.setState({ map: false })}
              >
                List
              </Button>
            </Col>
            <Col xs={24} sm={12}>
              <Button
                block
                color="primary"
                appearance={!this.state.map && "ghost"}
                onClick={() => this.setState({ map: true })}
              >
                Map
              </Button>
            </Col>
          </FlexboxGrid>
          <br />
          <PaperSTD>
            <Divider orientation="left">Filters</Divider>
            <FlexboxGrid>
              <Col md={12} sm={8} xs={24}>
                <HelpBlock>Company:</HelpBlock>
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon icon="search" />
                  </InputGroup.Addon>
                  <Input
                    block
                    value={businessStore.filter.query}
                    onChange={(e) => businessStore.updateQuery("query", e)}
                  />
                </InputGroup>
              </Col>
              <Col md={6} sm={8} xs={24}>
                <HelpBlock>Service Filter:</HelpBlock>
                <SelectPicker
                  block
                  data={servicedata}
                  appearance="default"
                  value={businessStore.filter.service}
                  onChange={(e) => businessStore.updateQuery("service", e)}
                />
              </Col>
              <Col md={6} sm={8} xs={24}>
                <HelpBlock>Speciality Filter:</HelpBlock>
                <SelectPicker
                  block
                  data={specialitydata}
                  appearance="default"
                  value={businessStore.filter.speciality}
                  onChange={(e) => businessStore.updateQuery("speciality", e)}
                />
              </Col>
            </FlexboxGrid>
            <br />
          </PaperSTD>
          <PaperSTD>{this.state.map ? mapview : businessblocks}</PaperSTD>
          <br />
        </Container>
      </div>
    );
  }
}

export default withRouter(observer(BusinessesList));
