import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { HelpBlock, Input, Tooltip, Whisper } from "rsuite";

class StdProdTitle extends Component {
  render() {
    let title = this.props.title;
    let value = this.props.value;
    return (
      <div>
        <HelpBlock style={{ fontWeight: "bold" }}>{title}</HelpBlock>
        <Whisper
          trigger="focus"
          placement="topEnd"
          speaker={<Tooltip>Required</Tooltip>}
        >
          <Input
            value={value}
            onChange={this.props.change}
            style={{ width: "100%" }}
          />
        </Whisper>
      </div>
    );
  }
}

export default withRouter(observer(StdProdTitle));
