import { Chip, Container } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Button, Col, FlexboxGrid } from "rsuite";
import { Parallax } from "react-parallax";
import { withRouter } from "react-router";
import businessStore from "../../../Stores/BusinessStore";
import userStore from "../../../Stores/UserStore";
import EditBusinessModal from "./EditModals/EditBusinessModal";
import EditServiceModal from "./EditModals/EditServiceModal";
import NewRequestCards from "./NewRequestCards";
import PaperSTD from "../../../Components/PaperSTD/PaperSTD";
import EditSpecialityModal from "./EditModals/EditSpecialityModal";
import letterStore from "../../../Stores/LetterStore";
import ContactSection from "./ContactSection/ContactSection";
import PostsCarousel from "../../LandingPage/PostsCarousel";
import LoadingParagraph from "../../../Components/LoadingPages/LoadingParagraph";
import { WhatsappShareButton, WhatsappIcon } from "react-share";

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showService: false,
      showMedia: false,
      showSpeciality: false,
    };
  }

  componentDidMount() {
    businessStore.getSingleBusiness(this.props.match.params.businessId);
    businessStore.getSingleBusinessPost(this.props.match.params.businessId);
    letterStore.resetMessage();
  }
  componentDidUpdate() {
    if (
      this.props.match.params.businessId !== businessStore.selectedBusiness._id
    ) {
      businessStore.getSingleBusiness(this.props.match.params.businessId);
    }
  }

  closeModal = () => {
    this.setState({
      showModal: false,
      showService: false,
      showMedia: false,
      showSpeciality: false,
    });
  };
  render() {
    let business = businessStore.selectedBusiness;

    let editButton;
    let editService;
    let editSpeciality;
    let editMedia;

    if (business) {
      if (userStore.signedIn) {
        if (businessStore.selectedBusiness._id === userStore.user.business) {
          editButton = (
            <Button
              block
              color="orange"
              appearance="ghost"
              onClick={() => {
                businessStore.selectEditBusiness();
                this.setState({ showModal: true });
              }}
            >
              Edit Basic Info
            </Button>
          );
          editService = (
            <Button
              block
              color="orange"
              appearance="ghost"
              onClick={() => {
                businessStore.selectEditBusiness();
                this.setState({ showService: true });
              }}
            >
              Edit Service
            </Button>
          );
          editSpeciality = (
            <Button
              block
              color="orange"
              onClick={() => {
                businessStore.selectEditBusiness();
                this.setState({ showSpeciality: true });
              }}
            >
              Edit Speciality
            </Button>
          );
          editMedia = (
            <Button
              color="orange"
              appearance="ghost"
              onClick={() => {
                businessStore.selectEditBusiness();
                this.setState({ showMedia: true });
              }}
            >
              Edit Gallery
            </Button>
          );
        }
      }
    }

    let specialitysection;
    if (business.speciality) {
      if (business.speciality.length > 0) {
        specialitysection = (
          <div>
            <FlexboxGrid justify="center">
              {business.speciality
                ? business.speciality.map((spec, index) => (
                    <Chip
                      key={index}
                      label={spec.name}
                      style={{ marginLeft: 10, marginRight: 10 }}
                    />
                  ))
                : "None"}
            </FlexboxGrid>
            <br />
          </div>
        );
      }
    }

    if (businessStore.loading) {
      return (
        <Container style={{ minHeight: "100vh" }}>
          <LoadingParagraph />
          <LoadingParagraph />
          <LoadingParagraph />
        </Container>
      );
    }

    return (
      <div>
        <EditBusinessModal
          show={this.state.showModal}
          onHide={this.closeModal}
        />
        <EditServiceModal
          show={this.state.showService}
          onHide={this.closeModal}
        />
        <EditSpecialityModal
          show={this.state.showSpeciality}
          onHide={this.closeModal}
        />
        <br />
        <Parallax
          blur={1}
          strength={10}
          bgImage={business.coverimage}
          bgImageAlt="Services"
          style={{
            height: 300,
            boxShadow: "10px 10px 70px -50px rgba(0,0,0,1)",
            display: "flex",
            flexWrap: "wrap",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Container>
            <img
              src={business.logo}
              roundedCircle
              style={{
                maxHeight: "200px",
                boxShadow: "0px 0px 10px 5px black",
                margin: "auto",
              }}
            />
          </Container>
        </Parallax>
        <Container
          style={{
            paddingLeft: "5%",
            paddingTop: "2%",
            paddingRight: "5%",
            textAlign: "center",
          }}
        >
          <PaperSTD title={business.name} orientation="center">
            {specialitysection}
            <h6>{business.description}</h6>
            <FlexboxGrid>
              <Col xs={12}>{editButton}</Col>
              <Col xs={12}>{editSpeciality}</Col>
            </FlexboxGrid>
          </PaperSTD>
          <PaperSTD title={"Offered Services"} orientation="center">
            <NewRequestCards services={business.services} />
            {editService}
          </PaperSTD>
          <PostsCarousel
            posts={businessStore.businesspost}
            business={businessStore.selectedBusiness._id}
          />
          <ContactSection business={business} />
        </Container>
      </div>
    );
  }
}

export default withRouter(observer(ProfilePage));
