import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { Card, CardActionArea, CardContent } from "@material-ui/core";
import { CardMedia, Container, Typography } from "@material-ui/core";
import "react-multi-carousel/lib/styles.css";
import businessStore from "../../Stores/BusinessStore";
import PaperSTD from "../../Components/PaperSTD/PaperSTD";
import ScrollMenu from "react-horizontal-scrolling-menu";
import {
  LeftCircleFilled,
  CaretLeftOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import HorizontalScroll from "../../Components/HorizontalScroll/HorizontalScroll";
import { IconButton, Icon, FlexboxGrid } from "rsuite";

class CompanyCarousel extends Component {
  render() {
    let buslist = businessStore.businessServices;
    let buslist1 = buslist.concat(buslist);
    let buslist2 = buslist1.concat(buslist);
    let buslist3 = buslist2.concat(buslist);

    let businesses = buslist3.map((business, index) => (
      <Card style={{ width: 200, margin: 10 }} key={index}>
        <CardActionArea
          onClick={() => {
            this.props.history.push(`/business/${business._id}`);
          }}
        >
          <CardMedia
            component="img"
            alt={business.name}
            height="140"
            image={business.logo}
            title={business.name}
          />
          <CardContent>
            <Typography gutterBottom variant="p" component="h5">
              {business.name}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{ whiteSpace: "normal" }}
            >
              {business.servicetext}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    ));

    return (
      <Container>
        <PaperSTD title={"Companies"}>
          <HorizontalScroll data={businesses} />
          <FlexboxGrid justify="center">
            <IconButton
              icon={<Icon icon="arrow-right" />}
              placement="right"
              active
              style={{ boxShadow: "0px 0px 3px black" }}
              onClick={() => this.props.history.push("/businesses")}
            >
              See All Companies
            </IconButton>
          </FlexboxGrid>
        </PaperSTD>
      </Container>
    );
  }
}

export default withRouter(observer(CompanyCarousel));
