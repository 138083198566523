import React, { Component } from "react";

import { observer } from "mobx-react";
import { withRouter } from "react-router";
import RequestList from "../Requests/RequestList";
import quoteStore from "../../../Stores/QuoteStore";

class BusinessDashboard extends Component {
  componentDidMount() {
    quoteStore.fetchOpenRequests();
  }
  render() {
    return (
      <div>
        <br />
        <RequestList />
      </div>
    );
  }
}

export default withRouter(observer(BusinessDashboard));
