import React, { PureComponent } from "react";
import ReactCrop from "react-image-crop";
import { Button, FlexboxGrid, Col } from "rsuite";
import "react-image-crop/dist/ReactCrop.css";
import busPostStore from "../../Stores/BusPostStore";
import Compress from "react-image-file-resizer";

class ImageCropper extends PureComponent {
  state = {
    src: null,
    crop: {
      unit: "%",
      width: 100,
      aspect: 16 / 9,
    },
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      // reader.addEventListener("load", () =>
      // this.setState({ src: reader.result })
      // );
      const oldfile = e.target.files[0];
      Compress.imageFileResizer(
        oldfile, // the file from input
        1280, // width
        720, // height
        "PNG", // compress format WEBP, JPEG, PNG
        70, // quality
        0, // rotation
        (uri) => {
          // reader.readAsDataURL(uri);
          this.setState({ src: uri });

          // You upload logic goes here
        },
        "base64" // blob or base64 default base64
      );
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return canvas.toDataURL("image/jpeg");

    // return new Promise((resolve, reject) => {
    //   canvas.toBlob((blob) => {
    //     if (!blob) {
    //       //reject(new Error('Canvas is empty'));
    //       console.error("Canvas is empty");
    //       return;
    //     }
    //     blob.name = fileName;
    //     window.URL.revokeObjectURL(this.fileUrl);
    //     this.fileUrl = window.URL.createObjectURL(blob);
    //     resolve(this.fileUrl);
    //   }, "image/jpeg");
    // });
  }

  render() {
    const { crop, croppedImageUrl, src } = this.state;

    let buttonUpload;
    if (croppedImageUrl) {
      buttonUpload = (
        <div>
          <Col xs={24} sm={12}>
            <Button
              block
              color="red"
              onClick={() => this.setState({ src: null })}
            >
              Cancel
            </Button>
          </Col>
          <Col xs={24} sm={12}>
            <Button
              block
              color="green"
              onClick={() => {
                busPostStore.addPhoto(croppedImageUrl);

                this.setState({ src: null });
              }}
            >
              Upload
            </Button>
          </Col>
        </div>
      );
    }
    return (
      <div style={{ minHeight: 100 }}>
        {!src && (
          <input type="file" accept="image/*" onChange={this.onSelectFile} />
        )}
        <FlexboxGrid justify="center">
          {src && (
            <div style={{ maxWidth: 500 }}>
              {buttonUpload}
              <ReactCrop
                src={src}
                crop={crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            </div>
          )}
        </FlexboxGrid>
      </div>
    );
  }
}

export default ImageCropper;
