import React, { Component } from "react";
import { observer } from "mobx-react";
import { Button, Modal } from "rsuite";
import makeList from "../../../Media/Static/CarMakes";
import modelList from "../../../Media/Static/CarModels";
import { withRouter } from "react-router";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import carStore from "../../../Stores/CarStore";
import Years from "../../../Media/Static/Years";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import UnifiedModal from "../../../Components/UnifiedModal/UnifiedModal";

class AddCarModal extends Component {
  render() {
    let models;
    if (carStore.newcar.make.length > 0) {
      models = modelList.filter(
        (car) => car.make.toLowerCase() === carStore.newcar.make.toLowerCase()
      );
    } else {
      models = modelList;
    }

    let disableButton = { val: false, message: "Click to Add" };
    if (carStore.newcar.make === "") {
      disableButton = { val: true, message: "Add the Make" };
    } else if (carStore.newcar.make === "") {
      disableButton = { val: true, message: "Add the Model" };
    }

    return (
      <UnifiedModal
        title={"Add a Car to your Garage"}
        show={this.props.show}
        onHide={this.props.onHide}
        footer={
          <Button
            block
            color="green"
            disabled={disableButton.val}
            onClick={() => carStore.postNewCar()}
          >
            {disableButton.message}
          </Button>
        }
      >
        <Autocomplete
          id="free-solo-demo"
          freeSolo
          options={makeList}
          onChange={(e) => carStore.editNewCar("make", e.target.innerText)}
          renderInput={(params) => (
            <TextField
              {...params}
              value={carStore.newcar.make}
              label="Car Make"
              margin="normal"
              variant="standard"
              onChange={(e) => carStore.editNewCar("make", e.target.value)}
            />
          )}
        />
        <Autocomplete
          id="free-solo-demo"
          freeSolo
          options={models.map((option) => option.model)}
          onChange={(e) => carStore.editNewCar("model", e.target.innerText)}
          renderInput={(params) => (
            <TextField
              {...params}
              value={carStore.newcar.model}
              label="Car Model"
              margin="normal"
              variant="standard"
              onChange={(e) => carStore.editNewCar("model", e.target.value)}
            />
          )}
        />

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Age</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={carStore.newcar.year}
            onChange={(e) => carStore.editNewCar("year", e.target.value)}
          >
            {Years.map((year) => (
              <MenuItem value={year} key={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </UnifiedModal>
    );
  }
}

export default withRouter(observer(AddCarModal));
