export default [
  { make: "Acura", model: "CL" },
  { make: "Acura", model: "ILX" },
  { make: "Acura", model: "Integra" },
  { make: "Acura", model: "Legend" },
  { make: "Acura", model: "MDX" },
  { make: "Acura", model: "MDX Sport Hybrid" },
  { make: "Acura", model: "NSX" },
  { make: "Acura", model: "RDX" },
  { make: "Acura", model: "RL" },
  { make: "Acura", model: "RLX" },
  { make: "Acura", model: "RLX Sport Hybrid" },
  { make: "Acura", model: "RSX" },
  { make: "Acura", model: "SLX" },
  { make: "Acura", model: "TL" },
  { make: "Acura", model: "TLX" },
  { make: "Acura", model: "TSX" },
  { make: "Acura", model: "Vigor" },
  { make: "Acura", model: "ZDX" },
  { make: "Alfa Romeo", model: "164" },
  { make: "Alfa Romeo", model: "4C" },
  { make: "Alfa Romeo", model: "4C Spider" },
  { make: "Alfa Romeo", model: "Giulia" },
  { make: "Alfa Romeo", model: "Spider" },
  { make: "Alfa Romeo", model: "Stelvio" },
  { make: "Aston Martin", model: "DB11" },
  { make: "Aston Martin", model: "DB9" },
  { make: "Aston Martin", model: "DB9 GT" },
  { make: "Aston Martin", model: "DBS" },
  { make: "Aston Martin", model: "Rapide" },
  { make: "Aston Martin", model: "Rapide S" },
  { make: "Aston Martin", model: "Vanquish" },
  { make: "Aston Martin", model: "Vanquish S" },
  { make: "Aston Martin", model: "Vantage" },
  { make: "Aston Martin", model: "Virage" },
  { make: "Audi", model: "80" },
  { make: "Audi", model: "90" },
  { make: "Audi", model: "100" },
  { make: "Audi", model: "A3" },
  { make: "Audi", model: "A3 Sportback e-tron" },
  { make: "Audi", model: "A4" },
  { make: "Audi", model: "A4 (2005.5)" },
  { make: "Audi", model: "A4 allroad" },
  { make: "Audi", model: "A5" },
  { make: "Audi", model: "A5 Sport" },
  { make: "Audi", model: "A6" },
  { make: "Audi", model: "A7" },
  { make: "Audi", model: "A8" },
  { make: "Audi", model: "allroad" },
  { make: "Audi", model: "Cabriolet" },
  { make: "Audi", model: "e-tron" },
  { make: "Audi", model: "Q3" },
  { make: "Audi", model: "Q5" },
  { make: "Audi", model: "Q7" },
  { make: "Audi", model: "Q8" },
  { make: "Audi", model: "Quattro" },
  { make: "Audi", model: "R8" },
  { make: "Audi", model: "RS 3" },
  { make: "Audi", model: "RS 4" },
  { make: "Audi", model: "RS 5" },
  { make: "Audi", model: "RS 6" },
  { make: "Audi", model: "RS 7" },
  { make: "Audi", model: "S3" },
  { make: "Audi", model: "S4" },
  { make: "Audi", model: "S4 (2005.5)" },
  { make: "Audi", model: "S5" },
  { make: "Audi", model: "S6" },
  { make: "Audi", model: "S7" },
  { make: "Audi", model: "S8" },
  { make: "Audi", model: "SQ5" },
  { make: "Audi", model: "TT" },
  { make: "Bentley", model: "Arnage" },
  { make: "Bentley", model: "Azure" },
  { make: "Bentley", model: "Azure T" },
  { make: "Bentley", model: "Bentayga" },
  { make: "Bentley", model: "Brooklands" },
  { make: "Bentley", model: "Continental" },
  { make: "Bentley", model: "Flying Spur" },
  { make: "Bentley", model: "Mulsanne" },
  { make: "BMW", model: "1 Series" },
  { make: "BMW", model: "2 Series" },
  { make: "BMW", model: "3 Series" },
  { make: "BMW", model: "4 Series" },
  { make: "BMW", model: "5 Series" },
  { make: "BMW", model: "6 Series" },
  { make: "BMW", model: "7 Series" },
  { make: "BMW", model: "8 Series" },
  { make: "BMW", model: "Alpina B7" },
  { make: "BMW", model: "i3" },
  { make: "BMW", model: "i8" },
  { make: "BMW", model: "M" },
  { make: "BMW", model: "M2" },
  { make: "BMW", model: "M3" },
  { make: "BMW", model: "M4" },
  { make: "BMW", model: "M5" },
  { make: "BMW", model: "M6" },
  { make: "BMW", model: "X1" },
  { make: "BMW", model: "X2" },
  { make: "BMW", model: "X3" },
  { make: "BMW", model: "X4" },
  { make: "BMW", model: "X5" },
  { make: "BMW", model: "X5 M" },
  { make: "BMW", model: "X6" },
  { make: "BMW", model: "X6 M" },
  { make: "BMW", model: "X7" },
  { make: "BMW", model: "Z3" },
  { make: "BMW", model: "Z4" },
  { make: "BMW", model: "Z4 M" },
  { make: "BMW", model: "Z8" },
  { make: "Buick", model: "Cascada" },
  { make: "Buick", model: "Century" },
  { make: "Buick", model: "Enclave" },
  { make: "Buick", model: "Encore" },
  { make: "Buick", model: "Envision" },
  { make: "Buick", model: "LaCrosse" },
  { make: "Buick", model: "LeSabre" },
  { make: "Buick", model: "Lucerne" },
  { make: "Buick", model: "Park Avenue" },
  { make: "Buick", model: "Rainier" },
  { make: "Buick", model: "Regal" },
  { make: "Buick", model: "Regal Sportback" },
  { make: "Buick", model: "Regal TourX" },
  { make: "Buick", model: "Rendezvous" },
  { make: "Buick", model: "Riviera" },
  { make: "Buick", model: "Roadmaster" },
  { make: "Buick", model: "Skylark" },
  { make: "Buick", model: "Terraza" },
  { make: "Buick", model: "Verano" },
  { make: "Cadillac", model: "Allante" },
  { make: "Cadillac", model: "ATS" },
  { make: "Cadillac", model: "ATS-V" },
  { make: "Cadillac", model: "Brougham" },
  { make: "Cadillac", model: "Catera" },
  { make: "Cadillac", model: "CT5" },
  { make: "Cadillac", model: "CT6" },
  { make: "Cadillac", model: "CT6-V" },
  { make: "Cadillac", model: "CTS" },
  { make: "Cadillac", model: "CTS-V" },
  { make: "Cadillac", model: "DeVille" },
  { make: "Cadillac", model: "DTS" },
  { make: "Cadillac", model: "Eldorado" },
  { make: "Cadillac", model: "ELR" },
  { make: "Cadillac", model: "Escalade" },
  { make: "Cadillac", model: "Escalade ESV" },
  { make: "Cadillac", model: "Escalade EXT" },
  { make: "Cadillac", model: "Fleetwood" },
  { make: "Cadillac", model: "Seville" },
  { make: "Cadillac", model: "Sixty Special" },
  { make: "Cadillac", model: "SRX" },
  { make: "Cadillac", model: "STS" },
  { make: "Cadillac", model: "XLR" },
  { make: "Cadillac", model: "XT4" },
  { make: "Cadillac", model: "XT5" },
  { make: "Cadillac", model: "XT6" },
  { make: "Cadillac", model: "XTS" },
  { make: "Chevrolet", model: "1500 Extended Cab" },
  { make: "Chevrolet", model: "1500 Regular Cab" },
  { make: "Chevrolet", model: "2500 Crew Cab" },
  { make: "Chevrolet", model: "2500 Extended Cab" },
  { make: "Chevrolet", model: "2500 HD Extended Cab" },
  { make: "Chevrolet", model: "2500 HD Regular Cab" },
  { make: "Chevrolet", model: "2500 Regular Cab" },
  { make: "Chevrolet", model: "3500 Crew Cab" },
  { make: "Chevrolet", model: "3500 Extended Cab" },
  { make: "Chevrolet", model: "3500 HD Extended Cab" },
  { make: "Chevrolet", model: "3500 HD Regular Cab" },
  { make: "Chevrolet", model: "3500 Regular Cab" },
  { make: "Chevrolet", model: "APV Cargo" },
  { make: "Chevrolet", model: "Astro Cargo" },
  { make: "Chevrolet", model: "Astro Passenger" },
  { make: "Chevrolet", model: "Avalanche" },
  { make: "Chevrolet", model: "Avalanche 1500" },
  { make: "Chevrolet", model: "Avalanche 2500" },
  { make: "Chevrolet", model: "Aveo" },
  { make: "Chevrolet", model: "Beretta" },
  { make: "Chevrolet", model: "Blazer" },
  { make: "Chevrolet", model: "Bolt EV" },
  { make: "Chevrolet", model: "Camaro" },
  { make: "Chevrolet", model: "Caprice" },
  { make: "Chevrolet", model: "Caprice Classic" },
  { make: "Chevrolet", model: "Captiva Sport" },
  { make: "Chevrolet", model: "Cavalier" },
  { make: "Chevrolet", model: "City Express" },
  { make: "Chevrolet", model: "Classic" },
  { make: "Chevrolet", model: "Cobalt" },
  { make: "Chevrolet", model: "Colorado Crew Cab" },
  { make: "Chevrolet", model: "Colorado Extended Cab" },
  { make: "Chevrolet", model: "Colorado Regular Cab" },
  { make: "Chevrolet", model: "Corsica" },
  { make: "Chevrolet", model: "Corvette" },
  { make: "Chevrolet", model: "Cruze" },
  { make: "Chevrolet", model: "Cruze Limited" },
  { make: "Chevrolet", model: "Equinox" },
  { make: "Chevrolet", model: "Express 1500 Cargo" },
  { make: "Chevrolet", model: "Express 1500 Passenger" },
  { make: "Chevrolet", model: "Express 2500 Cargo" },
  { make: "Chevrolet", model: "Express 2500 Passenger" },
  { make: "Chevrolet", model: "Express 3500 Cargo" },
  { make: "Chevrolet", model: "Express 3500 Passenger" },
  { make: "Chevrolet", model: "G-Series 1500" },
  { make: "Chevrolet", model: "G-Series 2500" },
  { make: "Chevrolet", model: "G-Series 3500" },
  { make: "Chevrolet", model: "G-Series G10" },
  { make: "Chevrolet", model: "G-Series G20" },
  { make: "Chevrolet", model: "G-Series G30" },
  { make: "Chevrolet", model: "HHR" },
  { make: "Chevrolet", model: "Impala" },
  { make: "Chevrolet", model: "Impala Limited" },
  { make: "Chevrolet", model: "Lumina" },
  { make: "Chevrolet", model: "Lumina APV" },
  { make: "Chevrolet", model: "Lumina Cargo" },
  { make: "Chevrolet", model: "Lumina Passenger" },
  { make: "Chevrolet", model: "Malibu" },
  { make: "Chevrolet", model: "Malibu (Classic)" },
  { make: "Chevrolet", model: "Malibu Limited" },
  { make: "Chevrolet", model: "Metro" },
  { make: "Chevrolet", model: "Monte Carlo" },
  { make: "Chevrolet", model: "Prizm" },
  { make: "Chevrolet", model: "S10 Blazer" },
  { make: "Chevrolet", model: "S10 Crew Cab" },
  { make: "Chevrolet", model: "S10 Extended Cab" },
  { make: "Chevrolet", model: "S10 Regular Cab" },
  { make: "Chevrolet", model: "Silverado (Classic) 1500 Crew Cab" },
  { make: "Chevrolet", model: "Silverado (Classic) 1500 Extended Cab" },
  { make: "Chevrolet", model: "Silverado (Classic) 1500 HD Crew Cab" },
  { make: "Chevrolet", model: "Silverado (Classic) 1500 Regular Cab" },
  { make: "Chevrolet", model: "Silverado (Classic) 2500 HD Crew Cab" },
  { make: "Chevrolet", model: "Silverado (Classic) 2500 HD Extended Cab" },
  { make: "Chevrolet", model: "Silverado (Classic) 2500 HD Regular Cab" },
  { make: "Chevrolet", model: "Silverado (Classic) 3500 Crew Cab" },
  { make: "Chevrolet", model: "Silverado (Classic) 3500 Extended Cab" },
  { make: "Chevrolet", model: "Silverado (Classic) 3500 Regular Cab" },
  { make: "Chevrolet", model: "Silverado 1500 Crew Cab" },
  { make: "Chevrolet", model: "Silverado 1500 Double Cab" },
  { make: "Chevrolet", model: "Silverado 1500 Extended Cab" },
  { make: "Chevrolet", model: "Silverado 1500 HD Crew Cab" },
  { make: "Chevrolet", model: "Silverado 1500 LD Double Cab" },
  { make: "Chevrolet", model: "Silverado 1500 Regular Cab" },
  { make: "Chevrolet", model: "Silverado 2500 Crew Cab" },
  { make: "Chevrolet", model: "Silverado 2500 Extended Cab" },
  { make: "Chevrolet", model: "Silverado 2500 HD Crew Cab" },
  { make: "Chevrolet", model: "Silverado 2500 HD Double Cab" },
  { make: "Chevrolet", model: "Silverado 2500 HD Extended Cab" },
  { make: "Chevrolet", model: "Silverado 2500 HD Regular Cab" },
  { make: "Chevrolet", model: "Silverado 2500 Regular Cab" },
  { make: "Chevrolet", model: "Silverado 3500 Crew Cab" },
  { make: "Chevrolet", model: "Silverado 3500 Extended Cab" },
  { make: "Chevrolet", model: "Silverado 3500 HD Crew Cab" },
  { make: "Chevrolet", model: "Silverado 3500 HD Double Cab" },
  { make: "Chevrolet", model: "Silverado 3500 HD Extended Cab" },
  { make: "Chevrolet", model: "Silverado 3500 HD Regular Cab" },
  { make: "Chevrolet", model: "Silverado 3500 Regular Cab" },
  { make: "Chevrolet", model: "Sonic" },
  { make: "Chevrolet", model: "Spark" },
  { make: "Chevrolet", model: "Spark EV" },
  { make: "Chevrolet", model: "Sportvan G10" },
  { make: "Chevrolet", model: "Sportvan G20" },
  { make: "Chevrolet", model: "Sportvan G30" },
  { make: "Chevrolet", model: "SS" },
  { make: "Chevrolet", model: "SSR" },
  { make: "Chevrolet", model: "Suburban" },
  { make: "Chevrolet", model: "Suburban 1500" },
  { make: "Chevrolet", model: "Suburban 2500" },
  { make: "Chevrolet", model: "Suburban 3500HD" },
  { make: "Chevrolet", model: "Tahoe" },
  { make: "Chevrolet", model: "Tracker" },
  { make: "Chevrolet", model: "TrailBlazer" },
  { make: "Chevrolet", model: "Traverse" },
  { make: "Chevrolet", model: "Trax" },
  { make: "Chevrolet", model: "Uplander Cargo" },
  { make: "Chevrolet", model: "Uplander Passenger" },
  { make: "Chevrolet", model: "Venture Cargo" },
  { make: "Chevrolet", model: "Venture Passenger" },
  { make: "Chevrolet", model: "Volt" },
  { make: "Chrysler", model: "200" },
  { make: "Chrysler", model: "300" },
  { make: "Chrysler", model: "300M" },
  { make: "Chrysler", model: "Aspen" },
  { make: "Chrysler", model: "Cirrus" },
  { make: "Chrysler", model: "Concorde" },
  { make: "Chrysler", model: "Crossfire" },
  { make: "Chrysler", model: "Fifth Ave" },
  { make: "Chrysler", model: "Grand Voyager" },
  { make: "Chrysler", model: "Imperial" },
  { make: "Chrysler", model: "LeBaron" },
  { make: "Chrysler", model: "LHS" },
  { make: "Chrysler", model: "New Yorker" },
  { make: "Chrysler", model: "Pacifica" },
  { make: "Chrysler", model: "Pacifica Hybrid" },
  { make: "Chrysler", model: "Prowler" },
  { make: "Chrysler", model: "PT Cruiser" },
  { make: "Chrysler", model: "Sebring" },
  { make: "Chrysler", model: "Town & Country" },
  { make: "Chrysler", model: "Voyager" },
  { make: "Daewoo", model: "Lanos" },
  { make: "Daewoo", model: "Leganza" },
  { make: "Daewoo", model: "Nubira" },
  { make: "Daihatsu", model: "Charade" },
  { make: "Daihatsu", model: "Rocky" },
  { make: "Dodge", model: "Avenger" },
  { make: "Dodge", model: "Caliber" },
  { make: "Dodge", model: "Caravan Cargo" },
  { make: "Dodge", model: "Caravan Passenger" },
  { make: "Dodge", model: "Challenger" },
  { make: "Dodge", model: "Charger" },
  { make: "Dodge", model: "Colt" },
  { make: "Dodge", model: "D150 Club Cab" },
  { make: "Dodge", model: "D150 Regular Cab" },
  { make: "Dodge", model: "D250 Club Cab" },
  { make: "Dodge", model: "D250 Regular Cab" },
  { make: "Dodge", model: "D350 Club Cab" },
  { make: "Dodge", model: "D350 Regular Cab" },
  { make: "Dodge", model: "Dakota Club Cab" },
  { make: "Dodge", model: "Dakota Crew Cab" },
  { make: "Dodge", model: "Dakota Extended Cab" },
  { make: "Dodge", model: "Dakota Quad Cab" },
  { make: "Dodge", model: "Dakota Regular Cab" },
  { make: "Dodge", model: "Dart" },
  { make: "Dodge", model: "Daytona" },
  { make: "Dodge", model: "Durango" },
  { make: "Dodge", model: "Dynasty" },
  { make: "Dodge", model: "Grand Caravan Cargo" },
  { make: "Dodge", model: "Grand Caravan Passenger" },
  { make: "Dodge", model: "Intrepid" },
  { make: "Dodge", model: "Journey" },
  { make: "Dodge", model: "Magnum" },
  { make: "Dodge", model: "Monaco" },
  { make: "Dodge", model: "Neon" },
  { make: "Dodge", model: "Nitro" },
  { make: "Dodge", model: "Ram 1500 Club Cab" },
  { make: "Dodge", model: "Ram 1500 Crew Cab" },
  { make: "Dodge", model: "Ram 1500 Mega Cab" },
  { make: "Dodge", model: "Ram 1500 Quad Cab" },
  { make: "Dodge", model: "Ram 1500 Regular Cab" },
  { make: "Dodge", model: "Ram 2500 Club Cab" },
  { make: "Dodge", model: "Ram 2500 Crew Cab" },
  { make: "Dodge", model: "Ram 2500 Mega Cab" },
  { make: "Dodge", model: "Ram 2500 Quad Cab" },
  { make: "Dodge", model: "Ram 2500 Regular Cab" },
  { make: "Dodge", model: "Ram 3500 Club Cab" },
  { make: "Dodge", model: "Ram 3500 Crew Cab" },
  { make: "Dodge", model: "Ram 3500 Mega Cab" },
  { make: "Dodge", model: "Ram 3500 Quad Cab" },
  { make: "Dodge", model: "Ram 3500 Regular Cab" },
  { make: "Dodge", model: "Ram 50 Regular Cab" },
  { make: "Dodge", model: "Ram Van 1500" },
  { make: "Dodge", model: "Ram Van 2500" },
  { make: "Dodge", model: "Ram Van 3500" },
  { make: "Dodge", model: "Ram Van B150" },
  { make: "Dodge", model: "Ram Van B250" },
  { make: "Dodge", model: "Ram Van B350" },
  { make: "Dodge", model: "Ram Wagon 1500" },
  { make: "Dodge", model: "Ram Wagon 2500" },
  { make: "Dodge", model: "Ram Wagon 3500" },
  { make: "Dodge", model: "Ram Wagon B150" },
  { make: "Dodge", model: "Ram Wagon B250" },
  { make: "Dodge", model: "Ram Wagon B350" },
  { make: "Dodge", model: "Ramcharger" },
  { make: "Dodge", model: "Shadow" },
  { make: "Dodge", model: "Spirit" },
  { make: "Dodge", model: "Sprinter 2500 Cargo" },
  { make: "Dodge", model: "Sprinter 2500 Passenger" },
  { make: "Dodge", model: "Sprinter 3500 Cargo" },
  { make: "Dodge", model: "Stealth" },
  { make: "Dodge", model: "Stratus" },
  { make: "Dodge", model: "Viper" },
  { make: "Eagle", model: "Premier" },
  { make: "Eagle", model: "Summit" },
  { make: "Eagle", model: "Talon" },
  { make: "Eagle", model: "Vision" },
  { make: "Ferrari", model: "430 Scuderia" },
  { make: "Ferrari", model: "458 Italia" },
  { make: "Ferrari", model: "458 Speciale" },
  { make: "Ferrari", model: "458 Spider" },
  { make: "Ferrari", model: "488 GTB" },
  { make: "Ferrari", model: "488 Spider" },
  { make: "Ferrari", model: "599 GTB Fiorano" },
  { make: "Ferrari", model: "599 GTO" },
  { make: "Ferrari", model: "612 Scaglietti" },
  { make: "Ferrari", model: "812 Superfast" },
  { make: "Ferrari", model: "California" },
  { make: "Ferrari", model: "F12berlinetta" },
  { make: "Ferrari", model: "F430" },
  { make: "Ferrari", model: "FF" },
  { make: "Ferrari", model: "GTC4Lusso" },
  { make: "Ferrari", model: "Portofino" },
  { make: "FIAT", model: "500" },
  { make: "FIAT", model: "124 Spider" },
  { make: "FIAT", model: "500 Abarth" },
  { make: "FIAT", model: "500c" },
  { make: "FIAT", model: "500c Abarth" },
  { make: "FIAT", model: "500e" },
  { make: "FIAT", model: "500L" },
  { make: "FIAT", model: "500X" },
  { make: "Fisker", model: "Karma" },
  { make: "Ford", model: "Aerostar Cargo" },
  { make: "Ford", model: "Aerostar Passenger" },
  { make: "Ford", model: "Aspire" },
  { make: "Ford", model: "Bronco" },
  { make: "Ford", model: "C-MAX Energi" },
  { make: "Ford", model: "C-MAX Hybrid" },
  { make: "Ford", model: "Club Wagon" },
  { make: "Ford", model: "Contour" },
  { make: "Ford", model: "Crown Victoria" },
  { make: "Ford", model: "E150 Cargo" },
  { make: "Ford", model: "E150 Passenger" },
  { make: "Ford", model: "E150 Super Duty Cargo" },
  { make: "Ford", model: "E150 Super Duty Passenger" },
  { make: "Ford", model: "E250 Cargo" },
  { make: "Ford", model: "E250 Super Duty Cargo" },
  { make: "Ford", model: "E350 Super Duty Cargo" },
  { make: "Ford", model: "E350 Super Duty Passenger" },
  { make: "Ford", model: "Econoline E150 Cargo" },
  { make: "Ford", model: "Econoline E150 Passenger" },
  { make: "Ford", model: "Econoline E250 Cargo" },
  { make: "Ford", model: "Econoline E350 Cargo" },
  { make: "Ford", model: "Econoline E350 Super Duty Cargo" },
  { make: "Ford", model: "Econoline E350 Super Duty Passenger" },
  { make: "Ford", model: "EcoSport" },
  { make: "Ford", model: "Edge" },
  { make: "Ford", model: "Escape" },
  { make: "Ford", model: "Escort" },
  { make: "Ford", model: "Excursion" },
  { make: "Ford", model: "Expedition" },
  { make: "Ford", model: "Expedition EL" },
  { make: "Ford", model: "Expedition MAX" },
  { make: "Ford", model: "Explorer" },
  { make: "Ford", model: "Explorer Sport" },
  { make: "Ford", model: "Explorer Sport Trac" },
  { make: "Ford", model: "F150 (Heritage) Regular Cab" },
  { make: "Ford", model: "F150 (Heritage) Super Cab" },
  { make: "Ford", model: "F150 Regular Cab" },
  { make: "Ford", model: "F150 Super Cab" },
  { make: "Ford", model: "F150 SuperCrew Cab" },
  { make: "Ford", model: "F250 Crew Cab" },
  { make: "Ford", model: "F250 Regular Cab" },
  { make: "Ford", model: "F250 Super Cab" },
  { make: "Ford", model: "F250 Super Duty Crew Cab" },
  { make: "Ford", model: "F250 Super Duty Regular Cab" },
  { make: "Ford", model: "F250 Super Duty Super Cab" },
  { make: "Ford", model: "F350 Crew Cab" },
  { make: "Ford", model: "F350 Regular Cab" },
  { make: "Ford", model: "F350 Super Cab" },
  { make: "Ford", model: "F350 Super Duty Crew Cab" },
  { make: "Ford", model: "F350 Super Duty Regular Cab" },
  { make: "Ford", model: "F350 Super Duty Super Cab" },
  { make: "Ford", model: "F450 Super Duty Crew Cab" },
  { make: "Ford", model: "Festiva" },
  { make: "Ford", model: "Fiesta" },
  { make: "Ford", model: "Five Hundred" },
  { make: "Ford", model: "Flex" },
  { make: "Ford", model: "Focus" },
  { make: "Ford", model: "Focus ST" },
  { make: "Ford", model: "Freestar Cargo" },
  { make: "Ford", model: "Freestar Passenger" },
  { make: "Ford", model: "Freestyle" },
  { make: "Ford", model: "Fusion" },
  { make: "Ford", model: "Fusion Energi" },
  { make: "Ford", model: "GT" },
  { make: "Ford", model: "Mustang" },
  { make: "Ford", model: "Probe" },
  { make: "Ford", model: "Ranger Regular Cab" },
  { make: "Ford", model: "Ranger Super Cab" },
  { make: "Ford", model: "Ranger SuperCab" },
  { make: "Ford", model: "Ranger SuperCrew" },
  { make: "Ford", model: "Taurus" },
  { make: "Ford", model: "Taurus X" },
  { make: "Ford", model: "Tempo" },
  { make: "Ford", model: "Thunderbird" },
  { make: "Ford", model: "Transit 150 Van" },
  { make: "Ford", model: "Transit 150 Wagon" },
  { make: "Ford", model: "Transit 250 Van" },
  { make: "Ford", model: "Transit 350 HD Van" },
  { make: "Ford", model: "Transit 350 Van" },
  { make: "Ford", model: "Transit 350 Wagon" },
  { make: "Ford", model: "Transit Connect Cargo" },
  { make: "Ford", model: "Transit Connect Passenger" },
  { make: "Ford", model: "Windstar Cargo" },
  { make: "Ford", model: "Windstar Passenger" },
  { make: "Ford", model: "ZX2" },
  { make: "Freightliner", model: "Sprinter 2500 Cargo" },
  { make: "Freightliner", model: "Sprinter 2500 Crew" },
  { make: "Freightliner", model: "Sprinter 2500 Passenger" },
  { make: "Freightliner", model: "Sprinter 3500 Cargo" },
  { make: "Freightliner", model: "Sprinter 3500XD Cargo" },
  { make: "Freightliner", model: "Sprinter WORKER Cargo" },
  { make: "Freightliner", model: "Sprinter WORKER Passenger" },
  { make: "Genesis", model: "G70" },
  { make: "Genesis", model: "G80" },
  { make: "Genesis", model: "G90" },
  { make: "Geo", model: "Metro" },
  { make: "Geo", model: "Prizm" },
  { make: "Geo", model: "Storm" },
  { make: "Geo", model: "Tracker" },
  { make: "GMC", model: "1500 Club Coupe" },
  { make: "GMC", model: "1500 Regular Cab" },
  { make: "GMC", model: "2500 Club Coupe" },
  { make: "GMC", model: "2500 Crew Cab" },
  { make: "GMC", model: "2500 HD Club Coupe" },
  { make: "GMC", model: "2500 HD Extended Cab" },
  { make: "GMC", model: "2500 HD Regular Cab" },
  { make: "GMC", model: "2500 Regular Cab" },
  { make: "GMC", model: "3500 Club Coupe" },
  { make: "GMC", model: "3500 Crew Cab" },
  { make: "GMC", model: "3500 Extended Cab" },
  { make: "GMC", model: "3500 Regular Cab" },
  { make: "GMC", model: "Acadia" },
  { make: "GMC", model: "Acadia Limited" },
  { make: "GMC", model: "Canyon Crew Cab" },
  { make: "GMC", model: "Canyon Extended Cab" },
  { make: "GMC", model: "Canyon Regular Cab" },
  { make: "GMC", model: "Envoy" },
  { make: "GMC", model: "Envoy XL" },
  { make: "GMC", model: "Envoy XUV" },
  { make: "GMC", model: "Jimmy" },
  { make: "GMC", model: "Rally Wagon 1500" },
  { make: "GMC", model: "Rally Wagon 2500" },
  { make: "GMC", model: "Rally Wagon 3500" },
  { make: "GMC", model: "Rally Wagon G2500" },
  { make: "GMC", model: "Rally Wagon G3500" },
  { make: "GMC", model: "Safari Cargo" },
  { make: "GMC", model: "Safari Passenger" },
  { make: "GMC", model: "Savana 1500 Cargo" },
  { make: "GMC", model: "Savana 1500 Passenger" },
  { make: "GMC", model: "Savana 2500 Cargo" },
  { make: "GMC", model: "Savana 2500 Passenger" },
  { make: "GMC", model: "Savana 3500 Cargo" },
  { make: "GMC", model: "Savana 3500 Passenger" },
  { make: "GMC", model: "Sierra (Classic) 1500 Crew Cab" },
  { make: "GMC", model: "Sierra (Classic) 1500 Extended Cab" },
  { make: "GMC", model: "Sierra (Classic) 1500 HD Crew Cab" },
  { make: "GMC", model: "Sierra (Classic) 1500 Regular Cab" },
  { make: "GMC", model: "Sierra (Classic) 2500 Crew Cab" },
  { make: "GMC", model: "Sierra (Classic) 2500 HD Crew Cab" },
  { make: "GMC", model: "Sierra (Classic) 2500 HD Extended Cab" },
  { make: "GMC", model: "Sierra (Classic) 2500 HD Regular Cab" },
  { make: "GMC", model: "Sierra (Classic) 3500 Crew Cab" },
  { make: "GMC", model: "Sierra (Classic) 3500 Extended Cab" },
  { make: "GMC", model: "Sierra (Classic) 3500 Regular Cab" },
  { make: "GMC", model: "Sierra 1500 Crew Cab" },
  { make: "GMC", model: "Sierra 1500 Double Cab" },
  { make: "GMC", model: "Sierra 1500 Extended Cab" },
  { make: "GMC", model: "Sierra 1500 HD Crew Cab" },
  { make: "GMC", model: "Sierra 1500 Limited Double Cab" },
  { make: "GMC", model: "Sierra 1500 Regular Cab" },
  { make: "GMC", model: "Sierra 2500 Crew Cab" },
  { make: "GMC", model: "Sierra 2500 Extended Cab" },
  { make: "GMC", model: "Sierra 2500 HD Crew Cab" },
  { make: "GMC", model: "Sierra 2500 HD Double Cab" },
  { make: "GMC", model: "Sierra 2500 HD Extended Cab" },
  { make: "GMC", model: "Sierra 2500 HD Regular Cab" },
  { make: "GMC", model: "Sierra 2500 Regular Cab" },
  { make: "GMC", model: "Sierra 3500 Crew Cab" },
  { make: "GMC", model: "Sierra 3500 Extended Cab" },
  { make: "GMC", model: "Sierra 3500 HD Crew Cab" },
  { make: "GMC", model: "Sierra 3500 HD Double Cab" },
  { make: "GMC", model: "Sierra 3500 HD Extended Cab" },
  { make: "GMC", model: "Sierra 3500 HD Regular Cab" },
  { make: "GMC", model: "Sierra 3500 Regular Cab" },
  { make: "GMC", model: "Sonoma Club Cab" },
  { make: "GMC", model: "Sonoma Club Coupe Cab" },
  { make: "GMC", model: "Sonoma Crew Cab" },
  { make: "GMC", model: "Sonoma Extended Cab" },
  { make: "GMC", model: "Sonoma Regular Cab" },
  { make: "GMC", model: "Suburban 1500" },
  { make: "GMC", model: "Suburban 2500" },
  { make: "GMC", model: "Terrain" },
  { make: "GMC", model: "Vandura 1500" },
  { make: "GMC", model: "Vandura 2500" },
  { make: "GMC", model: "Vandura 3500" },
  { make: "GMC", model: "Vandura G1500" },
  { make: "GMC", model: "Vandura G2500" },
  { make: "GMC", model: "Vandura G3500" },
  { make: "GMC", model: "Yukon" },
  { make: "GMC", model: "Yukon Denali" },
  { make: "GMC", model: "Yukon XL" },
  { make: "GMC", model: "Yukon XL 1500" },
  { make: "GMC", model: "Yukon XL 2500" },
  { make: "Honda", model: "Accord" },
  { make: "Honda", model: "Accord Crosstour" },
  { make: "Honda", model: "Accord Hybrid" },
  { make: "Honda", model: "Civic" },
  { make: "Honda", model: "Civic Type R" },
  { make: "Honda", model: "Clarity Electric" },
  { make: "Honda", model: "Clarity Fuel Cell" },
  { make: "Honda", model: "Clarity Plug-in Hybrid" },
  { make: "Honda", model: "CR-V" },
  { make: "Honda", model: "CR-Z" },
  { make: "Honda", model: "Crosstour" },
  { make: "Honda", model: "del Sol" },
  { make: "Honda", model: "Element" },
  { make: "Honda", model: "Fit" },
  { make: "Honda", model: "HR-V" },
  { make: "Honda", model: "Insight" },
  { make: "Honda", model: "Odyssey" },
  { make: "Honda", model: "Passport" },
  { make: "Honda", model: "Pilot" },
  { make: "Honda", model: "Prelude" },
  { make: "Honda", model: "Ridgeline" },
  { make: "Honda", model: "S2000" },
  { make: "HUMMER", model: "H1" },
  { make: "HUMMER", model: "H2" },
  { make: "HUMMER", model: "H3" },
  { make: "HUMMER", model: "H3T" },
  { make: "Hyundai", model: "Accent" },
  { make: "Hyundai", model: "Azera" },
  { make: "Hyundai", model: "Elantra" },
  { make: "Hyundai", model: "Elantra GT" },
  { make: "Hyundai", model: "Entourage" },
  { make: "Hyundai", model: "Equus" },
  { make: "Hyundai", model: "Excel" },
  { make: "Hyundai", model: "Genesis" },
  { make: "Hyundai", model: "Genesis Coupe" },
  { make: "Hyundai", model: "Ioniq Electric" },
  { make: "Hyundai", model: "Ioniq Hybrid" },
  { make: "Hyundai", model: "Ioniq Plug-in Hybrid" },
  { make: "Hyundai", model: "Kona" },
  { make: "Hyundai", model: "Kona Electric" },
  { make: "Hyundai", model: "NEXO" },
  { make: "Hyundai", model: "Palisade" },
  { make: "Hyundai", model: "Santa Fe" },
  { make: "Hyundai", model: "Santa Fe Sport" },
  { make: "Hyundai", model: "Santa Fe XL" },
  { make: "Hyundai", model: "Scoupe" },
  { make: "Hyundai", model: "Sonata" },
  { make: "Hyundai", model: "Sonata Hybrid" },
  { make: "Hyundai", model: "Sonata Plug-in Hybrid" },
  { make: "Hyundai", model: "Tiburon" },
  { make: "Hyundai", model: "Tucson" },
  { make: "Hyundai", model: "Tucson Fuel Cell" },
  { make: "Hyundai", model: "Veloster" },
  { make: "Hyundai", model: "Venue" },
  { make: "Hyundai", model: "Veracruz" },
  { make: "Hyundai", model: "XG300" },
  { make: "Hyundai", model: "XG350" },
  { make: "INFINITI", model: "EX" },
  { make: "INFINITI", model: "FX" },
  { make: "INFINITI", model: "G" },
  { make: "INFINITI", model: "I" },
  { make: "INFINITI", model: "J" },
  { make: "INFINITI", model: "JX" },
  { make: "INFINITI", model: "M" },
  { make: "INFINITI", model: "Q" },
  { make: "INFINITI", model: "Q40" },
  { make: "INFINITI", model: "Q50" },
  { make: "INFINITI", model: "Q60" },
  { make: "INFINITI", model: "Q70" },
  { make: "INFINITI", model: "QX" },
  { make: "INFINITI", model: "QX30" },
  { make: "INFINITI", model: "QX50" },
  { make: "INFINITI", model: "QX60" },
  { make: "INFINITI", model: "QX70" },
  { make: "INFINITI", model: "QX80" },
  { make: "Isuzu", model: "Amigo" },
  { make: "Isuzu", model: "Ascender" },
  { make: "Isuzu", model: "Axiom" },
  { make: "Isuzu", model: "Hombre Regular Cab" },
  { make: "Isuzu", model: "Hombre Spacecab" },
  { make: "Isuzu", model: "i-280 Extended Cab" },
  { make: "Isuzu", model: "i-290 Extended Cab" },
  { make: "Isuzu", model: "i-350 Crew Cab" },
  { make: "Isuzu", model: "i-370 Crew Cab" },
  { make: "Isuzu", model: "i-370 Extended Cab" },
  { make: "Isuzu", model: "Impulse" },
  { make: "Isuzu", model: "Oasis" },
  { make: "Isuzu", model: "Regular Cab" },
  { make: "Isuzu", model: "Rodeo" },
  { make: "Isuzu", model: "Rodeo Sport" },
  { make: "Isuzu", model: "Spacecab" },
  { make: "Isuzu", model: "Stylus" },
  { make: "Isuzu", model: "Trooper" },
  { make: "Isuzu", model: "VehiCROSS" },
  { make: "Jaguar", model: "E-PACE" },
  { make: "Jaguar", model: "F-PACE" },
  { make: "Jaguar", model: "F-TYPE" },
  { make: "Jaguar", model: "I-PACE" },
  { make: "Jaguar", model: "S-Type" },
  { make: "Jaguar", model: "X-Type" },
  { make: "Jaguar", model: "XE" },
  { make: "Jaguar", model: "XF" },
  { make: "Jaguar", model: "XJ" },
  { make: "Jaguar", model: "XK" },
  { make: "Jeep", model: "Cherokee" },
  { make: "Jeep", model: "Comanche Regular Cab" },
  { make: "Jeep", model: "Commander" },
  { make: "Jeep", model: "Compass" },
  { make: "Jeep", model: "Gladiator" },
  { make: "Jeep", model: "Grand Cherokee" },
  { make: "Jeep", model: "Liberty" },
  { make: "Jeep", model: "Patriot" },
  { make: "Jeep", model: "Renegade" },
  { make: "Jeep", model: "Wrangler" },
  { make: "Jeep", model: "Wrangler Unlimited" },
  { make: "Kia", model: "Amanti" },
  { make: "Kia", model: "Borrego" },
  { make: "Kia", model: "Cadenza" },
  { make: "Kia", model: "Forte" },
  { make: "Kia", model: "Forte Koup" },
  { make: "Kia", model: "Forte5" },
  { make: "Kia", model: "K900" },
  { make: "Kia", model: "Niro" },
  { make: "Kia", model: "Niro EV" },
  { make: "Kia", model: "Niro Plug-in Hybrid" },
  { make: "Kia", model: "Optima" },
  { make: "Kia", model: "Optima (2006.5)" },
  { make: "Kia", model: "Optima Hybrid" },
  { make: "Kia", model: "Optima Plug-in Hybrid" },
  { make: "Kia", model: "Rio" },
  { make: "Kia", model: "Rondo" },
  { make: "Kia", model: "Sedona" },
  { make: "Kia", model: "Sephia" },
  { make: "Kia", model: "Sorento" },
  { make: "Kia", model: "Soul" },
  { make: "Kia", model: "Soul EV" },
  { make: "Kia", model: "Spectra" },
  { make: "Kia", model: "Sportage" },
  { make: "Kia", model: "Stinger" },
  { make: "Kia", model: "Telluride" },
  { make: "Lamborghini", model: "Aventador" },
  { make: "Lamborghini", model: "Gallardo" },
  { make: "Lamborghini", model: "Huracan" },
  { make: "Lamborghini", model: "Murcielago" },
  { make: "Lamborghini", model: "Murcielago LP640" },
  { make: "Land Rover", model: "Defender 110" },
  { make: "Land Rover", model: "Defender 90" },
  { make: "Land Rover", model: "Discovery" },
  { make: "Land Rover", model: "Discovery Series II" },
  { make: "Land Rover", model: "Discovery Sport" },
  { make: "Land Rover", model: "Freelander" },
  { make: "Land Rover", model: "LR2" },
  { make: "Land Rover", model: "LR3" },
  { make: "Land Rover", model: "LR4" },
  { make: "Land Rover", model: "Range Rover" },
  { make: "Land Rover", model: "Range Rover Evoque" },
  { make: "Land Rover", model: "Range Rover Sport" },
  { make: "Land Rover", model: "Range Rover Velar" },
  { make: "Lexus", model: "CT" },
  { make: "Lexus", model: "ES" },
  { make: "Lexus", model: "GS" },
  { make: "Lexus", model: "GX" },
  { make: "Lexus", model: "HS" },
  { make: "Lexus", model: "IS" },
  { make: "Lexus", model: "IS F" },
  { make: "Lexus", model: "LC" },
  { make: "Lexus", model: "LFA" },
  { make: "Lexus", model: "LS" },
  { make: "Lexus", model: "LX" },
  { make: "Lexus", model: "NX" },
  { make: "Lexus", model: "RC" },
  { make: "Lexus", model: "RX" },
  { make: "Lexus", model: "SC" },
  { make: "Lexus", model: "UX" },
  { make: "Lincoln", model: "Aviator" },
  { make: "Lincoln", model: "Blackwood" },
  { make: "Lincoln", model: "Continental" },
  { make: "Lincoln", model: "Corsair" },
  { make: "Lincoln", model: "LS" },
  { make: "Lincoln", model: "Mark LT" },
  { make: "Lincoln", model: "Mark VII" },
  { make: "Lincoln", model: "Mark VIII" },
  { make: "Lincoln", model: "MKC" },
  { make: "Lincoln", model: "MKS" },
  { make: "Lincoln", model: "MKT" },
  { make: "Lincoln", model: "MKX" },
  { make: "Lincoln", model: "MKZ" },
  { make: "Lincoln", model: "Nautilus" },
  { make: "Lincoln", model: "Navigator" },
  { make: "Lincoln", model: "Navigator L" },
  { make: "Lincoln", model: "Town Car" },
  { make: "Lincoln", model: "Zephyr" },
  { make: "Lotus", model: "Elise" },
  { make: "Lotus", model: "Evora" },
  { make: "Lotus", model: "Evora 400" },
  { make: "Lotus", model: "Exige" },
  { make: "Lotus", model: "Exige S" },
  { make: "Maserati", model: "Coupe" },
  { make: "Maserati", model: "Ghibli" },
  { make: "Maserati", model: "GranSport" },
  { make: "Maserati", model: "GranTurismo" },
  { make: "Maserati", model: "Levante" },
  { make: "Maserati", model: "Quattroporte" },
  { make: "Maybach", model: "57" },
  { make: "Maybach", model: "62" },
  { make: "MAZDA", model: "323" },
  { make: "MAZDA", model: "626" },
  { make: "MAZDA", model: "929" },
  { make: "MAZDA", model: "B-Series Cab Plus" },
  { make: "MAZDA", model: "B-Series Extended Cab" },
  { make: "MAZDA", model: "B-Series Regular Cab" },
  { make: "MAZDA", model: "CX-3" },
  { make: "MAZDA", model: "CX-30" },
  { make: "MAZDA", model: "CX-5" },
  { make: "MAZDA", model: "CX-7" },
  { make: "MAZDA", model: "CX-9" },
  { make: "MAZDA", model: "MAZDA2" },
  { make: "MAZDA", model: "MAZDA3" },
  { make: "MAZDA", model: "MAZDA5" },
  { make: "MAZDA", model: "MAZDA6" },
  { make: "MAZDA", model: "Millenia" },
  { make: "MAZDA", model: "MPV" },
  { make: "MAZDA", model: "MX-3" },
  { make: "MAZDA", model: "MX-5 Miata" },
  { make: "MAZDA", model: "MX-5 Miata RF" },
  { make: "MAZDA", model: "MX-6" },
  { make: "MAZDA", model: "Navajo" },
  { make: "MAZDA", model: "Protege" },
  { make: "MAZDA", model: "Protege5" },
  { make: "MAZDA", model: "RX-7" },
  { make: "MAZDA", model: "RX-8" },
  { make: "MAZDA", model: "Tribute" },
  { make: "McLaren", model: "570GT" },
  { make: "McLaren", model: "570S" },
  { make: "McLaren", model: "650S" },
  { make: "McLaren", model: "675LT" },
  { make: "McLaren", model: "720S" },
  { make: "McLaren", model: "MP4-12C" },
  { make: "Mercedes-Benz", model: "190 E" },
  { make: "Mercedes-Benz", model: "300 CE" },
  { make: "Mercedes-Benz", model: "300 D" },
  { make: "Mercedes-Benz", model: "300 E" },
  { make: "Mercedes-Benz", model: "300 SD" },
  { make: "Mercedes-Benz", model: "300 SE" },
  { make: "Mercedes-Benz", model: "300 SL" },
  { make: "Mercedes-Benz", model: "300 TE" },
  { make: "Mercedes-Benz", model: "400 E" },
  { make: "Mercedes-Benz", model: "400 SE" },
  { make: "Mercedes-Benz", model: "400 SEL" },
  { make: "Mercedes-Benz", model: "500 E" },
  { make: "Mercedes-Benz", model: "500 SEC" },
  { make: "Mercedes-Benz", model: "500 SEL" },
  { make: "Mercedes-Benz", model: "500 SL" },
  { make: "Mercedes-Benz", model: "600 SEC" },
  { make: "Mercedes-Benz", model: "600 SEL" },
  { make: "Mercedes-Benz", model: "600 SL" },
  { make: "Mercedes-Benz", model: "A-Class" },
  { make: "Mercedes-Benz", model: "B-Class" },
  { make: "Mercedes-Benz", model: "C-Class" },
  { make: "Mercedes-Benz", model: "CL-Class" },
  { make: "Mercedes-Benz", model: "CLA" },
  { make: "Mercedes-Benz", model: "CLA-Class" },
  { make: "Mercedes-Benz", model: "CLK-Class" },
  { make: "Mercedes-Benz", model: "CLS" },
  { make: "Mercedes-Benz", model: "CLS-Class" },
  { make: "Mercedes-Benz", model: "E-Class" },
  { make: "Mercedes-Benz", model: "G-Class" },
  { make: "Mercedes-Benz", model: "GL-Class" },
  { make: "Mercedes-Benz", model: "GLA" },
  { make: "Mercedes-Benz", model: "GLA-Class" },
  { make: "Mercedes-Benz", model: "GLC" },
  { make: "Mercedes-Benz", model: "GLC Coupe" },
  { make: "Mercedes-Benz", model: "GLE" },
  { make: "Mercedes-Benz", model: "GLE Coupe" },
  { make: "Mercedes-Benz", model: "GLK-Class" },
  { make: "Mercedes-Benz", model: "GLS" },
  { make: "Mercedes-Benz", model: "M-Class" },
  { make: "Mercedes-Benz", model: "Mercedes-AMG C-Class" },
  { make: "Mercedes-Benz", model: "Mercedes-AMG CLA" },
  { make: "Mercedes-Benz", model: "Mercedes-AMG CLS" },
  { make: "Mercedes-Benz", model: "Mercedes-AMG E-Class" },
  { make: "Mercedes-Benz", model: "Mercedes-AMG G-Class" },
  { make: "Mercedes-Benz", model: "Mercedes-AMG GLA" },
  { make: "Mercedes-Benz", model: "Mercedes-AMG GLC" },
  { make: "Mercedes-Benz", model: "Mercedes-AMG GLC Coupe" },
  { make: "Mercedes-Benz", model: "Mercedes-AMG GLE" },
  { make: "Mercedes-Benz", model: "Mercedes-AMG GLE Coupe" },
  { make: "Mercedes-Benz", model: "Mercedes-AMG GLS" },
  { make: "Mercedes-Benz", model: "Mercedes-AMG GT" },
  { make: "Mercedes-Benz", model: "Mercedes-AMG S-Class" },
  { make: "Mercedes-Benz", model: "Mercedes-AMG SL" },
  { make: "Mercedes-Benz", model: "Mercedes-AMG SLC" },
  { make: "Mercedes-Benz", model: "Mercedes-AMG SLK" },
  { make: "Mercedes-Benz", model: "Mercedes-Maybach S 600" },
  { make: "Mercedes-Benz", model: "Mercedes-Maybach S-Class" },
  { make: "Mercedes-Benz", model: "Metris Cargo" },
  { make: "Mercedes-Benz", model: "Metris Passenger" },
  { make: "Mercedes-Benz", model: "Metris WORKER Cargo" },
  { make: "Mercedes-Benz", model: "Metris WORKER Passenger" },
  { make: "Mercedes-Benz", model: "R-Class" },
  { make: "Mercedes-Benz", model: "S-Class" },
  { make: "Mercedes-Benz", model: "SL" },
  { make: "Mercedes-Benz", model: "SL-Class" },
  { make: "Mercedes-Benz", model: "SLC" },
  { make: "Mercedes-Benz", model: "SLK" },
  { make: "Mercedes-Benz", model: "SLK-Class" },
  { make: "Mercedes-Benz", model: "SLR McLaren" },
  { make: "Mercedes-Benz", model: "SLS-Class" },
  { make: "Mercedes-Benz", model: "Sprinter 2500 Cargo" },
  { make: "Mercedes-Benz", model: "Sprinter 2500 Crew" },
  { make: "Mercedes-Benz", model: "Sprinter 2500 Passenger" },
  { make: "Mercedes-Benz", model: "Sprinter 3500 Cargo" },
  { make: "Mercedes-Benz", model: "Sprinter 3500 XD Cargo" },
  { make: "Mercedes-Benz", model: "Sprinter WORKER Cargo" },
  { make: "Mercedes-Benz", model: "Sprinter WORKER Passenger" },
  { make: "Mercury", model: "Capri" },
  { make: "Mercury", model: "Cougar" },
  { make: "Mercury", model: "Grand Marquis" },
  { make: "Mercury", model: "Marauder" },
  { make: "Mercury", model: "Mariner" },
  { make: "Mercury", model: "Milan" },
  { make: "Mercury", model: "Montego" },
  { make: "Mercury", model: "Monterey" },
  { make: "Mercury", model: "Mountaineer" },
  { make: "Mercury", model: "Mystique" },
  { make: "Mercury", model: "Sable" },
  { make: "Mercury", model: "Topaz" },
  { make: "Mercury", model: "Tracer" },
  { make: "Mercury", model: "Villager" },
  { make: "MINI", model: "Clubman" },
  { make: "MINI", model: "Convertible" },
  { make: "MINI", model: "Cooper" },
  { make: "MINI", model: "Countryman" },
  { make: "MINI", model: "Coupe" },
  { make: "MINI", model: "Hardtop" },
  { make: "MINI", model: "Hardtop 2 Door" },
  { make: "MINI", model: "Hardtop 4 Door" },
  { make: "MINI", model: "Paceman" },
  { make: "MINI", model: "Roadster" },
  { make: "Mitsubishi", model: "3000GT" },
  { make: "Mitsubishi", model: "Diamante" },
  { make: "Mitsubishi", model: "Eclipse" },
  { make: "Mitsubishi", model: "Eclipse Cross" },
  { make: "Mitsubishi", model: "Endeavor" },
  { make: "Mitsubishi", model: "Expo" },
  { make: "Mitsubishi", model: "Galant" },
  { make: "Mitsubishi", model: "i-MiEV" },
  { make: "Mitsubishi", model: "Lancer" },
  { make: "Mitsubishi", model: "Lancer Evolution" },
  { make: "Mitsubishi", model: "Mighty Max Macro Cab" },
  { make: "Mitsubishi", model: "Mighty Max Regular Cab" },
  { make: "Mitsubishi", model: "Mirage" },
  { make: "Mitsubishi", model: "Mirage G4" },
  { make: "Mitsubishi", model: "Montero" },
  { make: "Mitsubishi", model: "Montero Sport" },
  { make: "Mitsubishi", model: "Outlander" },
  { make: "Mitsubishi", model: "Outlander PHEV" },
  { make: "Mitsubishi", model: "Outlander Sport" },
  { make: "Mitsubishi", model: "Precis" },
  { make: "Mitsubishi", model: "Raider Double Cab" },
  { make: "Mitsubishi", model: "Raider Extended Cab" },
  { make: "Nissan", model: "200SX" },
  { make: "Nissan", model: "240SX" },
  { make: "Nissan", model: "300ZX" },
  { make: "Nissan", model: "350Z" },
  { make: "Nissan", model: "370Z" },
  { make: "Nissan", model: "Altima" },
  { make: "Nissan", model: "Armada" },
  { make: "Nissan", model: "cube" },
  { make: "Nissan", model: "Frontier Crew Cab" },
  { make: "Nissan", model: "Frontier King Cab" },
  { make: "Nissan", model: "Frontier Regular Cab" },
  { make: "Nissan", model: "GT-R" },
  { make: "Nissan", model: "JUKE" },
  { make: "Nissan", model: "Kicks" },
  { make: "Nissan", model: "King Cab" },
  { make: "Nissan", model: "LEAF" },
  { make: "Nissan", model: "Maxima" },
  { make: "Nissan", model: "Murano" },
  { make: "Nissan", model: "NV1500 Cargo" },
  { make: "Nissan", model: "NV200" },
  { make: "Nissan", model: "NV200 Taxi" },
  { make: "Nissan", model: "NV2500 HD Cargo" },
  { make: "Nissan", model: "NV3500 HD Cargo" },
  { make: "Nissan", model: "NV3500 HD Passenger" },
  { make: "Nissan", model: "NX" },
  { make: "Nissan", model: "Pathfinder" },
  { make: "Nissan", model: "Pathfinder Armada" },
  { make: "Nissan", model: "Quest" },
  { make: "Nissan", model: "Regular Cab" },
  { make: "Nissan", model: "Rogue" },
  { make: "Nissan", model: "Rogue Select" },
  { make: "Nissan", model: "Rogue Sport" },
  { make: "Nissan", model: "Sentra" },
  { make: "Nissan", model: "Stanza" },
  { make: "Nissan", model: "Titan Crew Cab" },
  { make: "Nissan", model: "Titan King Cab" },
  { make: "Nissan", model: "TITAN Single Cab" },
  { make: "Nissan", model: "TITAN XD Crew Cab" },
  { make: "Nissan", model: "TITAN XD King Cab" },
  { make: "Nissan", model: "TITAN XD Single Cab" },
  { make: "Nissan", model: "Versa" },
  { make: "Nissan", model: "Versa Note" },
  { make: "Nissan", model: "Xterra" },
  { make: "Oldsmobile", model: "88" },
  { make: "Oldsmobile", model: "98" },
  { make: "Oldsmobile", model: "Achieva" },
  { make: "Oldsmobile", model: "Alero" },
  { make: "Oldsmobile", model: "Aurora" },
  { make: "Oldsmobile", model: "Bravada" },
  { make: "Oldsmobile", model: "Ciera" },
  { make: "Oldsmobile", model: "Custom Cruiser" },
  { make: "Oldsmobile", model: "Cutlass" },
  { make: "Oldsmobile", model: "Cutlass Ciera" },
  { make: "Oldsmobile", model: "Cutlass Cruiser" },
  { make: "Oldsmobile", model: "Cutlass Supreme" },
  { make: "Oldsmobile", model: "Intrigue" },
  { make: "Oldsmobile", model: "LSS" },
  { make: "Oldsmobile", model: "Regency" },
  { make: "Oldsmobile", model: "Silhouette" },
  { make: "Oldsmobile", model: "Toronado" },
  { make: "Panoz", model: "Esperante" },
  { make: "Plymouth", model: "Acclaim" },
  { make: "Plymouth", model: "Breeze" },
  { make: "Plymouth", model: "Colt" },
  { make: "Plymouth", model: "Colt Vista" },
  { make: "Plymouth", model: "Grand Voyager" },
  { make: "Plymouth", model: "Laser" },
  { make: "Plymouth", model: "Neon" },
  { make: "Plymouth", model: "Prowler" },
  { make: "Plymouth", model: "Sundance" },
  { make: "Plymouth", model: "Voyager" },
  { make: "Pontiac", model: "Aztek" },
  { make: "Pontiac", model: "Bonneville" },
  { make: "Pontiac", model: "Firebird" },
  { make: "Pontiac", model: "G3" },
  { make: "Pontiac", model: "G5" },
  { make: "Pontiac", model: "G6" },
  { make: "Pontiac", model: "G6 (2009.5)" },
  { make: "Pontiac", model: "G8" },
  { make: "Pontiac", model: "Grand Am" },
  { make: "Pontiac", model: "Grand Prix" },
  { make: "Pontiac", model: "GTO" },
  { make: "Pontiac", model: "LeMans" },
  { make: "Pontiac", model: "Montana" },
  { make: "Pontiac", model: "Montana SV6" },
  { make: "Pontiac", model: "Solstice" },
  { make: "Pontiac", model: "Sunbird" },
  { make: "Pontiac", model: "Sunfire" },
  { make: "Pontiac", model: "Torrent" },
  { make: "Pontiac", model: "Trans Sport" },
  { make: "Pontiac", model: "Vibe" },
  { make: "Porsche", model: "911" },
  { make: "Porsche", model: "928" },
  { make: "Porsche", model: "968" },
  { make: "Porsche", model: "718 Boxster" },
  { make: "Porsche", model: "718 Cayman" },
  { make: "Porsche", model: "Boxster" },
  { make: "Porsche", model: "Carrera GT" },
  { make: "Porsche", model: "Cayenne" },
  { make: "Porsche", model: "Cayenne Coupe" },
  { make: "Porsche", model: "Cayman" },
  { make: "Porsche", model: "Macan" },
  { make: "Porsche", model: "Panamera" },
  { make: "Porsche", model: "Taycan" },
  { make: "Ram", model: "1500 Classic Crew Cab" },
  { make: "Ram", model: "1500 Classic Quad Cab" },
  { make: "Ram", model: "1500 Classic Regular Cab" },
  { make: "Ram", model: "1500 Crew Cab" },
  { make: "Ram", model: "1500 Quad Cab" },
  { make: "Ram", model: "1500 Regular Cab" },
  { make: "Ram", model: "2500 Crew Cab" },
  { make: "Ram", model: "2500 Mega Cab" },
  { make: "Ram", model: "2500 Regular Cab" },
  { make: "Ram", model: "3500 Crew Cab" },
  { make: "Ram", model: "3500 Mega Cab" },
  { make: "Ram", model: "3500 Regular Cab" },
  { make: "Ram", model: "C/V" },
  { make: "Ram", model: "C/V Tradesman" },
  { make: "Ram", model: "Dakota Crew Cab" },
  { make: "Ram", model: "Dakota Extended Cab" },
  { make: "Ram", model: "ProMaster 1500 Cargo" },
  { make: "Ram", model: "ProMaster 2500 Cargo" },
  { make: "Ram", model: "ProMaster 3500 Cargo" },
  { make: "Ram", model: "ProMaster Cargo Van" },
  { make: "Ram", model: "ProMaster City" },
  { make: "Ram", model: "ProMaster Window Van" },
  { make: "Rolls-Royce", model: "Dawn" },
  { make: "Rolls-Royce", model: "Ghost" },
  { make: "Rolls-Royce", model: "Phantom" },
  { make: "Rolls-Royce", model: "Wraith" },
  { make: "Saab", model: "900" },
  { make: "Saab", model: "9000" },
  { make: "Saab", model: "44442" },
  { make: "Saab", model: "44444" },
  { make: "Saab", model: "9-2X" },
  { make: "Saab", model: "9-4X" },
  { make: "Saab", model: "9-7X" },
  { make: "Saturn", model: "Astra" },
  { make: "Saturn", model: "Aura" },
  { make: "Saturn", model: "Ion" },
  { make: "Saturn", model: "L-Series" },
  { make: "Saturn", model: "Outlook" },
  { make: "Saturn", model: "Relay" },
  { make: "Saturn", model: "S-Series" },
  { make: "Saturn", model: "SKY" },
  { make: "Saturn", model: "VUE" },
  { make: "Scion", model: "FR-S" },
  { make: "Scion", model: "iA" },
  { make: "Scion", model: "iM" },
  { make: "Scion", model: "iQ" },
  { make: "Scion", model: "tC" },
  { make: "Scion", model: "xA" },
  { make: "Scion", model: "xB" },
  { make: "Scion", model: "xD" },
  { make: "smart", model: "fortwo" },
  { make: "smart", model: "fortwo cabrio" },
  { make: "smart", model: "fortwo electric drive" },
  { make: "smart", model: "fortwo electric drive cabrio" },
  { make: "SRT", model: "Viper" },
  { make: "Subaru", model: "Ascent" },
  { make: "Subaru", model: "B9 Tribeca" },
  { make: "Subaru", model: "Baja" },
  { make: "Subaru", model: "BRZ" },
  { make: "Subaru", model: "Crosstrek" },
  { make: "Subaru", model: "Forester" },
  { make: "Subaru", model: "Impreza" },
  { make: "Subaru", model: "Justy" },
  { make: "Subaru", model: "Legacy" },
  { make: "Subaru", model: "Loyale" },
  { make: "Subaru", model: "Outback" },
  { make: "Subaru", model: "SVX" },
  { make: "Subaru", model: "Tribeca" },
  { make: "Subaru", model: "WRX" },
  { make: "Subaru", model: "XV Crosstrek" },
  { make: "Suzuki", model: "Aerio" },
  { make: "Suzuki", model: "Equator Crew Cab" },
  { make: "Suzuki", model: "Equator Extended Cab" },
  { make: "Suzuki", model: "Esteem" },
  { make: "Suzuki", model: "Forenza" },
  { make: "Suzuki", model: "Grand Vitara" },
  { make: "Suzuki", model: "Kizashi" },
  { make: "Suzuki", model: "Reno" },
  { make: "Suzuki", model: "Samurai" },
  { make: "Suzuki", model: "Sidekick" },
  { make: "Suzuki", model: "Swift" },
  { make: "Suzuki", model: "SX4" },
  { make: "Suzuki", model: "Verona" },
  { make: "Suzuki", model: "Vitara" },
  { make: "Suzuki", model: "X-90" },
  { make: "Suzuki", model: "XL-7" },
  { make: "Suzuki", model: "XL7" },
  { make: "Tesla", model: "Model 3" },
  { make: "Tesla", model: "Model S" },
  { make: "Tesla", model: "Model X" },
  { make: "Toyota", model: "86" },
  { make: "Toyota", model: "4Runner" },
  { make: "Toyota", model: "Avalon" },
  { make: "Toyota", model: "Avalon Hybrid" },
  { make: "Toyota", model: "C-HR" },
  { make: "Toyota", model: "Camry" },
  { make: "Toyota", model: "Camry Hybrid" },
  { make: "Toyota", model: "Celica" },
  { make: "Toyota", model: "Corolla" },
  { make: "Toyota", model: "Corolla Hatchback" },
  { make: "Toyota", model: "Corolla Hybrid" },
  { make: "Toyota", model: "Corolla iM" },
  { make: "Toyota", model: "Cressida" },
  { make: "Toyota", model: "Echo" },
  { make: "Toyota", model: "FJ Cruiser" },
  { make: "Toyota", model: "GR Supra" },
  { make: "Toyota", model: "Highlander" },
  { make: "Toyota", model: "Highlander Hybrid" },
  { make: "Toyota", model: "Land Cruiser" },
  { make: "Toyota", model: "Matrix" },
  { make: "Toyota", model: "Mirai" },
  { make: "Toyota", model: "MR2" },
  { make: "Toyota", model: "Paseo" },
  { make: "Toyota", model: "Previa" },
  { make: "Toyota", model: "Prius" },
  { make: "Toyota", model: "Prius c" },
  { make: "Toyota", model: "Prius Plug-in Hybrid" },
  { make: "Toyota", model: "Prius Prime" },
  { make: "Toyota", model: "Prius v" },
  { make: "Toyota", model: "RAV4" },
  { make: "Toyota", model: "RAV4 Hybrid" },
  { make: "Toyota", model: "Regular Cab" },
  { make: "Toyota", model: "Sequoia" },
  { make: "Toyota", model: "Sienna" },
  { make: "Toyota", model: "Solara" },
  { make: "Toyota", model: "Supra" },
  { make: "Toyota", model: "T100 Regular Cab" },
  { make: "Toyota", model: "T100 Xtracab" },
  { make: "Toyota", model: "Tacoma Access Cab" },
  { make: "Toyota", model: "Tacoma Double Cab" },
  { make: "Toyota", model: "Tacoma Regular Cab" },
  { make: "Toyota", model: "Tacoma Xtracab" },
  { make: "Toyota", model: "Tercel" },
  { make: "Toyota", model: "Tundra Access Cab" },
  { make: "Toyota", model: "Tundra CrewMax" },
  { make: "Toyota", model: "Tundra Double Cab" },
  { make: "Toyota", model: "Tundra Regular Cab" },
  { make: "Toyota", model: "Venza" },
  { make: "Toyota", model: "Xtra Cab" },
  { make: "Toyota", model: "Yaris" },
  { make: "Toyota", model: "Yaris Hatchback" },
  { make: "Toyota", model: "Yaris iA" },
  { make: "Volkswagen", model: "Arteon" },
  { make: "Volkswagen", model: "Atlas" },
  { make: "Volkswagen", model: "Beetle" },
  { make: "Volkswagen", model: "Cabrio" },
  { make: "Volkswagen", model: "Cabriolet" },
  { make: "Volkswagen", model: "CC" },
  { make: "Volkswagen", model: "Corrado" },
  { make: "Volkswagen", model: "e-Golf" },
  { make: "Volkswagen", model: "Eos" },
  { make: "Volkswagen", model: "Eurovan" },
  { make: "Volkswagen", model: "Fox" },
  { make: "Volkswagen", model: "GLI" },
  { make: "Volkswagen", model: "Golf" },
  { make: "Volkswagen", model: "Golf Alltrack" },
  { make: "Volkswagen", model: "Golf GTI" },
  { make: "Volkswagen", model: "Golf III" },
  { make: "Volkswagen", model: "Golf R" },
  { make: "Volkswagen", model: "Golf SportWagen" },
  { make: "Volkswagen", model: "GTI" },
  { make: "Volkswagen", model: "Jetta" },
  { make: "Volkswagen", model: "Jetta GLI" },
  { make: "Volkswagen", model: "Jetta III" },
  { make: "Volkswagen", model: "Jetta SportWagen" },
  { make: "Volkswagen", model: "Passat" },
  { make: "Volkswagen", model: "Phaeton" },
  { make: "Volkswagen", model: "R32" },
  { make: "Volkswagen", model: "Rabbit" },
  { make: "Volkswagen", model: "Routan" },
  { make: "Volkswagen", model: "Tiguan" },
  { make: "Volkswagen", model: "Tiguan Limited" },
  { make: "Volkswagen", model: "Touareg" },
  { make: "Volkswagen", model: "Touareg 2" },
  { make: "Volvo", model: "240" },
  { make: "Volvo", model: "740" },
  { make: "Volvo", model: "850" },
  { make: "Volvo", model: "940" },
  { make: "Volvo", model: "960" },
  { make: "Volvo", model: "C30" },
  { make: "Volvo", model: "C70" },
  { make: "Volvo", model: "S40" },
  { make: "Volvo", model: "S60" },
  { make: "Volvo", model: "S70" },
  { make: "Volvo", model: "S80" },
  { make: "Volvo", model: "S90" },
  { make: "Volvo", model: "V40" },
  { make: "Volvo", model: "V50" },
  { make: "Volvo", model: "V60" },
  { make: "Volvo", model: "V70" },
  { make: "Volvo", model: "V90" },
  { make: "Volvo", model: "XC40" },
  { make: "Volvo", model: "XC60" },
  { make: "Volvo", model: "XC70" },
  { make: "Volvo", model: "XC90" },
];
