import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import moment from "moment";
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@material-ui/core";

import adminStore from "../../../Stores/AdminStore";
import PaperSTD from "../../../Components/PaperSTD/PaperSTD";
import RequestApprovalModal from "../Modal/RequestApprovalModal";

class RequestApprovalPage extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }

  componentDidMount() {
    adminStore.fetchPendingRequests();
  }
  closeModal = () => {
    this.setState({ showModal: false });
  };
  render() {
    let requests = adminStore.pendingrequests.map((request, index) => (
      <Card style={{ width: 200, margin: 10 }} key={index}>
        <CardActionArea
          onClick={() => {
            adminStore.selectRequest(request);
            this.setState({ showModal: true });
          }}
        >
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              Pending {moment(request.createdAt).fromNow()}
            </Typography>
            <Typography gutterBottom variant="h6" component="h4">
              {request.service.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {request.description}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              By: {request.user.email}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    ));

    return (
      <div>
        <RequestApprovalModal
          show={this.state.showModal}
          onHide={this.closeModal}
        />
        <PaperSTD
          title={`${adminStore.pendingrequests.length} Requests Pending`}
        >
          <h1>
            This is just a test
            <br />
          </h1>
          {requests}
        </PaperSTD>
      </div>
    );
  }
}

export default withRouter(observer(RequestApprovalPage));
