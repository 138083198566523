import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";

import {
  ListItemText,
  ListItem,
  ListItemIcon,
  Divider,
  Chip,
} from "@material-ui/core";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import { List } from "@material-ui/core";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import requestStore from "../../../Stores/RequestStore";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import requestSummaryStore from "../../../Stores/RequestSummaryStore";

class CustomerButtons extends Component {
  constructor(props) {
    super(props);
    this.state = { open: true, pending: false };
  }
  render() {
    let requests = requestStore.openRequests;
    let pending = requestStore.pendingRequests;

    let requestSection;
    // let requestSection = (
    //   <ListItem>
    //     <ListItemText primary={"0 Open Requests"} />
    //   </ListItem>
    // );
    if (requests) {
      if (requests.length > 0) {
        requestSection = (
          <div>
            <ListItem
              button
              onClick={() => this.setState({ open: !this.state.open })}
            >
              <ListItemIcon>
                {this.state.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                {requests.length} Open
              </ListItemIcon>
            </ListItem>
            {this.state.open &&
              requests.map((req, index) => (
                <ListItem
                  key={index}
                  button
                  onClick={() => {
                    this.props.history.push(`/request/${req.request._id}`);
                    requestSummaryStore.fetchSingleRequest(req.request._id);
                    this.props.closeDrawer();
                  }}
                >
                  <ListItemIcon>
                    <Chip
                      style={{
                        backgroundColor: req.quote.length > 0 && "#4dff00",
                        boxShadow: "0px 0px 3px",
                      }}
                      size="small"
                      label={`${req.quote.length}/${req.request.maxQuote}`}
                    />
                  </ListItemIcon>
                  <ListItemText primary={`${req.request.service.title}`} />
                </ListItem>
              ))}
          </div>
        );
      }
    }
    let pendingSection;
    // let pendingSection = (
    //   <ListItem>
    //     <ListItemText primary={"0 Pending Requests"} />
    //   </ListItem>
    // );
    if (pending) {
      if (pending.length > 0) {
        pendingSection = (
          <div>
            <ListItem
              button
              onClick={() => this.setState({ pending: !this.state.pending })}
            >
              <ListItemIcon>
                {this.state.pending ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                {pending.length} Pending
              </ListItemIcon>
            </ListItem>
            {this.state.pending &&
              pending.map((req, index) => (
                <ListItem
                  key={index}
                  button
                  onClick={() => {
                    this.props.history.push(`/request/${req.request._id}`);
                    requestSummaryStore.fetchSingleRequest(req.request._id);
                    this.props.closeDrawer();
                  }}
                >
                  <ListItemIcon>
                    <Chip
                      style={{
                        backgroundColor: req.quote.length > 0 && "#4dff00",
                        boxShadow: "0px 0px 3px",
                      }}
                      size="small"
                      label={`${req.quote.length}/${req.request.maxQuote}`}
                    />
                  </ListItemIcon>
                  <ListItemText primary={`${req.request.service.title}`} />
                </ListItem>
              ))}
          </div>
        );
      }
    }

    return (
      <List>
        <ListItem
          button
          onClick={() => {
            this.props.closeDrawer();
            this.props.history.push("/dashboard");
          }}
        >
          <ListItemIcon>
            <DriveEtaIcon />
          </ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            this.props.closeDrawer();
            this.props.history.push("/requests");
          }}
        >
          <ListItemIcon>
            <MoveToInboxIcon />
          </ListItemIcon>
          <ListItemText primary={"Requests"} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            this.props.closeDrawer();
            this.props.history.push("/newrequest");
          }}
        >
          <ListItemIcon>
            <AddCircleOutlineIcon style={{ color: "green" }} />
          </ListItemIcon>
          <ListItemText primary={"New Request"} />
        </ListItem>
        <Divider />
        {requestSection}
        {pendingSection}
      </List>
    );
  }
}

export default withRouter(observer(CustomerButtons));
