import React, { Component } from "react";
import RequestList from "../Requests/RequestList";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import MyCars from "../Cars/MyCars";
import NewRequestCards from "./NewRequestCards";
import requestStore from "../../../Stores/RequestStore";

class DashboardPage extends Component {
  componentDidMount() {
    requestStore.getServices();
  }
  render() {
    return (
      <div>
        <br />
        <NewRequestCards services={requestStore.services} />
        <RequestList />
        <MyCars />
      </div>
    );
  }
}

export default withRouter(observer(DashboardPage));
