import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Checkbox, Whisper } from "rsuite";
import Tooltip from "@material-ui/core/Tooltip";
import userStore from "../../../Stores/UserStore";

class IndividualSubBlock extends Component {
  render() {
    let type = this.props.type;
    let data = userStore.editprofileinfo[type];
    let category = "";
    let method = "";
    let contact = "";
    let text = "";

    if (type === "emailrequest") {
      category = "Request";
      method = "Email";
      contact = userStore.user.email;
      text =
        "You will receive an email everytime a request is submitted for a service that your company provides or if you are awarded the request.";
    } else if (type === "emailquote") {
      category = "Quote";
      method = "Email";
      contact = userStore.user.email;
      text =
        "You will receive an email everytime a quote is submitted for your request.";
    } else if (type === "textquote") {
      category = "Quote";
      method = "Text";
      contact = "+965-" + userStore.user.phone;
      text =
        "You will receive a mobile text message everytime a quote is submitted for your request.";
    } else if (type === "textrequest") {
      category = "Request";
      method = "Text";
      contact = "+965-" + userStore.user.phone;
      text =
        "You will receive a mobile text message everytime a request is submitted for a service that your company provides or if you are awarded the request.";
    }

    return (
      <Tooltip title={text} placement="top-start">
        <div>
          <Checkbox
            checked={data}
            disabled={this.props.disable}
            onChange={() => userStore.changeProfileInfo(type, !data)}
          >
            Subscribe to <strong>New {category}</strong> by {method} ({contact})
          </Checkbox>
        </div>
      </Tooltip>
    );
  }
}

export default withRouter(observer(IndividualSubBlock));
