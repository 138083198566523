import React, { Component } from "react";
import { observer } from "mobx-react";
import { Button, Col, Notification, FlexboxGrid } from "rsuite";
import { withRouter } from "react-router";
import TextField from "@material-ui/core/TextField";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import businessStore from "../../../../Stores/BusinessStore";
import userStore from "../../../../Stores/UserStore";
import axios from "axios";
import baseURL from "../../../../Media/baseURL";
import UnifiedModal from "../../../../Components/UnifiedModal/UnifiedModal";

const instance = axios.create({ baseURL: baseURL });

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

class EditBusinessModal extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }
  postBusinessEdits() {
    this.setState({ loading: true });
    let business = businessStore.editBusiness;
    let old = businessStore.selectedBusiness;
    let finalData = {};
    if (business.name !== old.name) finalData.name = business.name;
    if (business.arname !== old.arname) finalData.arname = business.arname;
    if (business.description !== old.description)
      finalData.description = business.description;
    if (business.ardescription !== old.ardescription)
      finalData.ardescription = business.ardescription;
    if (business.address !== old.address) finalData.address = business.address;
    if (business.phone !== old.phone) finalData.phone = business.phone;
    if (business.email !== old.email) finalData.email = business.email;
    if (business.lat !== old.lat) finalData.lat = business.lat;
    if (business.lng !== old.lng) finalData.lng = business.lng;
    if (business.status !== old.status) finalData.status = business.status;
    if (business.website !== old.website) finalData.website = business.website;
    if (business.instagram !== old.instagram)
      finalData.instagram = business.instagram;

    return instance
      .put(`/api/business/update/${business._id}`, finalData, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        businessStore.getSingleBusiness(res.data._id);
        this.setState({ loading: false });
        this.props.onHide();
        Notification["success"]({
          title: "Business Details Updated",
        });
      })
      .catch((err) => {
        Notification["error"]({
          title: "Business Details Not Updated",
        });
      });
  }

  render() {
    let business = businessStore.editBusiness;
    let disableButton = { val: false, message: "Submit Updates" };
    if (business.name === "") {
      disableButton = { val: true, message: "Add the company Name" };
    } else if (business.arname === "") {
      disableButton = { val: true, message: "Add the Arabic company Name" };
    } else if (business.description === "") {
      disableButton = { val: true, message: "Add the company Description" };
    } else if (business.ardescription === "") {
      disableButton = { val: true, message: "Add Arabic company Description" };
    } else if (business.email === "" || !validateEmail(business.email)) {
      disableButton = { val: true, message: "Add a valid Company Email" };
    } else if (business.phone === "") {
      disableButton = { val: true, message: "Add a valid Company Phone" };
    } else if (business.address === "") {
      disableButton = { val: true, message: "Add a valid Company Address" };
    }
    return (
      <UnifiedModal
        title={
          <FlexboxGrid>
            <Col xs={12}>Edit Business</Col>
            <Col xs={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="age-native-simple">Status</InputLabel>
                <Select
                  native
                  value={business.status}
                  onChange={(e) =>
                    businessStore.editEditBusiness("status", e.target.value)
                  }
                >
                  <option value={"Active"}>Active</option>
                  <option value={"Not Active"}>Not Active</option>
                </Select>
              </FormControl>
            </Col>
          </FlexboxGrid>
        }
        show={this.props.show}
        onHide={this.props.onHide}
        footer={
          <FlexboxGrid>
            <Col xs={12}>
              <Button
                block
                appearance="ghost"
                color="red"
                onClick={() => {
                  this.props.onHide();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                block
                color="green"
                disabled={disableButton.val}
                onClick={() => {
                  this.postBusinessEdits();
                }}
              >
                {disableButton.message}
              </Button>
            </Col>
          </FlexboxGrid>
        }
      >
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          label="English Business Name"
          multiline
          value={business.name}
          onChange={(e) =>
            businessStore.editEditBusiness("name", e.target.value)
          }
        />
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          label="Arabic Business Name"
          multiline
          value={business.arname}
          onChange={(e) =>
            businessStore.editEditBusiness("arname", e.target.value)
          }
        />
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          label="English Business Description"
          multiline
          value={business.description}
          onChange={(e) =>
            businessStore.editEditBusiness("description", e.target.value)
          }
        />
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          label="Arabic Business Description"
          multiline
          value={business.ardescription}
          onChange={(e) =>
            businessStore.editEditBusiness("ardescription", e.target.value)
          }
        />
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          label="Company Phone Number"
          multiline
          value={business.phone}
          onChange={(e) =>
            businessStore.editEditBusiness("phone", e.target.value)
          }
        />
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          label="Company Email"
          multiline
          value={business.email}
          onChange={(e) =>
            businessStore.editEditBusiness("email", e.target.value)
          }
        />
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          label="Company Address"
          multiline
          value={business.address}
          onChange={(e) =>
            businessStore.editEditBusiness("address", e.target.value)
          }
        />
        <FlexboxGrid>
          <Col xs={12}>
            <TextField
              margin="normal"
              fullWidth
              label="Latitude"
              type="number"
              value={business.lat}
              onChange={(e) =>
                businessStore.editEditBusiness("lat", e.target.value)
              }
            />
          </Col>
          <Col xs={12}>
            <TextField
              type="number"
              margin="normal"
              fullWidth
              label="Longitude"
              value={business.lng}
              onChange={(e) =>
                businessStore.editEditBusiness("lng", e.target.value)
              }
            />
          </Col>
        </FlexboxGrid>
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          label="Company Website"
          multiline
          value={business.website}
          onChange={(e) =>
            businessStore.editEditBusiness("website", e.target.value)
          }
        />
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          label="Company Instagram"
          multiline
          value={business.instagram}
          onChange={(e) =>
            businessStore.editEditBusiness("instagram", e.target.value)
          }
        />
      </UnifiedModal>
    );
  }
}

export default withRouter(observer(EditBusinessModal));
