import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { Container } from "@material-ui/core";
import { FlexboxGrid, Button } from "rsuite";
import CarCard from "./CarCard";
import logo from "../../../Media/logo.png";
import { Empty } from "antd";
import AddCarModal from "./AddCarModal";
import carStore from "../../../Stores/CarStore";
import { Card, CardActionArea } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import EditCarModal from "./EditCarModal";
import PaperSTD from "../../../Components/PaperSTD/PaperSTD";

class MyCars extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, showEdit: false };
  }

  closeModal = () => {
    this.setState({ showModal: false, showEdit: false });
  };
  openEditModal = () => {
    this.setState({ showModal: false, showEdit: true });
  };

  render() {
    let cardata;
    let addCarButton;

    if (carStore.cars.length > 0) {
      cardata = carStore.cars.map((car, index) => (
        <CarCard car={car} key={index} openEdit={this.openEditModal} />
      ));
      addCarButton = (
        <div style={{ padding: 10 }}>
          <Card
            style={{ width: 250, borderRadius: 10, margin: "auto" }}
            elevation={10}
          >
            <CardActionArea onClick={() => this.setState({ showModal: true })}>
              <CardContent>
                <Empty
                  image={logo}
                  imageStyle={{
                    height: 30,
                  }}
                  description={
                    <Button
                      block
                      color="green"
                      onClick={() => this.setState({ showModal: true })}
                    >
                      Add a Car
                    </Button>
                  }
                ></Empty>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      );
    } else {
      cardata = (
        <Empty
          image={logo}
          imageStyle={{
            height: 100,
          }}
          description={<span>No cars added.</span>}
        >
          <Button
            color="green"
            onClick={() => this.setState({ showModal: true })}
          >
            Add a Car Model
          </Button>
        </Empty>
      );
    }

    return (
      <Container>
        <PaperSTD title={"My Cars"}>
          <AddCarModal show={this.state.showModal} onHide={this.closeModal} />
          <EditCarModal show={this.state.showEdit} onHide={this.closeModal} />
          <FlexboxGrid>
            {addCarButton}
            {cardata}
          </FlexboxGrid>
          <br />
        </PaperSTD>
      </Container>
    );
  }
}

export default withRouter(observer(MyCars));
