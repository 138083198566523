import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Col, FlexboxGrid, HelpBlock, SelectPicker } from "rsuite";
import StdProdSubTitle from "../../../../Components/StandardInputs/StdProdSubTitle";
import StdProdTitle from "../../../../Components/StandardInputs/StdProdTitle";
import productStore from "../../../../Stores/ProductStore";

class Step0 extends Component {
  render() {
    let newprod = productStore.newproduct;

    let productTypes = [
      { value: "Product", label: "Product" },
      { value: "Service", label: "Service" },
    ];
    let showtypes = [
      { value: false, label: "Hide" },
      { value: true, label: "Show" },
    ];

    return (
      <div>
        <h6 style={{ textAlign: "center" }}>New Product Details</h6>

        <FlexboxGrid justify="space-around" align="top">
          <Col xs={24} sm={12}>
            <br />
            <HelpBlock style={{ fontWeight: "bold" }}>Type:</HelpBlock>
            <SelectPicker
              data={productTypes}
              style={{ width: "100%" }}
              value={newprod.type}
              searchable={false}
              cleanable={false}
              onChange={(e) => productStore.updateNewProduct("type", e)}
            />
            <br />
          </Col>

          <Col xs={24} sm={12}>
            <br />
            <HelpBlock style={{ fontWeight: "bold" }}>Visible:</HelpBlock>
            <SelectPicker
              data={showtypes}
              style={{ width: "100%" }}
              value={newprod.show}
              searchable={false}
              cleanable={false}
              onChange={(e) => productStore.updateNewProduct("show", e)}
            />
            <br />
          </Col>
          <Col sm={24} md={12}>
            <br />
            <StdProdTitle
              title={"* English Product Name"}
              value={newprod.title}
              change={(e) => productStore.updateNewProduct("title", e)}
            />
          </Col>
          <Col sm={24} md={12}>
            <br />
            <StdProdTitle
              title={"* Arabic Product Name"}
              value={newprod.titlear}
              change={(e) => productStore.updateNewProduct("titlear", e)}
            />
          </Col>
          <Col sm={24} md={12}>
            <br />
            <StdProdSubTitle
              title={"English Product Description"}
              value={newprod.subtitle}
              change={(e) => productStore.updateNewProduct("subtitle", e)}
            />
          </Col>
          <Col sm={24} md={12}>
            <br />
            <StdProdSubTitle
              title={"Arabic Product Description"}
              value={newprod.subtitlear}
              change={(e) => productStore.updateNewProduct("subtitlear", e)}
            />
          </Col>
        </FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(Step0));
