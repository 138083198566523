import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import AccountCircle from "@material-ui/icons/AccountCircle";

class AccountBarIcon extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }
  render() {
    return (
      <div>
        <AccountCircle onClick={() => this.props.history.push("/auth")} />
      </div>
    );
  }
}

export default withRouter(observer(AccountBarIcon));
