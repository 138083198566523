import React, { Component } from "react";
import { Parallax } from "react-parallax";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import logo from "../../Media/logo.png";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
class ImageParallax extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }
  render() {
    let medias = [];

    if (this.props.request.medias) {
      medias = this.props.request.medias;
    }
    if (medias.length === 0) {
      medias.push(logo);
    }
    let cardimages = medias.map((image, index) => (
      <Parallax
        key={index}
        blur={0}
        bgImage={image}
        strength={1}
        style={{
          minHeight: 150,
          boxShadow: "10px 10px 70px -50px rgba(0,0,0,0.75)",
          background:
            "radial-gradient(circle, rgba(51,47,47,0.9486169467787114) 0%, rgba(70,70,70,0.7077205882352942) 100%)",
        }}
      ></Parallax>
    ));

    return (
      <div>
        <Carousel
          autoPlay
          infiniteLoop
          swipeable
          showArrows
          useKeyboardArrows
          showThumbs={false}
          style={{ backgroundColor: "white" }}
        >
          {cardimages}
        </Carousel>
      </div>
    );
  }
}

export default withRouter(observer(ImageParallax));
