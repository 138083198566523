import React, { Component } from "react";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { green } from "@material-ui/core/colors";

class RequestTimeline extends Component {
  render() {
    let steps = [
      { main: "Request Created", detail: "Customer created a Request." },
      {
        main: "Quote Submissions",
        detail: "Quotes are being submitted by Companies.",
      },
      {
        main: "Customer Approval",
        detail: "The Customer has awarded a winning company.",
      },
    ];
    let activeStep = this.props.activeStep;
    if (this.props.steps) steps = this.props.steps;

    return (
      <Stepper
        alternativeLabel
        orientation={window.innerWidth < 500 ? "vertical" : "horizontal"}
        activeStep={activeStep}
        style={{ backgroundColor: "transparent" }}
        color={green[500]}
      >
        {steps.map((step) => (
          <Step key={step.main} color={green[500]}>
            <StepLabel>{step.main}</StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  }
}

export default RequestTimeline;
