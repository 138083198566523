import React, { Component, forwardRef } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import quoteStore from "../../../Stores/QuoteStore";
import PaperSTD from "../../../Components/PaperSTD/PaperSTD";

import MaterialTable from "material-table";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import moment from "moment";
import { Container } from "@material-ui/core";

class PastQuotesTable extends Component {
  componentDidMount() {
    quoteStore.fetchQuotes();
  }
  render() {
    const tableIcons = {
      Request: forwardRef((props, ref) => (
        <MoveToInboxIcon {...props} ref={ref} />
      )),
    };
    let quotes = quoteStore.quotes;
    let columnnames = [
      { title: "Status", removable: true, field: "quotestatus" },
      { title: "Service", removable: true, field: "service" },
      {
        title: "Submitted",
        removable: true,
        render: (row) => <span>{moment(row.createdAt).format("DD-MMM")}</span>,
      },
      { title: "Price", field: "price", removable: true },
      { title: "Duration", field: "duration", removable: true },
    ];

    return (
      <div>
        <br />
        <Container>
          <PaperSTD title="Quote List">
            {!quoteStore.loading && (
              <MaterialTable
                title={quotes.length + " Requests"}
                columns={columnnames}
                data={quotes}
                icons={tableIcons}
                options={{
                  paging: false,
                  grouping: true,
                  actionsColumnIndex: 0,
                  exportAllData: true,
                  toolbarButtonAlignment: "left",
                  maxBodyHeight: 1000,
                  tableLayout: "auto",
                  exportButton: false,
                  padding: "dense",
                  rowStyle: (rowData) => ({
                    backgroundColor:
                      rowData.quotestatus === "Lost" ? "#ffbdbd" : "#FFF",
                  }),
                }}
                actions={[
                  {
                    icon: tableIcons.Request,
                    tooltip: "Go To Request",
                    onClick: (event, rowData) =>
                      this.props.history.push("/request/" + rowData.requestId),
                  },
                ]}
              />
            )}
          </PaperSTD>
        </Container>
      </div>
    );
  }
}

export default withRouter(observer(PastQuotesTable));
