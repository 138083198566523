import React, { Component } from "react";
import { observer } from "mobx-react";
import { Button, Col, Modal, FlexboxGrid } from "rsuite";
import { withRouter } from "react-router";
import requestStore from "../../../Stores/RequestStore";
import moment from "moment";
import ImageParallaxLG from "../../../Components/ImageParallax/ImageParallaxLG";
import PaperSTD from "../../../Components/PaperSTD/PaperSTD";
import UnifiedModal from "../../../Components/UnifiedModal/UnifiedModal";

class RequestModal extends Component {
  render() {
    let editreq = requestStore.editrequest;
    let quotes = requestStore.quotes;

    let quotetotals = { price: 0, duration: 0, count: 0 };
    for (let i = 0; i < quotes.length; i++) {
      quotetotals.count += 1;
      quotetotals.price += quotes[i].price;
      quotetotals.duration += quotes[i].duration;
    }

    let quotesCards = (
      <PaperSTD title={`${quotes.length} / ${editreq.maxQuote} Quotes:`} small>
        {quotes.map((quote, index) => (
          <FlexboxGrid style={{ textAlign: "center" }} key={index}>
            <Col xs={8}>
              <h6>{index + 1}</h6>
            </Col>
            <Col xs={8}>
              <h6>{Math.round(1000 * quote.price) / 1000} KD</h6>
            </Col>
            <Col xs={8}>
              <h6>
                {Math.round(10 * quote.duration) / 10} hr
                {quote.duration !== 1 && "s"}
              </h6>
            </Col>
          </FlexboxGrid>
        ))}
        <hr />
        {quotes.length > 0 && (
          <FlexboxGrid style={{ textAlign: "center" }}>
            <Col xs={8}>
              <h6>Average</h6>
            </Col>
            <Col xs={8}>
              <h5>{Math.round(quotetotals.price / quotetotals.count)} KD</h5>
            </Col>
            <Col xs={8}>
              <h5>
                {Math.round(quotetotals.duration / quotetotals.count)} hr
                {Math.round(quotetotals.duration / quotetotals.count) !== 1 &&
                  "s"}
              </h5>
            </Col>
          </FlexboxGrid>
        )}
      </PaperSTD>
    );
    return (
      <UnifiedModal
        title={`${editreq.service.title} Request Details:`}
        show={this.props.show}
        onHide={this.props.onHide}
        footer={
          <Button block appearance="ghost" onClick={() => this.props.onHide()}>
            Close
          </Button>
        }
      >
        <div style={{ paddingLeft: "5%" }}>
          <h6>
            <strong>Description: </strong>
            {editreq.description}
            <br />
            <strong>Car: </strong>
            {editreq.year} {editreq.make} - {editreq.model}
            <br />
            <strong>Close Date: </strong>
            {moment(editreq.closedate).format("DD-MMM-YY")} (
            {moment(editreq.closedate).fromNow()})
          </h6>
        </div>

        {quotesCards}
        <Col style={{ padding: 20 }}>
          <Button
            block
            color="green"
            onClick={() => this.props.history.push(`/request/${editreq._id}`)}
          >
            Go to Request Page
          </Button>
        </Col>
        <ImageParallaxLG request={editreq} height={200} />
      </UnifiedModal>
    );
  }
}

export default withRouter(observer(RequestModal));
