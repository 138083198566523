import React, { Component } from "react";
import { Badge, Drawer, Image } from "antd";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Col, FlexboxGrid, Notification } from "rsuite";
import Resizer from "react-image-file-resizer";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import grey from "@material-ui/core/colors/grey";
import { green } from "@material-ui/core/colors";
import Modal from "antd/lib/modal/Modal";
import { Button } from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import baseURL from "../../../../Media/baseURL";
import userStore from "../../../../Stores/UserStore";
import productStore from "../../../../Stores/ProductStore";

const instance = axios.create({ baseURL: baseURL });

const resizeFile = async (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      800,
      800,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

class NewImageUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      confirmloading: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  dataURItoBlob = async (dataURI) => {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  };

  handleChange = async (event) => {
    let file;
    let image;
    let newFile;
    let count = productStore.newproduct.medias.length;
    for (let i = 0; i < event.target.files.length; i++) {
      count += 1;
      if (count <= 5) {
        file = event.target.files[i];
        image = await resizeFile(file);
        productStore.addNewImage(image);
      }
    }
    if (count > 5) {
      Notification["warning"]({ title: "Only 5 Images per product." });
    }
    this.props.onClose();
  };

  randomString = async (length) => {
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };

  handleOK = async () => {
    this.props.onClose();
  };

  render() {
    return (
      <Modal
        title="Upload New Images"
        visible={this.props.show}
        onOk={this.handleOK}
        onCancel={this.state.confirmloading ? false : this.props.onClose}
        okText="Submit Images"
        confirmLoading={this.state.confirmloading}
        footer={false}
      >
        <div>
          <h6>Up to 5 Images</h6>
          <label htmlFor="outlined-button-file">
            <input
              accept="image/*"
              id="outlined-button-file"
              multiple
              type="file"
              hidden
              onChange={this.handleChange}
            />
            <Button fullWidth variant="outlined" component="span">
              <PhotoCamera /> - Upload New Images
            </Button>
          </label>
          <hr />
          <FlexboxGrid justify="space-around">
            {productStore.newproduct.medias.map((image, index) => (
              <Badge.Ribbon
                text={
                  <Button
                    size="sm"
                    style={{
                      backgroundColor: "transparent",
                      fontSize: 12,
                      fontWeight: "bold",
                      color: "white",
                    }}
                    onClick={() => productStore.removeNewImage(image)}
                  >
                    Delete
                  </Button>
                }
                key={index}
                color="red"
                role="button"
              >
                <Image src={image} key={index} width={100} preview={true} />
              </Badge.Ribbon>
            ))}
          </FlexboxGrid>
        </div>
      </Modal>
    );
  }
}

export default withRouter(observer(NewImageUploader));
