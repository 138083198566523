import { CardMedia, Container } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Button, Col, FlexboxGrid } from "rsuite";
import { withRouter } from "react-router";
import PaperSTD from "../../../Components/PaperSTD/PaperSTD";
import diagram from "../../../Media/Wheel.png";
import userStore from "../../../Stores/UserStore";
class CreditPage extends Component {
  render() {
    return (
      <div>
        <br />
        <Container>
          <PaperSTD title="Credit Page">
            <FlexboxGrid style={{ padding: "5%" }}>
              <Col md={12} sm={24} xs={24}>
                Credit Available <h2>{userStore.user.credit}</h2>
                <hr />
                <h3>
                  Credit is used to submit requests for bids. At this stage, the
                  only way to increase your credit is to invite others.
                </h3>
                <br />
                <h3>For every friend that joins you will receive 2 credits.</h3>
                <br />
                <Button
                  block
                  color="green"
                  onClick={() => this.props.history.push("/referafriend")}
                >
                  Send Referrals
                </Button>
                <hr />
              </Col>
              <Col md={12} sm={24} xs={24}>
                <CardMedia
                  component="img"
                  alt="Credit Icon"
                  width="100%"
                  image={diagram}
                  title="Credit Icon"
                />
              </Col>
            </FlexboxGrid>
          </PaperSTD>
        </Container>
      </div>
    );
  }
}

export default withRouter(observer(CreditPage));
