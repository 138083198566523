import { Container } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import PaperSTD from "../../../Components/PaperSTD/PaperSTD";
import statisticStore from "../../../Stores/StatisticStore";

class StatisticsMain extends Component {
  componentDidMount() {
    statisticStore.fetchAllStats();
  }
  render() {
    console.log(statisticStore.allstats);
    console.log(statisticStore.quotes);
    return (
      <div>
        <Container>
          <br />
          <PaperSTD title="Statistics">
            <h3>{statisticStore.pageViews.length} Page Views</h3>
            <h3>{statisticStore.calls.length} Calls</h3>
            <h3>{statisticStore.direction.length} Direction Requests</h3>
            <hr />
            <h3>{statisticStore.awardedquotes.length} Awarded Quotes</h3>
            <h3>{statisticStore.pendingquotes.length} Pending Quotes</h3>
            <h3>{statisticStore.lostquotes.length} Lost Quotes</h3>
            <hr />
          </PaperSTD>
        </Container>
      </div>
    );
  }
}

export default withRouter(observer(StatisticsMain));
