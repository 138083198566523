import { Checkbox, FormControlLabel } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import businessStore from "../../../../../Stores/BusinessStore";

class ServiceRow extends Component {
  constructor(props) {
    super(props);
    this.state = { service: true };
  }
  render() {
    let service = this.props.service;
    let indexVal = businessStore.editBusiness.services.findIndex(
      (item) => item._id === service._id
    );

    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={indexVal > -1}
              onChange={() => {
                if (indexVal >= 0) {
                  businessStore.updateService("Remove", service);
                } else {
                  businessStore.updateService("Add", service);
                }
              }}
              name={service.title}
            />
          }
          label={service.title}
        />
      </div>
    );
  }
}

export default withRouter(observer(ServiceRow));
