import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Col, FlexboxGrid, HelpBlock, InputGroup, InputNumber } from "rsuite";
import { SelectPicker, Toggle, Tooltip, Button, Whisper } from "rsuite";
import StdProdNum from "../../../../Components/StandardInputs/StdProdNum";
import StdProdSubTitle from "../../../../Components/StandardInputs/StdProdSubTitle";
import StdProdTitle from "../../../../Components/StandardInputs/StdProdTitle";
import productStore from "../../../../Stores/ProductStore";

class Step1 extends Component {
  render() {
    let newprod = productStore.newproduct;

    let productTypes = [
      { value: "Product", label: "Product" },
      { value: "Service", label: "Service" },
    ];
    let showtypes = [
      { value: false, label: "Hide" },
      { value: true, label: "Show" },
    ];

    return (
      <div>
        <h6 style={{ textAlign: "center" }}>Pricing and Stock</h6>

        <FlexboxGrid justify="space-around" align="top">
          <Col xs={12} md={6}>
            <br />
            <StdProdNum
              title="Price"
              value={newprod.price}
              sub={() =>
                productStore.updateNewProduct("price", newprod.price - 1)
              }
              add={() =>
                productStore.updateNewProduct("price", newprod.price + 1)
              }
              change={(e) => productStore.updateNewProduct("price", e)}
            />
          </Col>
          <Col xs={12} md={6}>
            <br />
            <HelpBlock style={{ fontWeight: "bold", textAlign: "center" }}>
              Discount {newprod.discount.show ? "Final Price" : "Hidden"}{" "}
              <Toggle
                style={{
                  backgroundColor: newprod.discount.show ? "#26c902" : "grey",
                }}
                checkedChildren={<div>Discount Applied</div>}
                unCheckedChildren={<div>No Discount</div>}
                checked={newprod.discount.show}
                onChange={() => {
                  productStore.updateNewProduct(
                    "discountshow",
                    !newprod.discount.show
                  );
                }}
              />
            </HelpBlock>
            {newprod.discount.show && (
              <InputGroup>
                <InputGroup.Button
                  onClick={() =>
                    productStore.updateNewProduct(
                      "discountprice",
                      newprod.discount.price - 1
                    )
                  }
                  color="red"
                  disabled={newprod.discount.price < 1}
                >
                  <strong>-</strong>
                </InputGroup.Button>
                <InputNumber
                  max={10000}
                  min={0}
                  value={newprod.discount.price}
                  onChange={(e) =>
                    productStore.updateNewProduct("discountprice", e)
                  }
                />
                <InputGroup.Button
                  onClick={() =>
                    productStore.updateNewProduct(
                      "discountprice",
                      newprod.discount.price + 1
                    )
                  }
                  color="green"
                  disabled={newprod.discount.price > 9999}
                >
                  <strong>+</strong>
                </InputGroup.Button>
              </InputGroup>
            )}
          </Col>
          <Col xs={12} md={6}>
            <br />
            <StdProdNum
              title="Starting Inventory Count"
              value={newprod.stockcount}
              sub={() =>
                productStore.updateNewProduct(
                  "stockcount",
                  newprod.stockcount - 1
                )
              }
              add={() =>
                productStore.updateNewProduct(
                  "stockcount",
                  newprod.stockcount + 1
                )
              }
              change={(e) => productStore.updateNewProduct("stockcount", e)}
            />
          </Col>
          <Col xs={12} md={6}>
            <br />
            <StdProdNum
              title="Minimum Stock Count Alarm"
              value={newprod.stockalarm}
              sub={() =>
                productStore.updateNewProduct(
                  "stockalarm",
                  newprod.stockalarm - 1
                )
              }
              add={() =>
                productStore.updateNewProduct(
                  "stockalarm",
                  newprod.stockalarm + 1
                )
              }
              change={(e) => productStore.updateNewProduct("stockalarm", e)}
            />
          </Col>
        </FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(Step1));
