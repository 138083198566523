import React, { Component } from "react";
import { Paper, Container } from "@material-ui/core";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import BusinessCarousel from "../../Components/BusinesesCarousel/BusinessCarousel";
import NewRequestCards from "../Customer/Dashboard/NewRequestCards";
import requestStore from "../../Stores/RequestStore";
import CompanyCarousel from "./CompanyCarousel";
import RejectedSection from "../Customer/RejectedSection";
import busPostStore from "../../Stores/BusPostStore";
import PostsCarousel from "./PostsCarousel";

class LandingPage extends Component {
  componentDidMount() {
    requestStore.getServices();
  }
  render() {
    return (
      <div>
        <br />
        {requestStore.rejectedRequests.length > 0 && <RejectedSection />}
        <Paper style={{ justifyContent: "space-around" }} elevation={5}>
          <BusinessCarousel />
        </Paper>
        <br />
        <CompanyCarousel />
        <br />
        <Container>
          <PostsCarousel posts={busPostStore.allposts} />
        </Container>
        <br />
        <NewRequestCards services={requestStore.services} />
        <br />
      </div>
    );
  }
}

export default withRouter(observer(LandingPage));
