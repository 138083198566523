import { Card, CardActionArea } from "@material-ui/core";
import { CardContent, Typography } from "@material-ui/core";
import React, { Component } from "react";
import moment from "moment";
import ImageParallax from "../../../Components/ImageParallax/ImageParallax";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Divider } from "antd";

class RequestCard extends Component {
  render() {
    let request = this.props.request.request;
    let quotes = this.props.request.quotecount;

    let hours;
    let timeprint;
    let timeRem;

    if (moment(request.closedate) < moment()) {
      timeRem = `Closed.`;
      hours = 0;
      timeprint = 0;
    } else {
      timeprint = moment(request.closedate).diff(moment(), "minutes");
      hours = Math.floor(timeprint / 60);
      timeRem = `Closes in ${hours} hours.`;
      if (hours > 48) {
        timeRem = `Closes in ${moment(request.closedate).fromNow(true)}.`;
      }
    }

    return (
      <div style={{ padding: 10 }}>
        <Card style={{ width: 250 }} elevation={5}>
          <ImageParallax request={request} />
          <CardActionArea
            onClick={() => {
              // this.props.openModal();
              // quoteStore.fetchSingleRequest(request._id);
              this.props.history.push(`/request/${request._id}`);
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {request.service.title}
              </Typography>
              <p style={{ fontSize: 10, color: "red", fontWeight: "bold" }}>
                {timeRem}
              </p>
              <Typography variant="body2" color="textSecondary" component="p">
                {request.description}
              </Typography>
              <Divider>
                <Typography variant="body2" color="textSecondary" component="p">
                  {quotes} / {request.maxQuote} Quotes Submitted
                </Typography>
              </Divider>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    );
  }
}

export default withRouter(observer(RequestCard));
