import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  CheckboxGroup,
  Checkbox,
  Radio,
  RadioGroup,
  FlexboxGrid,
  HelpBlock,
} from "rsuite";

class SampleOption extends Component {
  render() {
    let option = this.props.option;
    let finaloptions;
    let addedprop;

    if (option.type === "Single") {
      finaloptions = (
        <RadioGroup inline>
          <FlexboxGrid align="top">
            {option.items.map((item, index) => (
              <Radio value={item.title} defaultChecked={index === 0}>
                {item.title}
                {item.price > 0 && <div>(+ {item.price} KD)</div>}
              </Radio>
            ))}
          </FlexboxGrid>
        </RadioGroup>
      );
    }
    if (option.type === "Multiple") {
      finaloptions = (
        <CheckboxGroup inline>
          <FlexboxGrid align="top">
            {option.items.map((item, index) => (
              <Checkbox value={item.title}>
                {item.title}
                {item.price > 0 && <div>(+ {item.price} KD)</div>}
              </Checkbox>
            ))}
          </FlexboxGrid>
        </CheckboxGroup>
      );
      if ((option.min > 0) & (option.min === option.max)) {
        addedprop = ` (Select ${option.min})`;
      } else if ((option.min > 0) & (option.max < option.items.length)) {
        addedprop = ` (Select ${option.min} to ${option.max})`;
      } else if ((option.min > 0) & (option.max === option.items.length)) {
        addedprop = ` (Select ${option.min} minimum)`;
      } else if ((option.min === 0) & (option.max < option.items.length)) {
        addedprop = ` (Select up to ${option.max})`;
      }
    }

    return (
      <div>
        <HelpBlock>
          {option.title}
          {addedprop}
        </HelpBlock>
        {finaloptions}{" "}
      </div>
    );
  }
}

export default withRouter(observer(SampleOption));
