import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { Card, CardActionArea, CardContent } from "@material-ui/core";
import { CardMedia, Container, Typography } from "@material-ui/core";
import requestStore from "../../../Stores/RequestStore";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import "react-multi-carousel/lib/styles.css";
import PaperSTD from "../../../Components/PaperSTD/PaperSTD";
import HorizontalScroll from "../../../Components/HorizontalScroll/HorizontalScroll";

class NewRequestCards extends Component {
  render() {
    let services = this.props.services.map((service, index) => (
      <Card style={{ width: 200, margin: 10 }} key={index}>
        <CardActionArea
          onClick={() => {
            if (requestStore.openRequests.length >= 3) {
              alert(
                `You have reached your Open Request Limit of ${requestStore.openRequests.length} requests. Please close old requests to create new ones.`
              );
            } else {
              requestStore.resetRequest(service);
              this.props.history.push("/newrequest");
            }
          }}
        >
          <CardMedia
            component="img"
            alt={service.title}
            height="140"
            image={service.media}
            title={service.title}
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="h4">
              {service.title}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
            ></Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    ));

    return (
      <Container>
        <PaperSTD title={"Services"}>
          <HorizontalScroll data={services} />
          <FlexboxGrid justify="center">
            <IconButton
              icon={
                <Icon
                  style={{ backgroundColor: "green", color: "white" }}
                  icon="arrow-right"
                />
              }
              placement="right"
              color="green"
              active
              style={{ boxShadow: "0px 0px 3px black" }}
              onClick={() => this.props.history.push("/newrequest")}
            >
              Create a New Request
            </IconButton>
          </FlexboxGrid>
        </PaperSTD>
      </Container>
    );
  }
}

export default withRouter(observer(NewRequestCards));
