import { Container } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import PaperSTD from "../../../../Components/PaperSTD/PaperSTD";
import BusinessFAQs from "./SubFolders/BusinessFAQs";
import BusinessGarageArabia from "./SubFolders/BusinessGarageArabia";
import BusinessPackages from "./SubFolders/BusinessPackages";
import BusinessServiceInfo from "./SubFolders/BusinessServiceInfo";
import { Animation } from "rsuite";

const { Fade, Collapse, Transition } = Animation;

class BusinessInfoPage extends Component {
  render() {
    return (
      <div>
        <br />
        <Container>
          <PaperSTD title="Join Garage Arabia">
            <hr />
            <h6>What is Garage Arabia</h6>
            <BusinessGarageArabia />
            <hr />
            <h6>Our Services</h6>
            <BusinessServiceInfo />
            <hr />
            <h6>Statistics</h6>
            <hr />
            <h6>FAQs</h6>
            <BusinessFAQs />
            <hr />
            <h6>Packages</h6>
            <Fade in>
              <BusinessPackages />
            </Fade>
            <hr />
          </PaperSTD>
        </Container>
      </div>
    );
  }
}

export default withRouter(observer(BusinessInfoPage));
