import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Paper,
  Typography,
} from "@material-ui/core";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import businessStore from "../../Stores/BusinessStore";
import busPostStore from "../../Stores/BusPostStore";
class BusinessCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }
  componentDidMount() {
    businessStore.getBusinesses();
    busPostStore.fetchAllPosts();
  }

  render() {
    let cardimages = businessStore.businesses.map((business, index) => (
      <div key={index}>
        <Card
          style={{
            width: "100%",
            background:
              "radial-gradient(circle, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0.65) 100%)",
            color: "white",
          }}
        >
          <CardActionArea
            onClick={() => this.props.history.push(`/business/${business._id}`)}
          >
            <CardMedia
              component="img"
              alt="BusinessCover"
              height="200"
              image={business.coverimage}
              title="BusinessCover"
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                color="white"
                style={{ color: "white" }}
              >
                {business.name}
              </Typography>
              <Typography variant="body2" component="p" color="white">
                {business.description}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    ));

    return (
      <div>
        <Paper
          style={{
            borderRadius: 20,
            justifyContent: "space-around",
          }}
          elevation={5}
        >
          <Carousel
            dynamicHeight={true}
            autoPlay
            infiniteLoop
            swipeable
            showArrows
            useKeyboardArrows
            showThumbs={false}
          >
            {cardimages}
          </Carousel>
        </Paper>
      </div>
    );
  }
}

export default withRouter(observer(BusinessCarousel));
