import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import letterStore from "../../../Stores/LetterStore";
import LetterCard from "./LetterCard";
import { Comment, Avatar, Input } from "antd";
import userStore from "../../../Stores/UserStore";
import { Button } from "rsuite";
import { Alert } from "rsuite";
import axios from "axios";
import baseURL from "../../../Media/baseURL";

const instance = axios.create({ baseURL: baseURL });

const { TextArea } = Input;

class SingleConversation extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, value: false };
  }

  componentDidMount() {
    let letterData = { conversationId: letterStore.selectedConvo._id };
    instance.put("/api/letter/conversation/seen", letterData, {
      headers: { authtoken: userStore.token },
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.value !== this.state.value) {
      letterStore.selectConversation(letterStore.selectedConvo, false);
    }
  }

  sendMessage = async () => {
    this.setState({ loading: true, value: !this.state.value });
    try {
      let letterData = {
        conversation: letterStore.selectedConvo._id,
        sender: userStore.user.group,
        userId: letterStore.selectedConvo.letters[0].user._id,
        businessId: letterStore.selectedConvo.letters[0].business._id,
        content: letterStore.newmessage,
      };
      await instance
        .post("/api/letter/conversation/reply", letterData, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.setState({ loading: false });
          letterStore.addLetter(res.data);
          Alert.success("Message Successfully Sent.", 5000);
        })
        .catch((err) => alert("An Error Occured and the car was not added."));
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    let convo = letterStore.selectedConvo;
    let letters = convo.letters;
    let lastletter = letters[letters.length - 1];

    let lettercards = letters.map((letter, index) => (
      <LetterCard letter={letter} key={index} />
    ));

    let avatar = (
      <Avatar style={{ boxShadow: "0px 0px 5px black" }} size="large">
        {lastletter.user ? lastletter.user.name.substring(0, 1) : "?"}
      </Avatar>
    );
    if (userStore.user.group === "Business") {
      avatar = (
        <Avatar
          src={lastletter.business.logo}
          alt={lastletter.business.name}
          style={{ boxShadow: "0px 0px 5px black" }}
        />
      );
    }

    return (
      <div>
        <h5>{convo.subject}</h5>
        <hr />
        {lettercards}
        <hr />
        <div
          style={{
            boxShadow: "0px 0px 5px black",
            borderRadius: 10,
            padding: 10,
          }}
        >
          <Comment
            avatar={avatar}
            content={
              <TextArea
                showCount
                maxLength={500}
                value={letterStore.newmessage}
                onChange={(e) => letterStore.updateNewMessage(e.target.value)}
                allowClear
                autoSize={{ minRows: 4, maxRows: 10 }}
              />
            }
          />
          <Button
            block
            color="green"
            disabled={letterStore.newmessage.length === 0 || this.state.loading}
            onClick={() => this.sendMessage()}
          >
            {this.state.loading ? "Loading..." : "Send"}
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(observer(SingleConversation));
