import React, { Component } from "react";
import { Button } from "rsuite";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Divider } from "antd";

import userStore from "../../../Stores/UserStore";

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showPasswordConf: false,
      signupform: false,
    };
  }

  render() {
    let disabledButton = false;
    let user = userStore.newuser;
    let validation = user.validation;

    if (user.email.length < 3) disabledButton = true;
    if (user.password.length < 6) disabledButton = true;

    let passerror = { val: false, message: "" };
    let emailerror = { val: false, message: "" };
    if (validation) {
      if (user.email.length < 6 || !validateEmail(user.email)) {
        emailerror.val = true;
        emailerror.message = "Please enter a Valid Email Address.";
        disabledButton = true;
      }
      if (user.password.length < 6) {
        passerror.val = true;
        passerror.message = "Password must be greater than 6 characters.";
        disabledButton = true;
      }
    }

    return (
      <div>
        <Divider>
          <h5>Email Login</h5>
        </Divider>
        <TextField
          error={emailerror.val}
          helperText={emailerror.val && emailerror.message}
          variant="standard"
          margin="normal"
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          disabled={userStore.loading}
          autoFocus
          value={user.email}
          onChange={(e) => userStore.editnewuser("email", e.target.value)}
          style={{ background: "rgb(255,255,255,0)" }}
        />
        <TextField
          error={passerror.val}
          helperText={passerror.val && passerror.message}
          variant="standard"
          margin="normal"
          fullWidth
          disabled={userStore.loading}
          name="password"
          label="Password"
          type={this.state.showPassword ? "text" : "password"}
          id="password"
          autoComplete="current-password"
          value={user.password}
          style={{ background: "rgb(255,255,255,0)" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onMouseOver={() => this.setState({ showPassword: true })}
                  onMouseLeave={() => this.setState({ showPassword: false })}
                >
                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => userStore.editnewuser("password", e.target.value)}
        />
        <br />
        <Button
          block
          color={!userStore.loading && "green"}
          disabled={disabledButton}
          style={{ boxShadow: "0px 0px 3px black" }}
          onClick={() => {
            let userData = {
              email: user.email,
              password: user.password,
              validation: validateEmail(user.email),
            };
            userStore.loginUser(userData);
          }}
        >
          {userStore.loading ? "Loading ..." : "Login"}
        </Button>
      </div>
    );
  }
}

export default withRouter(observer(LoginForm));
