import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import busPostStore from "../../../Stores/BusPostStore";
import { Input } from "antd";

const { TextArea } = Input;

class Step1 extends Component {
  render() {
    return (
      <div>
        <h6>Add Description</h6>
        <TextArea
          showCount
          maxLength={200}
          value={busPostStore.newpost.description}
          onChange={(e) => busPostStore.newpostText(e.target.value)}
          allowClear
          autoSize={{ minRows: 1, maxRows: 20 }}
        />
        <hr />
      </div>
    );
  }
}

export default withRouter(observer(Step1));
