import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { Paper } from "@material-ui/core";
import { Divider } from "antd";

class PaperSTD extends Component {
  render() {
    let orientation = "left";
    if (this.props.orientation) orientation = this.props.orientation;

    let title = <h4>{this.props.title}</h4>;
    let divider;
    if (this.props.small) title = <h6>{this.props.title}</h6>;

    if (this.props.title)
      divider = <Divider orientation={orientation}>{title}</Divider>;

    return (
      <div>
        <Paper
          style={{
            padding: 10,
            borderRadius: 10,
            justifyContent: "space-around",
          }}
          elevation={5}
        >
          {divider}
          {this.props.children}
        </Paper>
        <br />
      </div>
    );
  }
}

export default withRouter(observer(PaperSTD));
