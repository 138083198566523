import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import requestSummaryStore from "../../../Stores/RequestSummaryStore";
import { Container } from "@material-ui/core";
import SecondSection from "../../../Components/SingleRequest/SecondSection";
import userStore from "../../../Stores/UserStore";
import RequestTemplate from "../../../Components/SingleRequest/RequestTemplate";
import { Button } from "rsuite";
import NewQuoteModal from "../../Business/Quotes/NewQuoteModal";
import moment from "moment";
import LoadingParagraph from "../../../Components/LoadingPages/LoadingParagraph";

class RequestSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectQuote: false,
      showConfirm: false,
      quote: null,
      showEdit: false,
      showAdd: false,
      showClose: false,
    };
  }
  closeModal = () => {
    this.setState({
      selectQuote: false,
      showConfirm: false,
      showEdit: false,
      showAdd: false,
      showClose: false,
    });
  };

  componentDidMount() {
    requestSummaryStore.fetchSingleRequest(this.props.match.params.requestId);
  }

  render() {
    let request = requestSummaryStore.basicrequest;
    let quote = requestSummaryStore.basicquotes;
    if (requestSummaryStore.loading) {
      return <div style={{ minHeight: "100vh" }} />;
    }

    let usertype = "Guest";

    let submittedBid = requestSummaryStore.myquotes.length !== 0;

    if (userStore.userAccess === 4) {
      if (request.status === "Open") {
        if (submittedBid) {
          usertype = "Business Submitted";
        } else {
          usertype = "Business Not Submitted";
        }
      } else if (request.status === "Approved") {
        if (request.approved.business === userStore.user.business) {
          usertype = "Approved Business";
        } else {
          usertype = "Not Approved Business";
        }
      }
    } else if (request.user) {
      if (request.user._id === userStore.user._id) {
        usertype = "Creator";
      }
    }

    let busnotsubmitted = (
      <Button
        block
        disabled={
          request.status !== "Open" || moment(request.closedate) < moment()
        }
        color="green"
        onClick={() => this.setState({ showAdd: true })}
      >
        {request.status !== "Open"
          ? "The Request is Not Open to Bids"
          : moment(request.closedate) < moment()
          ? "Bids are closed."
          : "Submit a Quote."}
      </Button>
    );
    let firstsection = (
      <div>
        <h3
          style={{
            textAlign: "center",
            color:
              request.status === "Cancelled" || request.status === "Rejected"
                ? "red"
                : request.status === "Approved"
                ? "green"
                : "black",
          }}
        >
          {request.status} - {usertype}
        </h3>
        {usertype === "Business Not Submitted" && busnotsubmitted}
      </div>
    );
    if (requestSummaryStore.loading) {
      return <LoadingParagraph />;
    }
    return (
      <div>
        <NewQuoteModal
          show={this.state.showAdd}
          onHide={this.closeModal}
          requestId={request._id}
        />
        <br />
        <Container>
          <RequestTemplate
            req={request}
            quote={quote}
            firstsectionchildren={firstsection}
          />
          <br />
          <SecondSection req={request} />
        </Container>
      </div>
    );
  }
}

export default withRouter(observer(RequestSummary));
