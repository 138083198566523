import React, { Component } from "react";
import { Parallax } from "react-parallax";
import { Button, FlexboxGrid, Col } from "rsuite";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { Container, Paper } from "@material-ui/core";
import LoginForm from "./Email/LoginForm";
import SignUpForm from "./Email/SignUpForm";
import ForgotPassForm from "./Email/ForgotPassForm";
import GoogleAllAuth from "./Email/GoogleAllAuth";
import userStore from "../../Stores/UserStore";
import PhoneLogin from "./Phone/PhoneLogin";
import PhoneSignUp from "./Phone/PhoneSignUp";
import PhoneForgotPass from "./Phone/PhoneForgotPass";

class MainAuthPage extends Component {
  constructor(props) {
    super(props);
    this.state = { login: 0, email: true };
    this.changeLoginState = this.changeLoginState.bind(this);
    this.changeEmailStatus = this.changeEmailStatus.bind(this);
  }

  changeEmailStatus(val) {
    this.setState({ email: val, login: 0 });
    userStore.resetUser();
  }

  changeLoginState(val) {
    this.setState({ login: val });
  }

  render() {
    const insideStyles = {
      minHeight: 800,
      paddingTop: 50,
      background:
        "radial-gradient(circle, rgba(51,47,47,0.95) 0%, rgba(70,70,70,0.70) 100%)",
    };

    let showform;
    let showbutton = (
      <div>
        <Button
          block
          disabled={userStore.loading}
          appearance="ghost"
          style={{ boxShadow: "0px 0px 3px black" }}
          onClick={() => this.setState({ login: 1 })}
        >
          <i>Don't Have an Account? Sign Up Here</i>
        </Button>
        <br />
        <Button
          block
          appearance="ghost"
          color="red"
          disabled={userStore.loading}
          style={{ boxShadow: "0px 0px 3px black" }}
          onClick={() => this.setState({ login: 2 })}
        >
          <i>Forgot Password?</i>
        </Button>
      </div>
    );
    if (this.state.login === 0) {
      showform = (
        <LoginForm
          email={this.state.email}
          changeEmail={this.changeEmailStatus}
        />
      );
    } else if (this.state.login === 1) {
      showform = <SignUpForm />;
      showbutton = (
        <Button
          block
          disabled={userStore.loading}
          appearance="ghost"
          style={{ boxShadow: "0px 0px 3px black" }}
          onClick={() => this.setState({ login: 0 })}
        >
          <i>Already have an account? Sign In Here.</i>
        </Button>
      );
    } else if (this.state.login === 2) {
      showform = <ForgotPassForm changeLogin={this.changeLoginState} />;
      showbutton = (
        <Button
          block
          disabled={userStore.loading}
          appearance="ghost"
          style={{ boxShadow: "0px 0px 3px black" }}
          onClick={() => this.setState({ login: 0 })}
        >
          <i>Already have an account? Sign In Here.</i>
        </Button>
      );
    }

    if (!this.state.email) {
      if (this.state.login === 0) {
        showform = <PhoneLogin />;
        showbutton = (
          <div>
            <Button
              block
              disabled={userStore.loading}
              appearance="ghost"
              style={{ boxShadow: "0px 0px 3px black" }}
              onClick={() => this.setState({ login: 1 })}
            >
              <i>Don't Have an Account? Sign Up Here</i>
            </Button>
            <br />
            <Button
              block
              appearance="ghost"
              color="red"
              disabled={userStore.loading}
              style={{ boxShadow: "0px 0px 3px black" }}
              onClick={() => this.setState({ login: 2 })}
            >
              <i>Forgot Password?</i>
            </Button>
          </div>
        );
      } else if (this.state.login === 1) {
        showform = <PhoneSignUp />;
        showbutton = (
          <Button
            block
            disabled={userStore.loading}
            appearance="ghost"
            style={{ boxShadow: "0px 0px 3px black" }}
            onClick={() => this.setState({ login: 0 })}
          >
            <i>Already have an account? Sign In Here.</i>
          </Button>
        );
      } else if (this.state.login === 2) {
        showform = <PhoneForgotPass />;
        showbutton = (
          <Button
            block
            disabled={userStore.loading}
            appearance="ghost"
            style={{ boxShadow: "0px 0px 3px black" }}
            onClick={() => this.setState({ login: 0 })}
          >
            <i>Already have an account? Sign In Here.</i>
          </Button>
        );
      }
    }
    let finalinfo = (
      <div>
        {showform}
        <hr />
        {showbutton}
        {this.state.email && (
          <div>
            <hr />
            <GoogleAllAuth authType="Login" />
          </div>
        )}
      </div>
    );

    let mainButtons = (
      <FlexboxGrid>
        <Col xs={12}>
          <Button
            block
            appearance={this.state.email ? "primary" : "ghost"}
            onClick={() => this.changeEmailStatus(true)}
          >
            Email
          </Button>
        </Col>
        <Col xs={12}>
          <Button
            block
            appearance={this.state.email ? "ghost" : "primary"}
            onClick={() => this.changeEmailStatus(false)}
          >
            Phone
          </Button>
        </Col>
      </FlexboxGrid>
    );

    return (
      <Parallax
        blur={0}
        bgImage={"https://source.unsplash.com/1600x900/?car,garage"}
        bgImageAlt="Background Image"
        strength={150}
        style={{
          minHeight: 800,
          boxShadow: "10px 10px 70px -50px rgba(0,0,0,0.75)",
        }}
      >
        <div
          style={{
            minHeight: 800,
            width: "100%",
          }}
        >
          <div style={insideStyles}>
            <Container style={{ alignSelf: "center" }}>
              <Paper
                elevation={10}
                style={{
                  background:
                    "radial-gradient(circle, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 100%)",
                  borderRadius: 10,
                  padding: 30,
                  textAlign: "center",
                  maxWidth: 400,
                }}
              >
                {mainButtons}
                {finalinfo}
              </Paper>
            </Container>
          </div>
        </div>
      </Parallax>
    );
  }
}

export default withRouter(observer(MainAuthPage));
