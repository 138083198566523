import { makeObservable, observable } from "mobx";
import axios from "axios";
import userStore from "./UserStore";
import baseURL from "../Media/baseURL";
import { message } from "antd";
import moment from "moment";

const instance = axios.create({ baseURL: baseURL });

class CarStore {
  constructor() {
    this.cars = [];
    this.newcar = { make: "", model: "", year: 2020, nickname: "", medias: [] };
    this.editcar = {
      make: "",
      model: "",
      year: 2020,
      nickname: "",
      medias: [],
    };
    makeObservable(this, {
      cars: observable,
      newcar: observable,
      editcar: observable,
    });
  }

  resetNewCar() {
    this.newcar = { make: "", model: "", year: 2020, nickname: "", medias: [] };
    this.editcar = {
      make: "",
      model: "",
      year: 2020,
      nickname: "",
      medias: [],
    };
  }

  resetEditCar(car) {
    this.editcar = { ...car };
  }

  postNewCar() {
    let carData = {
      make: this.newcar.make,
      model: this.newcar.model,
      year: this.newcar.year,
      user: userStore.user._id,
      medias: this.newcar.medias,
    };
    return instance
      .post("/api/car/postnew", carData, {
        headers: { authtoken: userStore.token },
      })
      .then(() => {
        alert("Car Added Submitted.");
        this.resetNewCar();
        this.getUserCars();
      })
      .catch((err) => alert("An Error Occured and the car was not added."));
  }

  editEditCar(type, val) {
    this.editcar[type] = val;
  }

  editNewCar(type, val) {
    this.newcar[type] = val;
  }

  postPhotos = async (title, titlear, photo) => {
    const fd = new FormData();
    fd.append(
      "image",
      photo,
      `${photo.name}_${moment().format("DDMMMYYhhmm")}`
    );
    let image;
    await instance
      .post("api/media/new/upload", fd, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        image = res.data.fileLocation;
        message.success("Photo Uploaded");
      });
    return image;
  };

  putEditCar = async () => {
    let finalmediaid = [];
    let newmedia = {};
    let title = `${this.editcar.year} ${this.editcar.make} - ${this.editcar.model}`;

    for (let i = 0; i < this.editcar.medias.length; i++) {
      if (this.editcar.medias[i].file) {
        newmedia = await this.postPhotos(
          title,
          title,
          this.editcar.medias[i].file
        );
        finalmediaid.push(newmedia);
        newmedia = {};
      } else {
        finalmediaid.push(this.editcar.medias[i]);
      }
    }

    let carData = {
      make: this.editcar.make,
      model: this.editcar.model,
      year: this.editcar.year,
      user: userStore.user._id,
      medias: finalmediaid,
    };

    return instance
      .put(`/api/car/update/${this.editcar._id}`, carData, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        // console.log(res);
        message.success(
          `${this.editcar.year} ${this.editcar.make} - ${this.editcar.model} Updated`
        );
        this.resetNewCar();
        this.getUserCars();
      })
      .catch((err) => alert("An Error Occured and the car was not added."));
  };

  deleteCar(carId) {
    return instance
      .delete(`/api/car/delete/${carId}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        // console.log(res);
        alert(
          `${this.editcar.year} ${this.editcar.make} - ${this.editcar.model} Deleted`
        );
        this.resetNewCar();
        this.getUserCars();
      })
      .catch((err) => alert("Car was not deleted."));
  }

  getUserCars() {
    return instance
      .get(`/api/car/mycars`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((cars) => {
        this.cars = cars;
        console.log("User Cars Fetched");
      })
      .catch((err) => {
        console.log(err);
        console.error(err.response);
        alert("Error Fetching User Cars");
      });
  }

  addPhotos(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      if (event.target.files[i].size > 5000000) {
        alert(
          `Image #${i + 1} is too large (${Math.round(
            event.target.files[i].size / 1000000
          )} MB). Please upload images < 5 MB.`
        );
      } else {
        this.editcar.medias.push({
          src: URL.createObjectURL(event.target.files[i]),
          file: event.target.files[i],
        });
      }
    }
  }

  deleteImage(photo) {
    let files = [...this.editcar.medias];
    let final = files.filter((file) => file !== photo);
    this.editcar.medias = final;
  }
}

const carStore = new CarStore();
export default carStore;
