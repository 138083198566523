import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import PaperSTD from "../../../Components/PaperSTD/PaperSTD";
import SinglePostAntd from "../../../Components/SinglePost/SinglePostAntd";
import businessStore from "../../../Stores/BusinessStore";
import busPostStore from "../../../Stores/BusPostStore";
import { Paper } from "@material-ui/core";
import { FlexboxGrid } from "rsuite";

class BusinessPostPage extends Component {
  componentDidMount() {
    businessStore.getSingleBusiness(this.props.match.params.businessId);
    businessStore.getSingleBusinessPost(this.props.match.params.businessId);
  }
  render() {
    return (
      <div>
        <FlexboxGrid justify="center">
          <Paper
            style={{
              padding: 10,
              borderRadius: 10,
              justifyContent: "center",
              maxWidth: 500,
              minHeight: "80vh",
            }}
            elevation={5}
          >
            <h3 style={{ textAlign: "center" }}>
              {businessStore.selectedBusiness.name} Posts
            </h3>
            <div>
              {businessStore.businesspost.map((post, index) => (
                <SinglePostAntd
                  postNumId={post.busPostNumId}
                  postId={post._id}
                  text={post.description}
                  medias={post.medias}
                  business={post.business}
                  liked={post.liked}
                  key={index}
                />
              ))}
            </div>
          </Paper>
        </FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(BusinessPostPage));
