import React, { Component } from "react";
import { Parallax } from "react-parallax";
import { Button, FlexboxGrid, Col } from "rsuite";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { Container, Paper, TextField } from "@material-ui/core";
import { InputAdornment, IconButton } from "@material-ui/core";
import { Divider } from "antd";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import userStore from "../../Stores/UserStore";
import GoogleAllAuth from "./Email/GoogleAllAuth";
import PhoneRefer from "./Phone/PhoneRefer";

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

class ReferralPage extends Component {
  constructor(props) {
    super(props);
    this.state = { showPassword: false, showPasswordConf: false, email: true };
    this.changeEmailStatus = this.changeEmailStatus.bind(this);
  }
  componentDidMount() {
    userStore.fetchReferral(this.props.match.params.referralId);
  }

  changeEmailStatus(val) {
    this.setState({ email: val, login: 0 });
    userStore.resetUser();
  }

  render() {
    const insideStyles = {
      minHeight: 800,
      paddingTop: 50,
      background:
        "radial-gradient(circle, rgba(51,47,47,0.95) 0%, rgba(70,70,70,0.70) 100%)",
    };

    let mainButtons = (
      <FlexboxGrid>
        <Col xs={12}>
          <Button
            block
            appearance={this.state.email ? "primary" : "ghost"}
            onClick={() => this.changeEmailStatus(true)}
          >
            Email
          </Button>
        </Col>
        <Col xs={12}>
          <Button
            block
            appearance={this.state.email ? "ghost" : "primary"}
            onClick={() => this.changeEmailStatus(false)}
          >
            Phone
          </Button>
        </Col>
      </FlexboxGrid>
    );
    let user = userStore.newuser;
    let validation = userStore.newuser.validation;
    let phoneerror = { val: false, message: "" };
    let nameerror = { val: false, message: "" };
    let emailerror = { val: false, message: "" };
    let passerror = { val: false, message: "" };
    let passconferror = { val: false, message: "" };
    let disabledSubmit = false;
    let finalValidation = true;
    if (user.email.length < 6 || !validateEmail(user.email)) {
      emailerror.val = true;
      emailerror.message = "Please enter a Valid Email Address.";
      disabledSubmit = true;
      finalValidation = false;
    }
    if (user.password.length < 6) {
      passerror.val = true;
      passerror.message = "Password must be greater than 6 characters.";
      disabledSubmit = true;
      finalValidation = false;
    }
    if (user.passwordconf !== user.password) {
      passconferror.val = true;
      passconferror.message = "Passwords do not match.";
      disabledSubmit = true;
      finalValidation = false;
    }

    if (user.name.length < 3) {
      nameerror.val = true;
      nameerror.message = "Please enter a Name.";
      disabledSubmit = true;
      finalValidation = false;
    }
    if (!validation) {
      disabledSubmit = false;
      emailerror = { val: false, message: "" };
      passerror = { val: false, message: "" };
      passconferror = { val: false, message: "" };
      phoneerror = { val: false, message: "" };
      nameerror = { val: false, message: "" };
    }

    let showbutton;
    showbutton = (
      <Button
        block
        appearance="ghost"
        style={{ boxShadow: "0px 0px 3px black" }}
        onClick={() => this.props.history.push("/auth")}
      >
        <i>Already have an account? Sign In Here.</i>
      </Button>
    );

    let emaildata = (
      <div>
        <TextField
          error={emailerror.val}
          helperText={emailerror.val && emailerror.message}
          variant="standard"
          margin="normal"
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          disabled={userStore.loading}
          autoFocus
          value={userStore.newuser.email}
          onChange={(e) => userStore.editnewuser("email", e.target.value)}
          style={{ background: "rgb(255,255,255,0)" }}
        />
        <TextField
          error={passerror.val}
          helperText={passerror.val && passerror.message}
          variant="standard"
          margin="normal"
          fullWidth
          disabled={userStore.loading}
          name="password"
          label="Password"
          type={this.state.showPassword ? "text" : "password"}
          id="password"
          autoComplete="current-password"
          value={userStore.newuser.password}
          style={{ background: "rgb(255,255,255,0)" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  tabindex="-1"
                  aria-label="toggle password visibility"
                  onMouseOver={() => this.setState({ showPassword: true })}
                  onMouseLeave={() => this.setState({ showPassword: false })}
                >
                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => userStore.editnewuser("password", e.target.value)}
        />
        <TextField
          error={passconferror.val}
          helperText={passconferror.val && passconferror.message}
          variant="standard"
          margin="normal"
          fullWidth
          disabled={userStore.loading}
          name="passwordconf"
          label="Confirm Password"
          type={this.state.showPasswordConf ? "text" : "password"}
          id="passwordconf"
          autoComplete="current-passwordconf"
          value={userStore.newuser.passwordconf}
          style={{ background: "rgb(255,255,255,0)" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" tabindex="-1">
                <IconButton
                  tabindex="-1"
                  aria-label="toggle password visibility"
                  onMouseOver={() => this.setState({ showPasswordConf: true })}
                  onMouseLeave={() =>
                    this.setState({ showPasswordConf: false })
                  }
                >
                  {this.state.showPasswordConf ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) =>
            userStore.editnewuser("passwordconf", e.target.value)
          }
        />
        <TextField
          error={nameerror.val}
          helperText={nameerror.val && nameerror.message}
          variant="standard"
          margin="normal"
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoComplete="name"
          disabled={userStore.loading}
          value={userStore.newuser.name}
          onChange={(e) => userStore.editnewuser("name", e.target.value)}
          style={{ background: "rgb(255,255,255,0)" }}
        />

        <br />
        <Button
          block
          color={!userStore.loading && "green"}
          style={{ boxShadow: "0px 0px 3px black" }}
          disabled={disabledSubmit}
          onClick={() => {
            let userData = {
              email: user.email,
              password: user.password,
              name: user.name,
              validation: finalValidation,
              referralcode: this.props.match.params.referralId,
            };
            userStore.registerReferredUser(userData);
          }}
        >
          {userStore.loading ? "Loading ..." : "Sign Up"}
        </Button>
      </div>
    );

    return (
      <Parallax
        blur={0}
        bgImage={"https://source.unsplash.com/1600x900/?car,garage"}
        bgImageAlt="Background Image"
        strength={150}
        style={{
          minHeight: 800,
          boxShadow: "10px 10px 70px -50px rgba(0,0,0,0.75)",
        }}
      >
        <div
          style={{
            minHeight: 800,
            width: "100%",
          }}
        >
          <div style={insideStyles}>
            <Container style={{ alignSelf: "center" }}>
              <Paper
                elevation={10}
                style={{
                  background:
                    "radial-gradient(circle, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 100%)",
                  borderRadius: 10,
                  padding: 30,
                  textAlign: "center",
                  maxWidth: 400,
                }}
              >
                <h6>
                  Referred by: <strong>{userStore.referral.name}</strong>
                </h6>
                <p>
                  {userStore.referral.name} and yourself will both gain credit
                  after sign up.
                </p>
                <Divider>
                  <h5>Signup Form</h5>
                </Divider>
                {mainButtons}
                {this.state.email ? emaildata : <PhoneRefer />}
                <hr />
                {showbutton}
                <hr />
                <GoogleAllAuth
                  authType="referral"
                  referralcode={this.props.match.params.referralId}
                />
              </Paper>
            </Container>
          </div>
        </div>
      </Parallax>
    );
  }
}

export default withRouter(observer(ReferralPage));
