import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import { CardMedia, Typography, Chip } from "@material-ui/core";
import React, { Component } from "react";
import { Col, FlexboxGrid, Row } from "rsuite";
import PaperSTD from "../PaperSTD/PaperSTD";
import Confidential from "../ConfidentialLock/Confidential";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import TimerIcon from "@material-ui/icons/Timer";

class OpenQuoteCards extends Component {
  render() {
    let quote = this.props.quote;
    let myquote = this.props.myquote;
    let showdetails = this.props.showdetails;
    let bidinformation;

    if (myquote) {
      return (
        <PaperSTD title="My Quote">
          <FlexboxGrid>
            <Col md={8} sm={12} xs={24}>
              <CardMedia
                component="img"
                alt={quote.business.name}
                height="100"
                image={quote.business.logo}
                title={quote.business.name}
              />
            </Col>
            <Col md={16} sm={12} xs={24}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {quote.price} KD for {quote.duration} hours
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {quote.remarks}
                </Typography>
              </CardContent>
            </Col>
          </FlexboxGrid>
        </PaperSTD>
      );
    }

    if (this.props.approved) {
      if (showdetails) {
        bidinformation = (
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              <strong>Bid by: {quote.business.name}</strong>
            </Typography>
            <Typography variant="h5" component="h2">
              {quote.price} KD for {quote.duration} hours
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {quote.remarks}
            </Typography>
          </CardContent>
        );
      } else {
        bidinformation = (
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              <strong>Bid by: {quote.business.name}</strong>
            </Typography>
            <Typography variant="h6" component="h4">
              <Confidential />
            </Typography>
          </CardContent>
        );
      }
    } else {
      if (showdetails) {
        bidinformation = (
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              <strong>Bid by: {quote.business.name}</strong>
            </Typography>
            <Typography variant="h6" component="h4">
              {quote.price} KD for {quote.duration} hours
            </Typography>
            {this.props.cheapest && (
              <Chip
                variant="outlined"
                label="Lowest Price"
                size="small"
                icon={<AttachMoneyIcon />}
                style={{
                  backgroundColor: "#78ff7f",
                  boxShadow: "0px 0px 2px black",
                }}
              />
            )}
            {this.props.fastest && (
              <Chip
                variant="outlined"
                label="Fastest"
                size="small"
                icon={<TimerIcon />}
                style={{
                  backgroundColor: "#78ff7f",
                  boxShadow: "0px 0px 2px black",
                }}
              />
            )}
            <Typography variant="body2" color="textSecondary" component="p">
              {quote.remarks}
            </Typography>
          </CardContent>
        );
      } else {
        bidinformation = (
          <CardContent>
            <FlexboxGrid>
              <Typography gutterBottom variant="h6" component="h4">
                <Confidential />
              </Typography>
              <Col md={16} sm={12} xs={24}>
                <Typography variant="body2" color="textSecondary" component="p">
                  <strong>Bid by: {quote.business.name}</strong>
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Bid Details Confidential
                </Typography>
              </Col>
            </FlexboxGrid>
          </CardContent>
        );
      }
    }

    if (this.props.approved) {
      return (
        <div>
          <Card style={{ width: "100%" }}>
            <Col md={16} sm={12} xs={24}>
              {bidinformation}
            </Col>
            <Col md={8} sm={12} xs={24}>
              <CardMedia
                component="img"
                alt={quote.business.name}
                height="100"
                image={quote.business.logo}
                title={quote.business.name}
              />
            </Col>
            <CardActions>
              {showdetails && (
                <Button size="small" onClick={() => alert("Show Quote")}>
                  Quote Details
                </Button>
              )}
              <Button
                size="small"
                onClick={() =>
                  this.props.history.push(`/business/${quote.business._id}`)
                }
              >
                Company Page
              </Button>
            </CardActions>
          </Card>
        </div>
      );
    }

    return (
      <Col md={8} sm={12} xs={24}>
        <Card style={{ width: "100%" }}>
          <CardMedia
            component="img"
            alt={quote.business.name}
            height="100"
            image={quote.business.logo}
            title={quote.business.name}
          />
          {bidinformation}
          <CardActions>
            {showdetails && (
              <Button size="small" onClick={() => alert("Show Quote")}>
                Quote Details
              </Button>
            )}
            <Button
              size="small"
              onClick={() =>
                this.props.history.push(`/business/${quote.business._id}`)
              }
            >
              Company Page
            </Button>
          </CardActions>
        </Card>
      </Col>
    );
  }
}

export default withRouter(observer(OpenQuoteCards));
