import { makeObservable, observable } from "mobx";
import axios from "axios";
import userStore from "./UserStore";
import baseURL from "../Media/baseURL";

const instance = axios.create({ baseURL: baseURL });

class AdminStore {
  constructor() {
    this.pendingrequests = [];
    this.request = {
      service: { title: "" },
      description: "",
      year: "",
      make: "",
      model: "",
      closedate: null,
      adminfeedback: "",
    };

    makeObservable(this, {
      pendingrequests: observable,
    });
  }

  resetRequest() {
    this.request = {
      service: { title: "" },
      description: "",
      year: "",
      make: "",
      model: "",
      closedate: null,
      adminfeedback: "",
    };
  }

  closeLoading = async () => {
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve("r");
      }, 0);
    });
    this.loading = false;
  };

  selectRequest(request) {
    this.request = request;
    this.request.adminfeedback = "";
  }
  editfeedback(val) {
    this.request.adminfeedback = val;
  }

  fetchPendingRequests() {
    this.loading = true;
    return instance
      .get(`/api/request/pendingadmin/`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((requests) => {
        this.pendingrequests = requests;
        this.closeLoading();
        console.log("Admin Pending Requests Fetched");
      })
      .catch((err) => {
        console.log(err);
        this.closeLoading();
        console.error(err.response);
        alert("Error Fetching User Requests");
      });
  }
}

const adminStore = new AdminStore();
export default adminStore;
