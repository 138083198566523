import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton, Notification } from "rsuite";
import { Button as NewButton } from "rsuite";
import BusPostImages from "../ImageParallax/BusPostImages";
import busPostStore from "../../Stores/BusPostStore";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Card, Avatar, Tooltip, Button } from "antd";
import { LikeOutlined } from "@ant-design/icons";

import { WhatsappShareButton, WhatsappIcon } from "react-share";
import userStore from "../../Stores/UserStore";

const { Meta } = Card;

class SinglePostAntd extends Component {
  constructor(props) {
    super(props);
    this.state = { less: true };
  }
  render() {
    let postId;
    if (this.props.postId) postId = this.props.postId;
    let postNumId;
    if (this.props.postNumId) postNumId = this.props.postNumId;
    let text = this.props.text;
    let medias = this.props.medias;
    let disable = this.props.disable;
    let liked = this.props.liked;
    let small = this.props.small;

    let stl = { maxWidth: 500, maxText: 80 };
    if (this.props.small) {
      stl = { maxWidth: 200, maxText: 40 };
    }

    let postactions = [
      <div
        onClick={() => {
          if (userStore.signedIn) {
            busPostStore.likePost(postId, !liked);
          } else {
            Notification["info"]({
              title: `Please Sign In`,
              description: (
                <div>
                  Please sign in to interact with the post.
                  <hr />
                  <NewButton
                    block
                    color="green"
                    onClick={() => this.props.history.push("/auth")}
                  >
                    Sign In
                  </NewButton>
                </div>
              ),
            });
          }
        }}
      >
        <Tooltip title="Like">
          {this.props.liked ? (
            <Button
              shape="circle"
              icon={<LikeOutlined style={{ color: "white" }} />}
              style={{ backgroundColor: "green" }}
            />
          ) : (
            <Button
              style={{ color: "black" }}
              ghost
              shape="circle"
              icon={<LikeOutlined />}
            />
          )}
        </Tooltip>
      </div>,

      <Tooltip title="Share">
        <WhatsappShareButton
          url={`gakw.app/p/${postNumId}`}
          title={`New post by ${this.props.business.name}:`}
        >
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
      </Tooltip>,
    ];

    if (userStore.user.business === this.props.business._id) {
      postactions.push(
        <Tooltip title="Edit Post">
          <IconButton
            icon={<Icon icon="edit" />}
            appearance="ghost"
            color="red"
            onClick={() => this.props.history.push(`/editpost/${postId}`)}
          />
        </Tooltip>
      );
    }

    return (
      <FlexboxGrid
        justify="center"
        style={{
          margin: 5,
          marginTop: 20,
        }}
      >
        <div
          style={{
            maxWidth: stl.maxWidth,
            borderTopRightRadius: 5,
            borderTopLeftRadius: 5,
            boxShadow: "0px 0px 5px black",
            border: "1px solid black",
          }}
        >
          <Card
            style={{
              maxWidth: 500,
              borderRadius: 10,
            }}
            cover={<BusPostImages medias={medias} play={false} hide={small} />}
            actions={!this.props.small && postactions}
          >
            <Meta
              avatar={
                <Avatar
                  size="small"
                  src={this.props.business.logo}
                  onClick={() =>
                    this.props.history.push(
                      `/business/${this.props.business._id}`
                    )
                  }
                />
              }
              title={
                <div
                  style={{ whiteSpace: "normal" }}
                  onClick={() =>
                    this.props.history.push(
                      `/business/${this.props.business._id}`
                    )
                  }
                >
                  {this.props.business.name}
                </div>
              }
              description={
                (text.length > stl.maxText) &
                this.state.less &
                !this.state.small ? (
                  <div style={{ whiteSpace: "normal" }}>
                    {text.substring(0, stl.maxText - 1)}...
                    <i
                      style={{ color: "blue" }}
                      onClick={() => {
                        if (!small) {
                          this.setState({ less: false });
                        }
                      }}
                    >
                      more
                    </i>
                  </div>
                ) : (
                  <div style={{ whiteSpace: "normal" }}>{text}</div>
                )
              }
            />
          </Card>
        </div>
      </FlexboxGrid>
    );
  }
}

export default withRouter(observer(SinglePostAntd));
