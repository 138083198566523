import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";

class BusinessCard extends Component {
  render() {
    let business = this.props.business;
    let servtitles = [];
    for (let i = 0; i < business.services.length; i++) {
      servtitles.push(business.services[i].title);
    }
    return (
      <Card
        style={{
          width: 300,
          borderRadius: 10,
          boxShadow: "0px 0px 10px black",
          margin: 10,
        }}
      >
        <CardActionArea
          onClick={() => this.props.history.push(`/business/${business._id}`)}
        >
          <CardMedia
            image={business.logo}
            title={business.name}
            style={{ height: 175 }}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {business.name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              <strong>Services:</strong>{" "}
              {servtitles.length > 0 && servtitles.join(", ")}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}

export default withRouter(observer(BusinessCard));
