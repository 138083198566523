import { computed, makeObservable, observable } from "mobx";
import baseURL from "../Media/baseURL";
import axios from "axios";
import userStore from "./UserStore";
import { message } from "antd";
import businessStore from "./BusinessStore";

const instance = axios.create({ baseURL: baseURL });

class BusPostStore {
  constructor() {
    this.newpost = { description: "", medias: [] };
    this.postloadpage = 1;
    this.allposts = [];
    this.singlepost = {};
    this.loading = false;
    this.editpost = { description: "" };
    this.dailyposts = 0;

    makeObservable(this, {
      newpost: observable,
      postloadpage: observable,
      allposts: observable,
      loading: observable,
      singlepost: observable,
      editpost: observable,
      dailyposts: observable,
    });
  }

  fetchEditPost(postId) {
    this.loading = true;
    return instance
      .get(`/api/businesspost/single/${postId}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((posts) => {
        if (posts.length > 0) {
          this.editpost = posts[0];
          this.newpost = posts[0];
        }
      })
      .then(() => (this.loading = false))
      .catch((err) => {
        this.loading = false;
        console.log(err);
        console.error(err.response);
        // alert("Error Fetching All Received Messages");
      });
  }

  resetNewPost() {
    this.newpost = { description: "", medias: [] };
    this.singlepost = {};
  }

  addPhoto(event) {
    this.newpost.medias.push(event);
  }

  getDailyPosts() {
    return instance
      .get(`/api/businesspost/business/daily`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((posts) => {
        this.dailyposts = posts.total;
      })
      .catch((err) => {
        console.log(err);
        console.error(err.response);
        // alert("Error Fetching All Received Messages");
      });
  }

  newpostText(val) {
    this.newpost.description = val;
  }
  editpostText(val) {
    this.editpost.description = val;
  }

  removePhoto(photo) {
    let files = [...this.newpost.medias];
    let final = files.filter((file) => file !== photo);
    this.newpost.medias = final;
  }

  likePost(postId, like) {
    let indexVal = this.allposts.findIndex((post) => post._id === postId);
    this.allposts[indexVal].liked = like;
    return instance
      .put(`/api/businesspost/likepost/${postId}`, null, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((post) => {
        console.log(post);
        this.allposts[indexVal] = post;
      })
      .catch((err) => {
        this.allposts[indexVal].liked = !like;
        console.log(err);
        console.error(err.response);
        message.error("An issue occured");
        // alert("Error Fetching All Received Messages");
      });
  }

  fetchAllPosts() {
    this.postloadpage = 1;
    this.loading = true;
    return instance
      .get(`/api/businesspost/all?page=${this.postloadpage}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((posts) => {
        this.allposts = posts;
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
        console.error(err.response);
        // alert("Error Fetching All Received Messages");
      });
  }
  fetchSinglePost(postId) {
    this.loading = true;
    return instance
      .get(`/api/businesspost/single/${postId}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((posts) => {
        this.singlepost = posts[0];
        this.loading = false;
        businessStore.getSingleBusinessPost(this.singlepost.business._id);
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
        console.error(err.response);
        // alert("Error Fetching All Received Messages");
      });
  }
}

const busPostStore = new BusPostStore();
export default busPostStore;
