import React, { Component } from "react";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import requestStore from "../../../../Stores/RequestStore";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import makeList from "../../../../Media/Static/CarMakes";
import modelList from "../../../../Media/Static/CarModels";
import Years from "../../../../Media/Static/Years";
import { Button, SelectPicker } from "rsuite";
import carStore from "../../../../Stores/CarStore";

class Step1 extends Component {
  render() {
    let yearfinal = [];
    let modelfinal = [];
    let makefinal = [];
    for (let i in Years) {
      yearfinal.push({ label: Years[i], value: Years[i] });
    }

    let request = this.props.request;

    let models;
    if (request.make.length > 0) {
      models = modelList.filter(
        (car) => car.make.toLowerCase() === request.make.toLowerCase()
      );
    } else {
      models = modelList;
    }

    for (let i in models) {
      modelfinal.push({ label: models[i].model, value: models[i].model });
    }
    for (let i in makeList) {
      makefinal.push({ label: makeList[i], value: makeList[i] });
    }

    let myCars = carStore.cars.map((car, index) => (
      <Button
        style={{ margin: 5 }}
        size="sm"
        appearance="ghost"
        color="green"
        key={index}
        onClick={() => {
          if (this.props.type === "Edit") {
            requestStore.editEditRequest("make", car.make);
            requestStore.editEditRequest("model", car.model);
            requestStore.editEditRequest("year", parseFloat(car.year));
          } else {
            requestStore.editNewRequest("make", car.make);
            requestStore.editNewRequest("model", car.model);
            requestStore.editNewRequest("year", parseFloat(car.year));
          }
        }}
      >{`${car.year} ${car.make} - ${car.model}`}</Button>
    ));
    return (
      <div>
        {myCars}
        <hr />
        <div style={{ textAlign: "left" }}>
          <h6>Make:</h6>
          <SelectPicker
            size="lg"
            block
            appearance="subtle"
            placeholder="Make"
            data={makefinal}
            onChange={(e) => requestStore.editNewRequest("make", e)}
            value={request.make}
          />
          <br />
          <h6>Model:</h6>
          <SelectPicker
            size="lg"
            block
            appearance="subtle"
            placeholder="Model"
            data={modelfinal}
            onChange={(e) => requestStore.editNewRequest("model", e)}
            value={request.model}
          />
          <br />
          <h6>Year:</h6>
          <SelectPicker
            size="lg"
            block
            appearance="subtle"
            placeholder="Year"
            data={yearfinal}
            onChange={(e) => requestStore.editNewRequest("year", e)}
            value={request.year}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(observer(Step1));
