import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import moment from "moment";
import { Avatar, Card, CardActionArea, Typography } from "@material-ui/core";
import userStore from "../../../Stores/UserStore";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { Col, FlexboxGrid } from "rsuite";
import { Badge } from "antd";
import CheckIcon from "@material-ui/icons/Check";
import DoneAllIcon from "@material-ui/icons/DoneAll";

class MessageView extends Component {
  constructor(props) {
    super(props);
    this.state = { value: false };
  }

  render() {
    let convo = this.props.convo;

    let lastletter = convo.lastletter;

    let avatarCurrent = (
      <Avatar>
        {lastletter.user ? lastletter.user.name.substring(0, 1) : "?"}
      </Avatar>
    );
    let avatarOther = (
      <Avatar alt={lastletter.business.name} src={lastletter.business.logo} />
    );

    return (
      <div>
        <Card variant="outlined" style={{ margin: 3, paddingLeft: 10 }}>
          <CardActionArea onClick={() => this.props.selectConvo(convo)}>
            <FlexboxGrid>
              <Col xs={8} sm={4} md={4} lg={2}>
                <AvatarGroup max={2}>
                  {avatarOther}
                  {avatarCurrent}
                </AvatarGroup>
                <p style={{ fontSize: 10 }}>
                  {moment(lastletter.createdAt).fromNow()}
                </p>
              </Col>
              <Col xs={16} sm={20} md={20} lg={22}>
                <Typography
                  style={{ fontSize: 14 }}
                  color="textSecondary"
                  gutterBottom
                >
                  {lastletter.seenreceiver ? (
                    <DoneAllIcon fontSize="small" />
                  ) : (
                    <CheckIcon fontSize="small" />
                  )}
                  {convo.subject}
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  style={{
                    overflow: "hidden",
                  }}
                >
                  <Badge
                    count={convo.newletters}
                    style={{ backgroundColor: "#52c41a" }}
                  />{" "}
                  <strong>
                    {lastletter.sender === "Customer"
                      ? lastletter.user
                        ? lastletter.user.name
                        : "Old User"
                      : lastletter.business.name}
                    :
                  </strong>{" "}
                  <i>
                    {lastletter.content.length > 50
                      ? `${lastletter.content.substring(0, 50 - 1)}...`
                      : lastletter.content}
                  </i>
                </Typography>
              </Col>
            </FlexboxGrid>
          </CardActionArea>
        </Card>
      </div>
    );
  }
}

export default withRouter(observer(MessageView));
